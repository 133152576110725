import React, { useEffect, useState } from 'react';

export const AnalogClock = (props) => {
  const { time } = props
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  const secondDegrees = (seconds / 60) * 360;
  const minuteDegrees = ((minutes + seconds / 60) / 60) * 360;
  const hourDegrees = ((hours % 12 + minutes / 60) / 12) * 360;

  const styles = {
    clock: {
      width: '300px',
      height: '300px',
      position: 'relative',
      backgroundColor: '#1a1a1a',
      borderRadius: '38px',
      padding: '20px',
    },
    face: {
      width: '100%',
      height: '100%',
      backgroundColor: '#f5f5f5',
      borderRadius: '50%',
      position: 'relative',
    },
    number: {
      position: 'absolute',
      fontSize: '28px',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif',
      fontWeight: '500',
      color: '#333',
      textShadow: '0px 1px 2px rgba(0,0,0,0.25)',
      transform: 'translate(-50%, -50%)',
      width: '30px',
      height: '30px',
      textAlign: 'center',
      lineHeight: '30px',
    },
    hand: {
      position: 'absolute',
      bottom: '50%',
      left: '50%',
      transformOrigin: 'bottom',
      borderRadius: '4px',
      backgroundColor: '#333',
      boxShadow: '0px 1px 2px rgba(0,0,0,0.3)',
    },
    secondHand: {
      width: '2px',
      height: '45%',
      backgroundColor: '#ff8c00',
      transform: `translateX(-50%) rotate(${secondDegrees}deg)`,
      zIndex: 3,
    },
    minuteHand: {
      width: '4px',
      height: '40%',
      transform: `translateX(-50%) rotate(${minuteDegrees}deg)`,
      zIndex: 2,
    },
    hourHand: {
      width: '4px',
      height: '30%',
      transform: `translateX(-50%) rotate(${hourDegrees}deg)`,
      zIndex: 1,
    },
    centerDot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '8px',
      height: '8px',
      backgroundColor: '#ff8c00',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 4,
    },
  };

  const clockRadius = 130;
  const clockCenter = {
    x: 130,
    y: 130
  };

  const numbers = Array.from({ length: 12 }, (_, i) => {
    const number = i + 1;
    const angle = (number * 30 - 90) * (Math.PI / 180);
    const radius = 110;
    const x = Math.cos(angle) * radius + clockCenter.x;
    const y = Math.sin(angle) * radius + clockCenter.y;

    return (
      <div
        key={number}
        style={{
          ...styles.number,
          left: `${x}px`,
          top: `${y}px`,
        }}
      >
        {number}
      </div>
    );
  });

  return (
    <div style={styles.clock}>
      <div style={styles.face}>
        {numbers}
        <div style={{ ...styles.hand, ...styles.hourHand }} />
        <div style={{ ...styles.hand, ...styles.minuteHand }} />
        <div style={{ ...styles.hand, ...styles.secondHand }} />
        <div style={styles.centerDot} />
      </div>
    </div>
  );
};


