import React, { Component } from 'react'
import './GoogleSearchResults.css'

export const GoogleSearchResults = props => {
  const { results, renderMarkdown, openLink, q } = props
  return <div className='googleSearch'>
           <div className='fakeInputFieldContainer'>
             <div className='fakeInputField'>
               {q}
             </div>
           </div>
           <div className="google-results">
             {results.map((result, index) => {
               let url = result.url
               const h = url.indexOf('#')
               if (h > 0) {
                 url = url.substring(0, h)
               }
               return <div key={index} className="result-item">
                        <span className="result-url">{url}</span>
                        <div className className="aiLink result-title" onClick={e => openLink(url)}>
                          {result.title}
                        </div>
                        <div className="result-snippet">{renderMarkdown(result.snippet)}</div>
                      </div>
             })}
           </div>
         </div>
}

