import { BehaviorSubject, Subject, concat, from, of } from 'rxjs';
import { map, filter, scan, share } from 'rxjs/operators';

const getReplies = message => {
  if (message.role === 'assistant') {
    if (message.model) {
      return [message].concat(message.models || [])
    }
    return message.models || []
  }
  return []
}

/**
 * CanvasInterpreter - Core class that observes the transcript stream
 * and emits editor commands as an Observable
 */
class CanvasInterpreter {

  constructor() {
    // Subject for emitting editor commands
    this.commandSubject = new Subject();
        
    
    // Current available files
    this.filesSubject = new BehaviorSubject([]);
  }

  // Internal file state management
  fileStates = new Map();
  webBrowserStates = new Map();


  commands = []
  sendCommand = command => {
    this.commands.push(command)
    this.commandSubject.next(command)
  }

  observeCommands = () => {
    return concat(from(this.commands), this.commandSubject)
  }
  
  /**
   * Process the transcript observable to extract and handle tool calls
   * @param {Observable} transcript$ - Observable of messages
   */
  processTranscript(transcript$) {
    // Subscribe to the transcript and process messages
    return transcript$.subscribe(message => {
      const { role, ts } = message
      debugger
      if (message.tool_calls && Array.isArray(message.tool_calls)) {
        message.tool_calls.forEach(call => this.processToolCall(call));
      } else if (message.role === 'tool') {
        this.processToolResult(message)
      }
    })
  }


  /**
   * Process a tool call and emit appropriate commands
   * @param {Object} functionCall - Function call object
   */
  toProcess = {}
  processed = {}
  processToolResult = message => {
    const { tool_call_id } = message
    const call = this.toProcess[tool_call_id]
    this.processToolCall(call, message)
  }

  processToolCall = (functionCall, result) =>{
    const { id } = functionCall
    this.toProcess[id] = functionCall
    const { name, arguments: argsString } = functionCall.function;
    let args;
    try {
      args = JSON.parse(argsString || '{}');
    } catch (e) {
      console.error('Failed to parse tool call arguments:', e);
      return;
    }
    switch (name) {
      case 'evaluate':
      case 'navigate':
      case 'goBack':
      case 'goForward':
      case 'close':
        {
          let { url, target } = args
          if (url) {
            this.webBrowserStates.set(target, url)
            this.sendCommand({
              type: 'WEB_PAGE_NAVIGATE',
              target,
              url
            })
          }
        }
        break;
      case "read_text_file":
        // Emit read command with current content
        if (result) {
          const { content } = JSON.parse(result.content)
          this.fileStates.set(args.path, content)
          this.sendCommand({
            type: 'UPDATE_FILE',
            path: args.path,
            content: content
          });
        }
        break;
        
      case "write_text_file":
        const isNewFile = !this.fileStates.has(args.path);
        
        // Update internal state
        this.fileStates.set(args.path, args.content);
        
        // If this is a new file, emit file creation command
        if (isNewFile) {
          this.sendCommand({
            type: 'CREATE_FILE',
            path: args.path,
            content: args.content
          });
          
          // Update available files
          this.updateAvailableFiles();
        } else {
          // Otherwise emit update command
          this.sendCommand({
            type: 'UPDATE_FILE',
            path: args.path,
            content: args.content
          });
        }
        break;
        
      case "edit_text_file":
        const oldContent = this.fileStates.get(args.path) || '';
        if (!oldContent) {
          console.error(`Cannot edit non-existent file: ${args.path}`);
          return;
        }
        
        const newContent = oldContent.replace(args.old_string, args.new_string);
        
        // Update internal state
        this.fileStates.set(args.path, newContent);
        
        // Emit update command
        this.sendCommand({
          type: 'UPDATE_FILE',
          path: args.path,
          content: newContent
        });
        break;
    }
  }

  /**
   * Update available files list
   */
  updateAvailableFiles() {
    const files = Array.from(this.fileStates.keys());
    this.filesSubject.next(files);
  }


  /**
   * Get observable of available files
   * @returns {Observable} - Observable of file paths
   */
  observeAvailableFiles() {
    const files = Array.from(this.fileStates.keys())
    return concat(of(files), this.filesSubject)
  }

  /**
   * Get the commands observable
   * @returns {Observable} - Observable of editor commands
   */
  getCommands$() {
    return this.commands$;
  }

  /**
   * Clean up resources
   */
  dispose() {
    this.fileStates.clear();
    this.commandSubject.complete();
    this.filesSubject.complete();
  }
}

export default CanvasInterpreter;
