import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { parsePhoneNumber, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { SimpleButton, DeleteButton, FileChooser } from '../ChatGPT2/SimpleButton.js'
import { getComponents, pasteText, SimpleIcon, GearButton, SearchField} from '../ChatGPT2'
import { Markdown } from '../ChatGPT2/Markdown.js'
import { fromNow, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay, scrollOnKeyDown} from '../../classes/Util.js'
import { InfiniteScroll } from '../Scrolling'
import { scrollIntoViewIfNeeded } from '../Scrolling/ScrollIntoView'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import HF from '../../assets/Icons/Platforms/HuggingFace.svg'
import Gear from '../../assets/Icons/Settings.svg'
import Search from '../../assets/Icons/Search.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Right from '../../assets/Icons/Forward.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Copy from '../../assets/Icons/Copy.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import HuggingFace from '../../assets/Icons/Platforms/HuggingFace.svg'
import ToolServerIcon from '../../assets/Icons/ToolServer.svg'
import Paste from '../../assets/Icons/Paste.svg'
import Edit from '../../assets/Icons/Edit.svg'
import Cut from '../../assets/Icons/Share.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from '../Home/Usage.js'
import { HuggingFaceDatasets } from '../Home/Datasets.js'
import { ActionMenu } from '../Home/ActionMenu.js'
import { doRenderTool, toCode } from './RenderTool.js'
import { ToolConfigForm } from './ToolConfig.js'
import Update from '../../assets/Icons/Update.svg'
import Spin from '../../assets/Icons/Spin.svg'
import './index.css'

let selectedToolset


const createReactFileLinks = (data, opts) => {
  const {className, onClick} = opts
  // Regex that matches:
  // - Absolute paths that either have at least one extra slash (i.e. two or more slashes total)
  //   OR a root-level file that includes an extension.
  // - Optionally, a colon with a line number, and optionally another colon with a column number.
  //const regex = /(\/(?:[^:\s\/"'\[\]]+\/)+[^:\s\/"'\[\]]+|\/[^:\s\/"'\[\]]+\.[^:\s\/"'\[\]]+)(?::(\d+))?(?::(\d+))?/g;
  const regex = /(?:(?<=^)|(?<=[^A-Za-z0-9:]))(\/(?:[^:\s\/"'\[\]]+\/)+[^:\s\/"'\[\]]+|\/[^:\s\/"'\[\]]+\.[^:\s\/"'\[\]]+)(?::(\d+))?(?::(\d+))?/g;

  
  const parts = [];
  let lastIndex = 0;
  let match;
  
  while ((match = regex.exec(data)) !== null) {
    // Push any text before the current match.
    if (match.index > lastIndex) {
      parts.push(data.slice(lastIndex, match.index));
    }
    
    const filePath = match[1];
    const line = match[2];
    const column = match[3];
    let display = filePath;
    let link = `vscode://file/${filePath}`;
    
    if (line) {
      display += `:${line}`;
      link += `:${line}`;
      if (column) {
        display += `:${column}`;
        link += `:${column}`;
      }
    }
    
    // Create a React <a> element with a unique key.
    display = display.split('/').join('/\u200B')
    parts.push(
      <div onClick={() => onClick(link)} className={className} key={match.index} href={link} target="_blank" rel="noopener noreferrer">
        {display}
      </div>
    );
    
    lastIndex = regex.lastIndex;
  }
  
  // Append any remaining text after the last match.
  if (lastIndex < data.length) {
    parts.push(data.slice(lastIndex));
  }
  
  return parts;
};




const SimpleInput = props => {
  const { form, formErr, onChange, name, type, autocomplete, label, placeholder } = props
  return <div cassName='simpleInput'>
           <div className='simpleInputTitle'>{label || placeholder}</div>
           <BnInputField  name={name} label={placeholder} formErr={formErr} form={form} type={type} onChange={onChange} autoComplete={autocomplete} busy={form.busy}/>
         </div>
}
  

export class ToolServer extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.endpointStatus = {}
    this.state.pagination = {
      offset: 0,
      limit: 20
    }
  }

  renderHeader() {
    const onSearch = searchTerm => {
    }
    const refresh = async() => {
      const { offset, limit } = this.state.pagination
      await this.getLogs(0, limit)
    }
    return <div className='toolsetPageHeader'>
             <SimpleButton icon={this.state.busy ? Spin : Update} action={refresh}/><SearchField onSearch={onSearch}/>
           </div>
  }
  
  componentDidMount() {
    let { servers, paths, toolset, name, auth, args, env } = this.props.toolServer
    if (!args) args = []
    if (!env) env = {}
    servers = servers || []
    this.set('name', name)
    this.set('auth', auth || '')
    this.set('endpoint', servers.length > 0 ? servers[0].url : '')
    this.loadToolset()
    if (servers) {
      for (const server of servers) {
        const { url } = server
        this.state.endpointStatus[url] = {
          status: 'unavailable',
        }
        this.monitorEndpoint(url)
      }
    }
    this.getLogs(0, 20)
  }

  logs = {}

  getLogs = async (offset, limit) => {
    this.state.busy = true
    this.forceUpdate()
    const { logs } = await this.props.me.getToolServerLogs({toolServer: this.props.toolServer.id, offset, limit})
    for (const log of logs) {
      this.logs[log.id] = log
    }
    this.state.busy = false
    this.updateLogs()
  }

  updateLogs = () => {
    const logs = Object.values(this.logs).filter(x => (x.stdout || x.stderr))
    logs.sort((x, y) => {
      return y.ts - x.ts
    })
    this.setState({
      logs
    })
  }
  
  endpoints = {}

  checkEndpoint = async (url) => {
    try {
      const response = await fetch(url + "/openapi.json")
      this.state.endpointStatus[url] = {
        status: 'available',
        spec: JSON.stringify(await response.json(), null, '       ')
      }
    } catch (err) {
      console.error(err)
      this.state.endpointStatus[url].status = 'unavailable' 
    }
    this.forceUpdate()
  }

  monitorEndpoint = url => {
    if (url.startsWith("desktop")) {
      this.state.endpointStatus[url] = {
        status: this.props.toolServer.status,
      }
    } else {
      this.checkEndpoint(url)
      this.endpoints[url] = {
        interval: setInterval(this.checkEndpoint, 60000)
      }
    }
  }

  componentWillUnmount() {
    for (const url in this.endpoints) {
      clearInterval(this.endpoints[url].interval)
    }
    if (this.logsSub) {
      this.logsSub.unsubscribe()
    }
  }

  loadToolset = async () => {
    const { toolset } = this.props.toolServer
    if (toolset) {
      const { id, name } = toolset
      try {
        const toolsets = await this.props.me.loadToolset(id)
        this.setState({
          toolsets,
          tools: toolsets.flatMap(toolset => toolset.tools)
        })
      } catch (err) {
        // probably orphaned: fix me!!!
        //debugger
      }
    }
  }

  renderTools = () => {
    const { tools } = this.state
    if (tools) {
      tools.sort((x, y) => {
        return x.function.name.localeCompare(y.function.name)
      })
      let render = () => <div className='toolServerToolset'>
                           <div className='toolsetServerToolsetTitle'>
                             Tools
                           </div>
                           {tools.map(doRenderTool)}
                       </div>
      return render()
    }
  }

  renderDetail() {
    const output = []
    for (const endpoint in this.state.endpointStatus) {
      const { status, spec } = this.state.endpointStatus[endpoint]
      output.push(<div className='serverStatus'>
                    <div className='serverStatusTop'>
                      <div clasName='serverStatusEndpoint'>
                        {endpoint}
                      </div>
                      <div className='serverStatusStatus'>
                        {status}
                      </div>
                    </div>
                    <div className='serverStatusBottom'>
                      {spec && <Markdown components={this.getComponents()}>{"```\n"+spec+"\n```"}</Markdown>}
                    </div>
                  </div>)
    }
    let onKeyDown
    let autoFocus
    let tabIndex
    const renderLogs = items => {
      return items.map(item => {
        const { stdout, ts, stderr } = item
        const severity = stdout ? "INFO" : "ERROR"
        const timestamp = fromNow(ts)
        let logContent = stdout || stderr
        const onClick = link => {
          this.props.me.openLink(link);
        }
        logContent = createReactFileLinks(logContent, {className: "toolServerLogLink", onClick})
        return <div className='logRecord' key={item.id} data-log-id={item.id}>
                 <div className='logRecordTimestamp'>{timestamp}</div>
                 <div className='logRecordSeverity'>{severity}</div>
                 <div className='logRecordLog'>
                   {logContent}
                 </div>
               </div>
      })
    }
    const items = this.state.logs || []
    return <div className='toolServerDetail'>
             <div className='serverStatuses'>
               {output}
             </div>
             <div className='toolServerLogs'>
               <InfiniteScroll 
                 onCreate={this.setLogScroller}
                 onKeyDown={onKeyDown}
                 autoFocus={autoFocus}
                 tabIndex={tabIndex}
                 getId={item => item.id}
                 getSelector={id => `[data-log-id="${id}"]`}
                 items={items}
                 renderItems={renderLogs}
                 pageSize={50}/>
             </div>
             {!this.state.busy && items.length === 0 && <div className='logInfo'>No log records yet.</div>} 
           </div>
  }

  setLogScroller = ref => {
    this.logScroller = ref
  }

  
  renderContent() {
    const { isSystem, servers, paths, toolset, name, auth, id, toolConfig } = this.props.toolServer
    const save = async () => {
      const { auth, name, endpoint } = this.getForm()
      await this.props.save(id, { auth })
    }
    const selectToolset = () => {
    }
    let toolConfigForm
    if (toolConfig) {
      const { env, args, credentials } = toolConfig
      if (env || args || credentials ) {
        toolConfigForm = <ToolConfigForm toolsetName={name} toolConfig={toolConfig}/>
      }
    }
    const { endpoint } = this.getForm()
    let needsAuth = !(endpoint||"").startsWith("desktop")
    const onChange = isSystem ? undefined: this.onChange
    return <div className='toolServer'>
             <div className='toolServerFields'>
               <div className='toolServerField'>
               <div className='toolServerName'>
                 <SimpleInput form={this.getForm()} onChange={onChange} name='name' placeholder={'Name'}/>
               </div>
               </div>
               <div className='toolServerField'>
               <div className='toolServerEndpoints'>
                 <SimpleInput form={this.getForm()} onChange={onChange} name='endpoint' placeholder={'Server'}/>
               </div>
               </div>
               {needsAuth &&<div className='toolServerField'>
                 <div className='toolServerAuth'>
                 <SimpleInput form={this.getForm()} onChange={onChange} name='auth' placeholder={'Authorization'}/>
                </div>
               </div>}
               <div className='toolServerField'>
                 {!isSystem &&<div className='toolServerButtons'>
                 <SimpleButton label='Save' icon={Save} action={save}/>
                              </div>}
               </div>
               {toolConfigForm}
             </div>
             {isMobile() && this.renderDetail()}
           </div>
  }
}


class Toolset extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
    if (!this.props.chooseToolset) {
      ////debugger
    }
  }

  getComponents = () => {
    if (!this.components) {
      this.components = getComponents()
    }
    return this.components
  }

  editTitle = () => {
    const { title, name} = this.props.toolset
    const displayInfo = this.props.toolset.displayInfo || { heading: name || title }
    const { heading, summary } = displayInfo
    //debugger
    this.setState({
      title: heading,
      editingTitle: true
    }, () => {
      if (this.input) this.input.focus()
    })
  }

  commitEdit = async () => {
    if (this.state.editingTitle) {
      await this.props.saveTitle(this.state.title)
      this.stopEditingTitle()
    }
  }

  stopEditingTitle = () => {
    this.setState({editingTitle: false})
  }

  onChangeTitle = title => {
    this.setState({
      title
    })
  }

  setInput = ref => {
    //debugger
    this.input = ref
    if (ref && isDesktop()) {
      ref.focus()
    }
  }

  render() {
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    const toolset = this.props.toolset
    const { isFolder, isSystem } = toolset
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    let trash = this.props.trash
    let date
    date = fromNow(toolset.lastUpdated)
    let deleteButton = <DeleteButton label='Delete' trash={trash}/>
    let dateComp
    dateComp = <div className='toolsetDate'>{date}</div>
    let components = null
    let { usage } = toolset
    let dollars
    if (usage && usage.total) {
      dollars = '$'+formatPrice(usage.total)
    }
    let { name, tools } = toolset
    console.log("TOOLSET", toolset)
    let title = name
    let onBlur
    if (isMobile()) {
      onBlur = async () => {
        this.commitEdit()
      }
    }
    let onKeyDown
    if (isDesktop()) {
      onKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          this.commitEdit()
        } else if (event.key === 'Escape') {
          event.preventDefault()
          this.stopEditingTitle()
        }
      }
    }
    const action = this.props.open
    
    let contentDiv
    let titleDiv
    
    const displayInfo = this.props.toolset.displayInfo || { heading: title }
    const { heading, summary } = displayInfo
    contentDiv = <Markdown components={this.getComponents()}>{summary}</Markdown>
    let titleEditor
    if (this.state.editingTitle) {
      titleEditor = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={this.stopEditingTitle}>
                      <div className='toolsetTitle'>
                        <input
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          ref={this.setInput}
                          onChange={e=> this.onChangeTitle(e.target.value)}
                          value={this.state.title}/>
                      </div>
                    </ClickAwayListener>
    } else {
      titleDiv = <div className='keyboardMenuItemLabel taskDescriptionLabel'><Markdown components={this.getComponents()}>{heading}</Markdown></div>
    }
    const copy = async () => {
      //debugger
      await this.props.copy(this.props.toolset)
      await delay(0.3)
    }
    const duplicate = async () => {
      await this.props.duplicate(this.props.toolset)
      await delay(0.3)
    }
    const cut = async () => {
      await this.props.cut(this.props.folder, this.props.toolset)
    }
    let actions = this.props.actions || []
    if (this.props.folder) {
      actions.push({
        icon: Cut,
        label: 'Cut',
        action: cut
      })
    }

    actions.push({
      icon: Copy,
      label: 'Duplicate',
      action: duplicate
    })

    actions.push({
      icon: Copy,
      label: 'Copy',
      action: copy
    })
    if (!isSystem) {
      actions.push({
        icon: Edit,
        label: 'Edit',
        action: this.props.edit || this.editTitle
      })
      actions.push({
        button: () => <div className='deleteButtonHolder'>{deleteButton}</div>
      })
    }

    let icon
    let legacyIconSize
    if (isFolder) {
      icon = Folder
    } else {
      icon = Gear
      legacyIconSize = true
    }
    let chooseToolset
    if (this.props.chooseToolset) {
      chooseToolset = this.props.chooseToolset
    }
    const render = this.props.renderTask
    if (render) {
      return render({
        file: toolset,
        draggable: !this.state.editingTitle,
        onDragStart,
        title: toolset.name || toolset.title || (toolset.isFolder ? "New Folder" : this.props.emptyTitle),
        titleEditor,
        dateComp,
        onClick: this.props.open,
        actions,
        action,
        choose: chooseToolset
      })
    }
    return <div
             key={toolset.id}
             draggable={!this.state.editingTitle}
             onDragStart={onDragStart}
             className='toolsetTask'>
             <div className='toolsetTop'>
               <div  className='toolsetLeft'>
                 <div className='toolsetLeftTopRow'>
                   <div className='toolsetLeftTopRowLeft'>
                     <div className='toolsetLeftTopRowLeftIcon'>
	               <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                     </div>
                     {titleDiv}
                   </div>
                   <div className='toolsetTopRowRight'>{dateComp}</div>
                 </div>
                 <div className='toolsetMiddle' onClick={this.props.open}>
                   <div className='toolsetMiddleDescription'>
                     {contentDiv}
                     editing={this.state.editingTitle}
                   </div>
                 </div>             
               </div>
               {actions.length > 0 &&<div className='toolsetRight'>
                                       <ActionMenu className='toolsetActions' actions={actions}/>
                                     </div>}
             </div>
             <div className='toolsetContent'>
               {this.props.renderToolsetBody && this.props.renderToolsetBody(toolset)}
             </div>
             <div className='toolsetBottom'>
               {chooseToolset &&
                <div className='doneButton toolsetSelectButton'>
                  <SimpleButton label="Select" action={chooseToolset}/>
                </div>}
             </div>
           </div>
  }
}

class ToolsetEditor extends BnSubpage {

  setEditor = ref => {
    if (this.editor !== ref) {
      this.editor = ref
    }
  }

  componentDidMount() {
    //this.editor.innerText = this.props.toolset.content
  }
  
  edit = () => {
    this.setState({
      editing: true
    }, () => {
      this.editor.focus()
    })
  }

  stopEditing = () => {
    this.setState({
      editing: false
    })
  }
  
  save = async () => {
    await this.props.save(this.editor ? this.editor.innerText : this.props.toolset.content)
    this.stopEditing()
  }

  componentDidMount() {
    this.setState({
      selectedTool: this.props.toolset.tools && this.props.toolset.tools[0]
    })
  }

  renderHeader() {
    const onSearch = searchTerm => {
    }
    return <div className='toolsetPageHeader'>
             <SearchField onSearch={onSearch}/>
           </div>
  }

  renderDetail() {
    if (this.state.tools) {
      return renderTools()
    }
    return this.renderTool(this.state.selectedTool)
  }

  renderTool = tool => {
    const copy = async () => {
      try {
        navigator.clipboard.writeText(JSON.stringify(this.state.selectedTool, null, ' '))
      } catch (err) {
        console.error(err)
        //console.log(text)
      }
      this.props.copy(this.props.toolset)
      await delay(0.5)
    }
    const trash = async () => {
      await this.props.me.deleteToolset(this.props.toolset.id)
      if (isMobile()) {
        this.props.back()
      } else {
        this.setState({
          renderEditor: null
        })
      }
    }
    const { isSystem } = this.props.toolset
    if (!tool) {
      return <div className='toolsetEditorContainer'>
             </div>
    }
    const isSelected = this.state.selectedTool === tool
    let className = 'toolsetEditorContainer'
    return <div className={className}>
             <div className='toolsetEditorTopRow'>
               <div className='desktopToolsetDetailsTitle'>
                 {tool.function.name}
               </div>
               <div className='toolsetEditorBg'>
                 <div className='toolsetEditorRight'>
                   {!isSystem && <SimpleButton icon={Edit} action={this.edit}/>}
                   <SimpleButton icon={Copy} action={copy}/>
                 </div>
               </div>
               {!isSystem && <div className='toolsetEditorButtons'>
                 <SimpleButton label={'Save'} icon={Save} action={this.save}/>
                 <div className='toolsetDelete'>
                   <DeleteButton trash={trash}/>
                 </div>
                             </div>}
             </div>
             <div className='toolsetEditorBottomRow'>
               {
                 toCode(tool)
               }
             </div>
           </div>
    
  }


  renderContent() {
    console.log('toolset', this.props.toolset)
    const tools = this.props.toolset.tools || []
    let onKeyDown
    let autoFocus
    let tabIndex
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        enter: () => {
        },
        getIndex: () => {
          ////////debugger
          let selected
          if (this.state.selectedTool) {
            selected = tools.find(x => x.function.name === this.state.selectedTool.function.name)
          }
          if (selected) {
            return tools.indexOf(selected)
          }
          return -1
        },
        getLength: () => tools.length,
        setIndex: index => {
          //debugger
          const newSelection = tools[index]
          if (newSelection && newSelection.function.name !== this.state.selectedTool.function.name) {
            this.setState({
              selectedTool: newSelection
            }, () => {
              scrollIntoViewIfNeeded(this.refs[newSelection.function.name])
            })
          }
        }
      })
      tabIndex = -1
      autoFocus = true
    }
    const onFocus = e => {
    }
    tools.sort((x, y) => {
      return x.function.name.localeCompare(y.function.name)
    })
    return <div className='toolsetEditorPage'
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
                tabIndex={tabIndex}
                onFocus={onFocus}
           >
             {
               tools.map(tool => {
                 let titleDiv = tool.function.name
                 let contentDiv = tool.function.description
                 let icon= Gear
                 let legacyIconSize= true
                 let actions = []
                 let action = async () => {
                 }
                 const select = () => {
                   this.setState({
                     selectedTool: tool
                   }, () => {
                     scrollIntoViewIfNeeded(this.refs[tool.function.name])
                   })
                 }
                 const isSelected = this.state.selectedTool === tool
                 let className = 'toolsetTask'
                 if (isSelected) {
                   className += ' toolsetTaskSelected'
                 }
                 const setRef = ref => {
                   this.refs[tool.function.name] = ref
                 }
                 return <div ref={setRef} className={className} onClick={select}>
                          <div className='toolsetLeftTopRow'>
                            <div className='toolsetLeftTopRowLeftCentered'>
	                      <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                              {titleDiv}
                            </div>
                            <div className='toolsetRight'>
                              <ActionMenu className='toolsetActions' actions={actions}/>
                            </div>
                          </div>
                          <div className='toolsetBottomRow' onClick={this.props.open}>
                            <div className='toolsetMiddleDescription'>
                              {contentDiv}
                            </div>
                          </div>
                        </div>

               })
             }
           </div>
  }
  refs = {}
}

export class Toolsets extends BnSubpage {

  onDayChange = day => {
    this.setState({
      calDay: day
    }, this.updateObserver)
  }

  onViewChange = view => {
    this.setState({
      calView: view
    }, this.updateObserver)
  }
  
  setScrollRef = ref => {
    if (ref) {
      const scrollContainer = ref
      const hitBottom = () => {
        const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
        //////console.log({isAtBottom})
        return isAtBottom
      }
      ref.onscroll = () => {
        if (hitBottom()) {
          this.checkScroll()
        }
      }
    }
  }

  renderHuggingFace = () => {
    const importDataset = async opts => {
      const { dataset, numSamples } = opts
      const { url } = dataset
      this.back()
      await this.props.me.uploadDataset({
        url,
        parent: this.props.parent ? this.props.parent.id : undefined,
        numSamples 
      })
    }
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    return  () => <HuggingFaceDatasets
                    me={this.props.me}
                    importDataset={importDataset}
                    title={'HuggingFace'}
                    cancel={cancel}
                    back={back}/>
  }

  openHuggingFace = () => {
    let subpage
    subpage = this.renderHuggingFace()
    this.setState({
      huggingFaceOpen: true,
      subpage
    })
  }

  open = file => this.openToolset(file)
  
  openToolset = toolset => {
    this.state.openPrompt = toolset
    const commitEdit = async (content) => {
      await this.saveToolset(toolset)
      toolset.content = content
      this.saveToolset(toolset)
      this.back()
    }
    let render
    let back =  this.back
    if (toolset.isFolder) {
      const onSearch = searchTerm => this.props.onSearch(searchTerm, toolset.id)
      let title = toolset.name || toolset.title
      render = () => <Toolsets
                       onSearch={onSearch}
                       renderToolsetBody={this.props.renderToolsetBody}                       
                       taskActions={this.props.taskActions}
                       actions={this.props.actions}
                       renderFile={this.props.renderFile}
                       field={this.props.field}
                       chooseToolset={this.props.chooseToolset}
                       emptyTitle={this.props.emptyTitle}
                       newButton={this.props.newButton}
                       key={toolset.id}
                       itemType={this.props.itemType}
                       title={title}
                       folder={toolset}
                       toolset={toolset}
                       cut={this.props.cut}
                       copy={this.props.copy}
                       paste={this.props.paste}
                       duplicate={this.props.duplicate}
                       clipboard={this.props.clipboard}
                       onDrag={this.props.onDrag}
                       onDrop={this.props.onDrop}
                       me={this.props.me}
                       renderTask={this.props.renderTask}
                       observeToolsets={this.props.observeToolsets}
                       createNewToolset={this.props.createNewToolset}
                       createToolsetFolder={this.props.createToolsetFolder}
                       saveToolset={this.props.saveToolset}
                       deleteToolset={this.props.deleteToolset}
                       uploadToolset={this.props.uploadToolset}
                       addParent={this.props.addParent}
                       breadcrumbs={this.props.breadcrumbs.concat([{title, back}])}
                       back={back}/>
    } else {
      if (this.props.renderFile) {
        render = () => this.props.renderFile({
          me: this.props.me,
          file: toolset,
          back: this.back,
          breadcrumbs: this.props.breadcrumbs,
          chooseToolset: this.props.chooseToolset
        })
      } else {
        render = () => <ToolsetEditor
                         save={commitEdit}
                         me={this.props.me}
                         title={toolset.name || toolset.title}
                         copy={this.props.copy}
                         toolset={toolset}
                         breadcrumbs={this.props.breadcrumbs.concat([{title: toolset.name || toolset.title, back: this.back}])}
                         back={this.back}/>
      }        
    }
    this.setState({
      subpage: render
    })
  }

  createToolsetFolder = async () => {
    await this.props.createNewToolsetFolder({parentFolder: this.props.folder})
  }

  createNewToolset = async () => {
    const file = await this.props.createNewToolset({parentFolder: this.props.folder})
    return file
  }

  saveToolset = async (id, updates) => {
    return await this.props.saveToolset(id, updates)
  }

  deleteToolset = async (toolset) => {
    return await this.props.deleteToolset(toolset.id)
  }

  toolsets = {}

  updateObserver = () => {
    let start
    let end
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        start = startOfDay(this.state.calDay)
        end =  endOfDay(this.state.calDay)
        break
      case 'month':
        start = startOfMonth(this.state.calDay)
          end =  endOfMonth(this.state.calDay)
        break
    }
    if (this.sub && this.start === start && this.end == end) {
      return
      
    }
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.start = start
    this.end = end
    //debugger
    this.sub = this.props.observeToolsets({folder: this.props.folder, start, end}).subscribe(change => {
      ////debugger
      const { type } = change
      const toolset = change[this.props.field]
      if (type === 'removed') {
        delete this.toolsets[toolset.id]
      } else {
        this.toolsets[toolset.id] = toolset
      }
      this.updateLater('toolsets', this.updateToolsets)
    })
  }

  componentDidMount() {
    ////debugger
    this.updateObserver()
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  updateToolsets = () => {
    const toolsets = Object.values(this.toolsets)
    toolsets.sort((x, y) => {
      const cmp = y.lastUpdated - x.lastUpdated
      if (cmp) return cmp
      const getLabel = y => ((y.displayInfo && y.displayInfo.title) || y.name)
      const a = getLabel(x)
      const b = getLabel(y)
      return a.localeCompare(b)
    })
    const events = toolsets.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    ////debugger
    this.setState({
      toolsets,
      events
    })
  }
  
  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  checkScroll = () => {
  }

  searchIndex = 0
  onSearch = async searchTerm => {
    //debugger
    if (this.props.onSearch) {
      let parent
      if (this.props.toolset) {
        parent = this.props.toolset.id
      }
      let searchResults
      if (searchTerm.trim()) {
        const searchIndex = ++this.searchIndex
        searchResults = await this.props.onSearch(searchTerm, parent)
        debugger
        if (searchIndex !== this.searchIndex) {
          return
        }
      }
      this.setState({
        searchTerm,
        searchResults
      })
    }
  }

    
  renderContent() {
    const output = [this.renderListView()]
    return output
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  renderToolsets = (toolsets) => {
    const filt = this.getDateFilter()
    if (filt) {
      toolsets = toolsets.filter(filt)
    }
    toolsets.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    const { onDragToolset, onDropToolset, onDrop, handleDataTransfer } = this.getEventHandlers()
    return <div className='chatGptToolsetsMenuToolsets' onDrop={onDrop}>
             {
               toolsets.map(toolset => {
                 let chooseToolset
                 if (this.props.chooseToolset) {
                   chooseToolset = () => this.props.chooseToolset(toolset)
                 }
                 const openToolset = async () => {
                   this.openToolset(toolset)
                 }
                 const saveTitle = async (title) => {
                   toolset.name = title
                   toolset.title = title
                   let displayInfo = { heading: title }
                   ////debugger
                   await this.saveToolset(toolset.id, {name: title, displayInfo})
                 }
                 const trash = async () => {
                   await this.deleteToolset(toolset)
                   this.forceUpdate()
                 }
                 let actions = []
                 if (this.props.taskActions) {
                   actions = this.props.taskActions.map(f => {
                     return f(this, toolset)
                   }).filter(x=>x)
                 }
                 let renderToolsetBody
                 let renderTask
                 if (this.props.renderToolsetBody) {
                   renderToolsetBody = file => this.props.renderToolsetBody(file, this)
                 }
                 if (this.props.renderTask) {
                   renderTask = opts => this.props.renderTask(opts, this)
                 }
                 return <Toolset
                          renderToolsetBody={renderToolsetBody}
                          renderFile={this.props.renderFile}
                          chooseToolset={chooseToolset}
                          renderTask={renderTask}
                          emptyTitle={this.props.emptyTitle}
                          onDrag={onDragToolset}
                          onDrop={onDropToolset}
                          folder={this.props.folder}
                          key={toolset.id}
                          toolset={toolset}
                          saveTitle={saveTitle}
                          actions={actions}
                          copy={this.props.copy}
                          cut={this.props.cut}
                          duplicate={this.props.duplicate}
                          edit={null}
                          open={openToolset}
                          trash={trash}/>
               })
             }
           </div>
  }

  getToolsets = () => {
    let toolsets
    let events = []
    if (this.state.searchTerm && this.state.searchResults) {
      toolsets = this.state.searchResults
    } else if (this.props.folder) {
      toolsets = this.state.toolsets || []
      events = this.state.events || []
    } else {
      toolsets = Object.values(this.toolsets)
    }
    return { toolsets, events }
  }

  renderFooter() {
    const { toolsets, events } = this.getToolsets()
    const folders = toolsets.filter(x => x.isFolder)
    if (isDesktop()) {
      let folderCount = <div className='toolsetItemCount'>{folders.length} Folders</div>
      let fileCount = <div className='toolsetItemCount'>{toolsets.length - folders.length} {this.props.itemType}</div>
      let calendarPopup = <div className='toolsetCalendarPopup'>
                            <div className='toolsetsCal'>
                              <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
                            </div>
                          </div>
      return <div className='toolsetFooter'>
               {folderCount}
               <div className='toolsetFooterRight'>
                 {fileCount}
                 {calendarPopup}
               </div>
             </div>
    }
  }
  
  renderDetail() {
    let { toolsets, events } = this.getToolsets()
    let title = (this.props.folder && this.props.folder.name) || ''
    toolsets = toolsets.filter(x => !x.isFolder)
    //return  <div key={(this.props.folder && this.props.folder.id) || 'main'} className='desktopToolsetDetails'>{this.renderToolsets()}</div>
    let onKeyDown
    let autoFocus
    let tabIndex
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        enter: () => {
        },
        getIndex: () => {
          ////////debugger
          let selected
          if (this.props.selectedToolset) {
            selected = toolsets.find(x => x.user.id === this.props.selectedToolset.id)
          }
          if (selected) {
            return toolsets.indexOf(selected)
          }
          return -1
        },
        getLength: () => toolsets.length,
        setIndex: index => {
          //debugger
          const newSelection = toolsets[index]
          if (newSelection && newSelection !== this.props.selectedToolset) {
            this.props.select(newSelection)
          }
        }
      })
      tabIndex = 0
      autoFocus = true
    }
    let fileChooser
    if (this.props.uploadToolset) {
      const { onDragToolset, onDropToolset, onDrop, handleDataTransfer } = this.getEventHandlers()
      fileChooser = <div className='fileMenu'><FileChooser
                                                onCreate={this.setFileChooser}
                                                fileTypes={this.props.fileTypes || ".json"}
                                                handleDataTransfer={handleDataTransfer}/></div>
    }
    return <div className='desktopToolsetFiles'>
             <div className='desktopToolsetFilesScroller'>
               <InfiniteScroll 
                 onCreate={this.setFilesScroller}
                 onKeyDown={onKeyDown}
                 autoFocus={autoFocus}
                 tabIndex={tabIndex}
                 getId={item => item.id}
                 getSelector={id => `[data-${this.props.field}-id="${id}"]`}
                 items={toolsets}
                 renderItems={this.renderToolsets}
                 pageSize={35}
               />
             </div>           
           </div>           
  }

  renderHeader() {
    if (isDesktop()) {
      return <SearchField icon={Search} onSearch={this.onSearch} me={this.props.me}/>
    }
  }

  getEventHandlers = () => {
    const handleDataTransfer = async (event, transfer) => {
      const models = []//this.modelsView.getSelectedModelIds()
      if (transfer.files.length > 0) {
        event.preventDefault();
        const parent = this.props.folder ? this.props.folder.id : undefined
        for (const file of transfer.files) {
          await this.props.uploadToolset({parent, file, models})
        }
        return true;
      } else {
        const uriList = transfer.getData('text/uri-list')
        if (uriList) {
          event.preventDefault()
          // Split the URI list by newline to handle multiple URLs
          const urls = uriList.split('\n').filter(url => url.trim() !== '')
          console.log('URLs detected:', urls)
          for (const url of urls) {
            await this.props.uploadToolset({url, models})
          }
        }
      }
    }
    const onDrop = event => {
      handleDataTransfer(event, event.dataTransfer)
    }
    const onDragToolset = toolset => {
      selectedToolset = toolset
    }
    const onDropToolset = async toolset => {
      const src = this.state.selectedToolset
      const target = toolset
      if (src !== target) {
        if (target.props.toolset.isFolder) {
          if (await this.props.addParent({child: src.props.toolset, parent: target.props.toolset.id})) {
            this.openFolder(target.props.toolset)
          }
        }
      }
    }
    return { onDragToolset, onDropToolset, onDrop, handleDataTransfer }
  }

  getDateFilter = () => {
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    return filt
  }

  openSubpage = render => {
    this.setState({
      subpage: () => render(this.back)
    })
  }
  
  renderListView(bare) {
    const { onDragToolset, onDropToolset, onDrop, handleDataTransfer } = this.getEventHandlers()
    const { events, toolsets } = this.getToolsets()
    let className= 'toolsetTopLine'
    if (this.props.select) {
      className += ' toolsetTopLineSelect'
    }
    let fileChooser
    let actions = []
    //debugger
    if (this.props.actions) {
      actions = this.props.actions.map(action => {
        return action(this)
      }).filter(x=>x)
    }
    if (this.props.newButton) {
      actions.push(
      {
        label: "New Toolset",
        button: close => this.props.newButton(async () => {
          debugger
          const file = await this.createNewToolset()
          close()
          this.open(file)
        })
      })
    }
    actions.push(
      {
        label: "Folder",
        icon: NewFolder,
        action: this.createToolsetFolder,
      }
    )
    if (this.props.uploadToolset) {
      fileChooser = <div className='fileMenu'><FileChooser
                                                onCreate={this.setFileChooser}
                                                fileTypes={this.props.fileTypes || ".jsonl"}
                                                handleDataTransfer={handleDataTransfer}/></div>
      actions = actions.concat([
        {
          label: "Import",
          action: () => this.fileChooser.chooseFile(),
          icon: OpenFile
        }])
    }
    if (this.props.clipboard) {
      const child = this.props.clipboard
      //debugger
      if (!this.props.folder || !child.parents || !child.parents.find(id => id === this.props.folder.id)) {
        const paste = async () => {
          await this.props.paste({
            parent: this.props.folder ? this.props.folder.id: undefined,
            child: this.props.clipboard
          })
        }
        actions.push({
          label: "Paste",
          icon: Paste,
          action: paste
        })
      }
    }
    if (bare) {
      return this.renderToolsets(toolsets.filter(x => !x.isFolder))
    }
    if (isDesktop()) {
      const folders = toolsets.filter(x => x.isFolder)
      return <div className={'chatGptToolsetsMenu bnSubpageTopLevel'} ref={this.setScrollRef}>
               <div className={className}>
                 {
                   actions.length > 0 && <div className='desktopToolsetActions'>
                                           {actions.map(x => {
                                             const { icon, label, button, action } = x
                                             if (!label) {
                                               //debugger
                                             }
                                             if (button) return button(() => {})
                                             return <SimpleButton icon={icon} label={label} action={action}/>
                                           })
                                           }
                                         </div>}
                 {fileChooser}
               </div>
               {this.renderToolsets(folders)}
             </div>
    }
    return <div className={'chatGptToolsetsMenu bnSubpageTopLevel'} ref={this.setScrollRef}>
             <div className={className}>
               <ActionMenu className='toolsetActions' position="bottom left"  actions={actions}/>
               <SearchField onSearch={this.onSearch} me={this.props.me} menu={fileChooser}/>
             </div>
             <div className='toolsetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
             </div>
             {this.renderToolsets(toolsets)}
             
           </div>
  }
  

}


