const TokenType = {
  START_OBJECT: 'START_OBJECT',
  END_OBJECT: 'END_OBJECT',
  START_ARRAY: 'START_ARRAY',
  END_ARRAY: 'END_ARRAY',
  START_STRING: 'START_STRING',
  STRING_CONTENT: 'STRING_CONTENT',
  END_STRING: 'END_STRING',
  NUMBER: 'NUMBER',
  TRUE: 'TRUE',
  FALSE: 'FALSE',
  NULL: 'NULL',
  COLON: 'COLON',
  COMMA: 'COMMA',
  WHITESPACE: 'WHITESPACE'
};

class JsonLexer {
  constructor() {
    this.reset();
  }

  reset() {
    this.buffer = '';
    this.inString = false;
    this.escaped = false;
    this.numberBuffer = '';
    this.wordBuffer = '';
  }

  *tokenize(input) {
    for (const char of input) {
      if (this.inString) {
        if (this.escaped) {
          this.buffer += char;
          this.escaped = false;
          continue;
        }

        if (char === '\\') {
          this.escaped = true;
          this.buffer += char;
          continue;
        }

        if (char === '"') {
          if (this.buffer) {
            yield { type: TokenType.STRING_CONTENT, value: this.buffer };
          }
          yield { type: TokenType.END_STRING };
          this.buffer = '';
          this.inString = false;
          continue;
        }

        this.buffer += char;
        if (this.buffer.length >= 8192) { // Prevent buffer from growing too large
          yield { type: TokenType.STRING_CONTENT, value: this.buffer };
          this.buffer = '';
        }
        continue;
      }

      // Handle numbers
      if (/[\d.-]/.test(char)) {
        if (this.wordBuffer) {
          yield* this.handleWord();
        }
        this.numberBuffer += char;
        continue;
      } else if (this.numberBuffer) {
        if (/[eE]/.test(char)) {
          this.numberBuffer += char;
          continue;
        }
        yield { type: TokenType.NUMBER, value: this.numberBuffer };
        this.numberBuffer = '';
      }

      // Handle words (true, false, null)
      if (/[a-z]/i.test(char)) {
        this.wordBuffer += char;
        const word = this.wordBuffer.toLowerCase();
        if (word === 'true') {
          yield { type: TokenType.TRUE };
          this.wordBuffer = '';
        } else if (word === 'false') {
          yield { type: TokenType.FALSE };
          this.wordBuffer = '';
        } else if (word === 'null') {
          yield { type: TokenType.NULL };
          this.wordBuffer = '';
        }
        continue;
      } else if (this.wordBuffer) {
        // Invalid word - just ignore it and continue
        this.wordBuffer = '';
      }

      switch (char) {
        case '{':
          yield { type: TokenType.START_OBJECT };
          break;
        case '}':
          yield { type: TokenType.END_OBJECT };
          break;
        case '[':
          yield { type: TokenType.START_ARRAY };
          break;
        case ']':
          yield { type: TokenType.END_ARRAY };
          break;
        case '"':
          yield { type: TokenType.START_STRING };
          this.inString = true;
          break;
        case ':':
          yield { type: TokenType.COLON };
          break;
        case ',':
          yield { type: TokenType.COMMA };
          break;
        case ' ':
        case '\t':
        case '\n':
        case '\r':
          yield { type: TokenType.WHITESPACE };
          break;
      }
    }

    // Handle any remaining buffers
    if (this.numberBuffer) {
      yield { type: TokenType.NUMBER, value: this.numberBuffer };
      this.numberBuffer = '';
    }
    if (this.buffer && this.inString) {
      yield { type: TokenType.STRING_CONTENT, value: this.buffer };
      this.buffer = '';
    }
  }
}

module.exports = { JsonLexer, TokenType };