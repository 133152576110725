import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkGFM from 'remark-gfm'    
import remarkMath from 'remark-math'

import rehypeRaw from 'rehype-raw';
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import {micromark} from 'micromark'

/**
 * Inserts newlines before and after specified XML tags in a Markdown string.
 *
 * @param {string} markdown - The Markdown content.
 * @param {string[]} tags - Array of XML tag names to target.
 * @returns {string} - The updated Markdown with newlines added around the XML tags.
 */
function addNewlinesToXmlTags(markdown, tags) {
  tags.forEach(tag => {
    // Create regex patterns for:
    // 2. Opening tags: <tag ...>
    // 3. Closing tags: </tag>
    // Pattern for opening tags (non self-closing)
    const openingRegex = new RegExp(`<${tag}(\\s[^>]*?)?>`, 'g');
    markdown = markdown.replace(openingRegex, (match) => {
      return `\n${match.trim()}\n`;
    });

    // Pattern for closing tags
    const closingRegex = new RegExp(`</${tag}>`, 'g');
    markdown = markdown.replace(closingRegex, (match) => {
      return `\n${match.trim()}\n`;
    });
  });
  return markdown;
}

const validateMarkdown = (markdown) => {
  const errors = [];
  
  // Check for unmatched backticks
  const backtickCount = (markdown.match(/`/g) || []).length;
  if (backtickCount % 2 !== 0) {
    errors.push('Unmatched backticks (`) detected');
  }

  // Check for unmatched code block fences
  const codeBlockStarts = (markdown.match(/^```\w*$/gm) || []).length;
  const codeBlockEnds = (markdown.match(/^```$/gm) || []).length;
  if (codeBlockStarts !== codeBlockEnds) {
    errors.push('Unmatched code block fences (```) detected');
  }


  // Check for unmatched emphasis markers (* and _)
  const asteriskCount = (markdown.match(/\*/g) || []).length;
  const underscoreCount = (markdown.match(/_/g) || []).length;
  if (asteriskCount % 2 !== 0) {
    errors.push('Unmatched asterisks (*) detected');
  }
  if (underscoreCount % 2 !== 0) {
    errors.push('Unmatched underscores (_) detected');
  }

  // Check for malformed links
  const linkPattern = /\[([^\]]+)\]\(([^)]+)\)/g;
  const links = [...markdown.matchAll(linkPattern)];
  links.forEach(link => {
    const [fullMatch, text, url] = link;
    if (!text.trim()) {
      errors.push(`Empty link text in: ${fullMatch}`);
    }
    if (!url.trim()) {
      errors.push(`Empty URL in: ${fullMatch}`);
    }
  });

  // Check for broken reference-style links
  const references = new Set();
  const referenceDefinitions = markdown.match(/^\[.+\]:\s+\S+$/gm) || [];
  referenceDefinitions.forEach(def => {
    const reference = def.match(/^\[(.+)\]/)[1];
    references.add(reference);
  });

  const referenceLinks = markdown.match(/\[.+\]\[.+\]/g) || [];
  referenceLinks.forEach(link => {
    const reference = link.match(/\[.+\]\[(.+)\]/)[1];
    if (!references.has(reference)) {
      errors.push(`Missing reference definition for: ${reference}`);
    }
  });

  // Check for malformed lists
  const listLines = markdown.match(/^[\s-*+]\s.+/gm) || [];
  listLines.forEach(line => {
    if (!line.match(/^[\s-*+]\s+\S/)) {
      errors.push(`Malformed list item: "${line}" (missing space after list marker)`);
    }
  });

  return errors;
};


/**
 * Determines whether a text is likely to contain LaTeX mathematical notation.
 * Handles multiple currency values while avoiding false positives.
 * 
 * @param {string} text - The input text to analyze
 * @returns {boolean} - True if the text likely contains LaTeX math, false otherwise
 */
function isLaTeXText(text) {
    // No dollar signs means no LaTeX math
    if (!text.includes('$')) {
        return false;
    }

    // First, let's look for clear currency patterns that would rule out LaTeX
    const currencyPatterns = [
        // Multiple currency amounts in sequence indicate a price list
        /(?:\$\s*[\d,]+\.?\d*\s*(?:,|\s+|and\s+|&\s+|\$|$))+/,
        
        // Currency with units or labels
        /\$\s*[\d,]+\.?\d*\s*(?:USD|dollars?|k|K|thousand|million|billion)/i,
        
        // Price/cost labels
        /(?:price|cost|fee|payment|paid|amount)s?:?\s*\$\s*[\d,]+\.?\d*/i,
        
        // Lists and ranges of prices
        /\$\s*[\d,]+\.?\d*\s*(?:-|to|or)\s*\$\s*[\d,]+\.?\d*/,
        
        // Simple dollar amounts with optional decimals and commas
        /^\s*\$\s*[\d,]+\.?\d*\s*$/
    ];

    // If any currency pattern matches strongly, it's probably not LaTeX
    for (const pattern of currencyPatterns) {
        if (pattern.test(text)) {
            return false;
        }
    }

    // Now look for LaTeX indicators
    const latexPatterns = [
        // LaTeX commands
        /\$[^$]*\\[a-zA-Z]+/,
        
        // Mathematical notation
        /\$[^$]*[_^{}\[\]\\].*[_^{}\[\]\\][^$]*\$/,
        
        // Display math mode
        /\$\$[\s\S]+\$\$/,
        
        // Greek letters and common math operators
        /\$[^$]*\\(?:alpha|beta|gamma|theta|sum|int|frac|sqrt)/,
        
        // Variables with subscripts/superscripts
        /\$[^$]*[a-zA-Z]_\{[^}]+\}/,
        
        // Matrix notation
        /\$[^$]*\\begin\{[^}]+\}/
    ];

    // Count the number of LaTeX patterns we find
    const latexMatches = latexPatterns.reduce((count, pattern) => 
        count + (pattern.test(text) ? 1 : 0), 0);

    // Additional checks for LaTeX characteristics
    const hasMathKeywords = /\\(?:begin|end|text|left|right|quad|qquad)/.test(text);
    const hasComplexMath = /[_^]{\w+}/.test(text);

    // Make final determination
    // We need clear evidence of LaTeX patterns and no strong currency indicators
    return latexMatches > 0 || hasMathKeywords || hasComplexMath;
}

// Example usage with test cases
function runTests() {
    const testCases = [
        {
            text: "$100, $200, $300",
            expectedResult: false,
            description: "List of prices"
        },
        {
            text: "Prices: $50-$100",
            expectedResult: false,
            description: "Price range"
        },
        {
            text: "The integral is $\\int_0^\\infty e^{-x} dx$",
            expectedResult: true,
            description: "Mathematical integral"
        },
        {
            text: "Let $\\alpha$ be the angle",
            expectedResult: true,
            description: "Greek letter"
        },
        {
            text: "$1000 per month",
            expectedResult: false,
            description: "Simple currency amount"
        },
        {
            text: "Total: $500k in revenue",
            expectedResult: false,
            description: "Currency with k suffix"
        },
        {
            text: "$x^2 + y^2 = r^2$",
            expectedResult: true,
            description: "Circle equation"
        },
        {
            text: "Costs were $100 and $200",
            expectedResult: false,
            description: "Multiple prices in sentence"
        }
    ];

    //console.log("Running test cases:");
    testCases.forEach((test, index) => {
        const result = isLaTeXText(test.text);
        //console.log(`\nTest ${index + 1}: ${test.description}`);
        //console.log(`Input: ${test.text}`);
        //console.log(`Expected: ${test.expectedResult}, Got: ${result}`);
        //console.log(`Result: ${result === test.expectedResult ? 'PASS' : 'FAIL'}`);
    });
  //debugger
}


runTests()

function convertBracketsToDollars(text) {
  return text.replaceAll('\\[', '$$$$').replaceAll('\\]', '$$$$')
}


export const containsLatex = (text) => {
  text = preprocessLaTeX(text)
  const latexPatterns = [/mathbb/, /\$\\triangle/,/\$\$[^]+\$\$/, /$[^0-9]/, /\$[^$]*\S\$/, /[$][\\]/,/\\begin{.*}.*\\end{.*}/, /\\(frac|log_|text|times|textit|textbf){/, /\\times/, /\\(to|boxed)/, /\\cup/, /\\(le|ge)/, /\\\(|\\\)/, /[\$[^ ]+[{$]/];
  let result = latexPatterns.find((pattern, i) => {
    const matched = pattern.test(text)
    if (matched && i === 3 ) {
      const segments = classifySegments(text)
      //////console.log('segments', JSON.stringify(segments))
      for (const segment of segments) {
        const { token, type } = segment
        if (token.indexOf('$') >= 0) {
          if (type !== 'latex') {
            return false
          }
        }
      }
      //////console.log('matched', pattern, matched, text)
    }
    return matched
  }) ? text : undefined
  if (result) {
    //////console.log('containsLatext', result, text)
  }
  return result
}

// Function to classify segments of text
function classifySegments(text) {
    const monetaryPattern = /\$\d+/g;
    const latexPattern = /\$[^\$]+\$/g;

    // Find all matches for monetary amounts and LaTeX expressions
    const monetaryMatches = text.match(monetaryPattern) || [];
    const latexMatches = text.match(latexPattern) || [];

    // Classify text based on matches
    const classifiedText = text.split(' ').map(token => {
        if (monetaryMatches.includes(token)) {
            return { token, type: 'monetary' };
        } else if (latexMatches.some(expr => expr.includes(token))) {
            return { token, type: 'latex' };
        } else {
            return { token, type: 'text' };
        }
    });

    return classifiedText;
}

export const containsMarkdown = (text) => {
  const markdownPatterns = [/^#+\s/, /^[*-]\s/, /\[.*\]\(.*\)/, /[*][*]/, /##/, /[*][^*]+[*]/, /```/];
  return markdownPatterns.some(pattern => {
    const result = pattern.test(text)
    if (result) {
      ////console.log("matched", pattern, text)
    }
    return result
  });
}

const preprocessLaTeX = (content) => {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${equation}$$`,
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${equation}$`,
  );
  if (content !== inlineProcessedContent) {
    //////console.log("CONVERTED", inlineProcessedContent)
    return inlineProcessedContent
  }
  return content
}

export class Markdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    //console.log("markdown mounted", this.props.children)
  }

  componentWillUnmount() {
    //console.log("markdown mounted", this.props.children)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  cachedText = ''
  cached = null

  render() {
    let text = (""+this.props.children).trim()
    if (text == this.cachedText && this.cached) {
      return this.cached
    }
    this.cachedText = text
    function convertSingleToDoubleNewlines(text) {
      return text.replace(/([^\n])\n(?!\n)/g, '$1\n\n');
    }
    let modified = addNewlinesToXmlTags(text, ["think", "signature", "reasoning", "thinking"])
    if (modified !== text) {
      console.log("ADDED newlines", modified)
      text = modified
    }
    text = convertSingleToDoubleNewlines(text)
    //////console.log("markdown not cached", text)
    let katex
    let latex
    latex = isLaTeXText(text)
    let plugins = [remarkGFM]
    let rehypePlugins = [rehypeRaw]
    if (latex) {
      rehypePlugins.push(katex = rehypeKatex)
      plugins = plugins.concat([[remarkMath, { singleDollarTextMath: true}]])
    } else {
      if (text.indexOf("$") >= 0) {
        //////console.log("!containsLatex", text)
      }
    }
    if (!katex && !containsMarkdown(text)) {
      //return text
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return text
    }
    if (this.props.debug) {
      //console.log('re-rendering markdown', text)
    }
    return this.cached = <div className='markdownContainer'>
                           <ReactMarkdown key={this.props.key1}
                                          remarkPlugins={plugins}
                                          rehypePlugins={rehypePlugins}
                                          components={this.props.components}>
                             {text}
                           </ReactMarkdown>
                         </div>
  }
}



 




 
