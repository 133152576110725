import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import{ isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { getReplies, ModelSelection, getComponents, pasteText, ModelConfig, RadioButtons, GearButton, DeleteButton, FileChooser, SearchField, PlayButton, toThen, fromNow, ChatGPT2, Navigation } from '../ChatGPT2'
import { SimpleButton, SimpleIcon, SimpleConfigButton } from '../ChatGPT2/SimpleButton.js'
import { Model, ModelIcon, ModelLabel, ModelPrice, ModelsView } from '../ChatGPT2/ModelsMenu.js'
import { InfiniteScroll } from '../Scrolling'
import { PiecewiseScrollbar } from '../Scrolling/PiecewiseScrollbar.js'
import { Slider } from '../ChatGPT2/Slider.js'
import { Markdown } from '../ChatGPT2/Markdown.js'
import Update from '../../assets/Icons/Update.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Trending from '../../assets/Icons/Trending.svg'
import Spin from '../../assets/Icons/Spin.svg'
import File from '../../assets/Icons/File.svg'
import Import from '../../assets/Icons/Platforms/Custom.svg'
import HF from '../../assets/Icons/Platforms/HuggingFace.svg'
import EditIcon from '../../assets/Icons/Edit.svg'
import Send from '../../assets/Icons/Send.svg'
import Up from '../../assets/Icons/Up.svg'
import Down from '../../assets/Icons/Down.svg'
import Stop from '../../assets/Icons/Stop.svg'
import Share from '../../assets/Icons/Share.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Cross from '../../assets/Icons/Cross.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Cut from '../../assets/Icons/Share.svg'
import Plus from '../../assets/Icons/Plus.svg'
import Paste from '../../assets/Icons/Paste.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import HuggingFace from '../../assets/Icons/Platforms/HuggingFace.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Chat from '../../assets/Icons/Chat.svg'
import Question from '../../assets/Icons/Question.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from './Usage.js'
import { formatInt, scrollOnKeyDown, clone, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay} from '../../classes/Util.js'
import { getMetrics, storeMetrics } from '../../classes/Metrics.js'
import {InMemorySearch} from '../../classes/InMemorySearch'
import { SaveAs } from 'file-saver'
import { EditMenu } from './EditMenu.js'
import { ActionMenu } from './ActionMenu.js'
import moment from 'moment'
import momentDuration from 'moment-duration-format'
import { LossGraph } from './LossGraph.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { Dots } from '../Dots'
import { HeightmapVisualization } from './HeightMapViz.js'
import './Datasets.css'

export class Thread extends Component {
  setRef = ref => {
    this.ref = ref
  }
  scrollIntoView = () => { if (this.ref) this.ref.scrollIntoView({block: 'nearest'}) }
  componentDidUpdate(prevProps) {
    if (this.props.selected && !prevProps.selected) {
      this.scrollIntoView()
    }
  }
  render() {
    const thread = this.props.thread
    const onClick = (e) => {
      this.props.selectThread()
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    let trash = this.props.trash
    let date
    if (thread.lastUpdated) {
      date = fromNow(thread.lastUpdated)
    }
    let blurb = ''
    let deleteButton
    let deleteIcon = thread.busy ? Spin : Trash
    let dateComp
    let content
    let markdown = this.props.markdown || 'Empty Discussion'
    if (thread.lastUpdated && thread.messages > 0) {
      if (thread.title) {
        markdown = `# ${thread.title || ''}\n${thread.description}`
      } else {
        markdown = thread.description
      }
    }
    const components = null
    let { usage, credits } = thread
    let dollars
    let total = (usage && usage.total) || credits
    if (total) {
      dollars = '$'+formatPrice(total)
    }
    content = <Markdown components={components}>{markdown}</Markdown>
    let actions =  [
    ]
    actions.push({
      icon: Cut,
      action: this.props.cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: this.props.copy,
      label: "Copy"
    })
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton key={'deleteTask'} label='Delete' trash={
                                           async () => {
                                             //////////debugger
                                             await trash()
                                             close()
                                           }
                                         }/>
      })
    }
    if (this.props.subpages) {
      for (const page of this.props.subpages) {
        actions.push({
          label: page.label,
          action: () => {
            this.openSubpage(() => {
              return page.render(this.back)
            })
          }
        })
      }
    }
    if (date) {
      dateComp = <div className='taskDate'>{date}</div>
    }
    const reps = Math.round(this.props.reps)
    if (this.props.renderTask) {
      return this.props.renderTask({
        task: this.props.thread, onClick, content, dollars, dateComp, actions, reps
      })
    }
    let className1 = 'taskTitle'
    if (this.props.selected) {
      className1 += ' taskTitleSelected'
    }
    let open = this.props.open || onClick
    return <div ref={this.setRef} key={thread.id} className={className1} data-task-id={thread.id}>
             <div  className='taskTitleLeft'>
	       <div className='keyboardMenuItemIcon'><ReactSVG src={Chat}/></div>
             </div>
	     <div className='keyboardMenuItemLabel taskDescriptionLabel' onClick={onClick}>{content}</div>
             <div className='keyboardMenuItemRight'>
               <div className='modelPrice'>{dollars}</div>
               <div className='exampleReps'>{reps}</div>
               {dateComp}
               <div className='keyboardMenuItemActions'>
                 <ActionMenu actions={actions}/>
               </div>
             </div>
           </div>
  }
}

export class Examples extends BnSubpage {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  onScroll = ({baseOffset, offset, scrollTop}) => {
    this.props.onScroll({
      view: this.props.view,
      baseOffset,
      offset,
      scrollTop
    })
  }
  
  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (prevProps.selectedTask !== this.props.selectedTask) {
      if (this.infiniteScroll) {
        this.infiniteScroll.scrollToItem(this.props.selectedTask)
      }
    }
  }

  isThreadOpen = () => this.state.subpage

  openThread = () => {
    this.props.chatGPT.cacheEnabled = true
    const closeThread = () => {
      this.props.chatGPT.cacheEnabled = false
      this.back()
    }
    this.openSubpage(() => {
      return <MessagesView
               selectedMessage={this.props.selectedMessage}
               select={x => this.props.selectMessage(x.user)}
               resolveModel={this.props.resolveModel}
               messages={this.props.getMessages()}
               me={this.props.me}
               back={this.back}
               title={'Messages'}/>
    })
  }

  openSubpage = subpage => {
    this.setState({
      subpage
    })
  }

    
  renderContent() {
    return <div className='threadsPage bnSubpageTopLevel'>
             {this.props.renderHeader()}
             {this.renderTasks()}
          </div>
  }

  scrollToItem = item => {
    if (this.infiniteScroll) {
      this.infiniteScroll.scrollToItem(item)
    }
  }

  setInfiniteScroll = ref => {
    this.infiniteScroll = ref
  }

  onSearch = searchTerm => {
  }
  
  renderTasks = () => {
    const closeMenu = () => {
      //this.state.menuActive = false
      //this.forceUpdate()
    }
    let menuStyle
    let active = this.state.menuActive || this.props.selectedThread
    active = !this.props.selectedThread
    menuStyle = null
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    const x = 0
    const examples = this.props.examples
    let className1 = 'examplesMenuInfiniteScroll'
    if (this.props.searchTerm) {
      className1 += ' examplesMenu-search'
    } else {
      className1 += ' examplesMenu-'+this.props.view
    }
    let tabIndex
    const getAverageLength = (examples) => {
      let len = 0
      for (const {example} of examples) {
        const { line } = example
        len += line.length
      }
      return len / examples.length
    }
    const len = getAverageLength(examples)
    console.log("AVERAGE LEN", len)
    const renderExamples = examples => {
      return examples.map((thread, i) => {
        let prev = examples[i-1]
        let next = examples[i+1]
        const selectThread = () => {
          this.props.selectThread(thread)
        }
        const deleteTask = async () => {
          await this.props.deleteTask(thread)
        }
        let cancelDelete
        if (this.state.confirmDelete === thread.id) {
          cancelDelete = async () => {
            this.state.confirmDelete = null
            this.forceUpdate()
          }
        }
        let trash = this.props.deleteTask
        let copy
        let cut
        let paste
        if (this.props.cut) {
          cut = () => this.props.cut(thread)
        }
        if (this.props.copy) {
          copy = () => this.props.copy(thread)
        }
        if (this.props.paste) {
          paste = () => this.props.paste(thread)
        }
        let open
        if (isDesktop()) {
          open = () => {
            //////////debugger
            if (this.props.chatGPT.state.selectedTask &&
                this.props.chatGPT.state.selectedTask.id == thread.id) {
              this.openThread(this.props.chatGPT)
            }
          }
        }
        const reps = Math.max(Math.round(len / thread.example.line.length), 1)
        return <Thread key={thread.id} thread={thread} selectThread={selectThread}
                       resolveModel={this.props.resolveModel}
                       reps={reps}
                       open={open}
                       selected={
                         this.props.selectedThread && this.props.selectedThread.id == thread.id
                       }
                       cut={cut} copy={copy} paste={paste}
                       renderTask={this.props.renderTask}
                       trash={trash} cancelDelete={cancelDelete}/>
      })
    }
    const selectItem = item => {
      this.props.selectThread(item)
    }
    const selectedItem = this.state.selectedTask
    const onKeyDown = scrollOnKeyDown({
      getIndex: () => {
        if (this.props.selectedTask) {
          const selected = examples.find(x => x.id === this.props.selectedTask.id)
          if (selected) {
            return examples.indexOf(selected)
          }
          return -1
        }
      },
      getLength: () => examples.length,
      onHitStart: async () => {
        const { count, update } = await this.props.getBefore(11)
        if (count > 0) {
          update(() => {
            this.props.selectThread(this.props.examples[count-1])
          })
        }
      },
      onHitEnd: async () => {
        const offset = examples.length
        const { count, update } = await this.props.getAfter(11)
        if (count > 0) {
          update(() => {
            console.log('selecting thread', offset+1)
            this.props.selectThread(this.props.examples[offset+1])
          })
        }
      },
      setIndex: index =>  {
        const newSelection = examples[index]
        if (newSelection && newSelection.id !== this.props.selectedTask.id) {
          this.props.selectThread(newSelection)
        }
      }
    })
    return <div className={className1}>
             <InfiniteScroll
               onScroll={this.props.onScroll ? this.onScroll : undefined}
               onKeyDown={onKeyDown}
               autoFocus={isDesktop()}
               onCreate={this.setInfiniteScroll}
               key='examples'
               count={examples.length}
               items={examples}
               renderItems={renderExamples}
               getSelector={(id) => `[data-task-id="${id}"]`}
               getId={item => item.id}
               onLoadMore={
                 async (direction, pageSize) => {
                   if (direction === 'after') {
                     return await this.props.getAfter(pageSize)
                   } else {
                     return await this.props.getBefore(pageSize)
                   }
                 }
               }
             />
           </div>
  }

    
}

function formatDur(ms) {
  if (ms === undefined) return undefined
    const units = [
        { label: "y", value: 1000 * 60 * 60 * 24 * 365 }, // Years
        { label: "M", value: 1000 * 60 * 60 * 24 * 30 },  // Months (approximate)
        { label: "d", value: 1000 * 60 * 60 * 24 },       // Days
        { label: "h", value: 1000 * 60 * 60 },           // Hours
        { label: "m", value: 1000 * 60 },               // Minutes
        { label: "s", value: 1000 }                     // Seconds
    ];

    for (const unit of units) {
        if (ms >= unit.value) {
            return Math.floor(ms / unit.value) + unit.label;
        }
    }

    return "0s"; // Default case for very small durations
}


class SimpleChatMessage extends Component {

  onSwiper = swiper => {
    this.swiper = swiper
  }

  getPos = () => {
    return this.swiper ? this.swiper.activeIndex: 0
  }

  setPos = pos => {
    if (this.swiper) {
      this.swiper.slideTo(pos)
    }
    this.forceUpdate()
  }

  render()  {
    const { message } = this.props
    const { role, content, models } = message
    const renderContent = (role, content) => {
      return <div className={"simpleChatMessage simpleChatMessage-"+role}>
               <div className='simpleChatMessageModelName'>
                 {role}
               </div>
               <Markdown components={this.props.components}>{content}</Markdown>
             </div>
    }
    if (role === 'assistant' && models) {
      return <div className='simpleChatMessageModels'>
        <Swiper
          autoHeight={true}
          onSwiper={this.onSwiper}
          preventClicks={false}
          nested={true}
          modules={[Mousewheel]}
          allowTouchMove={!isDesktop()}
          mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
        >
          {
            models.map(x => {
              const { model, content } = x
              let contentDiv = <div className='simpleChatMessageModel'>
                                 <div className='simpleChatMessageModelContent'>
                                   {renderContent(model, content)}
                                 </div>
                               </div>
              return <SwiperSlide>{contentDiv}</SwiperSlide>
            })
          }
        </Swiper>
        <Navigation
          length={models.length}
          pos={this.getPos()}
          setPos={this.setPos}
        />
      </div>
    } else {
      return renderContent(capitalize(role), content)
    }
  }
}
  


export const HuggingFaceDataset = props => {
  const { dataset } = props
  const [provider, label] =  dataset.id.split('/')
  return <div className='huggingFaceDataset'>
           <div className='huggingFaceDatasetRight'>
             <div className='datasetProvider'>{provider}</div>
             <div className='datasetLabel'>{label}</div>
           </div>
         </div>
}

const ImportedDataset = props => {
  const { dataset, importDataset, deleteDataset, sampleDataset, isImported, selected } = props
  const date = fromNow(Date.parse(dataset.lastModified))
  let button
  if (isImported) {
    button = <DeleteButton icon={Cross} trash={deleteDataset}/>
  } else {
    //button = <SimpleButton icon={Right} action={sampleDataset}/>
  }
  let size = dataset.tags.find(x => x.startsWith("size_categories:"))
  let exampleCount
  if (size) {
    const [key, value] = size.split(':')
    exampleCount = value
  }
  const [provider, label] =  dataset.id.split('/')
  let className = 'importedModel'
  if (selected) {
    className += ' importedModelSelected'
  }
  return <div className={className}
              data-dataset-id={dataset.id}
              key={dataset.id}
              onClick={sampleDataset}>
           <div className='importedDatasetTopRow'>
             <div className='importedDatasetTopRowLeft'>
               <SimpleIcon src={HF}/>
               <div className='huggingFaceDatasetOrg'>{provider}</div>
             </div>
             <div className='importedDatsetTopRowRight'>
               <div className='importedDatasetExampleCount'>{exampleCount}</div>
               {button}
             </div>
           </div>
           <div className='importedDatasetBottomRow'>
             {label}
           </div>
         </div>
}

export class HuggingFaceDatasets extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.scanned = {
      ingested: [],
      samples: [],
    }
    this.state.pivotOffset = 0
    this.state.samples = 10
    this.state.scanLimit = 20
    this.state.samplePos = 0
    this.state.sampleZoom = 1
  }

  onSearch = async searchTerm => {
    this.state.busy = true
    this.state.searchTerm = searchTerm
    this.forceUpdate()
    const response = await this.props.me.listDatasetsToImport('hf', {
      q: searchTerm,
      limit: this.state.limit
    })
    if (searchTerm !== this.state.searchTerm) return
    const { searchResults } = response
    if (isDesktop() && (!this.state.dataset || !searchResults.find(x => x.id === this.state.dataset.id))) {
      this.sampleDataset(searchResults[0])
    }
    this.setState(response)
    this.state.busy = false
    this.forceUpdate()
  }

  refresh = () => {
    this.onSearch(this.state.searchTerm)
  }

  datasets = {}

  importDataset = async dataset => {
    const { url } = dataset
    this.back()
    const numSamples = this.state.samples
    await this.props.me.uploadDataset({
      url,
      parent: this.props.parent ? this.props.parent.id : undefined,
        numSamples 
    })
  }

  setDatasetsScroller = scroller => {
    this.datasetsScroller = scroller
  }

  setSamplesScroller = scroller => {
    this.samplesScroller = scroller
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.dataset !== prevState.dataset) {
      if (this.datasetsScroller) {
        this.datasetsScroller.scrollToItem(this.state.dataset)
      }
    }
  }

  sampleDataset = async dataset => {
    this.setState({
      dataset,
      samplePos: 0,
      sampleZoom: 1,
      pivotOffset: 0,
      scanned: {
        startOffset: 0,
        samples: [],
        ingested: [],
        endOffset: 0,
      },
      subpage: isMobile() ? () => <BnSubpage me={this.props.me} render={this.renderScan} back={this.back}/> : null
    }, async () => {
      if (dataset) {
        await this.scan()
        this.forceUpdate(() => {
          this.forceUpdate()
        })
      }
    })
    
  }

  componentDidMount() {
    const openLink = this.props.me.openWindow
    this.components = getComponents({openLink})
    this.onSearch('')
  }

  getSamplesBefore = async pageSize => {
    const count = 0//await this.scan({direction: 'backward'})
    return {
      count,
      update: () => this.forceUpdate()
    }
  }
  
  getSamplesAfter = async pageSize => {
    const count = 0//await this.scan({direction: 'forward'})
    return {
      count,
      update: () => this.forceUpdate()
    }
  }

  scanTo = async offset => {
    if (this.cancelScan) {
      this.cancelScan()
    }
    this.state.sampling = true
    this.forceUpdate()
    const setCancel = cancel => {
      this.cancelScan = cancel
    }
    let scanned
    try {
      scanned = await this.props.me.scanDataset(dataset, offset, scanLimit, setCancel)
    } catch (err) {
      console.warn(err)
      return
    }
    if (this.state.dataset !== dataset) return
    this.state.sampling = false
    const { error, metadata, samples, ingested } = scanned
    this.setState({
      scanned
    })
  }

  scan = async (opts = { direction: 'forward' }) => {
    if (this.cancelScan) {
      this.cancelScan()
    }
    this.cancelScan = null
    let { scanLimit, dataset } = this.state
    const current = this.state.scanned
    let { offset, direction } = opts
    if (offset === undefined) {
      if (direction === 'forward') {
        offset = current.endOffset
      } else {
        offset = current.startOffset - scanLimit
        if (offset < 0) {
          return
        }
      }
    }
    this.state.sampling = true
    this.forceUpdate()
    let startRow = 0
    let endRow = 10000
    let sampleCount = 30
    const setCancel = cancel => {
      this.cancelScan = cancel
    }
    let scanned
    try {
      scanned = await this.props.me.scanDataset(dataset, {startRow, endRow, sampleCount, setCancel})
    } catch (err) {
      console.warn(err)
      return
    }
    if (this.state.dataset !== dataset) return
    this.state.sampling = false
    const { error, metadata, samples, ingested } = scanned
    current.metadata = metadata
    if (error) {
      current.error = error
      return 0
    } else {
      if (direction === 'forward') {
        current.endOffset += samples.length
        for (const sample of samples) {
          current.samples.push(sample)
        }
        if (ingested) {
          let id = 0
          for (const sample of ingested) {
            sample.id = `${id + offset}`
            id++
            current.ingested.push(sample)
          }
        }
      } else {
        current.startOffset = offset
        current.samples = samples.concat(current.samples)
        if (ingested) {
          current.ingested = ingested.concat(current.ingested)
        }
      }
    }
    console.log("SCAN COMPLETE", samples.length)
    return samples.length
  }

  onPivotChange = offset => {
    const current = this.state.scanned
    const { ingested, startOffset } = current
    this.state.pivotOffset = offset
    const endOffset = startOffset + ingested.length
    console.log("onPivotChange", {offset, startOffset, endOffset})
    if (offset >= startOffset &&
        offset <= endOffset) {
      const item = ingested[offset - current.startOffset]
      this.samplesScroller.scrollToItem(item)
    } else {

    }
  }

  getViewportBounds = () => {
    const { startOffset, endOffset } = this.state.scanned
    let startRow = startOffset
    let pivotRow = this.state.pivotOffset || 0
    let endRow = endOffset
    return { startRow, pivotRow, endRow }
  }
        
  renderScan = () => {
    let content
    let { dataset, scanned } = this.state
    if (!dataset) {
      return
    }
    const { samples, error, metadata, ingested } = scanned
    let totalRows = 0
    if (metadata) {
      totalRows = metadata.totalRows
    }
    const rows = samples
    console.log({samples})
    let  { description, lastModified } = (metadata || {})
    if (error) {
      content = <div className='sampledError'>{error}</div>
    } else if (ingested && ingested.length > 0) {
      const desc = <div className='hfDatasetDescription'>
                     {(description || "").trim().replace(/(\r?\n\s*){3,}/g, '\n\n')}
                   </div>
      const formattedTotalRows = formatInt(totalRows)
      const renderMessages = samples => {
        return [desc].concat(samples.map((sample, i) => {
          const { messages } = sample
          return <div
                   data-dataset-sample-id={'sample-'+sample.id}
                   className={'simpleDatasetChatExample'}
                 >
                   <div className='rowNum'>Row {formatInt(rows[i].rowIndex+1)} of {formattedTotalRows}</div>
                   {
                     messages.map(message => <SimpleChatMessage components={this.components}  message={message}/>)
                   }
                 </div>
        }))
      }
      content = <InfiniteScroll
                  onCreate={this.setSamplesScroller}
                  items={ingested}
                  renderItems={renderMessages}
                  pageSize={10}
                  getId={item => 'sample-'+item.id}
                  getSelector={id => `[data-dataset-sample-id="${id}"]`}
                  onLoadMore={
                    async (direction, pageSize) => {
                      if (direction === 'after') {
                        return await this.getSamplesAfter(pageSize)
                      } else {
                        return await this.getSamplesBefore(pageSize)
                      }
                    }
                  }
                />
      

      if (totalRows > 0) {
        let { startRow, pivotRow, endRow } = this.getViewportBounds()
        content = <div className='sampleViewport'>
                    {content}
                    <PiecewiseScrollbar
                      pivotItem={this.state.samplePos}
                      onPivotChange={this.onPivotChange}
                      startRow={startRow}
                      endRow={endRow}
                      pivotRow={pivotRow}
                      totalItems={totalRows}/>
                  </div>
      }
    } else {
      if (samples.length > 0) {
        content = <div className='sampledSamples'>{samples ? JSON.stringify(samples, null, ' '): ''}</div>
      }
    }
    const { numSamples } = this.state
    const importDataset = () => this.importDataset(dataset)
    const canSample = ingested && ingested.length > 0
    const onChangeSamples = samples => {
      this.setState({
        samples
      })
    }
    return <div className='sampled'>
             <div className='sampledTitle'>
               <div className='sampledTitleIcon'>
                 <SimpleIcon src={this.state.sampling ? Spin : HF}/>
                 {dataset.id}
               </div>
               {canSample && <SimpleButton icon={Send} label="Sample" action={importDataset}/>}
             </div>
             {canSample &&<div className='providerViewSamples'>
                            <Slider label='Samples' onChange={onChangeSamples} value={this.state.samples} bounds={[1, 500]} />
                          </div>
             }
             <div className='samplesView'>
               {content}
             </div>
           </div>
  }

  renderDetail() {
    return this.renderScan()
  }


  getDatasets = () => {
    const datasets = this.state.searchResults || []
    return datasets
  }

  getDatasetsBefore = async pageSize => {
    let count = 0
    return {
      count,
      update: then => {
        if (then) then()
      }
    }
  }

  getDatasetsAfter = async pageSize => {
    let count = 0
    if (this.state.next) {
      const { limit } = this.state
      const response = await this.props.me.listDatasetsToImport('hf', {link: this.state.next, limit})
      let { searchResults, next, prev, first, last } = response
      count = searchResults.length
      const current = this.state.searchResults || []
      this.state.searchResults = current.concat(searchResults),
      this.state.next = next
    }
    return {
      count,
      update: then => {
        console.log("LIST DATASETS", 'update', count)
        this.forceUpdate(() => {
          setTimeout(() => this.forceUpdate, 30)
        })
      }
    }
  }
  
  renderContent() {
    const datasets = this.getDatasets()
    console.log("datasets", datasets.length)
    const renderItems = datasets => {
      return datasets.map(dataset => {
        const deleteDataset = () => this.deleteDataset(dataset)
        const importDataset = () => this.importDataset(dataset)
        const sampleDataset = () => this.sampleDataset(dataset)
        console.log({dataset})
        let selected
        if (this.state.dataset) {
          selected = dataset.id === this.state.dataset.id
        }
        return <ImportedDataset
                 key={dataset.id}
                 dataset={dataset}
                 selected={selected}
                 deleteDataset={deleteDataset}
                 importDataset={importDataset}
                 sampleDataset={sampleDataset}
                 />
      })
    }
    let onKeyDown
    let autoFocus
    let tabIndex
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        enter: () => {
        },
        getIndex: () => {
          //////////debugger
          let selected
          if (this.state.dataset) {
            selected = datasets.find(x => x.id === this.state.dataset.id)
          }
          if (selected) {
            return datasets.indexOf(selected)
          }
          return -1
        },
        getLength: () => datasets.length,
        setIndex: index => {
          //////////debugger
          const newSelection = datasets[index]
          if (newSelection && newSelection !== this.state.dataset) {
            this.sampleDataset(newSelection)
          }
        }
      })
      tabIndex = 0
      autoFocus = true
    }
    return <div className='providerViewDatasets'>
             <div className='providerViewTopLine'>
               <SimpleButton icon={this.state.busy ? Spin : Update} action={this.refresh}/>
               <SearchField placeholder="Search" onSearch={this.onSearch} me={this.props.me}/>
             </div>
             <div className='providerViewModels'>
               <InfiniteScroll
                 debug={true}
                 items={datasets}
                 renderItems={renderItems}
                 pageSize={20}
                 onCreate={this.setDatasetsScroller}
                 onKeyDown={onKeyDown}
                 autoFocus={autoFocus}
                 tabIndex={tabIndex}
                 getId={item => item.id}
                 getSelector={id => `[data-dataset-id="${id}"]`}
                 onLoadMore={
                   async (direction, pageSize) => {
                     if (direction === 'after') {
                       return await this.getDatasetsAfter(pageSize)
                     } else {
                       return await this.getDatasetsBefore(pageSize)
                     }
                   }
                 }
               />
             </div>
           </div>
  }
}


const getContent = x => {
  const shorten = prompt => {
    const comps = prompt.split(/[.]|\n/).filter(x=>x.trim())
    return comps.length > 1 ? comps[0] + "..." : prompt
  }
  if (x.messages.length > 0) {
    if (typeof x.messages[0].content  === 'string') {
      return shorten(x.messages[0].content)
    } else {
      ////debugger
      return ''
    }
  }
  return "New Example"
}

export const getFineTuningJobInfo = (model, fineTuningJob) => {
  const { job, events } = fineTuningJob
  let trainedTokens = 0
  let error
  let dur
  let message
  let price
  let status
  let eta
  let { epochs, batchSize, learningRateBoost } = fineTuningJob
  let hasError = false
  let start
  const { title, isFinetune, modelId, rootModel, ts } = model
  let version
  let summaryInfo
  console.log("JOB", fineTuningJob)
  if (isFinetune) {
    function formatDate(date) {
      const momentDate = moment(date)
      const currentYear = moment().year();
      
      // Check if the year of the date matches the current year
      if (momentDate.year() === currentYear) {
        return momentDate.format('MM/DD');
      } else {
        return momentDate.format('MM/DD/YY');
      }
    }
    const date = formatDate(model.ts)
    version = <div className='modelVersion'><div className='modelVersionDate'>{date}</div><div className='modelVersionId'>{modelId}</div></div>
  }
  const label = title
  switch (fineTuningJob.platform) {
    case 'openai':
      {
        status = capitalize(job.status.split('_').join(' '))
        trainedTokens += job.trained_tokens
        start = job.created_at * 1000
        if (model) {
          if (model.finetune) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {
          }
        }        
        if (events) {
          message = events[events.length-1].message
          let [steps, info] = message.split(":")
          //debugger
          if (!info) {
            console.log("no info", message)
          } else {
            let fields = info.split(',').map(field => {
              const [name,value] = field.trim().split("=")
              return {
                name,
                value
              }
            })
            fields.unshift({
              name: "Step",
              value: steps.substring("Step ".length)
            })
            summaryInfo = {
              fields
            }
          }
        } else {
          message = (job.error.code || '').split('_').join(' ') 
        }
        
        dur = (job.finished_at ? job.finished_at * 1000 : fineTuningJob.lastUpdated) - job.created_at * 1000
        if (!job.finished_at && job.estimated_finish && job.estimated_finish * 1000 > Date.now()) {
          eta = toThen(job.estimated_finish * 1000)
        }
        break
      }
      case 'gemini':
        {
          const hasError = job.state === "JOB_STATE_FAILED"
          if (typeof job.state !== 'string') {
            ////debugger
          }
          if (hasError) {
            message = job.error.message
          } 
          status = capitalize(job.state.split('_')[2].toLowerCase())
          const { tuningDataStats, supervisedTuningSpec, createTime, endTime } = job
          dur = (endTime ? Date.parse(endTime) : fineTuningJob.lastUpdated) - Date.parse(createTime)
          start = Date.parse(createTime)
          if (tuningDataStats) {
            const { hyperParameters } = supervisedTuningSpec
            const { learningRateMultiplier } = hyperParameters
            const { supervisedTuningDataStats } = tuningDataStats
            const { totalBillableTokenCount } =  supervisedTuningDataStats
            trainedTokens = totalBillableTokenCount
          }
          if (model) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {
            //////debugger
          }
          break
        }
      case 'mistral':
        status = capitalize(job.status.split('_').join(' ').toLowerCase())
        let tokens = job.metadata.train_tokens || job.trainedTokens
        if (tokens) {
          trainedTokens += tokens
        }
        start = job.metadata.created_at * 1000
        if (job.metadata.cost) {
          price = '€' +job.metadata.cost
        }
        if (job.events) { 
         const [event] = job.events
          if (event.data.error) {
            hasError = true
            message = event.data.error
          }
        }
        break
      case 'fireworksAI':
        {
          let state = typeof(job.state) === 'number' ? "PENDING" : job.state
          if (!state) {
            state = 'Failed'
          }
          if (typeof(state) !== 'string') {
            state = '' + state
          }
          start = job.create_time.seconds * 1000
          status = capitalize(state.toLowerCase())
          trainedTokens = fineTuningJob.trainedTokens
          price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
        }
        break
  }
  let info = { platform: fineTuningJob.platform, summaryInfo, learningRateBoost, batchSize, epochs, start, dur, price, status, trainedTokens, message, eta, modelInfo: { label, versionId: modelId, ts, isFinetune, model} }
  console.log("INFO", info, {fineTuningJob})
  return info
}


class TwoWaySliderButton extends Component {
  render() {
    const { icon, action} = this.props
    return <div className='twoWaySliderButton'>
             <SimpleButton
               autoRepeat
               icon={icon}
               action={action}/>
           </div>
  }
}

class TwoWaySlider extends Component {

  render() {
    const { lowerLabel, upperLabel, onChange, value } = this.props
    const v1 = Math.floor(value * 100)
    const v2 = 100 - v1
    console.log("value", value, {v1, v2})
    const start = async () => {
      onChange(1)
    }
    const end = async () => {
      onChange(0)
    }
    const up = async () => {
      const {value} = this.props
      const v1 = Math.floor(value * 100)
      const v2 = 100 - v1
      if (v2 < 100) {
        const dec = Math.max((v1 - 1)/100, 0)
        console.log("up", v1, v2, 'dec', dec)
        onChange(dec)
      }
    }
    const down = async () => {
      const {value} = this.props
      const v1 = Math.floor(value * 100)
      const v2 = 100 - v1
      if (v1 < 100) {
        const inc = Math.min((v1 + 1)/100, 1)
        console.log("down", v1, v2, 'inc', inc)
        onChange(inc)
      }
    }
    const num = v => {
      return <div className='twoWaySliderPct'>{v}</div>
    }
    const bg = pct => {
      const result = '#3b65c9' + ((pct/100)*255).toString(16).padStart(2, '0')
      console.log('bg', pct, result)
      return result
    }
    const leftStyle = {
      background: bg(v1)
    }
    const rightStyle = {
      background: bg(v2)
    }
    return <div className='twoWaySlider'>
             <div className='twoWaySliderLabel' onClick={start} style={leftStyle}>
               {lowerLabel}
             </div>
             <div className='twoWaySliderSlider'>
               <TwoWaySliderButton key='lo' icon={Left} action={down}/>
               <div className="twoWaySliderValue">
                 {num(v1)}/{num(v2)}%
               </div>
               <TwoWaySliderButton key='hi' icon={Right} action={up}/>
             </div>
             <div className='twoWaySliderLabel' onClick={end} style={rightStyle}>
               {upperLabel}
             </div>
           </div>
  }
}



export class FineTuneConfig extends Component {

  constructor(props) {
    super(props)
    this.state = {
      epochs: 1,
      batchSize: 1,
      learningRateBoost: 0,
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
    if (this.props.value) {
      this.setState(this.props.value)
    }
  }

  fireChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.getOpts())
    }
  }

  setOpts = (opts) => {
    this.setState(opts)
  }

  getOpts = () => {
    const opts = {}
    for (const opt in this.state) {
      opts[opt] = Math.round(this.state[opt])
    }
    return opts
  }

  setEpochs = (epochs) => {
    this.setState({epochs}, this.fireChange)
  }

  getEpochs = () => {
    return this.state.epochs
  }
  
  setBatchSize = (batchSize) => {
    this.setState({
      batchSize
    }, this.fireChange)
  }

  getBatchSize = () => {
    return this.state.batchSize
  }

  setLearningRate = (learningRate) => {
    this.setState({
      learningRateBoost: learningRate
    }, this.fireChange)
  }

  getLearningRate = () => this.state.learningRateBoost

  renderFineTuneConfig() {
    return <div className='modelConfig'>
             <div className='tempSlider'><Slider label='Epochs' onChange={this.setEpochs} value={this.getEpochs()} bounds={[1, 99]}/></div>
             <div className='tempSlider'><Slider label='Batch' onChange={this.setBatchSize} value={this.getBatchSize()} bounds={[1, 32]}/></div>
             <div className='tempSlider'><Slider label='Boost' onChange={this.setLearningRate} value={this.getLearningRate()} bounds={[-10, 10]}/></div>
           </div>
  }

  render() {
    return this.renderFineTuneConfig()
  }
}

function toGradient(dataArray) {
  // Interpolate between two colors (low and high) based on a value between 0 and 1
  function interpolateColor(lowColor, highColor, value) {
    const r = Math.round(lowColor[0] + (highColor[0] - lowColor[0]) * value);
    const g = Math.round(lowColor[1] + (highColor[1] - lowColor[1]) * value);
    const b = Math.round(lowColor[2] + (highColor[2] - lowColor[2]) * value);
    return `rgb(${r}, ${g}, ${b})`;
  }

  // Define the low and high colors
  const lowColor = [220, 220, 220];
  const highColor = [80, 80, 80];

  // Generate gradient stops based on steps and `train_mean_token_accuracy` values
  const stops = dataArray.map(item => {
    const position = ((item.step - Math.min(...dataArray.map(i => i.step))) / 
                      (Math.max(...dataArray.map(i => i.step)) - Math.min(...dataArray.map(i => i.step)))) * 100;
    const color = interpolateColor(lowColor, highColor, item.train_mean_token_accuracy); // Use train_mean_token_accuracy to get the color
    return `${color} ${position}%`;
  });

  // Create the linear gradient string
  const gradient = `linear-gradient(to right, ${stops.join(', ')})`;
  return gradient;
}

const formatPrice = price => price.toFixed(2)

const copySubject = new Subject()
let clipboardDataset

const observeClipboard = () => {
  if (clipboardDataset) {
    return concat(of(clipboardDataset), copySubject)
  }
  return copySubject
}

export const copyDataset = async (dataset) => {
  await delay(0.3)
  copySubject.next(clipboardDataset = dataset)
}


class DatasetFineTuningJob extends Component {

  constructor (props) {
    super(props)
    this.state = {
      lastEventTimestamp: 0,
    }
  }

  pollJob = async () => {
    if (!this.props.fineTuningJob) {
      return
    }
    if (this.inProgress) {
      return 
    }
    this.inProgress = true
    clearTimeout(this.pollTimeout)
    const { job, checkpoints, platform, outputModel, id } = this.props.fineTuningJob
    let done = false
    switch (platform) {
      case 'fireworksAI':
        {
          switch (job.status) {
            case 'FAILED':
            case 'COMPLETED':
              done = true
          }
        }
        break
      case 'openai':
        {
          switch (job.status) {
            case 'failed':
            case 'cancelled':
              done = true
              break
            case 'running':
              if (this.state.lastEventTimestamp === 0) {
                const cached = await getMetrics(id)
                if (cached) {
                  this.state.metrics = cached
                  this.forceUpdate()
                  this.lastEventTimestamp = cached[cached.length-1].created_at * 1000
                  this.metricsSubject.next(cached)
                }
              }
          }
        }
        break
      case 'gemini':
        {
          switch (job.state) {
            case 'JOB_STATE_FAILED':
            case 'JOB_STATE_CANCELLED':
              done = true
              break
          }
        }
        break
      case 'mistral':
        {
          switch (job.status) {
            case 'FAILED':
            case 'FAILED_VALIDATION':
              done = true
              break
          }
        }
    }
    if (outputModel && this.props.resolveModel(outputModel)) {
      return
    }
    const { events } = await this.props.me.pollDatasetFineTuningJob(this.props.fineTuningJob.id,
                                                                    {
                                                                      after: this.state.lastEventTimestamp
                                                                    }

                                                                   )
    //debugger
    if (this.unmounted) {
      return
    }
    if (events && events.length > 0) {
      this.state.lastEventTimestamp = events[0].created_at * 1000
      let metrics = this.state.metrics || []
      events.reverse()
      for (const event of events) {
        if (event.type === 'metrics') {
          metrics = metrics.concat(event.data)
        }
      }
      this.state.metrics = metrics
      this.metricsSubject.next(metrics)
      this.loadMetrics()
      this.setState({
        lastMessage: events[events.length-1].message
      })
    }
    this.inProgress = false
    this.forceUpdate()
    if (!done) {
      this.pollTimeout = setTimeout(this.pollJob, 45000)
    }
  }

  observeMetrics = () => {
    const { platform, job } = this.props.fineTuningJob
    if (platform === 'openai' && job.status === 'running' && !this.props.preview) {
      return concat(of(this.state.metrics|| []), this.metricsSubject)
    }
    return from([])
  }

  metricsSubject = new Subject()

  continueJob = async (splits, modelConfig) => {
    const info = this.getCurrentJobInfo()
    let files
    if (splits) {
      files = splits.getFiles()
    }
    let opts
    //debugger
    if (modelConfig) {
      opts = modelConfig.getOpts()
    } else {
      opts = info
    }
    const { epochs, batchSize, learningRateBoost } = opts
    let model = this.props.fineTuningJob.model
    if (this.props.fineTuningJob.platform == 'openai' && this.props.fineTuningJob.outputModel) {
      model = this.props.fineTuningJob.outputModel
    }
    if (!files) {
      files = this.props.fineTuningJob.datasetSplits
    }
    await this.props.createFineTuningJob({
      datasetId: this.props.dataset.id,
      datasetSplits: files,
      previous: this.props.fineTuningJob.id,
      models: [{
        epochs,
        batchSize,
        learningRateBoost,
        model,
      }]
    })
  }

  deleteJob = async () => {
    await this.props.me.deleteDatasetFineTuningJob(this.props.fineTuningJob.id)
  }

  cancelJob = async () => {
    await this.props.me.cancelDatasetFineTuningJob(this.props.fineTuningJob.id)
    await this.pollJob()
  }

  setRef = ref => {
    this.ref = ref
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      ////debugger
      this.props.select(this)
      this.ref.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }
  }

  setModelConfig = ref => {
    this.modelConfig = ref
    let { dataset, fineTuningJob } = this.props
    const { learningRateBoost, epochs, batchSize } = fineTuningJob
    this.modelConfig.setLearningRate(isNaN(learningRateBoost) ? 0 : learningRateBoost)
    this.modelConfig.setEpochs(epochs)
    this.modelConfig.setBatchSize(batchSize)
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
    this.pollJob()
    this.loadMetrics()
  }

  loadMetrics = async () => {
    const { fineTuningJob } = this.props
    if (!fineTuningJob) return
    if (fineTuningJob.platform === 'openai') {
      if (fineTuningJob.outputModel) {
        let selectedJobMetrics = await getMetrics(fineTuningJob.id)
        if (!selectedJobMetrics) {
          const { metrics } = await this.props.me.getDatasetFineTuneMetrics(fineTuningJob.id)
          await storeMetrics(fineTuningJob.id, metrics)
          selectedJobMetrics = metrics
        }
        this.setState({
          selectedJobMetrics: selectedJobMetrics.map(x => {
            let { step, train_loss, valid_loss } = x
            return { step, loss: train_loss || valid_loss, valid_loss, train_loss }
          })
        })
      } else {
        if (this.state.metrics) {
          this.setState({
            selectedJobMetrics: this.state.metrics.map(x => {
              let { step, train_loss, valid_loss } = x
              return { step, loss: train_loss || valid_loss, valid_loss, train_loss }
            })
          })
        }
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true
    clearTimeout(this.pollTimeout)
  }

  getCurrentJobInfo = () => {
    const file = this.props.fineTuningJob
    const model = this.props.resolveModel(file.model)
    return getFineTuningJobInfo(model, file)
  }

  getJobAction = () => {
    const file = this.props.fineTuningJob
    if (!file) return null
    const model = this.props.resolveModel(file.model)
    let { platform, modelInfo, summaryInfo, status, trainedTokens, start, dur, price, eta } = getFineTuningJobInfo(model, file)
    let buttonAction = async () => {
      await this.cancelJob()
      this.forceUpdate()
    }
    let buttonLabel = "Cancel"
    let buttonIcon = Stop
    let type = 'cancel'
    switch (status) {
      case 'Succeeded':
        if (platform === 'openai') {
          buttonLabel = "Continue"
        } else {
          buttonLabel = 'Repeat'
        }
        buttonIcon = Send
        buttonAction = async (config, splits) => {
          await this.continueJob(splits, config)
        }
        type = 'continue'
        break
      case 'Cancelled':
      case 'Failed':
        buttonLabel = "Retry"
        buttonIcon = Send
        buttonAction = 
        type = 'retry'
        buttonAction = async (config, splits) => {
          await this.continueJob(splits, config)
        }
        break
      case 'Running':
        break
    }
    return {
      type,
      icon: buttonIcon,
      label: buttonLabel,
      action: buttonAction,
    }
  }

  render() {
    const file = this.props.fineTuningJob
    let icon = File
    let className = 'toolsetTask'
    const isOpen = this.props.isOpen
    const toggleOpen = () => {
      let wasOpen = this.props.isOpen
      this.props.toggleOpen()
      if (!wasOpen) {
        this.pollJob()
      }
    }
    let dateComp
    let contentDiv
    let heading
    if (file) {
      let displayInfo
      displayInfo = file
      const model = this.props.resolveModel(file.model)
      let { platform, modelInfo, summaryInfo, status, trainedTokens, start, dur, price, eta, batchSize, epochs, learningRateBoost } = getFineTuningJobInfo(model, file)
      className += " fineTunedModel-"+status.toLowerCase()
      let date
      date = fromNow(file.lastUpdated)
      dateComp = <div className='toolsetDate'>{date}</div>
      const actions = [{
        label: "File",
        icon: File,
        action: () => {}
      }]
      let content
      if (isOpen) {
        if (platform === 'openai') {
          let metrics = this.state.selectedJobMetrics || []
          const onClick = currentStep => {
            this.props.onClickHeightMap(currentStep)
          }
          let style
          let { numExamples, numValidationExamples, datasetSplits } = file
          let todo = 1
          if (this.state.validationLoss) {
            metrics = metrics.filter(x => x.valid_loss)
            todo = numValidationExamples
          } else {
            metrics = metrics.filter(x => x.train_loss)
            todo = numExamples
          }
          const width = metrics.length / todo
          console.log("WIDTH", metrics.length, this.props.numExamples, width)
          if (width > 0 && width < 1) {
            style = { width: (width *100) + "%" }
          }
          content= <div className='heightMapContainer' style={style}>
                     <HeightmapVisualization selectedStep={this.props.selectedStep} data={metrics} onClick={onClick}/>
                   </div>
        } else {
          const { job, name, platform, events, status  } = file
          console.log({file})
        }
      }
      const InfoLine = (props) => {
        const { status, label, value } = props
        let boxClass = 'jobInfoBox'
        if (status) {
          boxClass += ' jobInfoBox-'+status
        }
        return <div className='jobInfoLine'>
                 <div className='jobInfoLabel'>
                   <div className={boxClass}/>
                   {label}
                 </div>
                 <div className='jobInfoValue'>
                   {value}
                 </div>
               </div>
      }
      let Status = status +": "
      console.log({status})
      icon = Spin
      switch (status) {
        case 'Succeeded':
          Status = "Finished: "
          start += dur
          icon = File
          break
        case 'Cancelled':
        case 'Failed':
          icon = File
          start += dur
          break
        case 'Running':
          Status = "Started: "
          break
      }
      const toggleLossGraph = () => {
        this.setState({
          validationLoss: !this.state.validationLoss
        })
      }
      heading = this.props.dataset.name
      console.log({summaryInfo})
      let className1 = 'toolsetContent'
      if (this.state.validationLoss) {
        className1 += " showValidationLoss"
      } else {
        className1 += " showTrainingLoss"
      }
      const showTrainingLoss = () => {
        this.setState({
          validationLoss: false
        })
      }
      const showValidationLoss = () => {
        this.setState({
          validationLoss: true
        })
      }
      let time = fromNow(start)
      if (time !== 'now') {
        time += " ago"
      }
      if (dur === 0) {
        dur = Date.now() - start
      }
      let validationButton = <div className='validationLoss' onClick={showValidationLoss}>Validation Loss</div>
      contentDiv = <div className={className1} style={isOpen ? null: { display: 'none'} }>
                     <div className='jobInfo' key="jobInfo">
                       <InfoLine status={status} label={Status+ time}  value={formatDur(dur)}/>
                       <InfoLine label={'Input:'}  value={<ModelLabel model={model}/>}/>
                       <InfoLine label={'Epochs:'}  value={epochs}/>
                       <InfoLine label={'Batch Size:'}  value={batchSize}/>
                       <InfoLine label={'LR Boost:'}  value={learningRateBoost}/>
                       <InfoLine label={'Trained tokens:'}  value={trainedTokens}/>
                     </div>
                     {
                       summaryInfo && <div className='jobInfo' key="summaryInfo">
                                        {
                                          summaryInfo.fields.map(field => {
                                            const { name, value } = field
                                            return <InfoLine label={capitalize(name)+":"}  value={value}/>
                                          })
                                        }
                                      </div>
                     }
                     <div className='heightMapTitle'><SimpleIcon src={Trending}/><div className='trainingLoss' onClick={showTrainingLoss}>Training Loss</div>{validationButton}</div>
                     {content}
                   </div>
    } else {
      heading = "Fine-tune"
      contentDiv = <div className='toolsetContent' style={isOpen ? null: { display: 'none'} }>
                   </div>
    }
    return <div className={className}>
             <div className='jobTop'>
               <div  className='jobLeft' >
	         <SimpleIcon src={icon}/>
               </div>
               <div className='toolsetHeading'>
                 {heading}
               </div>
               {dateComp}
               <div className='jobButtons'>
                 <SimpleButton icon={isOpen ? Up :Down} action={toggleOpen}/>
               </div>
             </div>
             <div className='jobBottom'>
               {contentDiv}
             </div>
           </div>
  }
    
  
  renderOld() {
    let { dataset, fineTuningJob } = this.props
    let {platform, outputModel, model, events, checkpoints, id, learningRateBoost, batchSize, epochs, seed, job, lastUpdated } = fineTuningJob
    epochs = Math.round(epochs) // hack
    console.log({fineTuningJob, model})
    if (outputModel) {
      model = this.props.resolveModel(outputModel) 
    } else {
      model = this.props.resolveModel(model)
    }
    let icon = File
    let legacyIconSize
    let canCancel = true
    let canContinue = false
    let isDone
    let Continue = "Continue"
    let jobStatus
    let learningRate
    switch (platform) {
      case 'gemini':
        {
          canContinue = false
          Continue = "Retry"
          switch (job.state) {
            case 'JOB_STATE_FAILED':
            case 'JOB_STATE_CANCELLED':
            case 'JOB_STATE_CANCELLING':
              isDone = job.state !== 'JOB_STATE_CANCELLING'
              //icon = Alert
              canCancel = false
              jobStatus = 'failed'
              canContinue = true
              break
            case 'JOB_STATE_SUCCEEDED':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              canContinue = true
              break
            default:
              icon = Spin
          }
        }
        break
      case 'fireworksAI':
        {
          canContinue = false
          switch (job.state) {
            case undefined:
            case 'FAILED':
              icon = Alert
              canCancel = false
              isDone = true
              jobStatus = 'failed'
              break
            case 'COMPLETED':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              break
            default:
              icon = Spin
          }
        }
        break
      case 'mistral':
        {
          canContinue = false
          const { hyperparameters } = job
          const { epochs, learning_rate, training_steps } = hyperparameters
          learningRate = learning_rate
          switch (job.status) {
            case 'FAILED':
            case 'FAILED_VALIDATION':
            case 'CANCELLED':
              //icon = Alert
              canCancel = false
              isDone = true
              jobStatus = 'failed'
              break
            case 'SUCCESS':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              canContinue = true
              Continue = 'Retry'
              break
            default:
              icon = Spin
          }
        }
        break
      case 'openai':
        jobStatus = job.status
        let totalEpochs = 0
        let m = this.props.resolveModel(outputModel)
        while (m) {
          if (m.job) {
            const hyperparameters = m.job.hyperparameters ||
                  (m.job.method && m.job.method.dpo &&  m.job.method.dpo.hyperparameters) ||
                  (m.job.method && m.job.method.supervised &&  m.job.method.supervised.hyperparameters)
            if (!hyperparameters) {
              //debugger
            } else {
              totalEpochs += hyperparameters.n_epochs
            }
          }
          m = this.props.resolveModel(m.baseModelId)
        }
        if (totalEpochs) {
          epochs = totalEpochs
        }
        switch (job.status) {
          case 'failed':
          case 'cancelled':
            //icon = Alert
            canCancel = false
            isDone = true
            canContinue = true
            Continue = 'Retry'
            break
          case 'succeeded':
            icon = File
            legacyIconSize=true
            canCancel = false
            canContinue = true
            isDone = true
            break
          default:
            icon = Spin
        }
        break
    }
    let className = 'fineTunedModel' + " fineTunedModel-"+jobStatus
    let trainedTokens = 0
    let hasError
    let error
    let message
    let price
    const date = fromNow(lastUpdated)
    let style
    let checkpointsDiv
    let status
    let createdAt
    let dur
    let eta
    switch (fineTuningJob.platform) {
      case 'openai':
        {
          if (job.learning_rate_muliplier) {
            learningRate = 'x '+job.learning_rate_multiplier
          }
          hasError = Object.values(job.error).length > 0
          status = capitalize(job.status.split('_').join(' '))
          trainedTokens += job.trained_tokens
          if (model) {
            if (model.finetune) {
              price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
            } else {
              ////debugger
            }
          } else {
            error = (job.error.code || '').split('_').join(' ') 
          }
          dur = (job.finished_at ? job.finished_at * 1000 : Date.now()) - job.created_at * 1000
          dur = moment.duration(dur).format("hh:mm:ss")
          if (dur.indexOf(":") < 0) {
            dur = null
          }
          if (false && checkpoints) {
            const background = toGradient(checkpoints.map(chkpt => chkpt.metrics))
            style = {
              background
            }
            checkpointsDiv = <div className='checkpoints'>
                               {
                                 checkpoints.map(chkpt => {
                                   return <div className='checkpoint'>
                                            {Math.round(chkpt.metrics.train_mean_token_accuracy*100)}%  
                                          </div>
                                 })
                               }
                             </div>
            
          }
          
          if (!job.finished_at && job.estimated_finish && job.estimated_finish * 1000 > Date.now()) {
            eta = toThen(job.estimated_finish * 1000)
          }
          break
        }
      case 'gemini':
        {
          hasError = job.state === "JOB_STATE_FAILED"
          if (typeof job.state !== 'string') {
            ////debugger
          }
          if (hasError) {
            message = job.error.message
          } 
          status = capitalize(job.state.split('_')[2].toLowerCase())
          const { tuningDataStats, supervisedTuningSpec, createTime, endTime } = job
          if (endTime) {
            dur = (endTime ? Date.parse(endTime) : Date.now()) - Date.parse(createTime)
            dur = moment.duration(dur, 'milliseconds').format("hh:mm:ss")
            if (dur.indexOf(":") < 0) {
              dur = null
            }
          }
          if (tuningDataStats) {
            const { hyperParameters } = supervisedTuningSpec
            const { learningRateMultiplier } = hyperParameters
            if (learningRateMultiplier && fineTuningJob.learningRateBoost) {
              learningRate = 'boost '+Math.round(fineTuningJob.learningRateBoost)
            }
            const { supervisedTuningDataStats } = tuningDataStats
            const { totalBillableTokenCount } =  supervisedTuningDataStats
            trainedTokens = totalBillableTokenCount
          }
          if (model) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {

          }
          break
        }
      case 'mistral':
        status = capitalize(job.status.split('_').join(' ').toLowerCase())
        let tokens = job.metadata.train_tokens || job.trainedTokens
        if (tokens) {
          trainedTokens += tokens
        }
        if (job.metadata.cost) {
          price = '€' +job.metadata.cost
        }
        if (job.events) {
          const [event] = job.events
          if (event.data.error) {
            hasError = true
            message = event.data.error
          }
        }
        break
      case 'fireworksAI':
        {
          let state = typeof(job.state) === 'number' ? "PENDING" : job.state
          if (!state) {
            state = "Failed"
          }
          state = '' + state
          status = capitalize(state.toLowerCase())
          trainedTokens = fineTuningJob.trainedTokens
          price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
        }
        break
    }
    let event
    if (events) {
      event = events[0]
    }
    const toggleConfig = () => {
      this.setState({showConfig: !this.state.showConfig})
    }
    let modelConfigStyle
    if (!this.state.showConfig) {
      modelConfigStyle = { display: 'none'}
    }
    console.log({eta})
    let actions = []
    let copyModel
    if (fineTuningJob.outputModel) {
      copyModel = async () => {
        try {
          navigator.clipboard.writeText(fineTuningJob.outputModel)
        } catch (err) {
          console.error(err)
          //console.log(text)
        }
        await delay(0.3)
      }
    }
    if (copyModel) {
      actions.push({
        label: "Copy",
        icon: Copy,
        action: copyModel
      })
    }
    actions.push({
      button: close => <DeleteButton
                         label="Delete"
                         trash={async () => {
                           await this.deleteJob()
                           close()
                         }}/>
    })
    if (this.props.selected) {
      className += ' fineTuningJobSelected'
    }
    const onClick = () => {
      this.props.select(this)
      this.pollJob()
    }
    return <div key={job.id} className={className} style={style} ref={this.setRef} onClick={onClick}>
             <div className='datasetLeft'>
               <div className='datasetLeftIcon'>
                 <SimpleIcon legacyIconSize={legacyIconSize} src={icon}/>
               </div>
             </div>
             <div className='projectMiddle'>
               <div className='projectMiddleTop'>
                 <div className='projectTitle'>
                   <div className='projectName'>{dataset.name}</div>
                   <div className='projectDate'>{eta || date}</div>
                 </div>
                 {model && <div key='model' className='projectModelRow'>
                             <ModelIcon model={model}/>
                             <ModelLabel model={model}/>
                           </div>}
                 {message && <div key='msg' className='projectErrInfo'>{message}</div>}
                 {event && <div key='event' className='projectModelInfoRow modelPriceStyle'>
                             {event.message}
                           </div>}
                 {<div key='pricesEtc' className='projectModelInfoRow modelPriceStyle'>
                    {[status, `${epochs} epochs`, learningRate && `learning rate ${learningRate}`, formatTokens(trainedTokens), price, dur].filter(x=>x).map(x => <div className='infoItem'>{x}<div className='infoSep'>|</div></div>)}
                  </div>}
               </div>
               {checkpointsDiv}
               {canContinue &&
                <div key='continue' className='continueTrain'>
                  <div className='continueTrainButtons'>
                    <div className='continueTrainButtonsTopRow'>
                      <SimpleButton label={Continue} icon={Send} action={this.continueJob}/>
                      <GearButton action={toggleConfig}/>
                    </div>
                    <div className='continueFineTuneConfig' style={modelConfigStyle}>
                      <FineTuneConfig onCreate={this.setModelConfig} model={model}/>
                    </div>
                  </div>
                </div>
               }
               {canCancel && <div key='cancel' className='continueTrain'>
                               <div className='continueTrainButtons'>
                                 <SimpleButton label='Cancel' icon={Cross} action={this.cancelJob}/>
                               </div>
                             </div>}
             </div>
             <div className='datasetRight'>
               <ActionMenu className='fineTuningJobMenu' actions={actions}/>
               {this.props.open && <SimpleButton key='right' icon={Right} action={this.props.open}/> }
             </div>
           </div>


  }
}

class DatasetSplits extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isOpen: {},
      files: []
    }
  }

  fileStatus = {}
  
  componentDidMount() {
    this.props.me.getDatasetFiles(this.props.dataset).then(files => {
      let defaultValue = files.length === 1 ? 0.85 : 1
      files.forEach(file => {
        this.fileStatus[file.id] = defaultValue
      })
      this.setState({
        files
      })
    })
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  getFiles = () => this.fileStatus

  render() {
    const getFileStatus = file => {
      return this.fileStatus[file.id]
    }
    const setFileStatus = (file, status) => {
      this.fileStatus[file.id] = status
    }
    const dataset = this.props.dataset
    const hidden = { display: 'none' }
    let style1
    if (this.state.files.length <= 1) {
      style1 = { display: 'none' }
    }
    return <div className='trainingInputs'>
             <div className='trainingFileLabel' style={style1}>
               <SimpleIcon src={Folder}/>
               <div className='trainingFileName'>
                 {capitalize(dataset.name)}
               </div>
             </div>
             <div className='trainingFiles'>
               {
                 this.state.files.map(file => {
                   //debugger
                   const onChange = value => {
                     console.log("onChange", file.name, getFileStatus(file), '=>', value)
                     setFileStatus(file, value)
                     this.forceUpdate()
                   }
                   const toggleConfig = async () => {
                     this.state.isOpen[file.id] = !this.state.isOpen[file.id]
                     this.forceUpdate()
                   }
                   const value = getFileStatus(file)
                   const isOpen = this.state.isOpen[file.id]
                   let style
                   if (!isOpen) {
                     style = hidden
                   }
                   let icon
                   let legacyIconSize
                   if (file.isHuggingFace) {
                     icon = HuggingFace
                   } else {
                     icon = File
                     legacyIconSize = true
                   }
                   return <div key={file.id} className='trainingFile'>
                            <div className='trainingFileTopRow'>
                              <div key='label' className='trainingFileLabel'>
                                <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                                <div key='name' className='trainingFileName'>
                                  {capitalize(file.name)}
                                </div>
                              </div>
                            </div>
                            <div key='status' className='trainingFileStatus'>
                              <TwoWaySlider key='trainingSplit' lowerLabel="Train" upperLabel="Validate" value={value} onChange={onChange}/>
                            </div>
                          </div>
                 })
               }
             </div>
           </div>
  }
}

export class DatasetFineTuningJobLauncher extends Component {

  constructor (props) {
    super(props)
    this.state = {}
    this.state.options = {}
    this.state.models = {}
    this.state.datasets = []
    this.state.files = []
    this.state.isOpen = {}
  }

  setModelsView = view => {
    this.modelsView = view
    if (view) {
      if (!view.getOption('fineTunable')) {
        view.toggleOption('fineTunable')
      }
    }
  }

  getFiles = () => this.fileStatus

  getModelsForFineTuning = () => {
    const models = (isSelected, select) =>  {
      const allModels = this.props.getModels()
      console.log({allModels})
      const finetunables = allModels.filter(x => x.finetune)
      const finetunables1 = finetunables.filter(x => !x.isFinetune)
      debugger
      return finetunables.map(x => {
        x.isSelected = () => isSelected(x.id)
        x.select = () => select(x.id)
        return x
      })
    }
    const getPrice = model => {
      const { finetune } = model
      const { price } = finetune
      const { training } = price
      return {
        input: training.price,
        output: training.price
      }
    }
    const vendors = this.props.vendors.filter(x => {
                       switch(x.id) {
                         case 'google':
                         case 'meta':
                         case 'mistral':
                         case 'openai':
                         case 'alibaba':
                           return true
                       }
    })
    return { vendors, getPrice, models }
  }
  
  fileStatus = {}
  
  componentDidMount() {
    this.props.me.getDatasetFiles(this.props.dataset).then(files => {
      let defaultValue = files.length === 1 ? 0.85 : 1
      files.forEach(file => {
        this.fileStatus[file.id] = defaultValue
      })
      this.setState({
        files
      })
    })
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  renderFiles() {
    const getFileStatus = file => {
      return this.fileStatus[file.id]
    }
    const setFileStatus = (file, status) => {
      this.fileStatus[file.id] = status
      this.forceUpdate()
    }
    const dataset = this.props.dataset
    //debugger
    const hidden = { display: 'none' }
    let style1
    if (this.state.files.length <= 1) {
      style1 = { display: 'none' }
    }
    return <div className='trainingInputs'>
             <div className='trainingFileLabel' style={style1}>
               <SimpleIcon src={Folder}/>
               <div className='trainingFileName'>
                 {capitalize(dataset.name)}
               </div>
             </div>
             <div className='trainingFiles'>
               {
                 this.state.files.map(file => {
                   //debugger
                   const onChange = value => {
                     console.log("onChange", file.name, getFileStatus(file), '=>', value)
                     setFileStatus(file, value)
                   }
                   const toggleConfig = async () => {
                     this.state.isOpen[file.id] = !this.state.isOpen[file.id]
                     this.forceUpdate()
                   }
                   const value = getFileStatus(file)
                   const isOpen = this.state.isOpen[file.id]
                   let style
                   if (!isOpen) {
                     style = hidden
                   }
                   let icon
                   let legacyIconSize
                   if (file.isHuggingFace) {
                     icon = HuggingFace
                   } else {
                     icon = File
                     legacyIconSize = true
                   }
                   return <div key={file.id} className='trainingFile'>
                            <div className='trainingFileTopRow'>
                              <div key='label' className='trainingFileLabel'>
                                <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                                <div key='name' className='trainingFileName'>
                                  {capitalize(file.name)}
                                </div>
                              </div>
                            </div>
                            <div key='status' className='trainingFileStatus'>
                              <TwoWaySlider key='trainingSplit' lowerLabel="Train" upperLabel="Validate" value={value} onChange={onChange}/>
                            </div>
                          </div>
                 })
               }
             </div>
           </div>
  }

  renderDetail() {
    const onOptionsChanged = () => {
      this.setState({
        models: this.modelsView.getSelectedModelIds()
      })
    }
    const filterVendors = vendors => {
      return vendors.filter(vendor => {
        switch (vendor.id) {
          case 'openai':
          case 'gemini':
          case 'meta':
          case 'alibaba':
          case 'mistral':
            return true
        }
      })
    }
    const key = 'fine-tuning-model'
    const category = 'fine-tuning'
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    const modelsView =  <ModelsView
                          key={key}
                          models={models}
                          onOptionsChanged={onOptionsChanged}
                          category={category}
                          observeOptions={sub => {
                            return {
                              subscribe: sub => this.props.me.observeModelOptions(category).subscribe(change => {
                                ////////debugger
                                sub(change)
                              })
                            }
                          }}
                          singleSelect={true}
                          onCreate={this.setModelsView}
                          me={this.props.me}
                          getPrice={getPrice}
                          vendors={vendors}
                          menuActive={true}
                          vendorOnly={false}
                          noSplitView={true}
                          vendorOpen={true}
                          inline={true}
                          preview={true}/>
    return <div className='datasetFineTuningJobLauncherDetail'>
             <div className='ftLauncherSection'>
               <div className='ftLauncherSectionTitle'>
                 Model
               </div>
             <div className='ftLauncherSectionContent'>
               {modelsView}
             </div>
                 {
                   this.modelsView &&
                     this.modelsView.getSelectedModelIds().length === 0 &&
                     <div className='modelError'>
                       Please select a model.
                     </div>
                 }
             </div>
           </div>
  }

  configs = {}
  setModelConfig = (model, ref) => {
    ////debugger
    this.configs[model.id] = ref
  }

  renderModelConfig = model => {
    return <div key={model.id} className='continueFineTuneConfig'>
             <FineTuneConfig onCreate={(ref) => this.setModelConfig(model, ref)} model={model}/>
           </div>
  }
  
  startFineTune = async (files) => {
    let models = this.modelsView.getSelectedModelIds().map(model => {
      const config = this.configs[model]
      let opts = { epochs: 1, batchSize: 1, learningRateBoost: 0 }
      if (config) {
        opts = config.getOpts()
      }
      const { epochs, batchSize, learningRateBoost, seed } = opts
        return {
          epochs: Math.round(epochs|| 1), batchSize: Math.round(batchSize || 1),
          learningRateBoost, seed, model
        }
    })
    if (models.length === 0) {
      await delay(0.5)
      this.modelsView.hidePopup()
      return
    }
    await this.props.action({
      datasetId: this.props.dataset.id,
      models,
      datasetSplits: files
    })
  }


  render() {
    const isOpen = this.props.isOpen
    const toggleOpen = this.props.toggleOpen
    const onCreateConfig = config => {
      this.newFineTuneConfig = config
    }
    return <div className='fineTuningJobLauncherView'>
             <div className='fineTuningJobLauncherViewTop'>
               <div className='toolsetHeading'>
                 <SimpleButton label='Fine-tuning' icon={File}/>
               </div>
               <div className='jobButtons'>
                 <SimpleButton icon={isOpen ? Up :Down} action={toggleOpen}/>
               </div>
             </div>
             <div className='fineTuningJobLauncherContent' style={isOpen ? null: { display: 'none'}}>
               <div className='fineTuningJobModelSelection'>
                 {this.renderDetail()}
               </div>
               <div className='fineTuningJobSplits'>
                 {this.renderContent()}
               </div>
             <div className='fineTuningJobConfig'>
               <div className='ftLauncherSection'>
                 <div className='ftLauncherSectionTitle'>
                   Hyperparameters
                 </div>
                 <div className='ftLauncherSectionContent'>
                   <FineTuneConfig onCreate={onCreateConfig}/>
                 </div>
               </div>
             </div>
             </div>
           </div>
  }

  renderContent() {
    let action = async () => {
      await this.startFineTune(this.fileStatus)
    }
    const buttons = <SimpleButton key='fine-tune' label='Fine-tune' icon={Send} action={action}/>
    const models = this.state.models || []
    const trashModel = async () => {
    }
    const modelAction = async () => {
    }
    const modelAdd = async () => {
    }
    return <div className='datasetFineTuningJobLauncher'>
             <div className='ftLauncherSection'>
               <div className='ftLauncherSectionTitle'>
                 Splits
               </div>
               <div className='ftLauncherSectionContent'>
                 {this.renderFiles()}
               </div>
             </div>
           </div>
  }
}


export class DatasetFineTuningView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.options = {}
    this.state.selectedJobMetrics = []
  }

  setModelsView = view => {
    this.modelsView = view
    if (view) {
      if (!view.getOption('fineTunable')) {
        view.toggleOption('fineTunable')
      }
    }
  }

  onViewChange = view => {
    this.setState({
      calView: view
    })
  }
  
  onDayChange = day => {
    this.setState({
      calDay: day
   })
  }
  
  componentDidMount() {
    this.updateObserver()
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  datasetsById = {}

  resolveDataset = dataset => {
    return this.datasetsById[dataset]
  }

  updateObserver = () => {
    if (this.sub) {
      this.sub.unsubscribe()
    }
    const dataset = this.props.dataset
    const observable = this.props.me.observeDatasetFineTuningJobs({dataset: this.props.dataset})
    this.sub = observable.subscribe(change => {
      const { type, fineTuningJob } = change
      if (type == 'removed') {
        delete this.fineTuningJobs[fineTuningJob.id]
      } else {
        this.fineTuningJobs[fineTuningJob.id] = fineTuningJob
      }
      this.updateFineTuningJobsLater()
    })
  }

  fineTuningJobs = {}

  updateFineTuningJobsLater = () => {
    this.updateLater('fineTuningJobs', this.updateFineTuningJobs)
  }

  updateFineTuningJobs = () => {
    const fineTuningJobs = Object.values(this.fineTuningJobs)
    const events = fineTuningJobs.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    this.setState({
      fineTuningJobs,
      events
    })
  }

  configs = {}
  setModelConfig = (model, ref) => {
    ////debugger
    this.configs[model.id] = ref
  }

  renderModelConfig = model => {
    return <div key={model.id} className='continueFineTuneConfig'>
             <FineTuneConfig onCreate={(ref) => this.setModelConfig(model, ref)} model={model}/>
           </div>
  }
  
  getModelsForFineTuning = () => {
    const models = (isSelected, select) =>  {
      const allModels = this.props.getModels()
      console.log({allModels})
      const finetunables = allModels.filter(x => x.finetune)
      const finetunables1 = finetunables.filter(x => !x.isFinetune)
      debugger
      console.log({finetunables})
      return finetunables.map(x => {
        x.isSelected = () => isSelected(x.id)
        x.select = () => select(x.id)
        return x
      })
    }
    const getPrice = model => {
      const { finetune } = model
      const { price } = finetune
      const { training } = price
      return {
        input: training.price,
        output: training.price
      }
    }
    const vendors = this.props.vendors.filter(x => {
                       switch(x.id) {
                         case 'google':
                         case 'meta':
                         case 'mistral':
                         case 'openai':
                         case 'alibaba':
                           return true
                       }
    })
    return { vendors, getPrice, models }
  }

  onSearch = async searchTerm => {
    this.setState({
      searchTerm
    })
    const searchResults =  await this.props.me.searchDatasetFineTuneJobs(searchTerm)
    this.setState({
      searchResults
    })
  }

  optionsSubject = new Subject()

  renderDetail() {
    return this.renderLossGraph()
  }

  renderDetail() {
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    let fineTuningJobs = this.state.fineTuningJobs || []
    fineTuningJobs.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      fineTuningJobs = fineTuningJobs.filter(filt)
    }
    const onOptionsChanged = options => {
    }
    const observeOptions = () => {
      return this.optionsSubject
    }
    const saveOptions = async () => {
    }
    const onChange = () => {
    }
    const { dataset  } = this.props
    let onKeyDown
    let tabIndex
    let autoFocus
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        getIndex: () => {
          let index = 0
          for (const fineTuningJob of fineTuningJobs) {
            if (this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id) {
              break
            }
            index++
          }
          return index
        },
        getLength: () => fineTuningJobs.length,
        setIndex: index => {
          this.selectJob(fineTuningJobs[index])
        }
      })
      tabIndex = 0
      autoFocus = true
    }
    return <div className='fineTunedModelsPage bnSubpageTopLevel'>
             <div className='datasetsTopLine'>
                         
               <SearchField  me={this.props.me}  onSearch={this.onSearch}/>
             </div>
             {this.props.dataset && <div className='datasetsFinetuneJobsMainTitle'>
               <SimpleIcon src={Folder}/><div className='datasetsTitleName'>{dataset.name}</div>
                                    </div>}
             <div className='datasetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
             </div>
             <div className='datasetsScroller'
                  onKeyDown={onKeyDown}
                  tabIndex={tabIndex}
                  autoFocus={autoFocus}
             >
               {
                 fineTuningJobs.map(fineTuningJob => this.renderFineTuningJob({fineTuningJob,
                                                                               menu,
                                                                               models}))
               }
             </div>            
           </div>
  }

  renderLossGraph = () => {
    let lossGraphView
    let jobId = 'none'
    let model
    let busy
    let dataset = this.props.dataset
    if (this.state.selectedJob) {
      busy = this.state.selectedJob.platform === 'openai' &&
        this.state.selectedJob.job.finished_at &&
        (this.state.selectedJobMetrics || []).length === 0
      
      
      jobId = this.state.selectedJob.id
      model = <Model busy={busy} single={true} model={this.props.resolveModel(this.state.selectedJob.outputModel ||
                                                                              this.state.selectedJob.model)}/>
      if (!dataset) {
        dataset = {
          id: this.state.selectedJob.datset,
          name: this.state.selectedJob.name
        }
      }
      return <LossGraph
               key={jobId}
               jobId={jobId}
               busy={busy}
               dataset={dataset}
               model={model}
               metrics={this.state.selectedJobMetrics}/>
    }
  }

  selectJob = async (fineTuningJob, comp) => {
    if (this.state.selectedJob &&
        this.state.selectedJob.id === fineTuningJob.id &&
        this.listener) {
      return
    }
    this.setState({
      selectedJob: fineTuningJob,
      selectedJobMetrics: []
    })
    if (fineTuningJob.platform === 'openai') {
      if (fineTuningJob.job.finished_at) {
        this.loadSelectedJobMetrics(fineTuningJob)
      } else if (comp) {
        if (this.listener) {
          this.listener.unsubscribe()
        }
        this.listener = comp.observeMetrics().subscribe(metrics => {
          ////debugger
          this.setState({selectedJobMetrics: metrics})
        })
      }
    }
  }

  loadSelectedJobMetrics = async fineTuningJob => {
    ////debugger
    if (fineTuningJob.platform === 'openai') {
      //debugger
      let selectedJobMetrics = await getMetrics(fineTuningJob.id)
      if (!selectedJobMetrics) {
        const { metrics } = await this.props.me.getDatasetFineTuneMetrics(fineTuningJob.id)
        await storeMetrics(fineTuningJob.id, metrics)
        selectedJobMetrics = metrics
      }
      if (this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id) {
        this.setState({
          selectedJobMetrics
        })
      }
    }
  }

  renderFineTuningJob = ({fineTuningJob, models, menu}) => {
    const model = this.props.resolveModel(fineTuningJob.model)
    const dataset = {
      id: fineTuningJob.dataset,
      name: fineTuningJob.name
    }
    let open
    let openJob = this.openJob
    if (openJob) {
      open = async () => {
        const getSubpage = await openJob({dataset, fineTuningJob, model})
        const subpage = getSubpage(this.back)
        this.setState({
          subpage
        })
      }
    }
    return <DatasetFineTuningJob
             createFineTuningJob={this.props.createFineTuningJob}
             numExamples={this.props.numExamples}
             onClickHeightMap={selectStep}
             preview={this.props.preview}
             selected={this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id}
             select={(comp) => this.selectJob(fineTuningJob, comp)}
             key={fineTuningJob.id}
             open={open}
             me={this.props.me}
             resolveModel={this.props.resolveModel}
             dataset={dataset}
             fineTuningJob={fineTuningJob}
             menu={menu}
             model={model}
           />
    
  }

  openJob = async ({dataset, fineTuningJob, model}) => {
    dataset = await this.props.me.resolveDataset(dataset.id)
    let fineTunedModel
    if (fineTuningJob.outputModel) {
      fineTunedModel = await this.props.resolveModel(fineTuningJob.outputModel)
    }
    const createFineTuningJob = async (opts) => {
      const { job, error } = this.props.me.createFineTuningJob(opts)
      if (job) {
        await delay(0.5)
        const render = await this.openJob({dataset, fineTuningJob: job, model: await this.props.resolveModel(job.model) })
      } 
    }
    return (back) => () => <DatasetExamplesView
                             createFineTuningJob={createFineTuningJob}
                             openFileSystem={this.props.openFileSystem}                             
                             systemPromptFileSystem={this.props.systemPromptFileSystem}
                             copySystemPrompt={this.props.copySystemPrompt}
                             cutSystemPrompt={this.props.cutSystemPrompt}
                             systemPrompts={this.props.systemPrompts}
                             fineTunedModel={fineTunedModel}
                             fineTuningJob={fineTuningJob}
                             copyTask={this.props.copyTask}
                             key={dataset.id}
                             title={<ModelLabel model={fineTunedModel || model}/>}
                             dataset={dataset}
                             getModels={this.props.getModels}
                             me={this.props.me}
                             back={back}
                             breadcrumbs={this.props.breadcrumbs.concat([{
                               title: <ModelLabel model={fineTunedModel || model}/>,
                               back: this.back
                             }])}
                             vendors={this.props.vendors}
                             resolveModel={this.props.resolveModel}
                           />
  }
  
}

export class DatasetExamplesView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state = {
      examples: [],
      tasks: [],
      jobOpen: props.jobOpen
    }
  }
  
  componentDidMount() {
    if (!this.props.getLaunchFineTuningJob) {
      //debugger
    }
    this.updateObserver()
    this.searchSub = this.taskSubject.subscribe(() => {
      // invalidate search index
      this.searchIndex = null
    })
    if (this.props.fineTuningJob) {
      this.observeJob(this.props.fineTuningJob.id)
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
    if (this.jobSub) this.jobSub.unsubscribe()
  }

  examples = {}
  exampleCount = 0
  byHashCode = {}
  snapshot = {}
  datasetsById = {}
  
  updateObserver = async () => {
    //debugger
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.examples = {}
    this.exampleCount = 0
    
    const dataset = this.props.dataset
    const { datasets, observable } = await this.props.me.observeDatasetExamples(dataset)
    this.datasetsById = {}
    for (const dataset of datasets) {
      this.datasetsById[dataset.id] = dataset
    }
    this.sub = observable.subscribe(async change => {
      const { type, example } = change
      if (change.type === 'removed' || example.deleted) {
        //debugger
        if (this.examples[example.id]) {
          delete this.examples[example.id]
          this.exampleCount--
          for (const id in this.chatGPT.received) {
            if (id.startsWith(example.id)) {
              const message = this.chatGPT.received[id]
              this.chatGPT.invalidateCache(message)
              delete this.chatGPT.received[id]
            }
          }
          if (this.tasks[example.id]) {
            const task = this.tasks[example.id]
            delete this.tasks[example.id]
            this.taskSubject.next({
              type: 'removed',
              task
            })
          }
        }
        if (this.state.searchResults) {
          this.state.searchResults = this.state.searchResults.filter(x => x.id !== example.id)
        }
      } else {
        let { line, id, messages } = example
        const participants = ['train'].concat(dataset.models)
        let inReplyTo
        let ts = 1
        let hashCode = await (messages ? hash(line = JSON.stringify(messages)) : hash(line))
        example.line = line
        example.hashCode = hashCode
        this.byHashCode[hashCode] = example
        if (this.snapshot[hashCode]) {
          example.metrics = this.snapshot[hashCode].metrics
          console.log("linked metrics", example)
        }
        if (!messages) {
          messages = JSON.parse(line).messages
          messages.forEach((message, i) => {
            if (message.role === 'assistant') {
              const content = message.content
              if (!message.models) {
                message.models = participants.slice(1).map(model => {
                  return {
                    model,
                    content: ''
                  }
                })
              }
            } 
            message.id = example.id + '-'+i
            message.ts = ts + i
            message.model = 'train'
            message.inReplyTo = inReplyTo
            inReplyTo = message.id
          })
          //delete example.line
          example.messages = messages
        } else {
          let seen = {}
          for (const model of participants) {
            seen[model] = true
          }
          messages.forEach(message => {
            if (message.role === 'assistant') {
              if (!message.model) {
                message.model = 'train'
              }
              if (message.models) for (const {model} of message.models) {
                if (!seen[model]) {
                  participants.push(model)
                  seen[model] = true
                }
              }
            }
          })
          messages.forEach((message, i) => {
            message.id = example.id + '-'+i
            message.ts = ts + i
            if (message.role === 'user') {
              message.model = 'train'
              message.models = participants
            } else {
              message.model = 'train'
              message.inReplyTo = inReplyTo
            }
            inReplyTo = message.id
          })
        }
        messages.forEach(message => {
          message.task = example.id
          if (this.chatGPT && this.chatGPT.state.selectedTask) {
            if (message.task === this.chatGPT.state.selectedTask.id) {
              message.content = message.content || ''
              this.chatGPT.received[message.id] = message
              this.chatGPT.invalidateCache(message)
              this.messageSubject.next({
                type: 'added',
                message
              })
            }
          }
        })
        console.log({messages})
        if (!this.examples[example.id]) {
          this.exampleCount++
          this.examples[example.id] = example
        } else {
          this.examples[example.id] = example
        }
      }
      this.updateExamplesLater()
    })
    if (this.props.fineTuningJob && this.props.fineTuningJob.outputModel) {
      this.props.me.getDatasetFineTuneData(this.props.fineTuningJob.id).then(async result => {
        console.log('fineTuneData', result)
        const { trainingFile, validationFile, metrics, type } = result
        this.setState({
          metrics
        })
        let i = 0
        const examples = []
        if (trainingFile) for (const line of trainingFile.split('\n')) {
          this.hasSnapshot = true
          const hashCode = await hash(line)
          const example = {
            index: i,
            line,
            hashCode,
            messages: JSON.parse(line)
          }
          this.snapshot[hashCode] = example
          examples.push(example)
          i++
        }
        if (metrics) metrics.forEach((metric, i) => {
          const example = examples[i % examples.length]
          let d = this.byHashCode[example.hashCode]
          if (d) {
            if (!d.metrics) {
              d.metrics = []
            }
            d.metrics.push(metric)
          }
          d = this.snapshot[example.hashCode]
          if (d) {
            if (!d.metrics) {
              d.metrics = []
            }
            d.metrics.push(metric)
          }
        })
        this.forceUpdate()
      })
    }
  }

  updateTaskLater = task => {
    this.updateLater(task.id, () => this.updateTask(task))
  }

  updateTask = task => {
    this.chatGPT.updateTask(task)
  }

  updateExamplesLater = () => {
    this.updateLater('updateExamples', this.updateExamples)
  }

  getSystemPrompt = () => {
    let systemPrompt
    if (this.props.parent) {
      systemPrompt = this.props.parent.systemPrompt
    }
    ////debugger
    if (!systemPrompt && this.props.parents) {
      for (let i = this.props.parents.length - 1; i >= 0; i--) {
        systemPrompt = this.props.parents[i].systemPrompt 
        if (systemPrompt) {
          break
        }
      }
    }
    return systemPrompt
  }

  mkThread = (example, lineNumber1, content) => {
    const { id, dataset, messages, lastUpdated, lineNumber } = example
    let title = this.datasetsById[dataset].name + " #" +(lineNumber+1)
    if (!content) content = getContent(example)
    const thread = {
      wasBroadcast: this.tasks[example.id],
      id,
      title,
      description: content,
      example,
      lastUpdated,
      messages: messages.length,
      example,
      systemPrompt: example.systemPrompt || this.getSystemPrompt()
    }
    return thread
  }

  getExamples = () => {
    if (this.state.searchTerm) {
      return this.state.searchResults
    }
    const tasks = this.state.tasks || []
    if (this.props.fineTuningJob) {
      tasks.sort((x, y) => {
        return x.example.lineNumber - y.example.lineNumber
      })
    } else {
      tasks.sort((x, y) => {
        const cmp = y.lastUpdated - x.lastUpdated
        if (cmp) {
          return cmp
        }
        return x.example.lineNumber - y.example.lineNumber
      })
    }
    return tasks
  }

  tasks = {}

  firstTime = true

  updateExamples = () => {
    //debugger
    const examples = Object.values(this.examples)
    examples.sort((x, y) => {
      return getContent(x).localeCompare(getContent(y))
    })
    examples.forEach((x, i) => {
      x.lineNumber = i
    })
    const tasks = examples.map((example, i) => this.mkThread(example, example.lineNumber))
    for (const task of tasks) {
      const type = this.tasks[task.example.id] ? 'updated' : 'added'
      this.tasks[task.example.id] = task
      this.taskSubject.next({
        type,
        task
      })
    }
    let { selectedTask } = this.state
    if (selectedTask) {
      selectedTask = this.tasks[selectedTask.id]
    }
    this.setState({
      examples,
      tasks,
      selectedTask
    }, this.checkFirstTaskLater)
  }

  checkFirstTaskLater = () => {
    this.updateLater("checkFirstTask", this.checkFirstTask)
  }

  checkFirstTask = () => {
    //debugger
    if (!isMobile() && this.firstTime && this.state.tasks.length > 0 && this.chatGPT) {
      this.firstTime = false
      const first = this.state.tasks[0]
      this.setState({
        selectedTask: first
      })
      this.forceUpdate()
    }
  }

  back = () => {
    this.props.back()
  }

  taskSubject = new Subject()
  
  onCreateChatGPT = chatGPT => {
    this.chatGPT = chatGPT
    this.checkFirstTask()
  }

  getSearchIndex = () => {
    const documents = Object.values(this.tasks).map(task => {
      const { example } = task
      const { messages } = example
      const { description, title } = task
      return {id: example.id, task, description, title, messages: messages.map(message => message.content).join('\n\n') }
    })
    if (!this.searchIndex) {
      this.searchIndex = new InMemorySearch({
        id: 'id',
        fields: [{field: 'description', boost: 10}, {field: 'messages', boost: 1}],
        documents,
      })
    }
    return this.searchIndex
  }

  messageSubject = new Subject()

  isInline() {
    return true
  }

  deleteTask = async (task) => {
    const { example } = task
    const id = example.id
    const examples = this.getExamples()
    let toSelect
    if (this.state.selectedTask &&
        this.state.selectedTask.id === task.id) {
      const index = examples.findIndex(x => x.id === task.id)
      const prev = examples[index-1]
      const next = examples[index+1]
      toSelect = next || prev
    }
    delete this.examples[id]
    delete this.tasks[id]
    this.forceUpdate()
    this.props.me.deleteDatasetExample(id)
    if (toSelect) {
      this.state.selectedTask = toSelect
    }
    this.forceUpdate()
  }

  setContinueSplits = ref => {
    this.continueSplits = ref
  }

  getContinueConfig = () => {
    if (!this.lastJobConfig) {
      const {
        epochs,
        batchSize,
        learningRateBoost 
      } = this.getFineTuningJob()
      this.lastJobConfig = {
        epochs,
        batchSize,
        learningRateBoost
      }
    }
    return this.lastJobConfig
  }

  onChangeContinueConfig = change => {
    this.lastJobConfig = change
    this.forceUpdate()
  }

  setContinueConfig = config => {
    this.continueConfig = config
  }
  
  renderSidebar() {
    const selectThread = (thread) => {
      let subpage
      const back = () => {
        this.setState({
          selectedTask: null,
          subpage: null
        })
      }
      if (isMobile()) {
        subpage = () => {
          const { renderChat } = this.getRenders()
          return renderChat({back, title: thread.title})
        }
      }
      this.setState({
        selectedTask: thread,
        subpage,
      }, () => {
        this.examplesView.scrollToItem(this.state.selectedTask)
      })
    }
    const renderTask = ({task, onClick, dateComp, dollars, content, reps}) => {
      const self = this
      let copyTask
      let cutTask
      if (this.props.copyTask) {
        copyTask = async () => {
          const self = this
          await this.props.copyTask(this.chatGPT, task)
        }
      }
      if (this.props.cutTask) {
        cutTask = async () => {
          const self = this
          await this.props.cutTask(this.chatGPT, task)
        }
      }
      let trash
      if (this.deleteTask) {
        trash = async () => {
          const self = this
          debugger
          await this.deleteTask(task)
        }
      }
      let actions =  [
      ]
      if (cutTask) {
        actions.push({
          icon: Cut,
          action: cutTask,
          label: "Cut"
        })
      }
      actions.push({
        icon: Copy,
        action: copyTask,
        label: "Copy"
      })
      if (trash) {
        actions.push({
          button: (close) => <DeleteButton key='deleteTask' label='Delete' trash={
                                             async () => {
                                               await trash()
                                               close()
                                             }
                                           }/>
        })
      }

      const { example } = task
      let { metrics, hashCode, line } = example
      let isTrain
      let background
      if (!metrics && hashCode) {
        const snap = this.snapshot[hashCode]
        if (snap) {
          metrics = snap.metrics
          isTrain = true
        }
      }
      let style
      if (metrics) {
        style = {
          background: toGradient(metrics)
        } 
      } else {
        if (isTrain) {
        }
      }
      let models
      if (example.messages) {
        let Models = {}
        for (const message of example.messages) {
          if (message.role === 'assistant') {
            if (message.models) {
              for (const {model} of message.models) {
                Models[model] = model
              }
            }
          }
        }
        const active = Object.keys(Models)
        models = active.map(x => this.props.resolveModel(x)).filter(x => x)
      }

      let className = 'taskTitle'
      if (this.state.selectedTask && this.state.selectedTask.id == task.id) {
        className += ' taskTitleSelected'
      }
      return <div key={example.id} className={className} style={style} onClick={onClick} data-task-id={example.id}>
               <div  className='taskTitleLeft'>
	         <div className='keyboardMenuItemIcon'><ReactSVG src={Chat}/></div>
               </div>
	       <div className='keyboardMenuItemLabel taskDescriptionLabel'>
                 {content}
                 <div className='taskModels'>{models.map(model => <ModelLabel model={model}/>)}</div>
               </div>
               <div className='keyboardMenuItemRight'>
                 <div className='modelPrice'>{dollars}</div>
                 <div className='exampleReps'>×{reps}</div>
                 {dateComp}
                 <div className='keyboardMenuItemActions fineTuningTaskActions'>
                   <ActionMenu className='fineTuningTaskMenu' actions={actions}/>
                 </div>
               </div>
             </div>
    }
    const renderHeader = () => {
    }
    const onCreate = examples => {
      this.examplesView = examples
    }
    const getHistory = () => {
      return {
        count: 0,
        update: () => {}
      }
    }
    const newExample = async () => {
      const example = await this.props.me.addDatasetExample({dataset: this.props.dataset.id,
                                                             messages: [
                                                               {
                                                                 role: "user",
                                                                 content: "Prompt goes here"
                                                               },
                                                               {
                                                                 role: "assistant",
                                                                 model: "train",
                                                                 content: "Completion goes here."
                                                               }]
                                                            })
      this.examples[example.id] = example
      const task = this.mkThread(example)
      selectThread(task)
    }
    const examples = this.getExamples()
    let className = 'datasetExamplesSidebar'
    if (this.state.selectedTask) {
      className += ' datasetExamplesSidebarWithSelection'
    }
    examples.sort((x, y) => {
      const cmp = y.lastUpdated - x.lastUpdated
      return cmp
    })
    let fineTune
    if (this.props.getLaunchFineTuningJob) {
      const back = () => {
        this.setState({
          subpage: null
        })
      }
      const launch = async ({models, datasetSplits, previous}) => {
        //debugger
        const launched = await this.props.createFineTuningJob(
          {
            datasetId: this.props.dataset.id,
            models,
            datasetSplits,
            previous
          })
        const { job, error } = launched
        //debugger
        if (job) {
          this.setState({
            fineTuningJob: job
          })
        } 
        return { job, error }
      }
      fineTune = async () => {
        this.setState({
          subpage: () =>  {
            this.props.getLaunchFineTuningJob(this.props.dataset, launch)(back,
                                                                          this.props.breadcrumbs)
          }
        })
      }
    } else {
      fineTune = async () => {
        const lastJob = this.fineTuningJobView.props.fineTuningJob
        const model = lastJob.outputModel || lastJob.model
        let lastJobConfig = lastJob.config ||
            {
              batchSize: 1,
              learningRateBoost: 0,
              epochs: 1
            }
        if (this.continueConfig) {
          lastJobConfig = this.continueConfig.getOpts()
        }
        let datasetSplits = {}
        if (this.continueSplits) {
          datasetSplits = this.continueSplits.getFiles()
        }
        let models = [{
          model,
          ...lastJobConfig
        }]
        const launched = await this.props.createFineTuningJob(
          {
            datasetId: this.props.dataset.id,
            models,
            datasetSplits
          })
        const { job, error } = launched
        if (job) {
          const back = () => {
            this.setState({
              subpage: null
            })
          }
          this.setState({
            subpage: () =>  {
              const title = <ModelLabel model={model}/>
              return <DatasetExamplesView 
                       resolveModel={this.resolveModel}
                       systemPromptFileSystem={this.props.systemPromptFileSystem}
                       openFileSystem={this.props.openFileSystem}                             
                       fineTuningJob={job}
                       copyTask={this.props.copyTask}
                       key={'fine-tuning-examples'+dataset.id+'-'+model}
                       title={title}
                       dataset={this.props.dataset}
                       getModels={this.props.getModels}
                       me={this.props.me}
                       back={back}
                       breadcrumbs={this.props.breadcrumbs.concat([{
                         back,
                         title: <div className='breadcrumb-model'>{title}</div>
                       }])}
                       vendors={this.props.vendors}
                     />
            }
          })
        }
      }
    }
    let fineTuneButton
    if (this.fineTuningJobView) {
      const jobAction = this.fineTuningJobView.getJobAction()
      if (jobAction) {
        const { type, icon, label, action } = jobAction
        const callAction = async () => {
          const self = this
          debugger
          await action(this.continueConfig, this.continueSplits)
        }
        const onCreateConfig = ref => {
          this.setContinueConfig(ref)
        }
        const onCreateDatasetSplits = ref => {
          this.setContinueSplits(ref)
        }
        if (type === 'continue' || type === 'retry') {
          fineTuneButton = <SimpleConfigButton label={label} action={callAction} icon={icon}>
                             <div className='continueConfigPopup'>
                               <div className='continueConfigPopupContent'>
                                 <div className='continueConfigPopupTitle'>
                                   Splits
                                 </div>
                                 <DatasetSplits dataset={this.props.dataset} me={this.props.me} onCreate={onCreateDatasetSplits}/>
                               </div>
                               <div className='continueConfigPopupContent'>
                                 <div className='continueConfigPopupTitle'>
                                   Hyperparameters
                                 </div>
                                 <FineTuneConfig value={this.getContinueConfig()} onChange={this.onChangeContinueConfig} onCreate={onCreateConfig}/>
                               </div>
                             </div>
                           </SimpleConfigButton>
        } else {
          fineTuneButton = <SimpleButton label={label} icon={icon} action={action}/>
        }
      }
    }
    if (!fineTuneButton) {
      fineTuneButton = <SimpleButton icon={Plus} label="Fine-tune" action={this.newFineTune}/>
    }
    const copy = async () => {
    }
    const paste = async () => {
    }
    const actions = [
      {
        label: "Copy",
        icon: Copy,
        action: copy
      },
      {
        label: "Paste",
        icon: Import,
        action: paste
      }
    ]
    return <div className={className}>
             <div className='datasetExamplesSidebarButtons'>
               <div className='datasetExamplesSidebarButtonsLeft'>
                 {fineTuneButton}
                 <SimpleButton icon={Plus} label="Example" action={newExample}/>
                 <SimpleButton icon={Share} label="Export" action={this.exportDataset}/>
               </div>
               <div className='datasetExamplesSidebarButtonsRight'>
                 <ActionMenu actions={actions}/>
               </div>
             </div>
             <Examples me={this.props.me}
                      view={'recent'}
                      onCreate={onCreate}
                      getBefore={getHistory}
                      getAfter={getHistory}
                      selectedTask={this.state.selectedTask}
                      examples={examples}
                      renderTask={renderTask}
                      renderHeader={renderHeader}
                      selectThread={selectThread}
                      openThread={selectThread}/>
             <div className='datasetExamplesCount'>
               {examples.length} Examples
               </div>
           </div>
  }

  setFineTuningJobLauncher = fineTuningJobLauncher => {
    this.fineTuningJobLauncher = fineTuningJobLauncher
  }

  newFineTune = async () => {
    const models = this.fineTuningJobLauncher.modelsView.getSelectedModelIds()
    //debugger
    if (models.length === 0) {
      this.setState({
        jobOpen: true
      })
      return
    }
    const [model] = models
    const config = this.fineTuningJobLauncher.newFineTuneConfig.getOpts()
    const datasetSplits = this.fineTuningJobLauncher.getFiles()
    const { job, error } = await this.props.me.createFineTuningJob({
      datasetId: this.props.dataset.id,
      models: [{
        model,
        ...config
      }],
      datasetSplits,
    })
    console.error(error)
    this.setState({
      jobOpen: true,
      fineTuningJob: job
    })
    this.observeJob(job.id)
  }

  observeJob = (id) => {
    if (this.jobSub) {
      this.jubSub.unsubscribe()
    }
    this.jobSub = this.props.me.observeFineTuningJob(id).subscribe(fineTuningJob => {
      fineTuningJob.id = id
      //debugger
      this.setState({
        fineTuningJob
      })
    })
  }

  exportDataset = async () => {
    const { dataset } = this.props
    const models = ['train']
    const examples = Object.values(this.examples)
    examples.sort((x, y) => {
      return x.lineNumber - y.lineNumber
    })
    const fileContents = examples.map(example => {
      const { messages } = example
      return JSON.stringify({
        messages: messages.map(message => {
          const { role, content, tool_calls, tool_call_id } = message
          return { role, content, tool_calls, tool_call_id }
        })
      })
    }).join('\n')
    const filename = dataset.name + ".jsonl"
    const blob = new Blob([fileContents], {type: "text/plain;charset=utf-8"})
    await saveAs(blob, filename)
  }


  createFineTuningJob = async opts => {
    const fineTuningJob = await this.props.me.createFineTuningJob(opts)
    this.setState({
      fineTuningJob
    })
  }

  getFineTuningJob = () => {
    return this.state.fineTuningJob || this.props.fineTuningJob
  }

  setFineTuningJobView = ref => {
    this.fineTuningJobView = ref
  }

  getRenders() {
    const self = this
    const trashDataset = async () => {
      await this.props.trash()
      this.back()
    }

    const getExamplesHistory = (lastUpdated, limit) => {
      return this.getExamples().filter(t => t.lastUpdated < lastUpdated).slice(0, limit)
    }
    
    const observeTasks = (date) => {
      const start = startOfWeek(date)
      const end = endOfWeek(date)
      const filt = t => t.lastUpdated >= start && t.lastUpdated <= end
      const tasks = this.getExamples().filter(filter).map(task => {
        return { type: 'added', task  }
      })
      return concat(from(tasks), this.taskSubject)
    }

    const observeTaskMessages = ({limit}) => {
      const task = this.state.selectedTask
      if (!task) {
        return from([])
      }
      const { example } = task
      return concat(from(example.messages.map(message => {
        return { type: 'added', message}
      })), this.messageSubject)
    }

    const observeRecentTasks = () => {
      const tasks = this.getExamples().map(task => {
        return { type: 'added', task }
      })
      let len = tasks.length
      return concat(from(tasks), this.taskSubject)
    }
    
    const deleteChatMessage = async (chatGPT, messageId) => {
      const { example } = this.state.selectedTask
      await this.props.me.deleteDatasetExampleMessage(example, messageId)
    }
    
    const searchChatMessages = () => {
      return []
    }
    
    const onFile = async chatGPT => {
    }
    
    const deleteTask = async (chatGPT, task) => {
      const { example } = task
      const threads = this.getExamples()
      const index = threads.findIndex(x => x.id === task.id)
      let prev = threads[index-1]
      let next = threads[indes+1]
      if (example.id === 'new-task') {
        await delay(0.5)
      } else {
        await this.props.me.deleteDatasetExample(example.id)
      }
      if (this.examples[example.id]) {
        delete this.examples[example.id]
        this.exampleCount--
      }
      delete chatGPT.tasks[task.id]
      delete this.tasks[task.id]
      debugger
      selectTask(next || prev)
      this.forceUpdate()
    }
    
    const searchTasks = async (searchTerm) => {
      let searchResults = await (this.getSearchIndex().search(searchTerm))
      //debugger
      searchResults = searchResults.map(x => x.task)
      if (searchTerm) {
        let found
        if (this.state.selectedTask) {
          found = searchResults.find(x => x.id === this.state.selectedTask.id)
        }
        if (!found) {
          this.state.selectedTask = searchResults[0]
        }
      }
      this.setState({
        searchTerm,
        searchResults,
      }, () => {
        if (!searchTerm) {
          if (this.examplesView && this.state.selectedTask) {// fix me!
            //debugger
            this.examplesView.scrollToItem(this.state.selectedTask)
          }
        }
      })
    }

    const createNewTask = () => {
      this.props.me.createNewDatasetExample(this.props.dataset.id).then(example => {
        const task = this.mkThread(example, 0, "New Training Example")
        this.tasks[task.id] = task
        this.taskSubject.next({
          type: 'added',
          task
        })
        this.chatGPT.selectThread(task)
      })
      return null
    }
    const onCloseTask = (chatGPT, task) => {
      const { example } = task
      if (example.id === 'new-task') {
        
      }
    }
    const uploadFile = () => {
    }
    const uploadTask = async chatGPT => {
    }
    const getMessage = (chatGPT) => {
      const msg = chatGPT.received[this.streamingId]
      if (!msg) {
      }
      return msg
    }
    const correct = async (chatGPT, {task, message, reply, model, judges}) => {
      const { example } = task
      let i = 0
      for (const message of example.messages) {
        if (message.id === reply.id) {
          break
        }
        i++
      }
      await this.props.me.correctDatasetExample({
        dataset: this.props.dataset.id,
        exampleId: example.id,
        messageIndex: i,
        judges,
        model
      })
    }

    const createNewMessage = (chatGPT, opts) => {
      const { text, contents, models, task } = opts
      const example = this.examples[task]
      return {
        role: 'user',
        id: example.id + '-'+example.messages.length,
        inReplyTo: example.id + '-'+(example.messages.length-1),
        content: text,
        contents,
        models,
        task,
        ts: Date.now(),
        utcOffset: this.props.me.utcOffset,
      }
    }

    const streamChat = async (chatGPT, msg, opts) => {
      let judges
      if (chatGPT.state.judgeChat) {
        judges = chatGPT.judgeView.getSelectedModelIds()
      }
      const dataset = this.props.dataset.id
      const example = this.examples[this.state.selectedTask.id]
      const exampleId = example.id
      let {id, role, content, utcOffset} = msg
      let models = chatGPT.modelsView.getSelectedModelIds()
      const { replay, config } = opts
      //debugger
      if (replay) {
        models = [replay]
        role = 'assistant'
        const rsp = example.messages.find(x => x.inReplyTo === id)
        if (!rsp) {
          throw new Error("Can't find response")
        }
        this.streamingId = rsp.id 
      } else {
        const rsp = example.messages.find(x => x.inReplyTo === id)
        if (rsp) {
          this.streamingId = rsp.id
        } else {
          //debugger
        }
      }
      const data = {id: this.streamingId, dataset, exampleId, role, content, models, judges, magpie: opts.magpie, config}
      return await this.props.me.streamInferenceExample(data, opts)
    }
    
    const getModels = () => {
      return [{
        size: "small",
        id: 'train',
        title: 'Train',
        getIcon: () => Hashtag,
        name: 'Train',
        vendor: "N/A",
        vendorId: "n/a",
        contexts: [{
          price: { input: 0, output: 0 }
        }],
        sortOrder: -1,
        isModel: id => id === 'train'
      }].concat(this.props.getModels())
    }

    const saveUserEdit = async (chatGPT, {task, message, content}) => {
      const {example} = task
      const lastUpdated = Date.now()
      example.lastUpdated = lastUpdated
      const found = example.messages.find(x => x.id === message.id)
      if (content !== undefined) {
        found.content = content
        task.description = content
      }
      await this.props.me.updateDatasetExample(example.id, { lastUpdated, messages: example.messages })
      this.forceUpdate()
    }

    const saveReplyEdit = async (chatGPT, {task, model, message, content, corrected}) => {
      const {example} = task
      //debugger
      let found = message
      if (model !== 'train') {
        found = message.models.find(x => x.model === model)
      }
      const lastUpdated = Date.now()
      example.lastUpdated = lastUpdated
      if (!found) {
        message.models.push({
          model, content, corrected
        })
      } else {
        found.content = content
        found.corrected = corrected
      }
      chatGPT.purgeCache(message)
      if (this.props.fineTuningJob) {
        this.forceUpdate()
        return
      }
      await this.props.me.updateDatasetExample(example.id, { lastUpdated, messages: example.messages })
    }

    const judge = async (chatGPT, judges, req, reply, useMsg) => {
      const { example } = this.state.selectedTask
      const dataset = this.props.dataset.id
      const exampleId = example.id
      let messageIndex = 0
      for (const message of example.messages) {
        if (message.id === reply.id) {
          break
        }
        messageIndex++
      }
      if (messageIndex === example.messages.length) {
        console.error("can't match reply")
        return
      }
      return await this.props.me.judgeExample({dataset, exampleId, judges, messageIndex })
    }
    const applyReply = async (chatGPT, {message, reply}) => {
      const { example } = this.state.selectedTask
      await this.props.me.applyReplyToExample({dataset: this.props.dataset.id, example, message, reply})
    }


    const deleteModel = async (chatGPT, {task, model }) => {
      const { example } = task
      example.messages.forEach(message => {
        switch (message.role) {
          case 'user':
            if (message.models) {
              message.models = message.models.filter(x => x !== model)
            }
            break
          case 'assistant':
            if (message.models) {
              message.models = message.models.filter(x => x.model !== model)
            }
        }
      })
      return await this.props.me.deleteModelFromExample({
        dataset: this.props.dataset.id,
        exampleId: example.id,
        model
      })
    }
    let copy
    let pasteTask
    let getNewButton

    const exportDataset = async () => {
      const models = ['train']
      const fileContents = await this.props.me.getDatasetExamples(this.props.dataset.id, models)
      const filename = dataset.name + ".jsonl"
      const blob = new Blob([fileContents], {type: "text/plain;charset=utf-8"})
      await saveAs(blob, filename)
    }
    
    const fineTunePage = (back) => {
      const examples = this.getExamples()
      return <DatasetFineTuningView
        numExamples={examples.length}
        openFileSystem={this.props.openFileSystem}                             
        systemPromptFileSystem={this.props.systemPromptFileSystem}
        key={'fine-tuning-'+dataset.id}
        title={dataset.name}
        back={back}
        title={'Fine-tune'}
        me={this.props.me}
        dataset={dataset}
        getModels={this.props.getModels}
        resolveModel={this.props.resolveModel}
        vendors={this.props.vendors}
        fineTunedModels={this.props.fineTunedModels}
      />
    }
      
    const subpages =[
    ]
    if (!this.props.dataset.isFolder) {
      getNewButton = (chatGPT, defaultNewAction) => {
        const actions = [
          {
            icon: Hashtag,
            label: "New Example",
            action: defaultNewAction
          },
          {
            label: 'Copy ' + this.props.dataset.name,
            icon: Copy,
            action: this.props.copyDataset
          },
          {
            label: "Export",
            icon: Share,
            action: exportDataset
          },
          {
            label: "Fine-tune "+ this.props.dataset.name,
            action: () => this.chatGPT.openSubpage(fineTunePage)
          }
        ]
        if (this.props.pasteTask) {
          actions.push({
            icon: Paste,
            label: "Paste Example",
            action: async () => {
              await this.props.pasteTask(this.props.dataset)
              if (this.cal) {
                this.cal.selectView('recent')
              }
            }
          })
        }
        return <ActionMenu actions={actions} position={'bottom left'}/>
      }
    } else {
      getNewButton = () => null
    }
    const dataset = this.props.dataset
    let className = 'datasetExamplesViewChatGPT bnSubpageTopLevel'
    if (this.props.fineTuningJob) {
      className += ' datasetExamplesViewFineTune'
      const exportData = async () => {
        const { trainingFile, validationFile, metrics, type } = 
              await this.props.me.getDatasetFineTuneData(this.props.fineTuningJob.id)
        const dataset = await this.props.me.resolveDataset(this.props.fineTuningJob.dataset)
        if (trainingFile) {
          const filename = dataset.name + "-train.jsonl"
          const blob = new Blob([trainingFile], {type: "text/plain;charset=utf-8"})
          await saveAs(blob, filename)
        }
        if (validationFile) {
          const filename = dataset.name + "-validate.jsonl"
          const blob = new Blob([validationFile], {type: "text/plain;charset=utf-8"})
          await saveAs(blob, filename)
        }
      }
      getNewButton = (chatGPT, defaultNewAction) => {
        const actions = [
          {
            icon: Share,
            label: "Export Data",
            action: exportData
          }
        ]
        return <ActionMenu actions={actions} position={'bottom left'}/>
      }
    }
    const selectSystemPrompt = async (chatGPT, task, systemPrompt) => {
      await this.props.me.saveDatasetExampleSystemPrompt(task, systemPrompt)
      this.forceUpdate()
    }
    const deleteSystemPrompt = async (chatGPT, task) => {
      const { example } = task
      example.systemPrompt = task.systemPrompt = null
      this.forceUpdate()
      await this.props.me.saveDatasetExampleSystemPrompt(task, null)
      this.forceUpdate()
    }
    const renderModelConfig = model => {
      const onCreate = ref => {
      }
      return <div key={model.id} className='continueFineTuneConfig'>
               <ModelConfig onCreate={onCreate} model={model}/>
             </div>
    }
    const sortTasks = (chatGPT, tasks, view) => {
      if (false && view === 'all') {
        tasks.sort((x, y) => {
          return x.description.localeCompare(y.description)
        })
      } else {
        tasks.sort((x, y) => {
          const cmp = y.lastUpdated = x.lastUpdated
          if (cmp) {
            return cmp
          }
          return x.example.lineNumber - y.example.lineNumber
        })
      }
      return tasks
    }
    const renderHeader = () => {
      const isOpen = this.state.jobOpen
      const toggleOpen = () => {
        this.setState({
          jobOpen: !this.state.jobOpen
        })
      }
      console.log("renderheader")
      let className = 'toolsetTask'
      const file = this.getFineTuningJob()
      let selectStep
      let selectedStep
      let contentDiv
      const examples = this.getExamples()
      if (file) {
        const model = this.props.resolveModel(file.model)
        const selectStep = step => {
          const examples = this.getExamples()
          const index = (step -1) % examples.length
          const selected = examples[index]
          if (selected) {
            this.setState({
              selectedTask: selected
            })
          }
        }
        const selectedStep = this.state.selectedTask?.example.lineNumber
        console.log({selectedStep})
        contentDiv = <DatasetFineTuningJob
                       createFineTuningJob={this.props.createFineTuningJob}
                       numExamples={examples.length}
                       isOpen={isOpen}
                       toggleOpen={toggleOpen}
                       onClickHeightMap={selectStep}
                       selectedStep={selectedStep}
                       onCreate={this.setFineTuningJobView}
                       me={this.props.me}
                       resolveModel={this.props.resolveModel}
                       dataset={this.props.dataset}
                       fineTuningJob={this.getFineTuningJob()}
                     />
      }  else {
        contentDiv =
          <DatasetFineTuningJobLauncher
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            getModels={this.props.getModels}
            vendors={this.props.vendors|| []}
            prices={this.props.prices}
            me={this.props.me}
            onCreate={this.setFineTuningJobLauncher}
            createFineTuningJob={this.createFineTuningJob}
            dataset={this.props.dataset}/>
      }
      return <div className='fineTuningJobDetails'>
               <SearchField onSearch={searchTasks} me={this.props.me}/>
               {contentDiv}
             </div>
    }
    let fineTunedModel
    let fineTuningJob = this.getFineTuningJob()
    if (fineTuningJob) {
      fineTunedModel = this.props.resolveModel(fineTuningJob.outputModel)
    }
    const renderChat = (opts = {}) => {
      const { title, back } = opts
      let breadcrumbs
      if (back) {
        breadcrumbs = this.props.breadcrumbs.concat([{
          back,
          title
        }])
      }
      const getOpenSubpage = f => {
        const breadcrumbs = this.props.breadcrumbs || []
        const back = () => {
          this.setState({
            subpage: null
          })
        }
        self.setState({
          subpage: () => f({back, breadcrumbs})
        })
      }
      return <div className={className}>
               <ChatGPT2
                 title={title}
                 back={back}
                 getOpenSubpage={getOpenSubpage}
                 task={this.state.selectedTask}
                 trash={trashDataset}
                 fullScreen={!isMobile()}
                 calendarViewSelection={['all', 'recent', 'week', 'day']}
                 sortTasks={sortTasks}
                 chatClass='datasetChat'
                 key={this.props.dataset.id}
                 fineTunedModel={fineTunedModel}
                 resource={this.props.dataset.id}
                 configure={renderModelConfig}
                 systemPromptFileSystem={this.props.systemPromptFileSystem}
                 cutMessage={this.props.cutMessage}
                 copyMessage={this.props.copyMessage}
                 pasteMessage={this.props.pasteMessage}
                 openFileSystem={this.props.openFileSystem}
                 copySystemPrompt={this.props.copySystemPrompt}
                 cutSystemPrompt={this.props.cutSystemPrompt}
                 deleteSystemPrompt={deleteSystemPrompt}
                 selectSystemPrompt={selectSystemPrompt}
                 systemPrompts={this.props.systemPrompts}
                 cutConversation={this.props.cutConversation}
                 copyMessage={this.props.copyMessage}
                 cutMessage={this.props.cutMessage}
                 pasteMessage={this.props.pasteMessage}
                 deleteMessage={this.props.deleteMessage}
                 subpages={subpages}
                 getNewButton={getNewButton}
                 deleteModel={deleteModel}
                 applyReply={applyReply}
                 judge={judge}
                 correct={correct}
                 saveUserEdit={saveUserEdit}
                 saveReplyEdit={saveReplyEdit}
                 getPlaceholder={(chatGPT) => chatGPT.state.judgeChat? "Talk to judge":  "User prompt"}
                 getDiscussionsLabel={() => 'Examples'}
                 availableCredits={Math.max(this.props.wordsPurchased - this.props.wordsUsed, 0)}
                 prices={this.prices}
                 vendors={this.props.vendors}
                 me={this.props.me}
                 goBack={back}
                 defaultModel={chatGPT => 'Attunewise Mini'}
                 onCreate={this.onCreateChatGPT}
                 fileTypes=".jsonl"
                 deleteTask = {deleteTask}
                 getTitle={
                   (chatGPT) => {
                     return ""
                   }
                 }
                 getTasksTitle={
                   chatGPT => this.props.dataset.name
                 }
                 getButtonLabel ={
                   (chatGPT, message) => {
                     if (chatGPT.state.judgeChat) {
                       return "Judge"
                     }
                     else if (!chatGPT.state.sending && message.inReplyTo) {
                       return 'Replay'
                     }
                     return 'Send'
                   }
                 }
                 vendors={this.props.vendors}
                 models={
                   (chatGPT, isSelected, select) => getModels().map(x => {
                     x.isSelected = () => isSelected(x.id)
                     x.select = () => select(x.id)
                     return x
                   })
                 }
                 isSearchFieldVisible={
                   (chatGPT, messages) => {
                     return chatGPT.state.slide === 0
                   }
                 }
                 observeTaskMessages={observeTaskMessages}
                 deleteChatMessage={deleteChatMessage}
                 getHistory={(chatGPT, task, earliest, limit) => []}
                 searchChatMessages={searchChatMessages}
                 getMessage={getMessage}
                 createNewMessage={createNewMessage}
                 streamChat={streamChat}
                 uploadFile={this.props.me.uploadFile}
                 onCloseTask={onCloseTask}
                 breadcrumbs={breadcrumbs}
               />
             </div>
    }
    
    return { renderHeader, renderChat }
  }
  
  renderContent() {
    const { renderHeader, renderChat } = this.getRenders()
    let detail
    if (!isMobile()) {
      detail = renderChat()
    }
    return <div className='splitView'>
             <div className='splitViewTop'>
               {renderHeader()}
             </div>
             <div className='splitViewBottom'>
               <div className='splitViewSidebar'>
                 {this.renderSidebar()}
               </div>
               <div className='splitViewDetail'>
                 {detail}
               </div>
             </div>
           </div>
  }
}


class DatasetFolderView extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  commitEdit = async () => {
    await this.props.me.setDatasetName(this.props.dataset, this.state.name)
    this.setState({
      editing: false
    })
  }

  cancelEdit = () => {
    this.setState({
      editing: false
    })
  }

  setInput = ref => {
    if (ref !== this.input) {
      if (this.input) {
        if (isDesktop()) {
          this.input.removeEventListener('keydown', this.handleKeyDown)
        }
      }
      this.input = ref
      if (this.input) {
        if (this.state.name === 'New Folder') {
          this.input.select()
        }
        if (isDesktop()) {
          this.input.addEventListener('keydown', this.handleKeyDown)
        }
      }
    }
  }

  componentDidMount() {
    this.sub = observeClipboard().subscribe(dataset => {
      this.forceUpdate()
    })
  }

  componentWillUnmount() {
    this.sub.unsubscribe()
  }

  render() {
    const { dataset } = this.props
    const { lastUpdated } = dataset
    let name = dataset.name || 'New Folder'
    let icon = Folder
    let contents = dataset.contents || []
    let date = fromNow(lastUpdated)
    const trash = async () => {
      await this.props.me.deleteDataset(dataset)
    }
    const edit = async () => {
      this.setState({
        name,
        editing: true
      })
    }
    let nameDiv
    if (!this.state.editing) {
      nameDiv = <div className='projectName'>{name}</div>
    } else {
      const onChange = (event) => {
        this.setState({
          name: event.target.value
        })
      }
      let onBlur
      if (!isDesktop()) {
        onBlur = e => this.commitEdit()
      }
      nameDiv = <ClickAwayListener onClickAway={this.cancelEdit}>
                  <div className='projectName projectNameEditor'><input ref={this.setInput} onBlur={onBlur} autoFocus value={this.state.name} onChange={onChange}/></div>
                </ClickAwayListener>
    }
    let icon1 = <div className='projectIcon projectFolderIcon' ><ReactSVG src={icon}/></div>
    const onDrop = e => this.props.onDrop(this)
    const onPaste = event => {
      const items = event.clipboardData.items;
      for (let item of items) {
        if (item.type === 'application/attunewiseDataset') {
          item.getAsString((data) => {
            const componentData = JSON.parse(data)
          })
        }
      }
    }
    let cut
    if (this.props.parent) {
      cut = async () => {
        await copyDataset(this.props.dataset)
        await this.props.me.removeDatasetParent({
          child: this.props.dataset,
          parent: this.props.parent.id
        })
      }
    }
    const copy = async () => copyDataset(this.props.dataset)
    let paste
    if (clipboardDataset &&
        clipboardDataset.id !== this.props.dataset.id &&
        !this.props.parents.find(x => x.id === clipboardDataset.id)) {
      paste = () => this.props.me.addDatasetParent({child: clipboard,
                                                    parent: this.props.dataset.id})
    }
    let actions =  [
      {
        icon: EditIcon,
        action: edit,
        label: "Edit"
      }
    ]
    actions.push({
      icon: Share,
      action: cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: copy,
      label: "Copy"
    })
    if (paste) {
      actions.push({
        icon: Paste,
        action: paste,
        label: "Paste"
      })
    }
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton noClickAway label='Delete' trash={async () => {
                                           await trash()
                                           close()
                                         }}/>
      })
    }
    let markdown
    return <div key={dataset.id}
                className='dataset datasetFolder'
                onPaste={onPaste}
                onDrop={onDrop}>
             <div className='projectLeft' >
               {icon1}
             </div>
             <div className='projectMiddle' onClick={() => this.props.open()}>
               <div className='projectTitle'>
                 {nameDiv}
                 <div className='projectDate'>{date}</div>
               </div>
               <div className='projectModelInfoRow modelPriceStyle'>
               </div>
               {markdown &&<div className='projectSummary' >
                 <Markdown components={getComponents()}>{markdown}</Markdown>
                           </div>}
             </div>
             <div className='projectRight'>
               <ActionMenu actions={actions}/>
             </div>
           </div>
  }
}


export class DatasetsView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.datasets = []
    this.state.events = []
    this.state.searchResults = []
  }

  openDataset = ({dataset, models}) => {
    const trash = async () => {
      await this.props.me.deleteDataset(dataset)
    }
    this.setState({
      subpage: () => <DatasetExamplesView
                       systemPromptFileSystem={this.props.systemPromptFileSystem}
                       openFileSystem={this.props.openFileSystem}
                       copySystemPrompt={this.props.copySystemPrompt}
                       cutSystemPrompt={this.props.cutSystemPrompt}
                       systemPrompts={this.props.systemPrompts}
                       showFineTune={true}
                       parents={this.props.parents}
                       copyDataset={() => copyDataset(dataset)}
                       copyTask={this.props.copyTask}
                       cutTask={this.props.cutTask}
                       pasteTask={this.props.pasteTask}
                       key={dataset.id}
                       title={dataset.name}
                       dataset={dataset} models={models}
                       me={this.props.me}
                       back={this.back}
                       trash={trash}
                       vendors={this.props.vendors}
                       resolveModel={this.resolveModel}
                       breadcrumbs={this.props.breadcrumbs.concat([{
                         title: dataset.name,
                         back: this.back
                       }])}
                       getModels={this.props.getModels}/>
    })
  }

  openModel = (model) => {
    this.setState({
      popup: () => <ModelConfig model={model} me={this.props.me} title={<ModelLabel model={model}/>} back={this.back}/>
    })
  }

  setModelsView = view => {
    this.modelsView = view
    if (view) {
      if (!view.getOption('fineTunable')) {
        view.toggleOption('fineTunable')
      }
    }
  }
  datasets = {}
  componentDidMount() {
    let parent = this.props.parent ? this.props.parent.id : undefined
    this.datasetsSub = this.props.me.observeDatasets({parent}).subscribe(change => {
      const { type, dataset } = change
      if (type === 'removed') {
        delete this.datasets[dataset.id]
      } else {
        this.datasets[dataset.id] = dataset
      }
      this.updateDatasetsLater()
    })
    this.clipboardSub = observeClipboard().subscribe(() => {
      this.forceUpdate()
    })
    if (this.props.onCreate) this.props.onCreate(this)
  }

  componentWillUnmount() {
    if (this.datasetsSub) {
      this.datasetsSub.unsubscribe()
    }
    if (this.clipboardSub) {
      this.clipboardSub.unsubscribe()
    }
  }

  updateDatasetsLater = () => {
    this.updateLater('updateDatasets', this.updateDatasets)
  }

  updateDatasets = () => {
    const datasets = Object.values(this.datasets)
    const events = datasets.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    this.setState({
      datasets,
      events
    })
  }

  resolveModel = id => this.props.models.find(model => model.id === id)
  
  openExportPopup = () => {
    
  }

  openFineTuningPopup = () => {
    const dataset = this.props.parent
    const buttons = <SimpleButton label='Fine-tune' icon={Send} action={this.startFineTune}/>
    this.setState({
      subpage: () => <DatasetFineTuningView
                       openFileSystem={this.props.openFileSystem}                             
                       systemPromptFileSystem={this.props.systemPromptFileSystem}
                       key={'fine-tuning-'+dataset.id}
                       title={dataset.name}
                       back={this.back}
                       title={'Fine-tune'}
                       me={this.props.me}
                       dataset={dataset}
                       getModels={this.props.getModels}
                       resolveModel={this.resolveModel}
                       vendors={this.props.vendors}
                       fineTunedModels={this.props.fineTunedModels}
                       breadcrumbs={this.props.breadcrumbs.concat([{
                         title: 'Fine-tune',
                         back: this.back
                       }])}
                       
                     />
      
    })
  }

  openModelPopup = ({title, buttons, configure}) => {
    const subject = new Subject()
    const observeOptions = () => {
      return subject
    }
    const saveOptions = async () => {
    }
    const onChange = () => {
    }
    const { vendors, models, getPrice } = this.getModelsForFineTuning()
    this.setState({
      subpage: () => <ModelPopup dataset={this.props.parent} title={title} me={this.props.me} back={this.back}
                                 getPrice={getPrice}
                                 models={models}
                                 vendors={vendors}
                                 onChange={onChange}
                                 fineTunedModels={this.props.fineTunedModels}
                                 observeOptions={observeOptions}
                                 saveOptions={saveOptions}
                                 configure={configure}
                     >
                       <div className='model-popup-buttons'>
                         {buttons}
                       </div>
                       </ModelPopup>
    })
  }
  
  openFolder = dataset => {
    this.setState({
      subpage: () => <DatasetsView
                       openFileSystem={this.props.openFileSystem}                             
                       systemPromptFileSystem={this.props.systemPromptFileSystem}
                       resolveModel={this.resolveModel}
                       copySystemPrompt={this.props.copySystemPrompt}
                       cutSystemPrompt={this.props.cutSystemPrompt}
                       systemPrompts={this.props.systemPrompts}
                       copyTask={this.props.copyTask}
                       cutTask={this.props.cutTask}
                       parents={(this.props.parents || []).concat([dataset])}
                       parent={dataset}
                       getModels={this.props.getModels}
                       prices={this.props.prices}
                       datasets={this.props.datasets}
                       models={this.props.models}
                       fineTunedModels={this.props.fineTunedModels}
                       vendors={this.props.vendors}
                       me={this.props.me}
                       breadcrumbs={this.props.breadcrumbs.concat([{
                         title: dataset.name || 'New Folder',
                         back: this.back
                       }])}
                       back={this.back}
                       title={dataset.name || 'New Folder'}/>
    })
  }

  onDayChange = day => {
    this.setState({
      calDay: day
    })
  }


  onViewChange = view => {
    this.setState({
      calView: view
    })
  }

  getModelsForFineTuning = () => {
    const models = (isSelected, select) => this.props.getModels().filter(x => x.finetune).map(x => {
      x.isSelected = () => isSelected(x.id)
      x.select = () => select(x.id)
      return x
    })
    debugger
    const getPrice = model => {
      const { finetune } = model
      const { price } = finetune
      const { training } = price
      return {
        input: training.price,
        output: training.price
      }
    }
    const vendors = this.props.vendors.filter(x => {
                       switch(x.id) {
                         case 'google':
                         case 'meta':
                         case 'mistral':
                         case 'openai':
                           return true
                       }
    })
    return { vendors, getPrice, models }
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  onBack() {
    this.state.huggingFaceOpen = false
    super.onBack()
  }

  renderHuggingFace = () => {
    const importDataset = async opts => {
      const { dataset, numSamples } = opts
      const { url } = dataset
      this.back()
      await this.props.me.uploadDataset({
        url,
        parent: this.props.parent ? this.props.parent.id : undefined,
        numSamples 
      })
    }
    const sampleDataset = async opts => {
      const { dataset, numSamples } = opts
      const { url } = dataset
      return await this.props.me.sampleDataset(dataset, numSamples)
    }
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    return  () => <HuggingFaceDatasets
                    title={"Sample from Dataset"}
                    me={this.props.me}
                    importDataset={importDataset}
                    sampleDataset={sampleDataset}
                    title={'Hugging Face'}
                    cancel={cancel}
                    breadcrumbs={this.props.breadcrumbs.concat([{
                      title: "Sample from Dataset",
                      back,
                    }])}
                    back={back}/>
  }

  openHuggingFace = () => {
    let subpage
    if (true || isMobile()) {
      subpage = this.renderHuggingFace()
    }
    this.setState({
      huggingFaceOpen: true,
      subpage
    })
  }

  getDatasets = () => {
    let datasets
    if (this.state.searchTerm) {
      datasets = this.state.searchResults
    } else {
      datasets = this.state.datasets || []
    }
    datasets.sort((x, y) => y.lastUpdated - x.lastUpdated)
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      datasets = datasets.filter(filt)
    }
    return datasets
  }

  setSystemPrompt = () => {
    const select = async systemPrompt => {
      const { parent } = this.props
      systemPrompt = clone(systemPrompt)
      parent.systemPrompt = systemPrompt
      await this.props.me.saveDatasetOptions({dataset: parent.id, options: { systemPrompt }})
      this.back()
    }
    const subpage = () => <SystemPrompts
                            copy={this.props.copySystemPrompt}
                            cut={this.props.cutSystemPrompt}
                            me={this.props.me}
                            title={'System Prompts'}
                            select={select}
                            systemPrompts={this.props.systemPrompts}
                            breadcrumbs={this.props.breadcrumbs.concat([{
                              title: "System Prompts",
                              back: this.back,
                            }])}
                            back={this.back}/>
    this.setState({
      subpage
    })
  }

  renderFooter() {
    const toolsets = Object.values(this.datasets)
    const folders = toolsets.filter(x => x.isFolder)
    if (isDesktop()) {
      let folderCount = <div className='toolsetItemCount'>{folders.length} Folders</div>
      let fileCount = <div className='toolsetItemCount'>{toolsets.length - folders.length} Datasets</div>
      let calendarPopup = <div className='toolsetCalendarPopup'></div>
      return <div className='toolsetFooter'>
               {folderCount}
               {fileCount}
               {calendarPopup}
             </div>
    }
  }

  renderDetail() {
    const datasets = this.getDatasets()
    let style1 = this.state.huggingFaceOpen ? { display: 'none' } : null
    let renderHuggingFace
    if (this.state.huggingFaceOpen) {
      renderHuggingFace = this.renderHuggingFace()
    }
    let fileChooser
    return <div className='datasetsDetail'>
             <SearchField onSearch={this.onSearch} me={this.props.me} menu={fileChooser}/>
             <div className='datasetsRight'>
               <div className='datasetsRightFolderTitle' style={style1}>
               </div>
               <div className='datasetsScroller' style={style1}>
                 {this.renderDatasets(datasets.filter(x => !x.isFolder))}
               </div>
               {renderHuggingFace && renderHuggingFace()}
             </div>
           </div>
  }

  
  
  renderDatasets = datasets => {
    const { fineTunedModels } = this.props
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    const {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    } = this.getEventHandlers()
    return datasets.map(dataset => {
      if (dataset.isFolder) {
        const open = () =>  this.openFolder(dataset)
        return <DatasetFolderView
                 openFileSystem={this.props.openFileSystem}                             
                 systemPromptFileSystem={this.props.systemPromptFileSystem}
                 dataset={dataset}
                 resolveModel={this.props.resolveModel}
                 parents={this.props.parents}
                 parent={this.props.parent}                                             
                 onDrop={onDropDataset}
                 me={this.props.me}
                 getModels={this.props.getModels}
                 open={open}
                 dataset={dataset}
                 fineTunedModels={fineTunedModels}
                 getModels={models}
                 vendors={vendors}
                 me={this.props.me}
                 getPrice={getPrice}
                 models={models}/>
      } else {
        const open = () => this.openDataset({dataset, models})
        return <DatasetView key={dataset.id}
                            openFileSystem={this.props.openFileSystem}                             
                            systemPromptFileSystem={this.props.systemPromptFileSystem}
                            cutTask={this.props.cutTask}
                            configure={null}
                            parents={this.props.parents}
                            parent={this.props.parent}
                            onDrag={onDragDataset}
                            openDataset={open}
                            dataset={dataset}
                            fineTunedModels={fineTunedModels}
                            getModels={models}
                            vendors={vendors}
                            me={this.props.me}
                            getPrice={getPrice}
                            models={models}
                            resolveModel={this.props.resolveModel}
               />
      }
    })
  }

  getEventHandlers = () => {
    const handleDataTransfer = async (event, transfer) => {
      const models = []//this.modelsView.getSelectedModelIds()
      if (transfer.files.length > 0) {
        event.preventDefault();
        const parent = this.props.parent ? this.props.parent.id : undefined
        for (const file of transfer.files) {
          const dataset = await this.props.me.createNewDataset({parentDataset: parent, summary: "Loading dataset...",
                                                                name: file.name, generatingDataset: true})
          await this.props.me.uploadDataset({dataset: dataset.id, parent, file, models})
        }
        return true;
      } else {
        const uriList = transfer.getData('text/uri-list')
        if (uriList) {
          event.preventDefault()
          // Split the URI list by newline to handle multiple URLs
          const urls = uriList.split('\n').filter(url => url.trim() !== '')
          console.log('URLs detected:', urls)
          for (const url of urls) {
            await this.props.me.uploadDataset({url, models})
          }
        }
      }
    }
    const onSearch = async (searchTerm) => {
      this.setState({
        searchTerm
      })
      const { results }  = await this.props.me.searchDatasets(this.props.parent, searchTerm)
      ////debugger
      this.setState({
        searchResults: results
      })
    }
    const onDrop = event => {
      handleDataTransfer(event, event.dataTransfer)
    }

    const onDragDataset = dataset => {
      this.setState({
        selectedDataset: dataset
      })
    }

    const onPasteDataset = async dataset => {
      const src = this.props.parent
      if (src.id !== dataset.id) {
        await this.props.me.addDatasetParent({child: dataset, parent: this.props.parent.id})
      }
    }
    
    const onDropDataset = async dataset => {
      ////debugger
      const src = this.state.selectedDataset || this.props.parent
      const target = dataset
      if (src !== target) {
        if (target.props.dataset.isFolder) {
          if (await this.props.me.addDatasetParent({child: src.props.dataset, parent: target.props.dataset.id})) {
            this.openFolder(target.props.dataset)
          }
        }
      }
    }
    return {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    }
  }

  getActionInfo = () => {
    const { fineTunedModels } = this.props
    const datasets = this.getDatasets()
    const {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    } = this.getEventHandlers()
    console.log({datasets})
    const onOptionsChanged = () => {
    }
    const onChange = () => {
    }
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    const newDataset = async () => {
      await this.props.me.createNewDataset({parentDataset: this.props.parent})
    }
    const newFolder = async () => {
      await this.props.me.createNewDatasetFolder(this.props.parent)
    }

    let fileChooser = <div className='fileMenu'><FileChooser onCreate={this.setFileChooser} handleDataTransfer={handleDataTransfer}/></div>

    const actions = [
      {
        label: "Dataset",
        action: newDataset,
        icon: Plus,
        legacyIconSize: true
      },
      {
        label: "Folder",
        action: newFolder,
        icon: NewFolder
      },
      {
        label: "Import",
        action: () => this.fileChooser.chooseFile(),
        legacyIconSize: true,
        icon: OpenFile
      },
      {
        label: "Sample",
        action: () => this.openHuggingFace(),
        icon: HF
      }
    ]
    if (this.props.parent) {
      const copy = () => copyDataset(this.props.dataset)
      actions.push(
        {
          label: "Copy",
          action: copy,
          icon: Copy
        }
      )
      if (clipboardDataset &&
          !this.props.parents.find(x => x.id === clipboardDataset.id)) {
        actions.push({
          label: "Paste",
          icon: Paste,
          action: () => onPasteDataset(clipboardDataset)
        })
      }
      actions.push({
        icon: Share,
        label: "Export",
        action: this.openExportPopup
      })
    }
    return {
      fileChooser,
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer,
      actions
    }
  }

  renderContent() {
    const { fineTunedModels } = this.props
    const datasets = this.getDatasets()
    const {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer,
      actions,
      fileChooser,
    } = this.getActionInfo()
    
    let rightContent
    let leftContent
    if (isMobile()) {
      leftContent = this.renderDatasets(datasets)
    } else  {
      leftContent = this.renderDatasets(datasets.filter(x => x.isFolder))
    }
    const menu = <ActionMenu actions={actions} position={"bottom left"}/>
    let sysPromptTitle
    if (this.props.parent) {
      sysPromptTitle = 'System Prompt'      
      if (this.props.parent.systemPrompt) {
        sysPromptTitle = this.props.parent.systemPrompt.title
      }
    }
    let searchField
    let calendar
    let buttons
    if (isMobile()) {
      searchField = <div className='datasetsTopLine'>
                      {menu}
                      <SearchField me={this.props.me}  menu={fileChooser} onSearch={onSearch}/>
                    </div>
      calendar = <div className='datasetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
                 </div>

    } else {
      if (actions.length > 0)  {
        buttons = <div className='desktopToolsetActions'>
                    {actions.map(x => {
                      const { icon, label, button, action } = x
                      if (!label) {
                        //debugger
                      }
                      if (button) return button(() => {})
                      return <SimpleButton icon={icon} label={label} action={action}/>
                    })
                    }
                  </div>
      }
    }
    return <div className='fineTunedModelsPage bnSubPageTopLevel'>
             {buttons}
             {searchField}
             {calendar}
             {this.props.parent && <div className='datasetsFinetuneJobsLine'>
                                     <div className='datasetsFinetuneJobsLineRow'>
                                       <div className='datasetFineTuneJobsTitle' onClick={this.setSystemPrompt} >{sysPromptTitle}</div><SimpleButton icon={Right} action={this.setSystemPrompt}/>
                                     </div>
                                     <div className='datasetsFinetuneJobsLineRow'>
                                       <div className='datasetFineTuneJobsTitle' onClick={this.openFineTuningPopup} >Fine-tune</div><SimpleButton icon={Right} action={this.openFineTuningPopup}/>
                                     </div>
                                   </div>
             }
             <div className='datasetsScroller' onDrop={onDrop}>
               {
                 leftContent
               }
             </div>
             {
               rightContent
             }
           </div>
  }
}

class DatasetView extends Component {

  constructor (props) {
    super(props)
    this.state = {
      options: {}
    }
  }

  optionsSubject = new Subject()

  setModelsView = view => {
    this.modelsView = view
  }

  renderModelConfig = model => {
    console.log("renderModelConfig", model)
    let opts = this.state.options[model.id]
    if (!opts) {
      opts = this.state.options[model.id] = {
        epochs: 1,
        batchSize: 1,
        learningRateBoost: 0,
      }
    }
    const setEpochs = (epochs) => {
      opts.epochs = epochs
      this.forceUpdate()
    }
    const getEpochs = () => {
      return opts.epochs
    }
    const setBatchSize = (batchSize) => {
      opts.batchSize = batchSize
      this.forceUpdate()
    }
    const getBatchSize = () => {
      return opts.batchSize
    }
    const setLearningRate = (learningRate) => {
      opts.learningRateBoost = learningRate || 0
      this.forceUpdate()
    }
    const getLearningRate = () => {
      return opts.learningRateBoost
    }
    return <div key={'modelConfig-'+model.id} className='modelConfig'>
             <div className='tempSlider'><Slider label='Epochs' onChange={setEpochs} value={getEpochs()} bounds={[1, 99]}/></div>
             <div className='tempSlider'><Slider label='Batch' onChange={setBatchSize} value={getBatchSize()} bounds={[1, 32]}/></div>
             <div className='tempSlider'><Slider label='Boost' onChange={setLearningRate} value={getLearningRate()} bounds={[-10, 10]}/></div>
           </div>
  }

  commitEdit = async () => {
    await this.props.me.saveDatasetSummary({dataset: this.props.dataset.id, summary: this.editable.innerText})
    this.setState({
      editingSummary: false
    })
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  cancelEdit = () => {
    this.setState({
      editingSummary: false
    })
  }

  setEditable = ref => {
    if (!this.editable) {
      ref.innerText = this.props.dataset.summary || ""
      this.editable = ref
      ref.focus()
    }
  }

  renderEditable = () => {
    const stopEditing = async () => {
      if (!this.state.editingSummary) {
        return
      }
      this.state.editingSummary = false
      if (isMobile()) {
        await this.commitEdit()
      } else {
        this.cancelEdit()
      }
    }
    const cancelEdit = async () => {
      this.cancelEdit()
    }
    return <ClickAwayListener mouseEvent={'mousedown'} onClickAway={cancelEdit}>
             <div className='editableContentContainer'>
               <div ref={this.setEditable} className='editableContent editableContentSummary'
                    onKeyDown={isDesktop() && this.handleKeyDown}
                    onPaste={pasteText}
                    contentEditable={true}
                    onBlur={stopEditing}/>
             </div>
           </ClickAwayListener>
  }
  
  startFineTune = async (files) => {
    let models = this.modelsView.getSelectedModelIds().map(model => {
      const opts = this.state.options[model] || {
        epochs: 1,
        batchSize: 1,
        learningRateBoost: 0
      }
      for (const field of ['epochs', 'batchSize', 'learningRateBoost']) {
        opts[field] = Math.round(opts.field)
      }
      const { epochs, batchSize, learningRateBoost, seed } = opts
      return {
        epochs, batchSize, learningRateBoost, seed, model
      }
    })
    if (models.length === 0) {
      await delay(0.5)
        return
    }
    await this.props.me.createFineTune({
      dataset: dataset.id,
        datasetSplits: files,
      models,
    })
  }
  
  render() {
    const { dataset } = this.props
    const { id, models, lastUpdated, summary, generatingDataset, examples } = dataset
    const model = models && models[0]
    let trainedTokens = 0
    let epochs = 0
    const open = () => this.props.openDataset({dataset, models})
    const edit = async () => {
      this.setState({
        editingSummary: true
      })
    }
    const play = async () => {
    }
    const trash = async () => {
      if (this.props.parent) {
        await this.props.me.removeDatasetParent({child: dataset, parent: this.props.parent.id})
      } else {
        await this.props.me.deleteDataset(dataset)
      }
    }
    let playing
    let date = fromNow(lastUpdated)

    const onOptionsChanged = () => {
    }

    const observeOptions = () => {
      return this.optionsSubject
    }

    const saveOptions = async (options) => {
      console.log("SAVE OPTIONS", options)
      await this.props.me.saveDatasetOptions({dataset: this.props.dataset.id,
                                              options,
                                              fineTuningOptions: this.state.options})
    }

    const onChange = () => {
    }


    const exportDataset = async () => {
      let models = this.modelsView.getSelectedModelIds()
      if (models.length === 0) models = ['train']
      const fileContents = await this.props.me.getDatasetExamples(dataset.id, models)
      let i = 0
      for (const fileContent of fileContents) {
        const blob = new Blob([fileContent], {type: "text/plain;charset=utf-8"})
        let filename
        const model = models[i]
        if (model === 'train') {
          filename = this.props.dataset.name + ".jsonl"
        } else {
          filename = this.props.dataset.name + "-" + model + ".jsonl"
        }
        await saveAs(blob, filename)
        i++
      }
    }

    const button = ({icon, label, action}) => <SimpleButton label={label} action={action} icon={icon}/>
    
    const menu = (label, icon, buttons, configure) => <ModelsView
                                                        key={dataset.id + '-' +label + '-menu'}
                                                        getIcon={() => icon}
                                                        className={'fineTunedModelsView'}
                                                        onOptionsChanged={onOptionsChanged}
                                                        onCreate={this.setModelsView}
                                                        me={this.props.me}
                                                        category={'dataset'}
                                                        getPrice={this.props.getPrice}
                                                        models={this.props.getModels}
                                                        vendors={this.props.vendors}
                                                        onChange={onChange}
                                                        observeOptions={observeOptions}
                                                        saveOptions={saveOptions}
                                                        configure={configure}
                                                      >
                                                        <div className='dataset-control-buttons'>
                                                          {buttons}
                                                        </div>
                                                      </ModelsView>

    const exportButton = button({icon: Share, label: "Export", action: exportDataset})
    const exportMenu = menu('export', Share, exportButton)
    const showSettings = () => {
    }
    const fineTuneMenu = menu('fine-tune',
                              Send,
                              button({icon: Send, label: "Fine-tune", action: startFineTune}),
                              this.renderModelConfig
                             )

    let icon1
    let legacyIconSize
    if (dataset.isHuggingFace) {
      icon1 = HuggingFace
    } else {
      icon1 = File
      legacyIconSize=true
    }
    
    let icon = <div className='datasetIcon' onClick={open}><SimpleIcon legacyIconSize={legacyIconSize} src={icon1}/></div>    
    if (generatingDataset) {
      icon = <div className='projectLeftBusy'><SimpleIcon src={Spin}/></div>
    }
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    let copy = () => copyDataset(dataset)
    let cut
    if (this.props.parent) {
      cut = async () => {
        await copyDataset(this.props.dataset)
        await this.props.me.removeDatasetParent({
          child: this.props.dataset,
          parent: this.props.parent.id
        })
      }
    }
    let paste

    let summaryDiv
    if (this.state.editingSummary) {
      summaryDiv = this.renderEditable()
    } else {
      summaryDiv = <Markdown>{summary}</Markdown>
    }
    let actions =  [
      {
        icon: EditIcon,
        action: edit,
        label: "Edit"
      }
    ]
    actions.push({
      icon: Share,
      action: cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: copy,
      label: "Copy"
    })
    if (paste) {
      actions.push({
        icon: Paste,
        action: paste,
        label: "Paste"
      })
    }
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton noClickAway label='Delete' trash={async () => {
                                           await trash()
                                           close()
                                         }}/>
      })
    }

    let { heading, title } = dataset
    const markdown = `
## ${heading}
${title}
`
    return <div key={dataset.id}
                className='dataset'
                draggable={!this.state.editingSummary}
                onDragStart={onDragStart}
                >
             <div className='projectLeft'>
               {icon}
             </div>
             <div className='projectMiddle' onClick={!this.state.editingSummary ? open: undefined}>
               <div className='projectTitle'>
                 <div className='projectName'>{dataset.name}</div>
                 <div className='projectDate'>{date}</div>
               </div>
               <div className='projectModelInfoRow modelPriceStyle'>
               </div>
               <div className='projectSummary' >
                 <Markdown components={getComponents()}>{markdown}</Markdown>
               </div>
             </div>
             <div className='projectRight'>
               <ActionMenu actions={actions}/>
             </div>
           </div>

  }
}

