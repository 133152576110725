import React from 'react';

export const CalendarDate = ({ date = new Date() }) => {
  const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const day = date.getDate();

  const styles = {
    container: {
      width: '300px',
      height: '300px',
      backgroundColor: '#fff',
      borderRadius: '38px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif',
    },
    header: {
      background: 'linear-gradient(to bottom, #FF625D 0%, #FF3B30 100%)',
      color: 'white',
      padding: '15px',
      textAlign: 'center',
      fontSize: '38px',
      fontWeight: '500',
      letterSpacing: '1px',
      textShadow: '0 1px 1px rgba(0,0,0,0.1)',
    },
    body: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      background: 'linear-gradient(to bottom, #FFFFFF 0%, #F7F7F7 100%)',
    },
    day: {
      fontSize: '180px',
      fontWeight: '300',
      color: '#1c1c1e',
      lineHeight: '0.9',
      letterSpacing: '-4px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>{month}</div>
      <div style={styles.body}>
        <div style={styles.day}>{day}</div>
      </div>
    </div>
  );
};

