import React from 'react';

/**
 * Creates a silver heightmap effect from an array of float values.
 * 
 * @param {Array<number>} heightData - Array of float values representing heights (typically 0.0 to 1.0)
 * @param {Object} options - Configuration options
 * @param {number} options.width - Width of the output canvas
 * @param {number} options.height - Height of the output canvas
 * @param {number} options.cellSize - Size of each heightmap cell in pixels
 * @param {string} options.baseColor - Base silver color (default: '#C0C0C0')
 * @param {string} options.highlightColor - Highlight silver color (default: '#E8E8E8')
 * @param {string} options.shadowColor - Shadow silver color (default: '#707070')
 * @param {number} options.contrast - Contrast amount for the heightmap (default: 0.5)
 * @param {number} options.ambient - Ambient light level (default: 0.3)
 * @param {number} options.lightAngle - Angle of the light source in radians (default: Math.PI/4)
 * @returns {HTMLCanvasElement} - Canvas element with the rendered silver heightmap
 */
function createSilverHeightmap(heightData, options = {}) {
  // Default options
  const {
    width = 500,
    height = 500,
    cellSize = 10,
    baseColor = '#C0C0C0',
    highlightColor = '#E8E8E8',
    shadowColor = '#707070',
    contrast = 0.5,
    ambient = 0.3,
    lightAngle = Math.PI / 4
  } = options;

  // Create canvas and get context
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  // Calculate grid dimensions
  const cols = Math.floor(width / cellSize);
  const rows = Math.floor(height / cellSize);

  // If heightData is not provided or too small, generate random data
  let normalizedHeightData = heightData;
  if (!heightData || heightData.length < cols * rows) {
    console.warn('Height data insufficient, generating random heights');
    normalizedHeightData = Array(cols * rows).fill(0).map(() => Math.random());
  }

  // Helper function to convert hex color to RGB array
  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  // Convert colors to RGB arrays
  const baseRgb = hexToRgb(baseColor);
  const highlightRgb = hexToRgb(highlightColor);
  const shadowRgb = hexToRgb(shadowColor);

  // Calculate light direction vector
  const lightDir = [Math.cos(lightAngle), Math.sin(lightAngle)];

  // Draw the heightmap
  for (let y = 0; y < rows - 1; y++) {
    for (let x = 0; x < cols - 1; x++) {
      const index = y * cols + x;
      
      // Get heights at current position and neighbors
      const h = normalizedHeightData[index];
      const hRight = normalizedHeightData[index + 1];
      const hBottom = normalizedHeightData[index + cols];
      
      // Calculate normal vector based on height differences
      const nx = (h - hRight);
      const ny = (h - hBottom);
      const nz = cellSize / Math.max(width, height);
      
      // Normalize the normal vector
      //const length = Math.sqrt(nx * nx + ny * ny + nz * nz);
      let length = 1
      const normalVector = [nx / length, ny / length, nz / length];
      
      // Calculate lighting factor (dot product of normal and light direction)
      // Add third component to light direction (z-component)
      const lightDir3D = [...lightDir, 0.5];
      const l3dLength = Math.sqrt(lightDir3D[0]**2 + lightDir3D[1]**2 + lightDir3D[2]**2);
      const normalizedLightDir = [
        lightDir3D[0] / l3dLength,
        lightDir3D[1] / l3dLength,
        lightDir3D[2] / l3dLength
      ];
      
      // Calculate dot product for lighting
      let lighting = normalVector[0] * normalizedLightDir[0] +
                    normalVector[1] * normalizedLightDir[1] +
                    normalVector[2] * normalizedLightDir[2];
                    
      // Apply contrast and clamp between ambient and 1.0
      lighting = ambient + (lighting * (1 - ambient) * contrast);
      lighting = Math.max(ambient, Math.min(1.0, lighting));
      
      // Determine color based on lighting factor
      let color;
      if (lighting > 0.55) {
        // Blend between base and highlight
        const blendFactor = (lighting - 0.55) / 0.45;
        color = baseRgb.map((c, i) => Math.round(c * (1 - blendFactor) + highlightRgb[i] * blendFactor));
      } else {
        // Blend between shadow and base
        const blendFactor = lighting / 0.55;
        color = shadowRgb.map((c, i) => Math.round(c * (1 - blendFactor) + baseRgb[i] * blendFactor));
      }
      
      // Apply height to color (higher = slightly brighter)
      const heightInfluence = h * 0.2;
      color = color.map(c => Math.min(255, Math.round(c * (1 + heightInfluence))));
      
      // Draw cell
      ctx.fillStyle = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
      ctx.fillRect(x * cellSize, y * cellSize, cellSize, cellSize);
    }
  }

  // Add a subtle silver texture effect with noise
  addSilverTextureEffect(ctx, width, height);

  return canvas;
}

/**
 * Adds a subtle silver texture effect to the canvas
 * 
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} width - Canvas width
 * @param {number} height - Canvas height
 */
function addSilverTextureEffect(ctx, width, height) {
  // Get the current image data
  const imageData = ctx.getImageData(0, 0, width, height);
  const data = imageData.data;
  
  // Add subtle noise to create a metallic texture
  for (let i = 0; i < data.length; i += 4) {
    // Small random variation for metallic effect
    const noise = (Math.random() - 0.5) * 10;
    
    // Apply noise consistently to all RGB channels to preserve silver look
    data[i] = Math.max(0, Math.min(255, data[i] + noise));
    data[i+1] = Math.max(0, Math.min(255, data[i+1] + noise));
    data[i+2] = Math.max(0, Math.min(255, data[i+2] + noise));
  }
  
  // Put the modified image data back
  ctx.putImageData(imageData, 0, 0);
}

function createLossGradient(data, targetElement) {
  // Get the width of the target element in pixels
  const width = targetElement.clientWidth;
  
  // Extract step and loss values from data
  const steps = data.map(item => item.step);
  function clipData(data, lowerPercentile = 1, upperPercentile = 99) {
    const sorted = [...data].sort((a, b) => a - b);
    const lowerIndex = Math.floor((lowerPercentile / 100) * sorted.length);
    const upperIndex = Math.ceil((upperPercentile / 100) * sorted.length) - 1;
    const lowerValue = sorted[lowerIndex];
    const upperValue = sorted[upperIndex];
    return data.map(value => {
      if (value < lowerValue) return lowerValue;
      if (value > upperValue) return upperValue;
      return value;
    });
  }
  let lossValues = data.map(item => item.loss);
  //lossValues = clipData(lossValues, 5, 95)
  
  // Find min and max loss for normalization
  let minLoss = Math.min(...lossValues);
  let maxLoss = Math.max(...lossValues);

  minLoss = 0.00
  maxLoss = 10;
  
  // Find min and max steps for position mapping
  const minStep = Math.min(...steps);
  const maxStep = Math.max(...steps);
  const stepRange = maxStep - minStep;
  
  // Determine number of color stops
  const numStops = Math.min(data.length, width / 10)
  
  // Create color stops by sampling the data
  const colorStops = [];
  
  for (let i = 0; i < numStops; i++) {
    // Calculate position and step
    const position = i / (numStops - 1) * 100;
    const currentStep = minStep + (position / 100) * stepRange;
    
    // Find loss value through interpolation
    let leftIndex = 0;
    while (leftIndex < steps.length - 1 && steps[leftIndex + 1] <= currentStep) {
      leftIndex++;
    }
    
    let rightIndex = steps.length - 1;
    while (rightIndex > 0 && steps[rightIndex - 1] >= currentStep) {
      rightIndex--;
    }
    
    let interpolatedLoss;
    if (leftIndex === rightIndex) {
      interpolatedLoss = lossValues[leftIndex];
    } else {
      const leftStep = steps[leftIndex];
      const rightStep = steps[rightIndex];
      const stepFraction = (currentStep - leftStep) / (rightStep - leftStep);
      interpolatedLoss = lossValues[leftIndex] + stepFraction * (lossValues[rightIndex] - lossValues[leftIndex]);
    }

    
    // Normalize loss value
    const normalizedLoss = ((interpolatedLoss - minLoss) / (maxLoss - minLoss || 1));
    
    // Create a silvery gray gradient
    // Range from dark gray (80,80,80) to light silver (220,220,220)
    const grayValue = Math.floor(80 + Math.pow(normalizedLoss,0.5) * 140);
    
    // Add a slight metallic effect by varying the channels slightly
    let w = 1
    const r = grayValue + Math.floor(Math.random() * w) - w/2;
    const g = grayValue + Math.floor(Math.random() * w) - w/2;
    const b = grayValue + Math.floor(Math.random() * w) - w/2;
    
    colorStops.push(`rgb(${r}, ${g}, ${b}) ${position}%`);
  }
  
  // Return the CSS linear-gradient string
  return `linear-gradient(to right, ${colorStops.join(', ')})`;
}



export class HeightmapVisualization extends React.Component {
  constructor(props) {
    super(props)
    
    // Initialize state
    this.state = {
      currentStep: 0,
      currentLoss: 0
    };
    
    // Create ref for the gradient bar
    this.gradientBarRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedStep !== prevProps.selectedStep) {
      if (this.props.selectedStep !== undefined) {
        const step = this.props.data.find(x => x.step === this.props.selectedStep+1)
        if (step) {
          if (this.state.currentStep !== step.step) {
            const mousePosition = Math.round(((step.step) / (this.props.data[this.props.data.length-1].step)) * 100)
            this.setState({
              currentStep: step.step,
              currentLoss: step.loss,
              mousePosition
            })
          }
        }
      }
    }
    if (this.props.data !== prevProps.data) {
      this.savedGradient = null
      this.forceUpdate()
    }
  }

  componentDidMount() {
    // Bind methods
    this.handleMouseMove = this.handleMouseMove.bind(this);
    let { step, loss } = this.props.data && this || {}
    this.state = {
      currentStep: step,
      currentLoss: loss,
    };
    this.forceUpdate()
  }


  // Handle mouse leave
  handleMouseLeave() {
    this.setState({
      mousePosition: null
    });
  }

  generateGradientCSS() {
    if (this.savedGradient) {
      return this.savedGradient
    }
    if (this.gradientBarRef.current) {
      const grad = createLossGradient(this.props.data, this.gradientBarRef.current)
      console.log({grad})
      this.savedGradient = grad
      return grad
    }
    const { data } = this.props
    const minLoss = Math.min(...data.map(d => d.loss));
    const maxLoss = Math.max(...data.map(d => d.loss));
    const minStep = Math.min(...data.map(d => d.step));
    const maxStep = Math.max(...data.map(d => d.step));
    
    // Determine the number of pixels to sample (assuming 500px max width)
    if (!this.gradientBarRef.current) {
      return
    }
    const pixelCount = this.gradientBarRef.current.clientWidth
    
    // Create an array of evenly spaced pixels
    const pixels = [];
    for (let i = 0; i < pixelCount; i++) {
      const percentage = i / (pixelCount - 1);
      // Calculate the corresponding step at this position
      const step = minStep + percentage * (maxStep - minStep);
      
      // Find the loss value at this step through interpolation
      let loss;
      
      // Find the nearest data points for interpolation
      const lowerDataPoint = data.reduce((prev, curr) => 
        (curr.step <= step && curr.step > prev.step) ? curr : prev, 
        { step: -Infinity, loss: 0 }
      );
      
      const upperDataPoint = data.reduce((prev, curr) => 
        (curr.step >= step && curr.step < prev.step) ? curr : prev, 
        { step: Infinity, loss: 0 }
      );
      
      // If we have exact match, use it
      if (lowerDataPoint.step === step) {
        loss = lowerDataPoint.loss;
      } 
      // If we're at the beginning, use the first point
      else if (lowerDataPoint.step === -Infinity) {
        loss = data[0].loss;
      } 
      // If we're at the end, use the last point
      else if (upperDataPoint.step === Infinity) {
        loss = data[data.length - 1].loss;
      } 
      // Otherwise, interpolate between two nearest points
      else {
        const ratio = (step - lowerDataPoint.step) / (upperDataPoint.step - lowerDataPoint.step);
        loss = lowerDataPoint.loss + ratio * (upperDataPoint.loss - lowerDataPoint.loss);
      }
      
      pixels.push({ position: percentage * 100, loss });
    }
    
    // Create gradient stops for each pixel
    let stops = '';
    let last
    pixels.forEach((pixel, index) => {
      // Calculate position (0-100%) based on loss valuec
      let range = maxLoss - minLoss
      if (range == 0) range = 1
      const lossPercentage = ((pixel.loss - minLoss) / (maxLoss - minLoss)) * 100;
      
      // Invert the percentage since lower loss means more to the right
      const invertedPosition = 100 - lossPercentage;
      
      // Calculate grayscale color value (50-220)
      let colorValue = Math.floor(255 - ((255/3) * (lossPercentage / 100)));
      if (last && last === colorValue) {

      }
      last = colorValue
      
      // Use the pixel position for the gradient placement
      stops += `rgba(${colorValue}, ${colorValue}, ${colorValue}, 1) ${pixel.position}%`;
      
      // Add comma if not the last item
      if (index < pixels.length - 1) {
        stops += ', ';
      }
    });
    
    return `linear-gradient(90deg, ${stops})`;
  }

  // Handle mouse movement over gradient bar
  handleMouseMove(e) {
    const { data } = this.props
    
    if (this.gradientBarRef.current) {
      const rect = this.gradientBarRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const relativeX = x / rect.width;
      
      // Calculate mouse position as percentage
      const mousePosition = relativeX * 100;
      
      // Find the nearest step based on position
      const minStep = Math.min(...data.map(d => d.step));
      const maxStep = Math.max(...data.map(d => d.step));
      const minLoss = Math.min(...data.map(d => d.loss));
      const maxLoss = Math.max(...data.map(d => d.loss));
      
      // Calculate the step value at this position (linear interpolation)
      const step = minStep + (relativeX * (maxStep - minStep));
      
      // Calculate the loss value at this position
      const estimatedLoss = maxLoss - (relativeX * (maxLoss - minLoss));
      
      // Find the closest data point
      let closestStep = Math.round(step);
      if (closestStep < minStep) closestStep = minStep;
      if (closestStep > maxStep) closestStep = maxStep;
      
      // Update current step and loss
      this.setState({
        currentStep: closestStep,
        currentLoss: data.find(d => d.step === closestStep)?.loss || estimatedLoss,
        mousePosition
      });
    }
  }

  render() {
    const { currentStep, currentLoss } = this.state
    
    const containerStyle = {
      fontSize: '14px',
      fontWeight: 'normal',
      width: '100%',
      color: '#fff',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRadius: '4px',
      background: 'rgb(7, 17, 17)'
    };

    const headerStyle = {
      marginBottom: '16px'
    };

    const rowStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px'
    };

    const stepLabelStyle = {
      color: '#4ade80', // green-500
      fontWeight: 'normal',
      marginLeft: 0
    };

    const stepValueStyle = !this.state.currentStep ? {display: 'none'} :{
      color: '#4ade80', // green-500
      fontSize: '14px',
      fontWeight: 'normal',
      position: 'absolute',
      top: -33,
      padding: '8px',
      left: `${this.state.mousePosition}%`,
      background: 'rgb(13, 35, 35)',
      width: 40
    };

    const gradientBarStyle = {
      height: '64px',
      width: '100%',
      marginBottom: '8px',
      overflow: 'visible',
      position: 'relative',
      cursor: 'pointer',
      background: this.generateGradientCSS()
    };

    const lossLabelStyle = {
      color: '#60a5fa', // blue-400
      marginLeft: 0,
      fontWeight: 'normal',
    };

    const lossValueStyle = !this.state.currentStep ? { display: 'none' }: {
      color: '#60a5fa', // blue-400
      fontSize: '14px',
      fontWeight: 'normal',
      position: 'absolute',
      bottom: -34,
      padding: '8px',
      left: `${this.state.mousePosition}%`,
      background: 'rgb(13, 35, 35)',
      width: 40
    };

    const onClick = e => {
      if (this.props.onClick) {
        this.props.onClick(this.state.currentStep)
      }
    }

    return (
      <div style={containerStyle} onClick={onClick}>
        {/* Header */}
        <div style={headerStyle}>{this.props.title}</div>
        
        {/* Step display */}
        <div style={rowStyle}>
          <div style={stepLabelStyle}>Step</div>
        </div>
        
        {/* Gradient bar */}
        <div 
          ref={this.gradientBarRef}
          style={gradientBarStyle}
          onMouseMove={this.handleMouseMove}
        >
        <div style={stepValueStyle}>{currentStep}</div>
        {/* Vertical line showing mouse position */}
          {this.state.mousePosition !== null && (
            <div 
              style={{
                position: 'absolute',
                top: 0,
                left: `${this.state.mousePosition}%`,
                height: '100%',
                width: '1px',
                backgroundColor: '#000'
              }}
            ></div>
          )}
          <div style={lossValueStyle}>{currentLoss.toFixed(2)}</div>
        </div>
        {/* Loss display */}
        <div style={rowStyle}>
          <div style={lossLabelStyle}>Loss</div>
        </div>
        
      </div>
    );
  }
}


