const { EventEmitter } = require('events');
const { JsonParser } = require('./parser');
const { JsonLexer } = require('./lexer');

class JsonReconstructor extends EventEmitter {
  constructor() {
    super();
    this.reset();
  }

  reset() {
    this.lexer = new JsonLexer();
    this.parser = new JsonParser();
    this.parser.on('update', () => {
      //debugger
      // On any update, emit the complete current value
      this.emit('update', this.parser.rootValue);
    });
  }


  getValue = () => this.parser.rootValue

  process(chunk) {
    //debugger
    for (const token of this.lexer.tokenize(chunk)) {
      this.parser.processToken(token);
    }
  }

  getValue() {
    return this.parser.rootValue;
  }
}

module.exports = { JsonReconstructor };
