import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as monaco from 'monaco-editor';
import { Subject } from 'rxjs';
import './CanvasEditor.css';

monaco.editor.defineTheme('my-dark-theme', {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: '', foreground: 'e7e7e7' },
    { token: 'comment', foreground: '808080', fontStyle: 'italic' },
    { token: 'keyword', foreground: 'bcbcbc' },
    { token: 'string', foreground: 'a5a5a5' },
    { token: 'number', foreground: 'a0a0a0' },
    { token: 'function', foreground: 'e7e7e7' },
    { token: 'variable', foreground: 'e7e7e7' }
  ],
  colors: {
    'editor.background': '#0d2033',              // buffer
    'editorGutter.background': '#0c1c1c',         // gutter
    'editorLineNumber.foreground': '#888888',    // gray line numbers
    'editorLineNumber.activeForeground': '#e7e7e7', // current line number
    'editor.foreground': '#e7e7e7',              // primary text color
    'editorCursor.foreground': '#e7e7e7',
    'editorLineHighlightBackground': '#122e41',
    'editorIndentGuide.background': '#2a2a2a',
    'editorIndentGuide.activeBackground': '#444444',
    'minimap.background': '#0d2033'
  }
});

/**
 * CanvasEditor component - Renders Monaco editor and emits edit events
 */
class CanvasEditor extends Component {
  constructor(props) {
    super(props);
    
    this.editorRef = React.createRef();
    this.editor = null;
    this.currentContent = '';
    
    // Create a subject for user edits
    this.editsSubject = new Subject();
  }

  componentDidMount() {
    if (!this.props.path) {
      debugger
    } else {
      this.initMonacoEditor();
    }
  }

  componentDidUpdate(prevProps) {
    // Update content when props change
    if (prevProps.content !== this.props.content) {
      this.updateContent(this.props.content);
    }
    
    // Update language when path changes
    if (prevProps.path !== this.props.path) {
      this.updateLanguage(this.props.path);
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.dispose();
    }
    // Complete the subject
    this.editsSubject.complete();
  }

  /**
   * Initialize Monaco editor
   */
  initMonacoEditor = () => {
    this.editor = monaco.editor.create(this.editorRef.current, {
      value: this.props.content || '',
      language: this.getLanguageFromPath(this.props.path),
      theme: 'my-dark-theme',
      automaticLayout: true,
      wordWrap: 'on',
      ...this.props.options
    });


    debugger
    // Listen for changes and notify via observable
    this.editor.onDidChangeModelContent((e) => {
      const newContent = this.editor.getValue();
      
      // Only notify if not triggered by our update
      if (this.currentContent !== newContent) {
        this.currentContent = newContent;
        
        // Emit edit event
        this.editsSubject.next({
          path: this.props.path,
          content: newContent
        });
      }
    });
    
    // Set initial content
    this.currentContent = this.props.content || '';
  };

  /**
   * Update editor content
   * @param {string} content - New content
   */
  updateContent = (content) => {
    if (!this.editor) return;
    
    // Update content without triggering change event
    this.currentContent = content;
    this.editor.setValue(content);
  };

  /**
   * Update editor language based on file path
   * @param {string} path - File path
   */
  updateLanguage = (path) => {
    if (!this.editor) return;
    
    const model = this.editor.getModel();
    const language = this.getLanguageFromPath(path);
    
    if (model.getLanguageId() !== language) {
      monaco.editor.setModelLanguage(model, language);
    }
  };

  /**
   * Get language from file path
   * @param {string} path - File path
   * @returns {string} - Language ID
   */
  getLanguageFromPath = (path) => {
    const extension = path.split('.').pop().toLowerCase();
    
    const languageMap = {
      'js': 'javascript',
      'jsx': 'javascript',
      'ts': 'typescript',
      'tsx': 'typescript',
      'py': 'python',
      'html': 'html',
      'css': 'css',
      'json': 'json',
      'md': 'markdown',
      'txt': 'plaintext',
      'c': 'c',
      'cpp': 'cpp',
      'java': 'java',
      'go': 'go',
      'rs': 'rust',
      'sql': 'sql',
      'rb': 'ruby',
      'php': 'php',
      'sh': 'shell',
      'yaml': 'yaml',
      'yml': 'yaml'
    };
    
    return languageMap[extension] || 'plaintext';
  };

  /**
   * Get observable of editor edits
   * @returns {Observable} - Observable of edits
   */
  getEdits$() {
    return this.editsSubject.asObservable();
  }

  render() {
    return (
      <div className="canvas-editor-container">
        <div ref={this.editorRef} className="monaco-editor-instance"></div>
      </div>
    );
  }
}

CanvasEditor.propTypes = {
  path: PropTypes.string.isRequired,
  content: PropTypes.string,
  options: PropTypes.object
};

CanvasEditor.defaultProps = {
  content: '',
  options: {}
};

export default CanvasEditor;
