import React, { Component }from 'react'
import { InputControl } from '../Keyboard'

export class SearchField extends Component {


  constructor (props) {
    super(props)
    this.state = {
    }
  }
  
  onSearch = term => {
    return this.props.onSearch(term)
  }

  clear = () => {
    this.searchField.clear()
  }

  getText = () => this.searchField.getText()

  setText = (text) => {
    this.searchField.setText(text, false)
  }

  getMarkdown = () => {
    const contents = this.searchField.getContent()
    const markdown = contents.map(content => {
      switch (content.type) {
        case 'text':
          return content.text
        case 'image_url':
          return `![](${content.image_url.url})`
      }
    }).join('')
    //console.log({markdown})
    return markdown
  }

  insertImage = url => {
    return this.searchField.insertImage(url)
  }

  insertTextAtCaret = (text) => {
    this.searchField.insertTextAtCaret(text, true, true)
  }

  focus = () => {
    this.searchField.focus()
  }

  setCaretPosition = pos => {
    this.searchField.setCaretPosition(pos)
  }

  setInputControl = editor => {
    if (editor) {
      ////////debugger
      this.sub = editor.observeEditorHeight().subscribe(this.onEditorHeightChanged)
    } else {
      if (this.sub) this.sub.unsubscribe()
    }
  }

  setSearchEditor = editor => {
    this.searchField = editor
  }
  
  onEditorHeightChanged = h => {
    if (this.props.onEditorHeightChanged) {
      this.props.onEditorHeightChanged(Math.max(h - 90, 40))
    }
  }

  search = searchTerm => {
    this.searchField.setText(searchTerm)
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }
  
  render() {
    let searchTerm = ''
    const clear = () => {
      this.searchField.clear()
      this.onSearch('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.onSearch(this.getMarkdown())
    }
    const onPaste = e => {
      if (this.props.onPaste) this.props.onPaste(e)
      setTimeout(onInput, 33)
    }
    let style
    let style2
    let filler
    let busy
    let icon = this.props.icon
    let label = this.props.label
    let action = this.props.action
    let button
    if (action) {
      button = <SimpleButton legacyIconSize={this.props.legacyIconSize} icon={icon} action={action} label={label}/>
    }
    let middleLeft = button
    return <div className='searchFieldInput'>
             <div className='chatHeader' style={style}>
               <div className='chatHeaderFiller'>{filler}</div>
             </div>
             <div className='inputControlContainer' style={style2}>
               <InputControl
                 onClick={this.props.onClick}
                 ref={this.setInputControl}
                 placeholder={this.props.placeholder || "Search"}
                 key={'search'}                 
                 busy={busy}
                 middleLeft={middleLeft}
                 me={this.props.me}
                 onSetEditor={this.setSearchEditor}
                 downward={true}
                 onClear={clear}
                 onFocus={onFocus}
                 onBlur={onBlur}
                 onInput={onInput}
                 onPaste={onPaste}
                 onKeyDown={this.props.onKeyDown}
                 menu={this.props.menu}
               />
             </div>
           </div>
  }
}
