import React, { Component }from 'react'
import { ReactSVG } from 'react-svg'
import Spin from '../../assets/Icons/Spin.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Filter from '../../assets/Icons/Filter.svg'
import { isMobile } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import ClickAwayListener from 'react-click-away-listener'
import Popup from 'reactjs-popup'
import './SimpleButton.css'
const MIN_ANIM_DUR = 1.0

export class SimpleConfigButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      selected: false
    }
  }
  render() {
    const { label, icon, children, action } = this.props
    const {active, selected} = this.state
    let className = 'simpleButtonConfigPopup'
    if (active) {
      if (selected) {
        className += ' simpleButtonConfigPopupActiveSelected'
      } else {
        className += ' simpleButtonConfigPopupActive'
    }
    } else if (selected) {
      className += ' simpleButtonConfigPopupSelected'
    }
    const trigger = <div id={'simpleButtonMenuTrigger'}>
                      <SimpleIcon src={Filter}/>
                    </div>
    let className0 = 'simpleButtonConfigPopupContainer'
    if (isMobile()) {
      className0 += ' simpleButtonConfigPopupMobile'
    }
    const config = <Popup closeOnDocumentClick position='bottom left' trigger={trigger}>
                     {close =>
                       <div className={className0}>
                         <div className={className}>
                           {children}
                         </div>
                       </div>
                     }
                   </Popup>
    return <div className='simpleButtonWithConfig'>
             <SimpleButton label={label} icon={icon} action={action}/>
             {config}
           </div>
  }
}

export class FileChooser extends Component {

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  reset = () => {
    this.fileChooser.value = ''
  }

  chooseFile = () => this.fileChooser.click()

  render() {
    let { button, icon, label, handleDataTransfer, fileTypes, action } = this.props
    const noAction = (async () => {})
    if (!icon) {
      icon = OpenFile
    }
    if (!button) {
      button = <SimpleButton icon={icon} label={label} action={noAction}/>
    }
    const open = async (event) => {
      this.fileChooser.click()
    }
    const onChange = async (event) => {
      if (action) action()
      handleDataTransfer(event, event.target)
      this.fileChooser.value = ''
    }
    const fileChooser = 
      <div className='fileChooser' onClick={open}>
        <input
          ref={this.setFileChooser}
          type={"file"}
          accept={fileTypes}
          style={{ display: 'none' }} // Hides the file input
          onChange={onChange}
        />                      
        {button}
      </div>
    return fileChooser
  }
}

export class DeleteButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  trash = async () => {
    if (!this.state.confirmDelete) {
      this.state.confirmDelete = true
      this.forceUpdate()
      return
    }
    this.state.busy = true
    this.forceUpdate()
    const start = Date.now()
    await this.props.trash()
    const end = Date.now()
    const elapsed = (end - start) / 1000
    const dur = MIN_ANIM_DUR - elapsed
    if (dur > 0) {
      await delay(dur)
    }
    this.state.busy = false
    this.state.confirmDelete = null
    this.forceUpdate()
  }

  cancelDelete = async () => {
    this.setState({
      confirmDelete: false
    })
  }

  render() {
    const { label } = this.props
    let deleteButton
    let deleteIcon = this.state.busy ? Spin : (this.props.icon || Trash)
    const trash = () => this.trash()
    let Delete = this.props.Delete || 'Delete'
    let className='threadDeleteButton'
    if (label) {
      className += ' threadDeleteButtonWithLabel'
    }
    if (this.state.confirmDelete) {
      deleteButton = <div key='delete' className='threadDeleteButtonConfirm'><SimpleButton label='Confirm?' icon={deleteIcon} action={trash}/></div>
      if (!this.props.noClickAway) {
        deleteButton = <ClickAwayListener onClickAway={this.cancelDelete}>
                         {deleteButton}
                       </ClickAwayListener>
        
      }
    } else {
      deleteButton = <div key='del' className={className}><SimpleButton label={label} action={trash} icon={deleteIcon}/></div>
    }
    return <div className='deleteButtonHolder'>{deleteButton}</div>
  }
}

export class SimpleIcon extends Component {
  constructor (props) {
    super(props)
  }

  afterInjection = svg => {
    this.svg = svg
  }
  setRef = ref => {
    this.ref = ref
  }
  render() {
    const { src, size, legacyIconSize } = this.props
    let style
    let className = 'simpleIcon'
    if (false && this.svg && this.ref) {
      const [x, y, w, h] = this.svg.attributes.viewBox.value.split(" ").map(x => parseFloat(x))
      const box = this.ref.getBoundingClientRect()
      if (w === box.width) {
        this.svg.style.width = box.width + 'px'
        this.svg.style.height = box.height + 'px'
      }
    }
    if (size) {
      const { w, h } = size
      style = {
        height: h,
        width: w,
        minWidth: w
      }
      className += ' simpleIconWithSize'
    }
    if (legacyIconSize) {
      className += ' legacyIconSize'
    }
    return <div ref={this.setRef} className={className} style={style}><ReactSVG afterInjection={this.afterInjection} src={src}/></div>
  }
}

export class SimpleButton extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  setRef = ref => {
    this.ref = ref
  }

  componentWillUnmount() {
    if (this.timeout1) {
      ////console.log("unmount autorepeat")
      clearTimeout(this.timeout1)
    }
  }

  action = async () => {
    if (this.state.busy) return
    if (!this.props.action) return
    this.state.busy = true
    this.forceUpdate()
    const start = Date.now()
    try {
      await this.props.action()
    } catch (err) {
      console.error(err)
    }
    const end = Date.now()
    const elapsed = (end - start) / 1000
    if (elapsed > 0.1) {
      const dur = MIN_ANIM_DUR - elapsed
      if (dur > 0) {
        await delay(dur)
      }
    }
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    let { icon, label } = props
    let action
    let className = 'simpleButton'
    if (label) {
      if (typeof(label) !== 'string') {
        className += ' simpleButtonLabelComponent'
      } else {
        className += ' simpleButtonWithLabel'
      }
    } else {
      className += ' simpleButtonNoLabel'
    }
    if (icon) {
      className += ' simpleButtonWithIcon'
    }
    if (props.action) {
      action = async () => {
        this.state.busy = true
        setTimeout(() => this.forceUpdate())
        //this.ref.scrollIntoView()
        await props.action()
        this.state.busy = false
        this.forceUpdate()
      }
    } else{
      className += ' simpleButtonInactive'
    }
    if (this.props.busy || this.state.busy) icon = Spin
    let longPressOps
    let onClick
    if (this.props.longPress) {
      longPressOps = this
      className += ' simpleButtonLongPressable'
    } else if (this.props.autoRepeat) {
      className += ' simpleButtonAutorepeat'
      const onHold = () => {
        ////console.log("onHold", this.down)
        clearTimeout(this.timeout1)
        props.action()
        this.timeout1 = setTimeout(onHold, 100)
      }
      const hold = () => {
        onHold()
      }
      const release = () => {
        this.down = false
        clearTimeout(this.timeout1)
        this.timeout = null
      }
      longPressOps = {
        handleMouseDown: e => {
          e.preventDefault()
          e.stopPropagation()
          hold()
        },
        handleMouseUp: e => {
          e.preventDefault()
          e.stopPropagation()          
          release()
        },
        handleMouseLeave: e => {
          release()
        },
        handleTouchStart: e => {
          e.preventDefault()
          e.stopPropagation()          
          hold()
        },
        handleTouchEnd: e => {
          e.preventDefault()
          e.stopPropagation()
          release()
        }
      }
    } else {
      longPressOps = {}
      if (this.props.keepFocus) {
        onClick = (event) => {
          event.preventDefault()
          this.action()
        }
      } else {
        onClick = this.action
      }
    }
    const legacyIconSize = !this.state.busy && this.props.legacyIconSize
    return <div ref={this.setRef}
                className={className}
                onClick={onClick}
                onMouseDown={longPressOps.handleMouseDown}
                onMouseUp={longPressOps.handleMouseUp}
                onMouseLeave={longPressOps.handleMouseLeave}
                onTouchStart={longPressOps.handleTouchStart}
                onTouchEnd={longPressOps.handleTouchEnd}
           >
             {icon && <SimpleIcon size={this.props.iconSize} key='icon' src={icon} legacyIconSize={legacyIconSize}/>}
             {label &&<div key='label' className='simpleButtonLabel'>
                        {label}
                      </div>}
           </div>

  }

  startPressTimer = () => {
    const { pressDuration = 300 } = this.props
    this.state.pressed = true
    this.setState({ isLongPress: false })
    this.timerRef = setTimeout(() => {
      this.setState({ isLongPress: true })
      if (navigator.vibrate) {
        navigator.vibrate(200);
      } else {
      }
      ////console.log("long press called")
      this.props.longPress()
    }, pressDuration);
  }

  cancelPressTimer = () => {
    this.state.pressed = false
    clearTimeout(this.timerRef)
    if (this.props.longPress && this.state.isLongPress) {
      function clearSelection() {
        if (window.getSelection) {
          // For most modern browsers
          window.getSelection().removeAllRanges()
        } else if (document.selection) {
          // For older IE browsers
          document.selection.empty()
        }
      }
      setTimeout(clearSelection, 60)
    } else {
      this.action()
    }
  };

  handleMouseDown = (e) => {
    this.startPressTimer();
    e.preventDefault()
  };

  handleMouseUp = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  }

  handleTouchStart = (e) => {
    e.preventDefault()
    this.startPressTimer();
  };

  handleTouchEnd = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  };

  
}
