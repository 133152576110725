import React, { Component } from 'react'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { SimpleButton, DeleteButton } from '../ChatGPT2'
import Plus from '../../assets/Icons/Plus.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Trash from '../../assets/Icons/Trash.svg'
import './ApiKeysManager.css'

class ApiKeysManager extends UComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiKeys: [],
      showNewKeyForm: false,
      copiedId: null
    };
  }

  apiKeys = {}

  componentDidMount() {
    this.sub = this.props.me.observeApiKeys().subscribe(change => {
      const { type, apiKey } = change
      if (type === 'removed') {
        delete this.apiKeys[type]
      } else {
        this.apiKeys[type] = apiKey
      }
      this.updateLater('updateApiKeys', this.updateApiKeys)
    })
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  updateApiKeys = () => {
    const apiKeys = Object.values(apiKeys)
    apiKeys.sort((x, y) => {
      return y.ts - x.ts
    })
    this.setState({
      apiKeys
    })
  }

  handleCreateKey = () => {
    const newKey = {
      id: Date.now(),
      key: `ak_${Math.random().toString(36).substring(2, 15)}`,
      created: new Date().toISOString().split('T')[0]
    };
    
    this.setState(prevState => ({
      apiKeys: [...prevState.apiKeys, newKey],
      showNewKeyForm: false
    }));
  };

  handleDeleteKey = (id) => {
    this.setState(prevState => ({
      apiKeys: prevState.apiKeys.filter(key => key.id !== id)
    }));
  };

  handleCopyKey = (id, key) => {
    try {
      navigator.clipboard.writeText(key);
      this.setState({ copiedId: id });
      setTimeout(() => this.setState({ copiedId: null }), 2000);
    } catch (err) {
    }
  };

  render() {
    const { apiKeys, showNewKeyForm, copiedId } = this.state;

    const styles = {
      container: {
        padding: '24px',
        backgroundColor: 'rgb(12, 28, 28)',
        minHeight: '100vh',
        color: '#fff'
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px'
      },
      title: {
        fontSize: '20px',
        fontWeight: '600'
      },
      button: {
        backgroundColor: '#2563eb',
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        cursor: 'pointer'
      },
      keyContainer: {
        backgroundColor: '#1e293b',
        padding: '16px',
        marginBottom: '16px'
      },
      keyHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
      },
      keyCode: {
        backgroundColor: '#0f172a',
        padding: '8px',
        fontFamily: 'monospace',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      deleteButton: {
        backgroundColor: '#991b1b',
        color: 'white',
        padding: '4px 8px',
        border: 'none',
        cursor: 'pointer'
      },
      copyButton: {
        backgroundColor: 'transparent',
        color: '#94a3b8',
        border: 'none',
        cursor: 'pointer',
        padding: '4px 8px'
      },
      created: {
        color: '#94a3b8',
        fontSize: '14px',
        marginTop: '8px'
      },
      copyMessage: {
        backgroundColor: '#064e3b',
        color: '#34d399',
        padding: '8px',
        marginTop: '8px'
      }
    };

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <h2 style={styles.title}>API Keys</h2>
          {!showNewKeyForm && (
            <SimpleButton icon={Plus} label="New API Key" action={
                            () => this.setState({ showNewKeyForm: true })
                          }/>
          )}
        </div>

        {showNewKeyForm && (
          <div style={styles.keyContainer}>
            <div className='apiKeyFormButtons'>
              <SimpleButton label="Create" action={this.handleCreateKey}/>
              <SimpleButton label="Cancel" action={() => this.setState({ showNewKeyForm: false })}/>
            </div>
          </div>
        )}

        {apiKeys.map(apiKey => (
          <div key={apiKey.id} style={styles.keyContainer}>
            <div style={styles.keyCode}>
              <span>{apiKey.key}</span>
              <div>
                <SimpleButton label={'Copy'}
                              icon={Copy}
                              action={
                                async ()  => {
                                  this.handleCopyKey(apiKey.id, apiKey.key)
                                }
                              }/>
                <DeleteButton
                  label='Delete'
                  trash={
                    this.handleDeleteKey(apiKey.id)
                  }/>
              </div>
            </div>
            {copiedId === apiKey.id && (
              <div style={styles.copyMessage}>
                API key copied to clipboard
              </div>
            )}
            <div style={styles.created}>
              Created on {apiKey.created}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export { ApiKeysManager }
