import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import CanvasEditor from './CanvasEditor';
import CanvasEditorTabs from './CanvasEditorTabs';
import './MultiFileCanvasEditor.css';

/**
 * MultiFileCanvasEditor component - Container that manages multiple editors
 * based on commands from the interpreter
 */
class MultiFileCanvasEditor extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentPath: null,
      openFiles: [],
      openWebPages: [],
      fileContents: new Map(),
      webContents: new Map()
    };
    
    // Create a subject for user edits
    this.editsSubject = new Subject();
    
    // Track editor refs
    this.editorRefs = new Map();
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    //debugger
    // Subscribe to commands
    this.commandsSubscription = this.props.commands$.subscribe(cmd => {
      //debugger
      this.handleCommand(cmd);
    });
    
    // Subscribe to available files
    if (this.props.availableFiles$) {
      this.filesSubscription = this.props.availableFiles$.subscribe(files => {
        // If we have no open files but files are available, open the first one
        if (files.length > 0 && this.state.openFiles.length === 0) {
          this.handleFileOpen(files[0]);
        }
        
        // Remove any open files that no longer exist
        this.setState(prevState => {
          const validOpenFiles = prevState.openFiles.filter(path => 
            files.includes(path)
          );
          
          // If current file is no longer valid, switch to first valid file
          let newCurrentPath = prevState.currentPath;
          if (newCurrentPath && !files.includes(newCurrentPath)) {
            newCurrentPath = validOpenFiles[0] || null;
          }
          
          return {
            openFiles: validOpenFiles,
            currentPath: newCurrentPath
          };
        });
      });
    }
  }

  componentWillUnmount() {
    // Clean up subscriptions
    if (this.commandsSubscription) {
      this.commandsSubscription.unsubscribe();
    }
    
    if (this.filesSubscription) {
      this.filesSubscription.unsubscribe();
    }
    
    // Complete the edits subject
    this.editsSubject.complete();
  }

  /**
   * Handle a command from the interpreter
   * @param {Object} cmd - Command object
   */
  handleCommand(cmd) {
    switch (cmd.type) {
      case 'CREATE_FILE':
        // Store file content
        this.setState(prevState => {
          const newFileContents = new Map(prevState.fileContents);
          newFileContents.set(cmd.path, cmd.content);
          
          return { fileContents: newFileContents };
        });
        break;
        
      case 'READ_FILE':
      case 'UPDATE_FILE':
        // Update file content
        this.setState(prevState => {
          const newFileContents = new Map(prevState.fileContents);
          newFileContents.set(cmd.path, cmd.content);
          return { fileContents: newFileContents };
        });
        break;
      case 'WEB_PAGE_NAVIGATE':
        // Update file content
        this.setState(prevState => {
          const newWebContents = new Map(prevState.webContents);
          newWebContents.set(cmd.target, cmd.url);
          return { webContents: newWebContents };
        });
        break;
        break

    }
  }

  /**
   * Get the edits observable
   * @returns {Observable} - Observable of editor edits
   */
  getEdits$() {
    return this.editsSubject.asObservable();
  }

  /**
   * Register an editor reference
   * @param {string} path - File path
   * @param {CanvasEditor} ref - Editor reference
   */
  registerEditor(path, ref) {
    if (ref && !this.editorRefs.has(path)) {
      this.editorRefs.set(path, ref);
      
      // Subscribe to editor edits
      const subscription = ref.getEdits$().subscribe(edit => {
        // Forward to edits subject
        this.editsSubject.next(edit);
      });
      
      // Store subscription for cleanup
      ref._subscription = subscription;
    }
  }

  /**
   * Unregister an editor reference
   * @param {string} path - File path
   */
  unregisterEditor(path) {
    const ref = this.editorRefs.get(path);
    if (ref) {
      // Clean up subscription
      if (ref._subscription) {
        ref._subscription.unsubscribe();
      }
      
      this.editorRefs.delete(path);
    }
  }

  /**
   * Handle file open
   * @param {string} path - File path
   */
  handleFileOpen = (path) => {
    this.setState(prevState => {
      // Already open?
      //debugger
      if (prevState.openFiles.includes(path)) {
        return { currentPath: path };
      }
      
      // Add to open files
      return {
        openFiles: [...prevState.openFiles, path],
        currentPath: path
      };
    });
  };

  /**
   * Handle tab change
   * @param {string} path - New active path
   */
  handleTabChange = (path) => {
    this.setState({ currentPath: path });
  };

  /**
   * Handle tab close
   * @param {string} path - Path to close
   */
  handleTabClose = (path) => {
    this.setState(prevState => {
      const newOpenFiles = prevState.openFiles.filter(p => p !== path);
      let newCurrentPath = prevState.currentPath;
      
      // If closing current path, switch to another tab
      if (path === prevState.currentPath) {
        const index = prevState.openFiles.indexOf(path);
        newCurrentPath = newOpenFiles[index] || newOpenFiles[0] || null;
      }
      
      return {
        openFiles: newOpenFiles,
        currentPath: newCurrentPath
      };
    });
    
    // Unregister editor
    this.unregisterEditor(path);
  };

  /**
   * Render a file editor
   * @param {string} path - File path
   * @returns {JSX.Element} - Editor component
   */
  renderFileEditor(path) {
    const content = this.state.fileContents.get(path) || '';
    let style = path === this.state.currentPath ? null : { display: 'none' }
    return (
      <div className='canvasEditorContainer' style={style}>
        <CanvasEditor
          
          key={path}
          ref={ref => this.registerEditor(path, ref)}
          path={path}
          content={content}
        />
      </div>
    );
  }

  render() {
    let { currentPath, openFiles } = this.state;
    openFiles = Array.from(this.state.fileContents.keys())
    console.log({openFiles})
    return (
      <div className="multi-file-canvas-editor">
        <div className="editor-container">
          <CanvasEditorTabs 
            filePaths={openFiles}
            activePath={currentPath}
            onTabChange={this.handleTabChange}
            onTabClose={this.handleTabClose}
            allFilePaths={this.props.availableFiles$.value || []}
            onFileOpen={this.handleFileOpen}
          />
          <div className="editor-wrapper">
            {
              openFiles.map(path => {
                return this.renderFileEditor(path)
              })
            }
            {!currentPath && (
              <div className="no-file-selected">
                <div className="no-file-message">No file selected</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MultiFileCanvasEditor.propTypes = {
  commands$: PropTypes.object.isRequired, // Observable of editor commands
  availableFiles$: PropTypes.object // Observable of available files
};

export default MultiFileCanvasEditor;
