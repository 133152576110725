import React, { Component, createRef } from 'react';
import './PiecewiseScrollbar.css';
const clamp = (x, low, high) => Math.min(high, Math.max(low, x))
/**
 * PiecewiseScrollbar
 *
 * A two-part scrolling system for efficiently navigating large datasets:
 * - Main scrollbar: Fixed-size thumb that navigates the viewport within the window
 * - Minimap: Shows window position within the entire dataset
 * - Zoom levels control sampling rate (striped sampling at zoom level > 0)
 */
export class PiecewiseScrollbar extends Component {
  constructor(props) {
    super(props);
    this.trackRef = createRef();
    this.thumbRef = createRef();
    this.minimapRef = createRef();
    
    this.state = {
      trackHeight: 0,
      trackWidth: 10,
      thumbSizePx: 10,
      thumbSize: 10,          // Fixed size of the main scrollbar thumb
      windowLocation: 0,
      scrollOffset: 0,
      isDragging: false,     // Main scrollbar drag state
      dragStartY: 0,
      isMinimapDragging: false, // Minimap drag state
      minimapDragStartY: 0,
      minimapDragStartRecord: 0
    }
  }

  onScroll = e => {
    const scrollOffset = e.target.scrollTop / e.target.scrollHeight
    this.setState({
      scrollOffset,
    })
  }

  componentDidMount() {
    this.updateTrackSize(null)
    window.addEventListener('resize', this.updateTrackSize);
    this.trackRef.current.addEventListener('scroll', this.onScroll)
    this.minimapRef.current.addEventListener('wheel', this.handleMinimapWheel, { passive: false });
    // Defer so the DOM is laid out
    setTimeout(this.initializeScrollbar, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTrackSize);
    if (this.trackRef.current) {
      this.trackRef.current.removeEventListener('scroll', this.onScroll)
    }
    if (this.minimapRef.current) {
      this.minimapRef.current.removeEventListener('wheel', this.handleMinimapWheel);
    }
  }

  updateTrackSize = (e) => {
    const trackHeight = this.trackRef.current.offsetHeight;
    const trackWidth = this.trackRef.current.offsetWidth;
    let windowHeight = e ? this.state.windowHeight: trackHeight
    this.setState({ trackHeight, trackWidth, windowHeight})
  };

  /**
   * Initialize the scrollbar with appropriate sizing
   */
  initializeScrollbar = () => {
    const { trackHeight, trackWidth, thumbHeightPx } = this.state;
    const { totalItems, viewportHeight } = this.props;
    
    if (!trackHeight || !trackWidth || totalItems <= 0) return;
    
    // Set initial window size (enough to fill ~3-5 viewports)
    const windowSize = trackHeight/ thumbHeightPx
    
    // Calculate thumb size based on viewportSize/windowSize ratio
    const thumbSize = trackWidth
    
    console.log('Initializing scrollbar:', { 
      trackHeight, 
      trackWidth, 
      thumbSize, 
      windowSize
    });

    this.setState({
      thumbSize,
      windowSize,
      windowStart: 0,
      viewportStart: 0,
      zoomLevel: 0
    });
  };

  /**
   * Handle mouse wheel on minimap to change zoom level
   */
  handleMinimapWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { deltaY } = e;
    if (!deltaY) return;
    const location = e.clientY
    // Calculate click position relative to minimap
    const minimapRect = this.minimapRef.current.getBoundingClientRect()
    if (!minimapRect) return
    const stretch = (deltaY / minimapRect.height) * 8
    this.state.windowLocation -= stretch
    this.state.windowHeight += stretch * 2
    this.state.windowHeight = clamp(this.state.windowHeight, 1, minimapRect.height)
    this.state.windowLocation = clamp(this.state.windowLocation, 0, minimapRect.height - this.state.windowHeight)
    console.log({stretch, windowHeight: this.state.windowHeight, location: this.state.windowLocation})
    this.forceUpdate()
  };

  handleMinimapMouseDown = (e) => {
    e.preventDefault()
    this.state.isMinimapDragging = true
    this.forceUpdate()
    this.state.minimapStartY = e.clientY
    document.addEventListener("mousemove", this.handleMinimapMouseMove)
    document.addEventListener("mouseup", this.handleMinimapMouseUp)
  };

  handleMinimapMouseMove = (e) => {
    e.preventDefault()
    if (!this.state.isMinimapDragging) return;
    const deltaY = e.clientY - this.state.minimapStartY
    const minimapRect = this.minimapRef.current.getBoundingClientRect()
    this.state.minimapStartY = e.clientY
    this.state.windowLocation += deltaY
    this.state.windowLocation = clamp(this.state.windowLocation, 0, minimapRect.height - this.state.windowHeight)
    this.forceUpdate()
  };

  handleMinimapMouseUp = e => {
    e.preventDefault()
    this.state.isMinimapDragging = false
    this.forceUpdate()
    document.addEventListener("mousemove", this.handleMinimapMouseMove)
    document.removeEventListener("mouseup", this.handleMinimapMouseUp)
   };

  // Compute the main scrollbar thumb's viewport indicator style based on props.viewportStart and props.viewportEnd
  getViewportIndicatorStyle = () => {
    const { startRow, pivotRow, endRow, totalItems } = this.props;
    
    // Calculate the viewport's position within the window (0 to 1)
    const locationFraction = Math.max(0, Math.min(1, (pivotRow / totalItems)));
    const heightFraction = (endRow - startRow) / totalItems
    const track = this.trackRef.current
    // Apply minimums for visibility
    const top = locationFraction*100 + "%"
    const height = heightFraction*100 + "%"
    
    return { top, height, position: 'absolute', width: '100%' };
  };

  // Compute minimap thumb style representing the current window within the dataset
  getMinimapThumbStyle = () => {
    const { windowLocation, windowHeight} = this.state;
    let top = windowLocation
    return { 
      top, 
      height: windowHeight, 
      position: 'absolute', 
    };
  };

  // Compute the inner (non-interactive) minimap viewport indicator representing the main scrollbar thumb
  getMinimapViewportIndicatorStyle = () => {
    const { viewportStart, windowStart, windowSize } = this.state;
    const { totalItems } = this.props;
    if (totalItems <= 0 || windowSize <= 0) return { height: 5 };
    
    // Get minimap thumb dimensions
    const minimapThumb = this.getMinimapThumbStyle();
    if (!minimapThumb.height) return { height: 5 };
    
    // Calculate viewport position as fraction of window
    const viewportFraction = Math.max(0, Math.min(1, (viewportStart - windowStart) / windowSize));
    
    // For single record viewport, calculate height based on 1/windowSize
    // This represents a single record's height in the minimap
    const viewportSizeFraction = Math.min(1, 1 / windowSize);
    
    // Calculate position and size
    const viewportTop = viewportFraction * minimapThumb.height;
    const viewportHeight = Math.max(2, viewportSizeFraction * minimapThumb.height);
    
    // Ensure indicator stays within the minimap thumb
    const clampedTop = Math.min(minimapThumb.height - viewportHeight, viewportTop);
    
    return { 
      top: clampedTop, 
      height: viewportHeight, 
      position: 'absolute', 
      width: '100%' 
    };
  };

  // Convert from dataset index to screen position (pixels)
  datasetToScreenY = (recordIndex) => {
    const { trackHeight, windowStart, windowSize } = this.state;
    if (windowSize <= 0) return 0;
    
    // Calculate position as fraction of window
    const fraction = (recordIndex - windowStart) / windowSize;
    // Convert to pixels
    return fraction * trackHeight;
  };
  
  // Convert from screen position (pixels) to dataset index
  screenToDatasetY = (screenY) => {
    const { trackHeight, windowStart, windowSize } = this.state;
    if (trackHeight <= 0) return windowStart;
    
    // Calculate as fraction of track height
    const fraction = screenY / trackHeight;
    // Convert to dataset index
    return windowStart + (fraction * windowSize);
  };
  
  // Calculate thumb offset in pixels based on viewport position
  getThumbOffsetPx = () => {
    const { viewportStart, windowStart, windowSize, thumbSizePx, trackHeight } = this.state;
    
    // If the window is empty or smaller than the thumb, position at top
    if (windowSize <= 0) {
      return 0;
    }
    
    // Calculate the position as a fraction of the window
    const viewportFraction = (viewportStart - windowStart) / windowSize;
    
    // Constrain to valid range (0 to 1)
    const clampedFraction = Math.max(0, Math.min(1, viewportFraction));
    
    // Calculate maximum travel distance for thumb
    const maxTravel = trackHeight - thumbSizePx;
    
    // Convert to pixels
    return clampedFraction * maxTravel;
  };
  
  // Calculate thumb height in pixels
  getThumbSizePx = () => {
    return this.state.thumbSizePx;
  };

  // Render a debug panel showing important state values
  renderDebugPanel = () => {
    const { 
      windowStart, 
      windowSize, 
      viewportStart, 
      zoomLevel, 
      thumbSizePx,
      trackHeight,
      trackWidth 
    } = this.state;
    
    const { totalItems } = this.props;
    
    // Calculate some derived values
    const windowEnd = windowStart + windowSize;
    const windowPercentage = ((windowSize / totalItems) * 100).toFixed(2);
    const viewportPercentage = ((thumbSizePx / trackHeight) * 100).toFixed(2);
    const samplingRate = this.getSamplingRate();
    
    const style = {
      position: 'absolute',
      right: '40px',
      top: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      fontFamily: 'monospace',
      fontSize: '12px',
      zIndex: 1000,
      pointerEvents: 'none'
    };
    
    return (
      <div style={style}>
        <div><strong>Zoom Level:</strong> {zoomLevel}</div>
        <div><strong>Window:</strong> {windowStart} - {windowEnd} ({windowSize} items)</div>
        <div><strong>Window %:</strong> {windowPercentage}% of dataset</div>
        <div><strong>Viewport:</strong> {viewportStart}</div>
        <div><strong>Viewport %:</strong> {viewportPercentage}% of track</div>
        <div><strong>Total Items:</strong> {totalItems}</div>
        <div><strong>Sampling:</strong> 1:{samplingRate}</div>
        <div><strong>Thumb Size:</strong> {thumbSizePx.toFixed(1)}px</div>
        <div><strong>Track Size:</strong> {trackHeight.toFixed(1)}px × {trackWidth.toFixed(1)}px</div>
      </div>
    );
  };

  onMouseDown = e => {
    e.preventDefault()
    this.state.isDragging = true
    this.state.startY = e.clientY
    const track = this.trackRef.current
    const rect = track.getBoundingClientRect()
    this.state.thumbOffset = e.target.getBoundingClientRect().top - rect.top
    document.addEventListener("mousemove", this.onMouseMove)
    document.addEventListener("mouseup", this.onMouseUp)
    this.forceUpdate()
  }

  onMouseMove = e => {
    if (!this.state.isDragging) return;
    const { startY } = this.state
    const deltaY = e.clientY - startY;
    const track = this.trackRef.current
    const maxThumbTop = track.clientHeight - this.state.thumbSizePx
    const windowSize = track.clientHeight / this.state.thumbSizePx
    this.state.thumbOffset += deltaY
    this.state.startY = e.clientY
    const scrollRatio = this.state.thumbOffset / track.clientHeight
    const offset = Math.round(this.props.startRow + scrollRatio * windowSize)
    const rect = track.getBoundingClientRect()
    this.forceUpdate()
    this.props.onPivotChange(offset)
  }

  onMouseUp = e => {
    e.preventDefault()
    this.state.isDragging = false
    document.removeEventListener("mousemove", this.onMouseMove)
    document.removeEventListener("mouseup", this.onMouseUp)
    this.forceUpdate()
  }

  render() {
    const { thumbSizePx } = this.state;
    const track = this.trackRef.current
    const { startRow, endRow, pivotRow } = this.props
    let thumbOffset
    if (this.state.isDragging) {
      thumbOffset = this.state.thumbOffset
    } else {
      thumbOffset = ((pivotRow - startRow) / (endRow - startRow)) * 100 + "%"
    }
    console.log({thumbOffset})
    // Calculate thumb style
    const thumbStyle = {
      height: `${thumbSizePx}px`,
      top: thumbOffset,
      position: 'absolute',
    };
    
    // Calculate minimap thumb style
    const minimapThumbStyle = this.getMinimapThumbStyle();
    
    // Calculate viewport indicator styles
    const viewportIndicatorStyle = this.getViewportIndicatorStyle();
    const minimapViewportIndicatorStyle = this.getMinimapViewportIndicatorStyle();
    return (
      <div className="scrollbar-container">
        {false && this.renderDebugPanel()}
        <div
          ref={this.trackRef}
          className="scrollbar-track"
          style={{ height: '100%', position: 'relative' }}
        >
          <div
            ref={this.thumbRef}
            className="scrollbar-thumb"
            style={thumbStyle}
            onMouseDown={this.onMouseDown}
          >
            {/* Non-interactive viewport indicator within the main scrollbar thumb */}
            <div
              className="viewport-indicator"
              style={viewportIndicatorStyle}
            />
          </div>
        </div>
        <div className="dataset-minimap">
          <div className="minimap-track"
               ref={this.minimapRef}
          >
            <div
              className="minimap-thumb"
              style={minimapThumbStyle}
              onMouseDown={this.handleMinimapMouseDown}
            >
              {/* Non-interactive inner minimap viewport indicator representing the main scrollbar thumb */}
              <div
                className="minimap-viewport-indicator"
                style={minimapViewportIndicatorStyle}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Calculate the current sampling rate based on zoom level
  getSamplingRate = () => {
    const { zoomLevel } = this.state;
    // At zoom level 0, sample every record (rate = 1)
    // At zoom level 1, sample every N records (where N depends on your zoom ratio)
    // We'll use powers of 2 for simplicity
    return Math.max(1, Math.pow(2, zoomLevel));
  };
}
