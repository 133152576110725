import React, { Component } from 'react'
import ToolServer from '../../assets/Icons/ToolServer.svg'
import Down from '../../assets/Icons/Down.svg'
import Up from '../../assets/Icons/Up.svg'
import { getComponents, pasteText, SimpleIcon, SimpleButton, DeleteButton, SearchField } from '../ChatGPT2'
import './RenderTool.css'

function convertSchemaToTypescriptFunction(schema) {
  if (!schema || schema.type !== 'function' || !schema.function) {
    throw new Error('Invalid schema: Must be a function schema');
  }

  const func = schema.function;
  let doc = `/**\n * @${func.name} - ${func.description}\n*`;

  // Build the parameter list and interface for the TypeScript function
  let params = ''
  let sep = ''
  let docSep = ''
  if (func.parameters && func.parameters.properties) {
    for (const [paramName, paramDetails] of Object.entries(func.parameters.properties)) {
      const optional = func.parameters.required && !func.parameters.required.includes(paramName) ? '?' : '';
      doc += docSep
      doc += `\n* @${paramName} ${paramDetails.description ? (' - ' + paramDetails.description) : ''}`
      params += sep
      sep = ', '
      params += `${paramName}${optional}: ${convertSchemaToTypescript(paramDetails)}`
    }
  }

  // Add the function parameters to the function signature
  doc += `\n**/\n\nfunction ${func.name}(${params}): any;\n`;

  // Combine the interface and function declaration
  return doc
}

function convertSchemaToTypescript(schema) {
  // Helper function to convert each schema definition to TypeScript
  const convertDefinition = (definition) => {
    if (!definition) {
      return 'any';
    }

    if (definition.$ref) {
      return definition.$ref.replace('#/definitions/', '');
    }

    switch (definition.type) {
      case 'string':
        return 'string';
      case 'number':
        return 'number';
      case 'integer':
        return 'number';
      case 'boolean':
        return 'boolean';
      case 'object':
        const properties = Object.entries(definition.properties || {})
          .map(([key, value]) => {
            const optional = definition.required && !definition.required.includes(key) ? '?' : '';
            return `${key}${optional}: ${convertDefinition(value)};`;
          })
          .join(' ');
        return `{ ${properties} }`;
      case 'array':
        return `${convertDefinition(definition.items)}[]`;
      case 'null':
        return 'null';
      default:
        return 'any';
    }
  };

  // Handle combining schemas with oneOf, anyOf, allOf
  const combineSchemas = (schemas, combiner) => {
    if (!schemas) return 'any';
    return schemas.map(convertDefinition).join(combiner);
  };

  // Main conversion starts here
  if (schema.oneOf) {
    return combineSchemas(schema.oneOf, ' | ');
  } else if (schema.anyOf) {
    return combineSchemas(schema.anyOf, ' | ');
  } else if (schema.allOf) {
    return combineSchemas(schema.allOf, ' & ');
  } else if (schema.enum) {
    return schema.enum.map((value) => JSON.stringify(value)).join(' | ');
  } else {
    return convertDefinition(schema);
  }
}

class ToolView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }
  render() {
    const { tool } = this.props
    let code
    if (true) {
      code = convertSchemaToTypescriptFunction(tool)
    } else {
      const json = JSON.stringify(tool, null, '    ')
      code = json
    }

    const [x, y] = tool.function.name.split('.')
    const heading = y || x
    
    const isOpen = this.state.open
    const open = async() => {
      this.setState({
        open: !this.state.open
      })
    }
    const contentDiv = <div className='toolCode'>
                         {code}
                       </div>
    return <div className='systemPromptContainer toolContainer'>
             <div className='systemPromptHeader'>
               <div className='systemPromptHeaderLeft'>
                 <div className='systemPromptHeaderIcon'>
                   <SimpleIcon src={ToolServer}/>
                 </div>
                 <div className='systemPromptHeaderTitle'>
                   {heading}
                 </div>
               </div>
               <div className='systemPromptHeaderDisclosure'>
                 <SimpleButton icon={isOpen ? Up: Down} action={open}/> 
               </div>
             </div>
             {isOpen && <div className='systemPromptBody'>
               {contentDiv}
                        </div>}
           </div>
  }
}

export const toCode = (tool) => {
  return convertSchemaToTypescriptFunction(tool)
}

export const doRenderTool = (tool) => {
  return <ToolView tool={tool}/>
}
