import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { getComponents, pasteText, SimpleIcon, SimpleButton, DeleteButton, SearchField } from '../ChatGPT2'
import { Markdown } from '../ChatGPT2/Markdown.js'
import { selectAll, fromNow, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay} from '../../classes/Util.js'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Right from '../../assets/Icons/Forward.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Cross from '../../assets/Icons/Cross.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Paste from '../../assets/Icons/Paste.svg'
import Edit from '../../assets/Icons/Edit.svg'
import Cut from '../../assets/Icons/Share.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from '../Home/Usage.js'
import { ActionMenu } from '../Home/ActionMenu.js'
import { doRenderTool } from '../Tools/RenderTool.js'
import { Toolsets } from '../Tools'
import './index.css'

let selectedSystemPrompt


class SystemPromptToolset extends UComponent {

  constructor (props) {
    super(props)
    this.state = {
      toolsets: [],
      tools: []
    }
  }
  
  toolsets = {}
  componentDidMount() {
    debugger
    this.sub = this.props.me.observeToolsetsRecursively(this.props.toolset.id).subscribe(change => {
      const { type, toolset} = change
      if (type === 'removed') {
        delete this.toolsets[toolset.id]
      } else {
        this.toolsets[toolset.id] = toolset
      }
      this.updateLater("toolsets", this.updateToolsets)
    })
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  updateToolsets = () => {
    const toolsets = Object.values(this.toolsets)
    const tools = toolsets.flatMap(toolset => {
      return toolset.tools || []
    })
    this.setState({
      toolsets,
      tools
    })
  }


  render() {
    const toolset = this.props.toolset
    const { tools } = this.state
    tools.sort((x, y) => {
      return x.function.name.localeCompare(y.function.name)
    })
    return <div className='systemPromptToolset'>
             {!this.props.isSystem &&<div key='non-system' className='systemPromptToolsetTopRow'>
               <div className='systemPromptToolsetTitle'>
                 {toolset.name}
               </div>
               <div className='systemPromptToolsetClear'>
                 <SimpleButton icon={Cross} action={this.props.clearToolset}/>
               </div>
             </div>}
             <div key='toolsetTools' className='systemPromptToolsetTools'>
               {
                 tools.map(tool => {
                   return <div key={tool.function.name} className='systemPromptToolsetTool'>
                            <div className='desktopToolsetDetailsTitle'>
                              {tool.function.name}
                            </div>
                            <div className='toolsetEditorBg'>
                              <div className='toolsetEditorLeft1'>
                                {
                                  doRenderTool(tool)
                                }
                              </div>
                            </div>
                          </div>
                 })
               }
             </div>
           </div>
  }
}

export class SystemPrompt extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  editTitle = () => {
    this.setState({
      title: this.props.systemPrompt.title,
      editingTitle: true
    }, () => {
      this.input.focus()
    })
  }

  commitEdit = async () => {
    if (this.state.editingTitle) {
      await this.props.save(this.state.title)
      this.stopEditingTitle()
    }
  }

  stopEditingTitle = () => {
    this.setState({editingTitle: false})
  }

  onChangeTitle = title => {
    this.setState({
      title
    })
  }

  setInput = ref => {
    this.input = ref
    if (ref && isDesktop()) {
      ref.focus()
    }
  }

  render() {
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    const systemPrompt = this.props.systemPrompt
    const { isFolder, isSystem } = systemPrompt
    const onClick = () => {
      this.props.selectSystemPrompt()
    }
    let trash = this.props.trash
    let date
    date = fromNow(systemPrompt.lastUpdated)
    let deleteButton = <DeleteButton label='Delete' trash={trash}/>
    let dateComp
    dateComp = <div className='systemPromptDate'>{date}</div>
    let components = getComponents()
    let { usage } = systemPrompt
    let dollars
    if (usage && usage.total) {
      dollars = '$'+formatPrice(usage.total)
    }
    let { name, title, content } = systemPrompt
    content = content || '' 
    let titleDiv
    let onBlur
    const para = content.indexOf('\n\n')
    if (para > 0) {
      content = content.substring(0, para) + "..."
    } 
    const sentence = content.indexOf('. ')
    if (sentence > 0 && sentence + 5 < content.length) {
      content = content.substring(0, sentence) + "..."
    }
    
    onBlur = async () => {
      this.commitEdit()
    }
    let onKeyDown
    if (isDesktop()) {
      onKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          this.commitEdit()
        } else if (event.key === 'Escape') {
          event.preventDefault()
          this.stopEditingTitle()
        }
      }
    }
    if (this.state.editingTitle) {
      titleDiv = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={this.stopEditingTitle}>
                   <div className='systemPromptTitle'>
                     <input
                       onBlur={onBlur}
                       onKeyDown={onKeyDown}
                       ref={this.setInput}
                       onChange={e=> this.onChangeTitle(e.target.value)}
                       value={this.state.title}/>
                   </div>
                 </ClickAwayListener>
    } else {
      titleDiv = <div className='systemPromptTitle'>
        {title}
      </div>
    }
    const select = this.props.select
    const action = select || this.props.open
    const contentDiv = <Markdown components={components}>{content}</Markdown>
    const copy = async () => {
      await this.props.copy(this.props.systemPrompt)
      await delay(0.3)
    }
    const cut = async () => {
      await this.props.cut(this.props.folder, this.props.systemPrompt)
    }
    const actions = []
    if (this.props.folder) {
      actions.push({
        icon: Cut,
        label: 'Cut',
        action: cut
      })
    }
    actions.push({
      icon: Copy,
      label: 'Copy',
      action: copy
    })
    if (!isSystem && this.props.systemPrompt.isFolder) {
      actions.push({
        icon: Edit,
        label: 'Edit',
        action: this.props.edit || this.editTitle
      })
    }
    if (!isSystem) {
      actions.push({
        button: () => <div className='deleteButtonHolder'>{deleteButton}</div>
      })
    }

    let icon
    if (isFolder) {
      icon = Folder
    } else {
      icon = UserSaid
    }
    let className='systemPromptTask'
    if (this.props.isSelected) {
      className += ' systemPromptTaskSelected'
    }
    return <div
             key={systemPrompt.id}
             draggable={!this.state.editingTitle}
             onDragStart={onDragStart}
             onClick={this.props.open}             
             className={className}>
             <div  className='systemPromptLeft' >
               <div className='systemPromptLeftTopRow'>
                 <div className='systemPromptLeftTopRowLeft'>
	           <SimpleIcon src={icon}/>
                   {titleDiv}
                 </div>
                 <div className='systemPromptTopRowRight'>{dateComp}</div>
               </div>
               <div className='systemPromptMiddle'>
                 <div className='systemPromptMiddleDescription'>
                   <Markdown components={components}>{content}</Markdown>
                 </div>
               </div>
             
             </div>
             <div className='systemPromptRight'>
               <ActionMenu className='systemPromptActions' actions={actions}/>
               <SimpleButton icon={Right} action={action}/>
             </div>
           </div>
  }
}

export class SystemPromptEditor extends BnSubpage {


  setEditor = ref => {
    if (this.editor !== ref) {
      this.editor = ref
      if (ref) {
        ref.innerText = this.props.systemPrompt.content || ''
      }
    }
  }

  setTitleEditor = ref => {
    if (this.titleEditor !== ref) {
      this.titleEditor = ref
      if (ref) {
        ref.value = this.props.systemPrompt.name || 'New System Prompt'
        debugger
        if (!this.props.systemPrompt.name) {
          ref.focus()
        }
      }
    }
  }

  componentDidMount() {
  }
  
  edit = () => {
    this.setState({
      editing: true
    }, () => {
      this.editor.focus()
    })
  }

  editTitle = () => {
    this.setState({
      editingTitle: true
    }, () => {
      this.titleEditor.focus()
      if (!this.props.systemPrompt.name) {
        selectAll(this.titleEditor)
      }
    })
  }

  stopEditing = () => {
    this.setState({
      editing: false,
      editingTitle: false
    })
  }

  clearToolset = async () => {
    await this.props.save({
      toolset: null
    })
    this.setState({
      toolset: null
    })
  }
  
  save = async () => {
    let name = this.titleEditor ? this.titleEditor.value : this.props.systemPrompt.name
    await this.props.save({
      name: name,
      content:  this.editor ? this.editor.innerText : this.props.systemPrompt.content,
      toolset: this.state.toolset ? this.state.toolset: this.props.systemPrompt.toolset
    })
    this.stopEditing()
  }

  selectTools = () => {
    let cut
    const onCreate = (comp) => {
      
    }
    let copy
    const select = (toolset) => {
      this.setState({
        toolset
      })
      this.back()
    }
    const render = this.props.chooseToolsets({
      key: this.props.systemPrompt.id,
      chooseToolset: select,
      back: this.back
    })
    debugger
    this.setState({
      subpage: () => render()
      
    })
  }

  renderDetail() {
    const { copy, trash, copyTitle, setTools, buttons, instructions } = this.getOps()
    return <div className='systemPromptDetails'>
             <div className='systemPromptEditorSections'>
               {instructions()}
             </div>
             {buttons}
           </div>
  }

  getOps = () => {
    const copy = async () => {
      try {
        navigator.clipboard.writeText(this.editor.innerText)
      } catch (err) {
        console.error(err)
        //console.log(text)
      }
      this.props.copy(this.props.systemPrompt)
      await delay(0.5)
    }
    const trash = async () => {
      await this.props.me.deleteSystemPrompt(this.props.systemPrompt.id)
      if (isMobile()) {
        this.props.back()
      } else {
        this.setState({
          renderEditor: null
        })
      }
    }
    const copyTitle = () => {
    }
    const setTools = () => {
      this.selectTools()
    }

    const { isSystem } = this.props.systemPrompt
    let buttons
    if (false && !isSystem) {
      debugger
      buttons = <div className='systemPromptEditorButtons'>
                  <SimpleButton label={'Save'} icon={Save} action={this.save}/>
                  <div className='systemPromptDelete'>
                    <DeleteButton trash={trash}/>
                  </div>
                </div>
    }
    const onBlur = () => {
      this.save()
    }
    let instructions  =  () => <Section key='instructions' name='Instructions'
                                        style={{flexGrow: 1, maxHeight: 'calc(100% - 0px)'}}
                                  row2={
                                    <div className='systemPromptEditorContainerEditor'>
                                      <div ref={this.setEditor}
                                           onPaste={pasteText}
                                           onBlur={onBlur}
                                           className='systemPromptEditor'
                                           contentEditable={this.state.editing}/>
                                    </div>
                                 }
                                        actions={isSystem ? [] : 
                                    [
                                      {
                                        icon: Edit,
                                        action: this.edit
                                      },
                                   ]
                                  }/>
    const Section = ({ key, name, content, actions, style, row2, row2Class }) => {
      return <div key={key} className='systemPromptEditorSection' style={style}>
               <div key='row1' className='systemPromptEditorSectionRow1'>
                 {name &&<div key='name' className='systemPromptEditorSectionTitle'>
                           {name}
                         </div>}
                 <div key='ed' className='systemPromptEditorSectionContent'>
                   {content}
                 </div>
                 <div key='ed-right' className='systemPromptEditorSectionRight'>
                   {
                     actions.map(x => {
                       const { icon, action } = x
                       return <SimpleButton icon={icon} action={action}/>
                     })
                   }
                 </div>
               </div>
               {
                 row2 && <div key='row2' className={'systemPromptEditorSectionRow2 ' + (row2Class || '')} >
                           {row2}
                         </div>
               }
             </div>
    }
    return { copy, trash, copyTitle, setTools, buttons, instructions, Section }
  }

  renderToolset = toolset => {
    return <Toolset key={toolset.id}
             renderTask={this.props.renderTask}
             emptyTitle={this.props.emptyTitle}
             onDrag={onDragToolset}
             onDrop={onDropToolset}
             folder={this.props.folder}
             key={toolset.id}
             select={selectToolset}
             toolset={toolset}
             save={saveTitle}
             copy={this.props.copy}
             cut={this.props.cut}
             edit={this.state.openPrompt ? null: openToolset}
             open={openToolset}
             trash={trash}/>
  }
  
  renderContent() {
    const { copy, trash, copyTitle, setTools, buttons, instructions, Section } = this.getOps()

    const { isSystem } = this.props.systemPrompt

    const onBlur = () => {
      this.save()
    }
    let onKeyDown
    if (isDesktop()) {
      onKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          this.save()
        } else if (event.key === 'Escape') {
          this.titleEditor.value = this.props.systemPrompt.name
          this.titleEditor.blur()
        }
      }
    }

    const onChangeTitle = title => {
      this.setState({
        title: title
      })
    }

    const onClickAway = () => {
    }
                                    
    const titleContent = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={onClickAway}>
                           <div className='systemPromptTitle'>
                             <input
                               className='systemPromptTitleEditor'
                               onBlur={onBlur}
                               onKeyDown={onKeyDown}
                               ref={this.setTitleEditor}/>
                           </div>
                         </ClickAwayListener>

    let right
    let section3
    let inlineButtons

    if (isDesktop()) {
      section3 = null
    } else {
      section3 = instructions()
      inlineButtons = buttons
    }
    const renderToolset = () => {
      const toolset = this.state.toolset || this.props.systemPrompt.toolset
      if (toolset) {
        return <SystemPromptToolset key={toolset.id} isSystem={this.props.systemPrompt.isSystem} clearToolset={this.clearToolset} me={this.props.me} toolset={toolset}/>
      }
    }
    let toolset = this.state.toolset || this.props.systemPrompt.toolset
    return <div className='systemPromptEditorContainer'>
             <div className='systemPromptEditorBg'>
               <div className='systemPromptEditorSections'>
                 {!isSystem && <Section key='nameSection' name=''
                          content={titleContent}
                          actions={isSystem ? [] :
                          [
                            {
                              icon: Edit,
                              action: this.editTitle
                            },
                          ]
                 }/>}
                 <Section key='toolsSection' name={toolset ? "Tools" : "Toolset"} 
                          row2={renderToolset()}
                          row2Class={'sysPromptToolsetRow2' }
                          actions={isSystem ? [] :
                          [
                            {
                              icon: Right,
                              action: setTools
                            },
                          ]
                        }/>
                 {section3}
               </div>
             </div>
             {inlineButtons}
    </div>
  }
}



