import React, { Component } from 'react'
import { isSafari, isChrome, isMobile, isDesktop } from '../../classes/Platform.js'
import { listOnKeyDown } from '../../classes/Util.js'
import { DataProvider } from './DataProvider.js'
import './index.css'


/**
 * Easing function: easeInOutCubic
 * @param {number} t - Progress of the animation (0 to 1)
 * @returns {number} - Eased progress
 */
function easeInOutCubic(t) {
  return t < 0.5
    ? 4 * t * t * t
    : 1 - Math.pow(-2 * t + 2, 3) / 2;
}

/**
 * Finds the closest scrollable ancestor of an element.
 * @param {Element} element - The target element.
 * @returns {Element|Window} - The nearest scrollable ancestor or the window if none found.
 */
function getScrollableParent(element) {
  if (!(element instanceof Element)) {
    throw new Error('The provided target is not a DOM element.');
  }

  const overflowRegex = /(auto|scroll|hidden)/;

  let parent = element.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);
    const overflowY = style.overflowY;
    const overflowX = style.overflowX;

    if (overflowRegex.test(overflowY) || overflowRegex.test(overflowX)) {
      // Check if the element is scrollable
      if (parent.scrollHeight > parent.clientHeight || parent.scrollWidth > parent.clientWidth) {
        return parent;
      }
    }
    parent = parent.parentElement;
  }

  // If no scrollable parent found, return window
  return window;
}

/**
 * Calculates the position of an element relative to a container.
 * @param {Element} target - The target element.
 * @param {Element|Window} container - The scrollable container.
 * @returns {{ top: number, left: number }} - The top and left positions.
 */
function getRelativePosition(target, container) {
  const src = target.getBoundingClientRect()
  const dst = (container instanceof Element) ? container.getBoundingClientRect() : {
    top: 0,
    left: 0
  }
  return {
    top: src.top - dst.top,
    left: src.left - dst.left
  }
  let posX = 0, posY = 0;
  let el = target;

  while (el && el !== container && el.offsetParent) {
    posX += el.offsetLeft;
    posY += el.offsetTop;
    el = el.offsetParent;
  }

  return { top: posY, left: posX };
}

/**
 * Custom smooth scroll into view function with `block` and `inline` alignment options
 * @param {Element} target - The target element to scroll into view
 * @param {Object} options - Optional parameters
 * @param {number} options.duration - Duration of the scroll in ms
 * @param {number} options.offset - Additional offset in pixels
 * @param {function} options.easing - Easing function
 * @param {string} options.block - Vertical alignment: 'start', 'center', 'end', 'nearest'
 * @param {string} options.inline - Horizontal alignment: 'start', 'center', 'end', 'nearest'
 * @returns {Promise} - Resolves when scrolling is complete
 */
function customScrollIntoView(target, options = {}) {
  ////debugger
  return new Promise((resolve, reject) => {
    if (!(target instanceof Element)) {
      reject(new Error('Target must be a DOM element.'));
      return;
    }

    const {
      duration = options.behahvior === 'instant' ? 0: 700,
      offset = 0,
      easing = easeInOutCubic,
      block = 'start',   // 'start', 'center', 'end', 'nearest'
      inline = 'start',  // 'start', 'center', 'end', 'nearest'
    } = options;


    const scrollableParent = getScrollableParent(target);
    if (!scrollableParent) {
      console.warn("scrollable parent not found", scrollableParent)
      return target.scrollIntoView(options)
    }

    // Calculate the target's position relative to the scrollable parent
    const relativePos = getRelativePosition(target, scrollableParent);
    console.log({relativePos})
    const targetHeight = target.offsetHeight;
    const targetWidth = target.offsetWidth;

    let destinationY, destinationX;

    // Vertical Alignment (block)
    switch (block) {
      case 'start':
        destinationY = relativePos.top + offset;
        break;
      case 'center':
        destinationY = relativePos.top - (scrollableParent === window ? window.innerHeight / 2 - targetHeight / 2 : scrollableParent.clientHeight / 2 - targetHeight / 2) + offset;
        break;
      case 'end':
        destinationY = relativePos.top + targetHeight - (scrollableParent === window ? window.innerHeight : scrollableParent.clientHeight) + offset;
        break;
      case 'nearest':
        const parentRect = scrollableParent === window ? { top: 0, bottom: window.innerHeight } : scrollableParent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (scrollableParent === window) {
          if (targetRect.top < 0) {
            destinationY = window.pageYOffset + targetRect.top + offset;
          } else if (targetRect.bottom > window.innerHeight) {
            destinationY = window.pageYOffset + targetRect.bottom - window.innerHeight + offset;
          } else {
            destinationY = window.pageYOffset; // No scrolling needed
          }
        } else {
          if (targetRect.top < parentRect.top) {
            destinationY = scrollableParent.scrollTop + targetRect.top - parentRect.top + offset;
          } else if (targetRect.bottom > parentRect.bottom) {
            destinationY = scrollableParent.scrollTop + targetRect.bottom - parentRect.bottom + offset;
          } else {
            destinationY = scrollableParent.scrollTop; // No scrolling needed
          }
        }
        break;
      default:
        destinationY = relativePos.top + offset;
    }
    
    // Horizontal Alignment (inline)
    switch (inline) {
      case 'start':
        destinationX = relativePos.left + offset;
        break;
      case 'center':
        destinationX = relativePos.left - (scrollableParent === window ? window.innerWidth / 2 - targetWidth / 2 : scrollableParent.clientWidth / 2 - targetWidth / 2) + offset;
        break;
      case 'end':
        destinationX = relativePos.left + targetWidth - (scrollableParent === window ? window.innerWidth : scrollableParent.clientWidth) + offset;
        break;
      case 'nearest':
        const parentRectInline = scrollableParent === window ? { left: 0, right: window.innerWidth } : scrollableParent.getBoundingClientRect();
        const targetRectInline = target.getBoundingClientRect();

        if (scrollableParent === window) {
          if (targetRectInline.left < 0) {
            destinationX = window.pageXOffset + targetRectInline.left + offset;
          } else if (targetRectInline.right > window.innerWidth) {
            destinationX = window.pageXOffset + targetRectInline.right - window.innerWidth + offset;
          } else {
            destinationX = window.pageXOffset; // No scrolling needed
          }
        } else {
          if (targetRectInline.left < parentRectInline.left) {
            destinationX = scrollableParent.scrollLeft + targetRectInline.left - parentRectInline.left + offset;
          } else if (targetRectInline.right > parentRectInline.right) {
            destinationX = scrollableParent.scrollLeft + targetRectInline.right - parentRectInline.right + offset;
          } else {
            destinationX = scrollableParent.scrollLeft; // No scrolling needed
          }
        }
        break;
      default:
        destinationX = relativePos.left + offset;
    }
    console.log("destination y", destinationY)
    // Clamp destination positions to valid scroll ranges
    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
    /*
    if (scrollableParent === window) {
      const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
      const maxScrollX = document.documentElement.scrollWidth - window.innerWidth;

      destinationY = clamp(destinationY, 0, maxScrollY);
      destinationX = clamp(destinationX, 0, maxScrollX);
    } else {
      const maxScrollY = scrollableParent.scrollHeight - scrollableParent.clientHeight;
      const maxScrollX = scrollableParent.scrollWidth - scrollableParent.clientWidth;

      destinationY = clamp(destinationY, 0, maxScrollY);
      destinationX = clamp(destinationX, 0, maxScrollX);
      }
    */
    // Current scroll positions
    let startY, startX;
    if (scrollableParent === window) {
      startY = window.pageYOffset;
      startX = window.pageXOffset;
    } else {
      startY = scrollableParent.scrollTop;
      startX = scrollableParent.scrollLeft;
    }
    destinationY += startY
    destinationX += startX

    const distanceY = destinationY - startY;
    const distanceX = destinationX - startX;
    let startTime = null;

    console.log({startY, destinationY})
    
    // Animation loop
    function animation(currentTime) {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1); // Ensure progress doesn't exceed 1

      const easedProgress = easing(progress);
      const currentY = startY + distanceY * easedProgress;
      const currentX = startX + distanceX * easedProgress;

      if (scrollableParent === window) {
        window.scrollTo(currentX, currentY);
      } else {
        scrollableParent.scrollTo(currentX, currentY);
      }

      if (timeElapsed < duration) {
        window.requestAnimationFrame(animation);
      } else {
        // Ensure we reach the destination
        if (scrollableParent === window) {
          window.scrollTo(destinationX, destinationY);
        } else {
          scrollableParent.scrollTo(destinationX, destinationY);
        }
        resolve();
      }
    }

    // Start the animation
    window.requestAnimationFrame(animation);
  });
}

export class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  setDataProvider = dataProvider => {
    this.dataProvider = dataProvider
  }

  onLoadMore = async (direction) => {
    if (this.props.onLoadMore) {
      const { count, update } =  await this.props.onLoadMore(direction, this.props.pageSize || 11)
      return { count, update }
    }
    return { count: 0, update: () => {}}
  }

  onUnload = async () => {
  }

  
  scrollToItem = async (item, options) => {
    if (this.dataProvider) await this.dataProvider.scrollToItem(item, options)
  }

  scrollToTop = async () => {
    if (this.dataProvider) await this.dataProvider.scrollToTop()
  }

  scrollToBottom = async () => {
    if (this.dataProvider) await this.dataProvider.scrollToBottom()
  }

  scrollToTopItem = async () => {
    if (this.dataProvider) await this.dataProvider.scrollToTopItem()
  }

  scrollToBottomItem = async () => {
    if (this.dataProvider) await this.dataProvider.scrollToBottomItem()
  }

  scrollToBottomItem = async () => this.scrollToItem(this.props.items[this.props.items.length-1])

  setScrollPos = async ({
    baseOffset,
    offset,
    scrollTop
  }) => {
    if (this.dataProvider) {
      debugger
      await this.dataProvider.setScrollPos({
        baseOffset,
        offset,
        scrollTop
      })
    }
  }

  render() {
    return <DataProvider
             onScroll={this.props.onScroll}
             isBottomAligned={this.props.isBottomAligned}
             onKeyDown={this.props.onKeyDown}
             autoFocus={this.props.autoFocus}
             onCreate={this.setDataProvider}
             onUnload={this.onUnload}
             onLoadMore={this.onLoadMore}
             windowSize={this.props.pageSize || 11}
             items={this.props.items}
             getId={this.props.getId || (x => x.id)}
             getSelector={this.props.getSelector || (id => `[data-id="${id}"]`)}
             renderItems={this.props.renderItems}
             debug={this.props.debug}
           />
  }
             
}
