import lunr from "lunr"

function dotProduct(vecA, vecB) {
  return vecA.reduce((sum, val, i) => sum + val * vecB[i], 0);
}

function norm(vec) {
  return Math.sqrt(vec.reduce((sum, val) => sum + val * val, 0));
}

function cosineSimilarity(vecA, vecB) {
  return dotProduct(vecA, vecB) / (norm(vecA) * norm(vecB));
}

const customTokenizer = (input) => {
  if (!input || typeof input !== "string") return [];
  // Split input by spaces and hyphens, retain the original input as well
  const splitTokens = input.split(/[-\s]+/);
  return lunr.tokenizer(splitTokens.join(" "));
}


export class InMemorySearch {

  refs = {}

  constructor ({id, fields, documents}) {
    this.index({id, fields, documents})
  }
  
  index = ({id, fields, documents}) => {
    const self = this
    this.id = id
    this.idx = lunr(function() {
      this.ref(id)
      this.tokenizer = customTokenizer
      for (let x of fields) {
        if (typeof x === 'string') {
          this.field(x)
        } else {
          const { field, boost } = x
          this.field(field, { boost })
        }
      }
      for (const doc of documents) {
        self.refs[doc[id]] = doc
        this.add(doc)
      }
    })
  }

  deleteDocument = id => {
    delete this.refs[id]
    this.idx.remove({id})
  }

  indexDocument = doc => {
    const id = this.id(doc)
    deleteDocument(id)
    this.refs[doc[id]] = doc
    this.idx.add(doc)
  }

  search = query => {
    const exact = this.idx.search(query).map(result => {
      console.log('result', result)
      return this.refs[result.ref]    
    }).filter(x=>x)
    if (exact.length > 0) {
      return exact
    }
    const prefix = this.idx.search(query+"*").map(result => {
      console.log('result', result)
      return this.refs[result.ref]    
    }).filter(x=>x)
    return prefix
  }
}


