import React from 'react';

class ToolConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        args: props.initialValues?.args || {},
        env: props.initialValues?.env || {},
        credentials: props.initialValues?.credentials || {}
      }
    };
  }

  updateConfig(path, value) {
    this.setState(prevState => {
      const newConfig = { ...prevState.config };
      let current = newConfig;
      path.slice(0, -1).forEach(p => {
        current[p] = { ...current[p] };
        current = current[p];
      });
      current[path[path.length - 1]] = value;
      return { config: newConfig };
    });
  }

  renderField(key, schema, path = []) {
    const value = path.reduce((obj, key) => obj[key], this.state.config) || '';
    
    switch (schema.type) {
      case 'array':
        return (
          <div key={key} style={{ marginBottom: '1rem' }}>
            <label>
              <div>{schema.description || key}</div>
              <textarea
                value={Array.isArray(value) ? value.join('\n') : ''}
                onChange={(e) => {
                  const newValue = e.target.value.split('\n').filter(Boolean);
                  this.updateConfig(path, newValue);
                }}
                placeholder={`Enter ${key} (one per line)`}
                style={{ width: '100%', minHeight: '100px' }}
              />
            </label>
            {schema.description && (
              <div style={{ fontSize: '0.875rem', color: '#666' }}>
                {schema.description}
              </div>
            )}
          </div>
        );

      case 'object':
        return (
          <div key={key} style={{ 
            border: '1px solid #ccc', 
            padding: '1rem',
            marginBottom: '1rem',
            borderRadius: '4px'
          }}>
            <h3 style={{ marginBottom: '0.5rem' }}>{key}</h3>
            {schema.description && (
              <p style={{ fontSize: '0.875rem', color: '#666', marginBottom: '1rem' }}>
                {schema.description}
              </p>
            )}
            {Object.entries(schema.properties || {}).map(([propKey, propSchema]) => 
              this.renderField(propKey, propSchema, [...path, propKey])
            )}
          </div>
        );

      default: // string, number, boolean
        return (
          <div key={key} style={{ marginBottom: '1rem' }}>
            <label>
              <div>{schema.description || key}</div>
              <input
                type={schema.type === 'number' ? 'number' : 'text'}
                value={value}
                onChange={(e) => {
                  const newValue = schema.type === 'number' 
                    ? parseFloat(e.target.value)
                    : e.target.value;
                  this.updateConfig(path, newValue);
                }}
                placeholder={`Enter ${key}`}
                style={{ width: '100%' }}
              />
            </label>
          </div>
        );
    }
  }

  render() {
    const { toolConfig, toolName } = this.props;

    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.config);
      }}>
        <h2 style={{ marginBottom: '1rem' }}>Configure {toolName}</h2>

        {toolConfig.args && (
          this.renderField('args', toolConfig.args, ['args'])
        )}

        {toolConfig.env && (
          this.renderField('env', toolConfig.env, ['env'])
        )}

        {toolConfig.credentials && (
          this.renderField('credentials', toolConfig.credentials, ['credentials'])
        )}

        <button type="submit" style={{
          backgroundColor: '#0066cc',
          color: 'white',
          padding: '0.5rem 1rem',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}>
          Save Configuration
        </button>
      </form>
    );
  }
}

export { ToolConfigForm }
