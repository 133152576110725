import React from 'react';
import './index.css';

export class FadeSpan extends React.Component {
  setRef = ref => {
    this.ref = ref;
  }
  
  componentDidMount() {
    requestAnimationFrame(() => {
      const el = this.ref;
      if (el) {
        el.style.transition = 'opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0)';
        const delay = (this.props.index * 0.01) + 'ms';
        el.style.transitionDelay = delay;
        
        setTimeout(() => {
          if (this.ref) {
            this.ref.style.display = 'inline';
            requestAnimationFrame(() => {
              if (this.ref) {
                this.ref.style.opacity = 1;
              }
            });
          }
        }, this.props.index * 0.01);
      }
    });
  }
  
  render() {
    return (
      <span
        id={this.props.id}
        ref={this.setRef}
        className="fade-span"
      >
        {this.props.children}
      </span>
    );
  }
}

// Component to handle fake native elements with animation
export class FakeNative extends React.Component {
  setRef = ref => {
    this.ref = ref;
  }
  
  componentDidMount() {
    requestAnimationFrame(() => {
      const el = this.ref;
      if (el) {
        el.style.transition = 'opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0)';
        const delay = (this.props.index * .01) + 'ms';
        el.style.transitionDelay = delay;
        
        setTimeout(() => {
          if (this.ref) {
            this.ref.style.opacity = 1;
          }
        }, this.props.index * 0.01);
      }
    });
  }
  
  render() {
    const { className, children, style = {} } = this.props;
    return (
      <div
        ref={this.setRef}
        className={className}
        style={{
          opacity: 0,
          ...style
        }}
      >
        {children}
      </div>
    );
  }
}

export class FadeComponents {
  constructor({id, openLink}) {
    this.id = id;
    this.openLink = openLink
  }

  reset = (fade) => {
    this.index = 0;
    this.keyIndex = 0;
    this.fade = fade;
  }

  index = 0;
  keyIndex = 0;
  fade = true;
  
  nextKey = (node) => {
    if (!this.fade) {
      return undefined;
    }
    this.keyIndex++;
    return 'fade-key-'+this.keyIndex;
  }

  wrapWords = (children) => {
    if (!this.fade) {
      return children;
    }
    if (!children) return null;
    if (Array.isArray(children)) {
      return children.map(this.wrapWords);
    }
    else if (typeof children === 'string') {
      return children.split('').map(char => {
        const index = this.index++;
        const key = this.id + '-fade-span-' + index;
        return <FadeSpan key={key} index={index}>{char}</FadeSpan>;
      });
    }
    return children;
  };

  // Component renderers
  p = opts => {
    const { node, children, ...props } = opts;
    const key = this.nextKey(node);
    return <p key={key} {...props}>{this.wrapWords(children)}</p>;
  }

  ul = opts => {
    const { node, children, ...props } = opts;
    let className = 'custom-list ul-list';
    if (children && children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
      className += ' olSingle';
    }
    return <ul key={this.nextKey(node)} className={className} {...props}>{this.wrapWords(children)}</ul>;
  }

  ol = opts => {
    const { node, children, ...props } = opts;
    let className = 'custom-list ol-list';
    if (children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
      className += ' olSingle';
    }
    return <ol key={this.nextKey(node)} className={className} {...props}>{this.wrapWords(children)}</ol>;
  }

  li = opts => {
    const { node, children, ...props } = opts;
    const key = this.nextKey(node);
    const index = this.index++;
    const wrapped = this.wrapWords(children);
    
    return (
      <div className="custom-li-container" key={key} {...props}>
        <FakeNative 
          className="custom-li-marker" 
          index={index}
        />
        <div className="custom-li-content">
          {wrapped}
        </div>
      </div>
    );
  }

  // Add table support
  table = opts => {
    const { node, children, ...props } = opts;
    return (
      <div className="table-container" key={this.nextKey(node)}>
        <table {...props}>{this.wrapWords(children)}</table>
      </div>
    );
  }

  thead = opts => {
    const { node, children, ...props } = opts;
    return <thead key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</thead>;
  }

  tbody = opts => {
    const { node, children, ...props } = opts;
    return <tbody key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</tbody>;
  }

  tr = opts => {
    const { node, children, ...props } = opts;
    return <tr key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</tr>;
  }

  th = opts => {
    const { node, children, ...props } = opts;
    return <th key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</th>;
  }

  td = opts => {
    const { node, children, ...props } = opts;
    return <td key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</td>;
  }

  // Fix horizontal rule animation with fake element
  hr = opts => {
    const { node, ...props } = opts;
    const key = this.nextKey(node);
    const index = this.index++;

    return (
      <FakeNative 
        key={key}
        className="fake-hr"
        index={index}
        style={{
          height: '1px',
          margin: '2em 0',
          background: 'linear-gradient(to right, transparent, currentColor, transparent)'
        }}
      />
    );
  }

  h1 = opts => {
    const { node, children, ...props } = opts;
    return <h1 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h1>;
  }

  h2 = opts => {
    const { node, children, ...props } = opts;
    return <h2 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h2>;
  }

  h3 = opts => {
    const { node, children, ...props } = opts;
    return <h3 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h3>;
  }

  strong = opts => {
    const { node, children, ...props } = opts;
    return <strong key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</strong>;
  }

  em = opts => {
    const { node, children, ...props } = opts;
    return <em key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</em>;
  }

  a = opts => {
    const { node, children, ...props } = opts;
    return <a key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</a>;
  }

  del = opts => {
    const { node, children, ...props } = opts;
    return <del key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</del>;
  }

  blockquote = opts => {
    const { node, children, ...props } = opts;
    return <blockquote key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</blockquote>;
  }

  code = opts => {
    const { node, children, className, ...props } = opts;
    return (
      <div className='aiCode' key={this.nextKey(node)} className={className} {...props}>
        {this.wrapWords(children)}
      </div>
    );
  }

  pre = opts => {
    const { node, children, ...props } = opts;
    return <div className='aiPre' key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</div>;
  }

  think = opts => {
    const { node,children, ...props } = opts
    return <div className={'thinking'}  key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</div>;
  }
  
  reasoning = opts => {
    const { node,children, ...props } = opts
    return <div className={'thinking'}  key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</div>;
  }
  
  signed = opts => {
    const { node, ...props } = opts
    return <div className={'signed'} {...props}/>
  }
}
