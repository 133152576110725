const { EventEmitter } = require('events');
const { TokenType } = require('./lexer');

class JsonParser extends EventEmitter {
  constructor() {
    super();
    this.reset();
  }

  reset() {
    this.stack = [];
    this.stringBuffer = '';
    this.numberBuffer = '';
    this.inString = false;
    this.rootValue = null;
  }

  get current() {
    return this.stack[this.stack.length - 1];
  }

  getPath() {
    const path = [];
    for (const {key} of this.stack.slice(0, -1)) {
      if (key !== null) path.push(key);
    }
    const current = this.current;
    if (current && current.key !== null) {
      path.push(current.key);
    }
    return path;
  }

  emitUpdate() {
    this.emit('update', {
      path: this.getPath(),
      value: JSON.parse(JSON.stringify(this.rootValue))
    });
  }

  setValue(value) {
    if (this.stack.length === 0) {
      this.rootValue = value;
    } else if (Array.isArray(this.current.object)) {
      if (this.current.object.length > 0) {
        this.current.object[this.current.object.length - 1] = value;
      } else {
        this.current.object.push(value);
      }
    } else {
      this.current.object[this.current.key] = value;
      if (!Array.isArray(this.current.object)) {
        this.current.key = null;
      }
    }
    this.emitUpdate();
  }

  processToken(token) {
    switch (token.type) {
      case TokenType.START_OBJECT:
        const newObj = {};
        if (this.stack.length === 0) {
          this.rootValue = newObj;
          this.stack.push({ object: newObj, key: null });
        } else if (Array.isArray(this.current.object) || this.current.key !== null) {
          if (Array.isArray(this.current.object)) {
            this.current.object.push(newObj);
          } else {
            this.current.object[this.current.key] = newObj;
          }
          this.stack.push({ object: newObj, key: null });
          this.emitUpdate();
        }
        break;

      case TokenType.START_ARRAY:
        const newArr = [];
        if (this.stack.length === 0) {
          this.rootValue = newArr;
          this.stack.push({ object: newArr, key: null });
        } else if (Array.isArray(this.current.object) || this.current.key !== null) {
          if (Array.isArray(this.current.object)) {
            this.current.object.push(newArr);
          } else {
            this.current.object[this.current.key] = newArr;
          }
          this.stack.push({ object: newArr, key: null });
          this.emitUpdate();
        }
        break;

      case TokenType.END_OBJECT:
      case TokenType.END_ARRAY:
        // Complete any pending number
        if (this.numberBuffer) {
          const num = Number(this.numberBuffer);
          if (Array.isArray(this.current.object)) {
            if (this.current.object.length > 0) {
              this.current.object[this.current.object.length - 1] = num;
            } else {
              this.current.object.push(num);
            }
          } else if (this.current.key !== null) {
            this.current.object[this.current.key] = num;
          }
          this.numberBuffer = '';
          this.emitUpdate();
        }
        this.stack.pop();
        if (this.stack.length > 0 && !Array.isArray(this.current.object)) {
          this.current.key = null;
        }
        this.emitUpdate();
        break;

      case TokenType.START_STRING:
        this.stringBuffer = '';
        this.inString = true;
        break;

      case TokenType.STRING_CONTENT:
        {
          const prevContent = this.stringBuffer;
          this.stringBuffer += token.value;
          let str = this.stringBuffer
          if (str.endsWith("\\")) {
            if (str[str.length-2] !== "\\") {
              str += "\\"
            }
          }
          if (this.inString && prevContent !== str) {
            if (Array.isArray(this.current.object)) {
              if (this.current.object.length > 0) {
                this.current.object[this.current.object.length - 1] = str;
              } else {
                this.current.object.push(str);
              }
            } else if (this.current.key !== null) {
              this.current.object[this.current.key] = str;
            }
            this.emitUpdate();
          }
        }
        break;

      case TokenType.END_STRING:
        {
          this.inString = false;
          const str = this.stringBuffer;
          if (!Array.isArray(this.current.object) && this.current.key === null) {
            this.current.key = str;
          } else {
            if (Array.isArray(this.current.object)) {
              if (this.current.object.length > 0) {
              this.current.object[this.current.object.length - 1] = str;
              } else {
                this.current.object.push(str);
              }
            } else {
              this.current.object[this.current.key] = str;
              this.current.key = null;
            }
            this.emitUpdate();
          }
        }
        break;

      case TokenType.NUMBER:
        this.numberBuffer += token.value;
        if (token.value.includes('e') || token.value.includes('.') || token.value.includes('-')) {
          const num = Number(this.numberBuffer);
          if (Array.isArray(this.current.object)) {
            if (this.current.object.length > 0) {
              this.current.object[this.current.object.length - 1] = num;
            } else {
              this.current.object.push(num);
            }
          } else {
            this.current.object[this.current.key] = num;
            this.current.key = null;
          }
          this.numberBuffer = '';
          this.emitUpdate();
        }
        break;

      case TokenType.TRUE:
        this.setValue(true);
        break;

      case TokenType.FALSE:
        this.setValue(false);
        break;

      case TokenType.NULL:
        this.setValue(null);
        break;

      case TokenType.COMMA:
        if (this.numberBuffer) {
          const num = Number(this.numberBuffer);
          if (Array.isArray(this.current.object)) {
            this.current.object.push(num);
          } else {
            this.current.object[this.current.key] = num;
            this.current.key = null;
          }
          this.numberBuffer = '';
          this.emitUpdate();
        }
        if (Array.isArray(this.current.object)) {
          // Prepare for next array element
          this.current.object.push(undefined);
        }
        break;
    }
  }
}

module.exports = { JsonParser };
