import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import { UIButton } from '../chat/components/Button'
import { UIOKCancel } from '../chat/components/OKCancel'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import { Oof } from '../KeyboardLogin'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Mic from '../../assets/Icons/Mic_1.svg'
import Send from '../../assets/Icons/Send.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Stop from '../../assets/Icons/Stop.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import Speaker from '../../assets/Icons/Speaker.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/UserSaid.svg'
import Plus from '../../assets/Icons/Plus.svg'
import Saved from '../../assets/Icons/SavedCommands.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import { langs } from '../../classes/Lang.js'
import {SpectrumAnalyzer} from '../SpectrumAnalyzer'
import ClickAwayListener from 'react-click-away-listener'
import KeyboardLogo3D from '../../assets/Icons/KB3DLogo.png'
import TurndownService from 'turndown'
import { parseTable, renderTable, renderTableMarkdown } from './Table.js'
import { getPortal } from '../Client'
import { TemplateVariable, renderTemplate } from './TemplateVariable.js'
import { Code } from './Code.js'
import { arrayEq } from '../../classes/Util.js'
import Markdown from 'markdown-to-jsx'
import './index.css'

const join = (arr, f) => {
  const result = []
  arr.forEach((x, i) => {
    if (i > 0) {
      result.push(f())
    }
    result.push(x)
  })
  return result
}

const LangMenuSep = props => {
  return <div className='langMenuSep'/>
}


const nbsp = new RegExp(String.fromCharCode(160), "gi");

export const walkDOM = (node, func) => {
  func(node)
  node = node.firstChild;
  while(node) {
    walkDOM(node, func)
    node = node.nextSibling
  }
}


const parseAnalysis = document => {
  const domParser = new DOMParser()
  const result = domParser.parseFromString(document, 'text/html')
  const output = []
  result.body.getElementsByTagName('root')[0].childNodes.forEach(node => {
    if (node.nodeType === 3) {
      const text = node.textContent
      output.push({
        type: 'text',
        text
      })
    } else if (node.nodeType === 1) {
      if (node.nodeName.toLowerCase() === 'mistake') {
        const attributes = node.attributes
        const type = attributes.type.textContent
        const explanation = attributes.explanation.textContent
        const suggestedFix = attributes.suggestedFix.textContent
        const text = node.textContent
        output.push({
          type: 'mistake',
          mistake: { type, explanation, suggestedFix, text }
        })
      } else {
        //debugger
      }
    }
  })
  return {
    analysis: output
  }
}

//parseAnalysis(test)

class BlockListElement extends Component {
  render() {
    return this.props.element.elements.map(x => <BlockElement element={x} selected={this.props.selection === x} select={this.props.select}/>)
  }
}

class BlockElement extends Component {
  render() {
    const x = this.props.element
    const selected = this.props.selected
    const getBlockClassName = elem => {
      switch (elem.type) {
        case 'sentence':
          return 'blockSentence'
        case 'paragraph':
          return 'blockParagraph'
        case 'listItem':
          return 'blockListItem'
        case 'list':
          return 'blockList'
      }
    }
    const getClassName = (e) => {
      let className = getBlockClassName(e)
      if (selected) {
        return className + ' ' + className + 'Selected'
      }
      return className
    }
    const onClick = e => {
      e.preventDefault()
      this.props.select(e)
    }
    return <span onPointerDown={onClick} className={getClassName(x)}>{x.text.substring(x.start, x.end)}</span>
  }
}

function getCharacterOffsetFromScreenCoordinates(divElement, x, y) {
  //debugger
  // Get the range object from the div element
  var range = document.createRange();
  range.selectNodeContents(divElement);
  
  // Get the root node of the range object
  var rootNode = range.commonAncestorContainer;
  
  // Create a new range object for the character at the given coordinates
  var newRange = document.createRange();
  var foundTextNode = null;
  
  // Iterate through all the nodes in the root node
  var nodeIterator = document.createNodeIterator(rootNode, NodeFilter.SHOW_TEXT);
  var currentNode = nodeIterator.nextNode();
  while (currentNode) {
    // Check if the current node contains the character at the given coordinates
    newRange.selectNodeContents(currentNode);
    var rects = newRange.getClientRects();
    for (var i = 0; i < rects.length; i++) {
      if (rects[i].left <= x && rects[i].right >= x && rects[i].top <= y && rects[i].bottom >= y) {
        foundTextNode = currentNode;
        break;
      }
    }
    if (foundTextNode) {
      break;
    }
    currentNode = nodeIterator.nextNode();
  }
  
  // Get the character offset of the found text node from the div element
  var characterOffset = 0;
  if (foundTextNode) {
    newRange.selectNodeContents(foundTextNode);
    var startOffset = newRange.startOffset;
    var endOffset = newRange.endOffset;
    for (var i = startOffset; i < endOffset; i++) {
      newRange.setStart(foundTextNode, i);
      newRange.setEnd(foundTextNode, i+1);
      const rect = newRange.getBoundingClientRect()
      if (!(rect.left > x || rect.right < x || rect.top > y || rect.bottom < y)) {
        break;
      }
      characterOffset++;
    }
  }
  
  return characterOffset;
}


let blockId = 0
function parseBlock(text) {
  let elements = [];
  let e
  // Recursively parse the block of text and capture position information using regex exec
  function parse(text, start, parent) {
    // Regex patterns to match different types of elements
    const wordPattern = /\b\w+\b/g;
    const sentencePattern = /[^.!?]+[.!?]+/g;
    const paragraphPattern = /(\n\s*\n)+/g;
    const listPattern = /^(\s*(?:-|\d+\.)\s+.+\n)+/gm;
    let match = null;
    if (false) while ((match = wordPattern.exec(text)) !== null) {
      elements.push(e={parent, type: "word", start: start + match.index, end: start + match.index + match[0].length });
    }
    if (false) while ((match = sentencePattern.exec(text)) !== null) {
      elements.push(e={parent, type: "sentence", start: start + match.index, end: start + match.index + match[0].length });
    }
    if (false) while ((match = paragraphPattern.exec(text)) !== null) {
      elements.push(e={parent, type: "paragraph", start: start + match.index, end: start + match.index + match[0].length });
    }
    while ((match = listPattern.exec(text)) !== null) {
      let listStart = start + match.index;
      let listEnd = listStart + match[0].length;
      let itemStart = listStart;
      let itemEnd = listStart;
      let itemPattern = /^\s*(?:-|\d+\.)\s+(.+)\n/gm;
      let itemMatch = null;
      let list
      elements.push(list={ type: "list", start: listStart, end: listEnd });
      list.elements = []
      while ((itemMatch = itemPattern.exec(match[0])) !== null) {
        itemStart = listStart + itemMatch.index;
        itemEnd = itemStart + itemMatch[0].length;
        list.elements.push(e={parent:e, type: "listItem", start: itemStart, end: itemEnd });
        e.elements = []
        parse(itemMatch[1], itemStart, e);
        if (e.elements.length === 0) {
          e.elements = undefined
        }
      }
    }
  }
  const contains = (e1, e2) => {
    return !(e2.start > e1.end || e2.end < e1.start)
  }
  parse(text, 0, {elements});
  elements = elements.filter(x => {
    return !(x.type === 'sentence' && find(elements, y => y.type === 'list' && contains(y, x)))
  })
  const f = e => {
    let n = blockId
    blockId++
    const id = 'b' + n
    e.id = id
    e.text = text
    if (e.elements) {
      e.elements.map(f)
    }
    return e
  }
  return {
    text,
    elements: elements.map(f)
  }
}

function getRandomSubarray(arr, size) {
    var shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
}

const expandTemplate = template => {
  let text = ''
  template.fragments.forEach(x => {
    if (x.type == 'variable') {
      const value = template.vars[x.variable]
      if (value !== undefined) {
        text += value
      } else {
        text += x.fullMatch
      }
    } else {
      text += x.text
    }
  })
  return text
}

const displayTemplate = (template, selected) => {
  let text = ''
  template.fragments.forEach(x => {
    if (x.type == 'variable') {
      let value = template.vars[x.variable]
      if (x != selected && value !== undefined) {
        text += value
      } else {
        text += '[ ' + x.variable + ' ]'
      }
    } else {
      text += x.text
    }
  })
  return text
}

const renderDisplayTemplate = (template, selected) => {
  let output = []
  template.fragments.forEach(x => {
    if (x.type == 'variable') {
      let value = template.vars[x.variable]
      if (x != selected && value !== undefined) {
      } else {
        value = x.variable
      }
      output.push(<span className='keyboardTemplateVariableDisplay'>{value}</span>)
    } else {
      output.push(x.text)
    }
  })
  return output
}

const labelForTemplate = template => {
  let text = ''
  template.fragments.forEach(x => {
    if (x.type == 'variable') {
      text += '[' + x.variable + ']'
    } else {
      text += x.text
    }
  })
  return text
}


const tryParseTemplate = (text, isSystem) => {
  try {
    if (text.indexOf('```') >= 0) {
      return null
    }
    return parseTemplate(text, isSystem)
  } catch (ignored) {
    console.error(ignored)
  }
  return null
}

export const renderCode = (code, copyToClipboard, onClick) => {
  return <div className='template'>
           {
             code.fragments.map(x => {
               let text
               if (x.type == 'code') {
                 const copy = async () => {
                   await copyToClipboard(x.code)
                 }
                 return <Code copy={copy} value={x.code} setValue={value => {
                                //x.code = value
                                //this.renderCurrentDocumentFromCode(data.code)
                              }} onClick={onClick}/>
               } else {
                 text = <Markdown children={x.text}/>
               }
               return text
             })
           }
         </div>
}

export const parseCode = text => {
  const nl = text.indexOf('\n')
  const type = text.substring(3, nl)
  if (type === 'latex') {
    //return null
  }
  let regex
  const split = text.split('```')
  const fragments = []
  if (split.length === 2) {
    const [code, text] = split
    fragments.push({type: 'code',  code, index: 0})
    fragments.push({type: 'text', text, index: code.length + 3})
  } else {
    regex = /```([\s\S]*?)```/g
    let match
    let lastIndex = 0
    let seen = false
    while ((match = regex.exec(text)) !== null) {
      ////console.log(match)
      seen = true
      let [fullMatch, code] = match
      const index = match.index
      if (index < lastIndex) {
        ////debugger
        break
      }
      if (index > lastIndex) {
        fragments.push({type: 'text', text: text.substring(lastIndex, index), index: lastIndex })
      }
      const newLine = code.indexOf('\n')
      const lang = code.substring(0, newLine)
      code = code.substring(newLine+1)
      fragments.push({ type: 'code',  code , lang, index: index, fullMatch})
      lastIndex = index + fullMatch.length
    }
    if (lastIndex < text.length) {
      fragments.push({ text: text.substring(lastIndex), index: lastIndex })
    }
    if (!seen) {
      return
    }
  }
  return {
    fragments
  }
}

const parseTemplate = (template, isSystem) => {
  let fragments = [];
  let stack = [];
  const str = template
  const vars = {}
  const seen = {}
  let numVars = 0
  let lastIndex = 0
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '[') {
      if (stack.length === 0) {
        fragments.push({ type: 'text', text: str.slice(lastIndex, i), index: i });
      }
      stack.push(i);
    } else if (str[i] === ']') {
      let start = stack.pop();
      if (stack.length === 0) {
        const index = i
        const fullMatch = str.slice(start, index+1)
        let variable = str.slice(start + 1, i);
        const array = variable.split('|')
        variable = array[0].trim()
        if (seen[variable]) {
          seen[variable]++
          variable += seen[variable]
        } else {
          seen[variable] = 1
        }
        let options = array[1]
        if (options) {
          options = options.trim().split(',').map(x => x.trim())
        }
        numVars++
        fragments.push({ type: 'variable',
                         isSystem: isSystem && options,
                         containingTemplate: template,
                         variable: variable, options, index: index, fullMatch, display: array[0].trim() })
        lastIndex = i+1
      }
    }
  }
  
  if (numVars === 0) {
    return
  }

  if (lastIndex < template.length) {
    fragments.push({ text: template.substring(lastIndex), index: lastIndex })
  }

  if (stack.length > 0) {
    console.error('Invalid string: unclosed placeholders');
  }
  const result = {
    numVars,
    vars,
    fragments,
    template,
    isSystem
  }
  fragments.forEach(x => {
    x.containingTemplate = result
  })
  ////console.log(result)
  return result
}


const parseTemplateOld = (template, isSystem) => {
  const regex = /\[(.*?)\]/g
  let match
  let lastIndex = 0
  const fragments = []
  const vars = {}
  const seen = {}
  let numVars = 0
  while ((match = regex.exec(template)) !== null) {
    ////console.log(match)
    let [fullMatch, variable] = match
    const index = match.index
    if (index > lastIndex) {
      fragments.push({type: 'text', text: template.substring(lastIndex, index), index: lastIndex })
    }
    const array = variable.split('|')
    variable = array[0].trim()
    if (seen[variable]) {
      seen[variable]++
      variable += seen[variable]
    } else {
      seen[variable] = 1
    }
    let options = array[1]
    if (options) {
      options = options.trim().split(',').map(x => x.trim())
    }
    numVars++
    fragments.push({ type: 'variable',  variable: variable, options, index: index, fullMatch, display: array[0].trim() })
    lastIndex = index + fullMatch.length
  }

  if (numVars === 0) {
    return
  }

  if (lastIndex < template.length) {
    fragments.push({ text: template.substring(lastIndex), index: lastIndex })
  }

  return {
    numVars,
    vars,
    fragments,
    template,
    isSystem
  }
}

export const KeyboardTitle = props => {
  return <div className='keyboardHeaderTitle'>
           <div className='keyboardHeaderTitleIcon'>
             <img src={KeyboardLogo3D}/>
             </div>
           <div className='keyboardHeaderTitleText'>{props.title || 'IntelliKey'}</div>
         </div>
  
}

function setCurrentCursorPosition(node, chars) {
  if (chars >= 0) {
    const selection = window.getSelection()
    const range = createRange(node, { count: chars })
    if (range) {
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange()
    range.selectNode(node)
    range.setStart(node, 0)
  }
  
  if (chars.count === 0) {
    range.setEnd(node, chars.count)
  } else if (node && chars.count >0) {
    if (node.nodeType === Node.TEXT_NODE || node.nodeName == "BR") {
      const text = node.textContent
      if (text.length < chars.count) {
        chars.count -= text.length
      } else {
        range.setEnd(node, chars.count)
        chars.count = 0
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);
        
        if (chars.count === 0) {
          break;
        }
      }
    }
  } 
  return range;
}


const fun = document.createElement('div')
export const makeTextPlain = maybeHtml => {
  fun.innerHTML = maybeHtml
  return fun.innerText
}

const onClick = (action) => e => {
  if (e.button === 0) {
    e.preventDefault()
    return action()
  }
}

const isWhitespace = (c) => {
    return c === ' '
        || c === '\n'
        || c === '\t'
        || c === '\r'
        || c === '\f'
        || c === '\v'
        || c === '\u00a0'
        || c === '\u1680'
        || c === '\u2000'
        || c === '\u200a'
        || c === '\u2028'
        || c === '\u2029'
        || c === '\u202f'
        || c === '\u205f'
        || c === '\u3000'
        || c === '\ufeff'
}


class InputControlInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: "",
      editorWidth: 20,
      editorHeight: 0,
    }
  }

  insertPlainTextAtCaret = (text) => {
    this.pushFocus()
    // Get the caret position in the div
    var caretPos = window.getSelection().getRangeAt(0).startOffset;
    // Create a text node with the text to be inserted
    var textNode = document.createTextNode(text)
    // Get the div
    var div = this.ref
    // Get the range
    var range = window.getSelection().getRangeAt(0)
    range.deleteContents();
    // Insert the text node
    range.insertNode(textNode)
    range.setStartAfter(textNode);
    range.collapse(true);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    if (text) {
      this.setIsEmpty(false)
    } else {
      this.checkIsEmpty()
    }
    this.popFocus()
  }

  insertImage = url => {
    this.pushFocus();
    const img = document.createElement('img');
    img.src = url;
    const sel = window.getSelection();
    const insertNewline = (range) => {
      const newLine = document.createElement("div");
      newLine.innerHTML = "<br>"; // Or simply: newLine.textContent = ""; for a blank <div>
      range.insertNode(newLine);
      // Move the cursor to the new line
      const newRange = document.createRange();
      newRange.setStart(newLine, 0);
      newRange.setEnd(newLine, 0);
      sel.removeAllRanges()
      sel.addRange(newRange)
      sel.collapseToEnd()
    }
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.insertNode(img);
      range.setStartAfter(img);
      insertNewline(range)
    } else {
      // If the rangeCount is 0, append the image at the end
      this.ref.appendChild(img);
      const range = document.createRange();
      range.setStartAfter(img);
      insertNewline(range)
    }
    this.setIsEmpty(false);
    this.popFocus();
    setTimeout(this.props.onInput)
    return (src) => {
      img.src = src
      this.setIsEmpty(false)
      this.onInput()
    }
  }

  insertImageOld = url => {
    this.pushFocus()
    const img = document.createElement('img');
    img.src = url
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false); // Collapse the range to the end point of the insert
      
      // Update the selection
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      // If the rangeCount is 0, append the image at the end
      this.ref.appendChild(img);
    }
    this.setIsEmpty(false)
    this.popFocus()
    return img
  }

  focusStack = []

  peekFocus = () => {
    return this.focusStack[this.focusStack.length-1]
  }

  pushFocus = () => {
    this.focusStack.push(this.focused)
    if (!this.focused) {
      this.focus()
    }
  }

  popFocus = () => {
    const wasFocused = this.peekFocus()
    if (wasFocused) {
      this.focus()
    } else {
      this.blur()
    }
    this.focusStack.pop()
  }

  getContent = () => {
    let content  = []
    let result = ''
    const flush = () => {
      if (result) {
        result = result.replace(/\n•[ ]+/g, "\n•  ");
        content.push({
          type: "text",
          text: result
        })
        result = ''
      }
    }
    const apply = node => {
      walkDOM(node, n => {
        if (n instanceof HTMLVideoElement) {
        }
        else if (n instanceof HTMLImageElement) {
          const url = n.src
          flush()
          content.push({
            type: "image_url",
            image_url: {
              url
            }
          })
        } else if (n.nodeName == "WBR") {
        } else if (n.nodeName == "BR") {
          result += "\n";
        } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
          let textContent = n.textContent.replace(nbsp, " ");
          result += textContent;
          if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
            result += '\n'
          }
        }
      })
      flush()
    }
    apply(this.ref)
    return content
  }
  

  getCaretOffset = () => {
    this.pushFocus()
    const { textBeforeCaret } = this.getTextSurroundingCaret()
    this.popFocus()
    return textBeforeCaret.length
  }

  getTextSurroundingCaret = () => {
    this.pushFocus()
    const range = window.getSelection().getRangeAt(0).cloneRange();
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.ref);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const postCaretRange = range.cloneRange()
    postCaretRange.selectNodeContents(this.ref)
    postCaretRange.setStart(range.endContainer, range.endOffset)
    const result = {
      textBeforeCaret: preCaretRange.toString(),
      textAfterCaret: postCaretRange.toString()
    }
    this.popFocus()
    return result
  }

  getTextSurroundingCaretAsync = async () => {
    this.pushFocus()
    await delay(5.0)
    const range = window.getSelection().getRangeAt(0).cloneRange();
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.ref);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const postCaretRange = range.cloneRange()
    postCaretRange.selectNodeContents(this.ref)
    postCaretRange.setStart(range.endContainer, range.endOffset)
    const result = {
      textBeforeCaret: preCaretRange.toString(),
      textAfterCaret: postCaretRange.toString()
    }
    this.popFocus()
    return result
  }

  scrollToCaret = () => {
    const element = this.ref
    var range = window.getSelection().getRangeAt(0);
    var selectionRect = range.getBoundingClientRect();
    
    var topOfElement = element.getBoundingClientRect().top;
    var bottomOfElement = element.getBoundingClientRect().bottom;
    
    // If the caret is above the top of the element, scroll up
    if (selectionRect.top < topOfElement) {
      element.scrollTop -= (topOfElement - selectionRect.top);
    } else  {
      // If the caret is below the bottom of the element, scroll down
      if (selectionRect.bottom > bottomOfElement) {
        element.scrollTop += (selectionRect.bottom - bottomOfElement);
      }
    }
  }

  endSentence = () => {
    const { textBeforeCaret, textAfterCaret } = this.getTextSurroundingCaret()
    if (!textBeforeCaret.endsWith('.')) {
      this.pushFocus()
      //get the caret position
      if (!textBeforeCaret.trim().endsWith('.')) {
        const before = textBeforeCaret.trim() + '. '      
        this.setText(before + textAfterCaret)
        this.setCaretPosition(before.length)
        this.popFocus()
      }
    }
  }
  
  setCaretPosition = cursorOffset => {
    this.pushFocus()
    const selection = window.getSelection()
    document.execCommand('selectAll')
    selection.collapseToStart()
    while (cursorOffset > 0) {
      selection.modify('move', 'forward', 'character')
      this.scrollToCaret()
      cursorOffset--
    }
    this.popFocus()
    if (isDesktop()) {
      this.focus()
    }
  }

  setCaretPositionDebug = async cursorOffset => {
    //////console.log('setCaretPosition', cursorOffset)
    this.focus()
    const selection = window.getSelection()
    document.execCommand('selectAll')
    await delay(0.5)
    selection.collapseToStart()
    await delay(0.5)
    while (cursorOffset > 0) {
      selection.modify('move', 'forward', 'character')
      this.scrollToCaret()
      cursorOffset--
      await delay(0.2)
    }
  }

  tryDeleteSpaceBeforeCaret = () => {
  }

  insertTextAtCaret = (text, noUpdate, select) => {
    if (this.isEmpty()) {
      return this.setText(text)
    }
    const wasFocused = this.focused
    this.pushFocus()
    let whitespaceBefore = false
    let whitespaceAfter = false
    let prev
    if (!wasFocused) {
      prev = this.getText()
      //////console.log("beforeCaret '" +  prev + "'")
      whitespaceBefore = endsWithWhitespace(prev)
      document.execCommand('selectAll')
      window.getSelection().collapseToEnd()
    } else {
      const { textBeforeCaret, textAfterCaret } = this.getTextSurroundingCaret()
      prev = textBeforeCaret
      whitespaceBefore = endsWithWhitespace(textBeforeCaret)
      whitespaceAfter = startsWithWhitespace(textAfterCaret)
    }
    const insideWord = !whitespaceBefore && !whitespaceAfter
    if (!insideWord) {
      if (!whitespaceBefore && !text.startsWith("'")) {
        text = ' ' + text
      }
      if (!whitespaceAfter) {
        text = text + ' '
      }
    }
    const sel = window.getSelection();
    let range = sel.getRangeAt(0);
    range.deleteContents();
    var el = document.createElement("div");
    el.innerText = text;
    var frag = document.createDocumentFragment(), node, lastNode;
    while ( (node = el.firstChild) ) {
      lastNode = frag.appendChild(node);
    }
    range.insertNode(frag);
    // Preserve the selection
    if (lastNode) {
      range = range.cloneRange();
      range.setStartAfter(lastNode);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      if (!select) {
        sel.collapseToEnd()
      }
    }
    this.scrollToVisible()
    this.popFocus()
    if (text) {
      this.setIsEmpty(false)
    }
  }

  scrollToVisible = () => {
    const div = this.ref

    // Get the current cursor position in the div
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    
    // Calculate the top and bottom positions of the cursor
    const top = rect.top + window.pageYOffset - div.offsetTop;
    const bottom = top + rect.height;
    
    // Check if the cursor is outside the visible area of the div
    if (top < div.scrollTop || bottom > div.scrollTop + div.clientHeight) {
      // Scroll the div to make the cursor visible
      div.scrollTop = top - (div.clientHeight / 2);
    }
  }

  getNode = () => {
    return this.ref;
  }

  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDrop(e);
  }

  init = ()=> {
    if (this.ref) {
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(entries => {
          clearTimeout(this.resizeTimeout)
          this.resizeTimeout = setTimeout(() => {
            try {
              this.setState({
                editorHeight: this.ref.offsetHeight,
                editorWidth: this.ref.offsetWidth
              }, () => {
                this.onEditorHeightChanged(this.state.editorHeight)
              })
            } catch (err) {
              console.warn(err)
            }
          })
        })
        this.resizeObserver.observe(this.ref)
      }
    }
  }

  isFocused= ()=> this.ref && document.activeElement == this.ref;

  selectAll = () => {
    const range = document.createRange();
    range.selectNodeContents(this.ref);
    this.lastSelectionRange = range;
    if (document.activeElement == this.ref) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  // total fucking hack to workaround apple TextDocumentProxy bugs
  onSelectionChange = (e) => {
    if (this.focused) {
      if (document.activeElement === this.ref) {
        const sel = document.getSelection()
        var n = sel.focusNode
        var found = false
        while (n) {
          if (n === this.ref) {
            found = true
            break
          }
          n = n.parentNode
        }
        if (!found) {
          //////console.log('selection change', sel)
          // apple bugs have moved the selection away from the focus node
          // force it back
          if (this.lastSelectionRange) {
            sel.removeAllRanges();
            sel.addRange(this.lastSelectionRange);
          }
        } else {
          this.lastSelectionRange = sel.getRangeAt(0);
        }
      }
    }
  }

  componentDidMount() {
    //document.addEventListener("selectionchange", this.onSelectionChange);
    this.init();
  }
  
  componentWillUnmount() {
    //document.removeEventListener("selectionchange", this.onSelectionChange);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  restoreSelection = () => {
    if (this.lastSelectionRange) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      var range = this.lastSelectionRange;
      sel.addRange(range);
    }
  }

  onFocus=(e)=> {
    this.focused = true;
    this.forceUpdate()
    if (this.focusStack.length > 0) {
      return
    }
    if (this.props.onFocus) this.props.onFocus(e, this, ()=>{});
  }

  onEditorHeightChanged = height => {
    if (this.props.onEditorHeightChanged) {
      this.props.onEditorHeightChanged(height - 21)
    }
  }

  onBlur=(e)=> {
    this.focused = false;
    this.forceUpdate()
    if (this.focusStack.length > 0) {
      return
    }
    if (this.props.onBlur) this.props.onBlur(e, this, ()=>{});
  }

  onKeyDown=(e)=> {
    if (this.props.onKeyDown) this.props.onKeyDown(e, this, ()=>{});
  }

  onKeyUp=(e)=> {
    if (this.props.onKeyUp) this.props.onKeyUp(e, this, ()=>{});
  }

  onDrop=(e)=> {
    //debugger
    if (this.props.onDrop) this.props.onDrop(e, this, ()=>{});
  }

  onPaste=(e)=> {
    //debugger
    if (this.props.onPaste) this.props.onPaste(e, this, ()=>{});
  }

  onPointerDown = e => {
    this.dragging = true
    this.onPointerDrag(e)
  }

  onPointerUp = e => {
    this.dragging = false
  }

  onPointerMove = e => {
    if (this.dragging) this.onPointerDrag(e)
  }

  onPointerDrag = e => {
    if (this.props.onPointerDrag) {
      this.props.onPointerDrag(e, this, () => {})
    }
  }

  focus = () => {
    this.ref.focus();
  }

  blur = () => {
    if (this.ref) this.ref.blur();
  }

  lastIsEmpty = true
  isEmptySubject = new Subject()

  isEmpty = () =>  this.lastIsEmpty

  setIsEmpty = isEmpty => {
    if (this.lastIsEmpty != isEmpty) {
      this.lastIsEmpty = isEmpty
      this.isEmptySubject.next(isEmpty)
      if (this.placeholder) this.placeholder.style.display = isEmpty ? '' : 'none'
    }
  }

  setPlaceholder = ref => {
    this.placeholder = ref
  }

  nonEmptyLen = 0
  checkIsEmpty = () => {
    const isEmpty = this.getIsEmpty()
    this.setIsEmpty(isEmpty)
  }
  
  getIsEmpty = () => {
    //debugger
    if (!this.ref) { 
      return true;
    }    
    var isEmpty = true
    let len = 0
    try {
      walkDOM(this.ref, node => {
        if (node.nodeType === 3) {
          len += node.nodeValue.length
          if (node.nodeValue.length > 0) {
            throw new Error('not-empty')
          }
        } else if (node.nodeName == 'IMG'){
          throw new Error('not-empty')
        } else if (node.nodeName == 'VIDEO'){
          throw new Error('not-empty')
        }
      })
    } catch (ignored) {
      //console.error(ignored)
      isEmpty = false
    }
    this.nonEmptyLen = len
    return isEmpty
  }

  observeIsEmpty = () => {
    return concat(of(this.lastIsEmpty), this.isEmptySubject)
  }

  checkIsEmptyLater = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.checkIsEmpty, 1000 / 30)
  }

  onInput = (e) => {
    if (this.lastIsEmpty || this.nonEmptyLen === 1) {
      this.checkIsEmpty()
    } else {
      this.checkIsEmptyLater()
    }
    if (this.props.onInput) {
      this.props.onInput(e)
    }
  }

  setRef = ref => {
    if (ref) {
      this.ref = ref
    }
  }

  setAutocompletes = completions => {
  }

  applyCompletion = completion => {
    this.props.applyCompletion(completion)
  }

  onPaste = e => {
    if (this.props.onPaste) {
      this.props.onPaste(e, () => {})
    }
    //debugger
    if (!e.defaultPrevented) {
      e.preventDefault()
      let plainText = e.clipboardData.getData('text/plain')
      if (!plainText) {
        const text = e.clipboardData.getData('text/html')
        plainText = makeTextPlain(text)
      }
      this.insertPlainTextAtCaret(plainText || '')
    }
  }

  render = () => {
    let h = this.state.editorHeight
    let y = 0
    const bgStyle = {height: this.state.editorHeight + 11}
    const offsetStyle = this.props.downward ? undefined : { top: -this.state.editorHeight + h + y }
    const isEmpty = this.props.isEmpty || this.isEmpty
    const crossStyle = (!isEmpty()) ? offsetStyle : { display: 'none' }
    //////console.log('crossStyle', this.isEmpty(), crossStyle)
    let className = 'inputControlInputEditor' +
                           (this.props.downward ? ' inputControlEditorDownward' : '') +
        (this.focused ? ' inputControlEditorFocused' : '')
    if (this.props.disabled) {
      className += ' inputControlEditorDisabled'
    }
    const busy =  this.props.busy
    const { editorIcon } = this.props
    if (editorIcon) {
      className += ' inputControlEditorWithIcon'
    }
    return <div className={className}>
             <div className='inputControlInputEditorBg' style={bgStyle}>
               {editorIcon && <div className='inputControlEditorIcon'><ReactSVG src={editorIcon}/></div>}
             </div>
             <div className='inputControlInputEditorContainer'>
               <div className={'inputControlInputEditorInput'} contentEditable={!this.props.disabled} ref={this.setRef}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyDown={this.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    onDrop={this.onDrop}
                    onPointerMove={this.onPointerMove}
                    onPointerDown={this.onPointerDown}
                    onInput={this.onInput}
                    onPaste={this.onPaste}
                    onClick={this.props.onClick}
              />
             {this.props.placeholder && <div ref={this.setPlaceholder} className={'inputControlEditorPlaceholder'}>{this.props.placeholder}</div>}</div>
             {
               !busy && !this.props.disabled && this.props.clear && <div key='cross' className='inputControlEditorCross' style={crossStyle} onMouseDown={
                                                                           e => {
                                                                             e.preventDefault()
                                                                             this.props.clear()
                                                                           }}>
                                                                      <ReactSVG src={Cross}/>
                                                                    </div>
             }
             {busy && <div key='busy' className='inputControlEditorCross bnInputFieldClear'>
              <ReactSVG src={Spin}/>
              </div>}
             
           </div>
  }

  undo = () => {
    document.execCommand("undo");
  }

  setText = (text, fireOnInput = true) => {
    if (!text) {
      if (this.ref) {
        this.ref.innerHTML = ''
      }
      this.setIsEmpty(true)
      return true
    } else if (this.ref && this.ref.innerText != text) {
      let scrollTop = this.ref.scrollTop
      this.ref.innerText = text
      if (this.focused) {
        // move caret to end
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
      }
      this.ref.scrollTop = scrollTop
      if (fireOnInput && this.props.onInput) this.props.onInput()
      this.setIsEmpty(!text)
      return true
    }
    return false
  }
  
  clear = () => {
    this.ref.innerText = "";
    this.setIsEmpty(true)
    this.forceUpdate();
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  getHTML=() => {
    return this.ref.innerHTML
  }

  getNode = () => {
    return this.ref
  }

  getText=() => {
    if (!this.ref) {
      debugger
    }
    return this.getContent().filter(({type}) => type == 'text').map(x => x.text).join('')
  }

  doInsert=(html, selectPastedContent) => {
    document.execCommand("insertHTML", false, html);
  }
}

class InputControlSpeechLangMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  componentDidUpdate() {
    if (!this.props.hasFocus && this.state.showMenu) {
      this.hideMenu()
    }
  }

  hideMenu = () => {
    this.toggleMenu(false)
  }

  showMenu = () => {
    this.props.onChange(true)
    this.toggleMenu(true)
  }

  isShowing = () => {
    return this.state.showMenu
  }

  voiceLangs = {}

  toggleMenu = () => {
    const show = !this.state.showMenu
    this.voiceLangs = {}
    this.props.onChange(show)
    this.setState({
      showMenu: show,
      submenu: null,
      autodetectComplete: false
    })
  }

  selectLang = (name, dialect, iso, hasVoice) => {
    this.props.selectLang({
      name,
      dialect,
      iso,
      hasVoice
    })
    this.toggleMenu()
  }

  back = () => {
    this.setState({
      submenu: null
    })
  }

  hideMenu1 = () => {
    if (this.ignoreClickAway) {
      setTimeout(() => {
        this.ignoreClickAway = false
      }, 200)
    } else {
      this.hideMenu()
    }
  }

  renderDialects = (lang, dialects) => {
    let { maxHeight, maxWidth } = this.getMaxSize()
    //if (maxHeight !== undefined) maxHeight -= 40
    const style = { maxHeight, maxWidth }
    return <ClickAwayListener
             onClickAway={this.hideMenu1}>
             <div className={'inputControlSpeechLangSubmenu'} >             
               <div className='inputControlSpeechLangBack' onMouseDown={onClick(this.back)}>
                 <div className='inputControlSpeechLangBackIcon'>
                   <ReactSVG src={Left}/>
                 </div>
                 <div className='inputControlSpeechLangSubmenuLang'>{lang}</div>
               </div>
               <div className='inputControlSpeechLangMenu' style={style}>
                 {
                   join(dialects.map((dialect, j) => {
                     //////console.log("dialect", dialect)
                     const name = dialect[1]
                     const selected = this.props.selectedLang.iso === dialect[0]
                     const hasVoice = this.voiceLangs[dialect[0]]
                     return this.renderDialect(name, dialect[0], () => {
                       this.selectLang(lang, name, dialect[0], hasVoice)
                     }, dialect[0].split('-')[0], selected)
                   }), () => <LangMenuSep/>)
                 }
               </div>
             </div>
           </ClickAwayListener>
  }
  // 1
  renderLang = (name, iso, dialects, i) => {
    const selected = iso === this.props.selectedLang.iso.split('-')[0]
    const dialectIso = dialects[0][0]
    if (dialects.length === 1) {
      return this.renderDialect(name, dialectIso , () => {
        const country = dialects[0][1]
        const hasVoice = this.voiceLangs[dialectIso]
        this.selectLang(name, country, iso, hasVoice)
      }, iso, selected)
    }
    return this.renderDialect(name, dialectIso, () => {
      this.setState({
        lang: name,
        submenu: dialects
      })
    }, <ReactSVG src={Right}/>, selected)
  }

  // 1
  renderDialect = (name, iso, action, icon, selected) => {
    let hasVoice
    if (this.voiceLangs) {
      hasVoice = this.voiceLangs[iso]
    }
    return <div key={name} className='inputControlSpeechLangLang' onMouseUp={
                  e => {
                    e.preventDefault()
                  }
                } onMouseDown={onClick(action)}>
             <div className='inputControlSpeechLangIcon'>{icon}</div>
             <div className='inputControlSpeechLangLabel'>{name}</div>
             {(hasVoice || selected) && <div className='inputControlSpeechLangMenuIcon'>
                                          <div className='inputControlSpeechLangVoice'>{hasVoice && <ReactSVG src={Speaker}/>}</div>
                                          <div className='inputControlSpeechLangSelected'>{selected && <ReactSVG src={CheckMark}/>}</div>
                                        </div>}
    </div>
  }

  setRef = ref => {
    this.ref = ref
  }

  render() {
    //return ReactDOM.createPortal(this.renderMenu(), getPortal())
    const lang = this.props.selectedLang || {
      name: "English",
      dialect: "United States",
      iso: "en-US",
      hasVoice: true
    }
    //////console.log("selectedLang", this.props.selectedLang, '=>', lang)
    const { name, iso } = lang
    //////console.log("iso", iso)
    return <div className={'inputControlSpeechLangContainer'  + (this.props.downward ? ' inputControlLangMenuDownward' : '')}>
             <div ref={this.setRef} className='inputControlSpeechLangIcon'
                  onMouseUp={e => {
                    setTimeout(() => {
                      this.ignoreClickAway = false
                    }, 50)
                    e.preventDefault()
                  }}
                  onMouseDown={e => {
                    this.ignoreClickAway = true
                    setTimeout(() => {
                      this.toggleMenu()
                    }, 50)
                  }}>{iso.split('-')[0]}</div>
             {this.state.showMenu && this.renderMenu()}
           </div>
    
  }

  getMaxHeight = () => {
    if (!this.ref) return undefined
    const { top, left, right, bottom } = this.ref.getBoundingClientRect()
    if (this.props.downward) {
      //////console.log("getMaxHeight", top, bottom);
      return window.visualViewport.height - this.props.me.getSafeAreaInsetBottom()
    } else {
      return window.visualViewport.height - bottom - 30
    }
  }

  getMaxSize = () => {
    if (!this.ref) return undefined
    const { top, left, right, bottom } = this.ref.getBoundingClientRect()
    let maxHeight
    let maxWidth = window.innnerWidth - this.props.me.getSafeAreaInsetLeft() - left
    if (isNaN(maxWidth)) {
      ////debugger
    }
    const windowHeight = window.visualViewport.height
    if (this.props.downward) {
      //////console.log("getMaxHeight", top, bottom);
      maxHeight = windowHeight - this.props.me.getSafeAreaInsetBottom() - bottom
    } else {
      maxHeight = top - this.props.me.getSafeAreaInsetTop() - 30
    }
    return { maxHeight, maxWidth }
  }

  renderMenu = () => {
    if (this.props.downward) {
      return this.doRenderMenu1()
    } else {
      return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
    }
    //return this.doRenderMenu()
  }

  doRenderMenu = () => {
    let style
    if (this.ref) {
      const { top, bottom, left } = this.ref.getBoundingClientRect()
      const maxHeight =  window.visualViewport.height - bottom 
      style = {left, bottom: maxHeight, maxHeight: maxHeight, position: "absolute" }
    }
    
    return <div className='inputControlSpeechLangMenuContainer' style={style}>
             {this.doRenderMenu1()}
           </div>
  }

  
  doRenderMenu1() {
    if (this.state.submenu) {
      return this.renderDialects(this.state.lang, this.state.submenu)
    }
    let autodetect = this.props.autodetect && (async () => {
      if (this.state.autodetected) {
        const { name, iso, dialect } = this.state.autodetected
        const hasVoice = this.voiceLangs[iso]
        this.selectLang(name, dialect, iso, hasVoice)
      }
    })
    const selectLang = this.selectLang
    let label = 'Autodetect'
    let icon = <ReactSVG src={Spin}/>
    let autodetectLang 
    if (this.state.autodetectComplete) {
      if (this.state.autodetected) {
        const { name, iso, dialect } = this.state.autodetected
        if (iso) {
          autodetectLang = iso
          icon = iso.split('-')[0]
          label = `${name} (Detected)`
        } else {
          autodetect = null
        }
      } else {
          autodetect = null
      }
    }

    let { maxHeight } = this.getMaxSize()
    const style = {
      maxHeight: maxHeight + 30
    }

    return <ClickAwayListener
             onClickAway={this.hideMenu1}>
             <div className='inputControlSpeechLangMenu' style={style}>
               {autodetect && this.renderDialect(label, autodetectLang, autodetect, icon)}
               {
                 join(langs.map((lang, i) => {
                                 //////console.log('lang', lang)
                                 const name = lang[0]
                                 const dialects = lang.slice(1)
                                 const iso = dialects[0][0].split('-')[0]
                   return this.renderLang(name, iso, dialects,  i)
                 }), () => <LangMenuSep/>)
               }
             </div>
           </ClickAwayListener>
  }
  
}


class InputControlSpeech extends Component {

  action = async () => {
    await this.props.action()
  }

  componentDidMount() {
    this.updateSpectrumAnalyzer()
  }

  componentDidUpdate() {
    this.updateSpectrumAnalyzer() 
  }

  componentWillUnmount() {
    this.updateSpectrumAnalyzer(false) 
  }

  updateSpectrumAnalyzer() {
    if (this.spectrumAnalyzer) {
      this.spectrumAnalyzer.setActive(this.props.active)
    }
  }
  
  setSpectrumAnalyzer = ref => {
    this.spectrumAnalyzer = ref
    if (ref) {
      this.updateSpectrumAnalyzer()
    }
  }


  render() {
    let speakerAction
    if (this.props.speak) {
      speakerAction = this.props.selectedLang.hasVoice && this.props.speak
    } else if (this.props.toggleSpeech) {
      speakerAction = this.props.toggleSpeech
    }
    //console.log("speaker action", speakerAction)
    let menuStyle = this.props.action ? null : { display: 'none' }
    let style = (this.props.active ||
                 this.props.hasFocus ||
                 this.speechMenuShowing) ? {opacity: 1.0 } : (this.props.fadeTransition ? { opacity: 0 } : { display: 'none' })
    return <div className={'keyboardInputControlSpeech' + (this.props.active ? ' keyboardInputControlSpeechActive' : '')} style={style}>
             <KeyboardButton keepFocus label='Speak' icon={Mic} action={this.props.action}/>
             <div className='keyboardInputControlSpeechLangSelection' style={menuStyle}>
               <InputControlSpeechLangMenu
                 hasFocus={this.props.hasFocus}
                 downward={this.props.downward} me={this.props.me} autodetect={this.props.autodetect} selectedLang={this.props.selectedLang} selectLang={this.props.selectLang} onChange={showing => {
                                             this.speechMenuShowing = showing
                                             this.forceUpdate()
                                           }
                                           }/>
             </div>
             {false &&<div className='inputControlSpectrumAnalyzer'>
               <SpectrumAnalyzer onCreate={this.setSpectrumAnalyzer}/>
             </div>}
             <div className='inputControlSpeechSpeaker'>
               {speakerAction && <KeyboardButton1 keepFocus icon={Speaker} className={'iconSpeaker' + (this.props.speakerActive ? ' iconSpeakerActive' : '')} action={speakerAction} />}
               </div>
           </div>
  }
}

export class InputControl extends Component {

  constructor (props) {
    super(props)
    this.state = {
      editorHeightOffset: 0
    }
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onCreate) {
      this.props.onCreate(null)
    }
  }

  onEditorHeightChanged = editorHeightOffset => {
    ////console.log("editor height offset", editorHeightOffset)
    this.editorHeightSubject.next(editorHeightOffset + 130)
    this.setState({
      editorHeightOffset
    })
  }

  editorHeightSubject = new Subject()

  observeEditorHeight = () => {
    return concat(of(this.editorHeightOffset), this.editorHeightSubject)
  }

  getEditor = () => {
    return input
  }

  setInput = ref => {
    this.input = ref
    if (this.props.onSetEditor) this.props.onSetEditor(ref)
  }

  insertImage = url => {
    return this.input.insertImage(url)
  }
  onFocus = () => {
    this.setState({
      isActive: true,
      focused: true
    })
    if (this.props.onFocus) this.props.onFocus()
  }

  onBlur = () => {
    this.setState({
      focused: false
    })
    if (this.props.onBlur) this.props.onBlur()
  }

  speak = async () => {
    if (this.state.speakerActive) {
      this.props.speak(true) // cancel
      this.setState({
        speakerActive: false
      })
    } else {
      this.setState({
        speakerActive: true
      })
      this.props.speak().then(() => {
        //debugger
        this.setState({
          speakerActive: false
        })
      })
    }
  }


  setTemp = (e) => {
    const { left, width } = e.target.getBoundingClientRect()
    const x = e.clientX - left
    const w = width
    ////console.log("clientX", x, "clientLeft", left, "clientWidth", width)
    const t = (x / w)
    if (t >= 0 && t <= 1.0) {
      return this.props.setTemperature(t)
    }
  }

  setSliderRef = ref => {
    this.sliderRef = ref
  }

  render() {
    let topStyle
    let bottomStyle
    if (!this.props.downward) {
      topStyle = {
        transform: `translate(0, ${-this.state.editorHeightOffset}px)`
      }
    } else {
      bottomStyle = {
        transform: `translate(0, ${this.state.editorHeightOffset}px)`
      }
    }
    let undoClass = 'iconUndo'
    let redoClass = 'iconRedo'
    let className = 'inputControl' + (this.props.menu ? ' inputControlMenu' : '')
    if (this.props.applyCompletion) {
      className += ' inputControlWithAutocomplete'
    }
    let factual = 'rgb(0, 0, 255, 0.3)'
    let creative = 'rgb(255, 255, 0, 0.3)'
    let sliderStyle
    if (this.props.getTemperature && this.sliderRef) {
      const t1 = this.props.getTemperature()
      const t0 = 0
      const left = 82
      const w = `calc(100% - ${41 + 41 + 41 + 125}px)`
      ////console.log("TEMP", t1)
      sliderStyle = {
        left: left,
        width: w,
        background: `linear-gradient(90deg, ${factual}, ${factual} ${t1 * 100}%, ${creative} ${t1 * 100}%, ${creative})`
      }
    }
    const onPointerDown = e => {
      this.setState({
        settingTemp: true
      })
      this.setTemp(e)
    }
    const onPointerMove = e => {
      if (this.state.settingTemp) {
        this.setTemp(e)
      }
    }
    const onPointerUp = e => {
      this.setState({
        settingTemp: false
      })
    }
    ////console.log('sliderStyle', sliderStyle)
    let style
    let showSpeechInput = this.props.speechInputActive || this.props.speechInputNoFocus
    if (!showSpeechInput) {
      topStyle = {
        display: 'none'
      }
    } else {
      if (!topStyle) {
        topStyle = {}
      }
      topStyle.background =  'rgb(0, 26, 25)'
      if (this.props.downward && this.props.speechInputAction) {
        style={
          transform: 'translate(0, -40px)'
        }
      }
    }
    return <div className={className} style={style}>
             <div className='inputControlTopRow' style={topStyle}>
               {this.props.speechInputAction &&<InputControlSpeech
                                                 fadeTransition={this.props.fadeTransition}
                                                 downward={this.props.downward}
                                                 me={this.props.me}
                                                 hasFocus={this.state.focused || this.props.speechInputNoFocus}
                                                 active={this.props.speechInputActive}
                                                 action={this.props.speechInputAction}
                                                 selectLang={this.props.selectSpeechInputLang}
                                                 selectedLang={this.props.selectedLang}
                                                 autodetect={this.input && !this.input.isEmpty() && this.props.autodetect}
                                                 speakerActive={this.props.toggleSpeech ? this.props.speakerActive : this.state.speakerActive}
                                                 speak={this.props.speak && this.input && !this.input.isEmpty() && this.speak}
                                               />}
             </div>
             <div className='inputControlMiddleRow'>
               {this.props.middleLeft}
               <InputControlInput
                 editorIcon={this.props.editorIcon}
                 isEmpty={this.props.isEmpty}
                 disabled={this.props.disabled}
                 downward={this.props.downward}
                 ref={this.setInput}
                 onClick={this.props.onClick}
                 onEditorHeightChanged={this.onEditorHeightChanged}
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onInput={this.props.onInput}
                 onKeyDown={this.props.onKeyDown}
                 onPaste={this.props.onPaste}
                 onDrop={this.props.onDrop}
                 placeholder={this.props.placeholder}
                 clear={this.props.onClear}
                 busy={this.props.busy}
               />
               {this.props.menu && <div className='inputControlMiddleRowContainer' style={bottomStyle}>
               {this.props.menu}
                                   </div>}
             </div>
             <div className='inputControlBottomRow' style={bottomStyle}>
               {this.props.bottomRow}
               <div className='keyboardButtonApplyLeft'>
                 {this.props.undo !== undefined && <KeyboardButton1 icon={Undo} className={undoClass} action={this.props.undo} />}
                 {this.props.redo !== undefined && <KeyboardButton1 icon={Redo} className={redoClass}  action={this.props.redo} />}
               </div>
               {
                 this.props.copy && this.props.action && <KeyboardButton1 keepFocus action={this.props.copy} icon={Copy}/>
               }
               {this.props.share && <KeyboardButton1 className='savedButtonShare' action={this.props.share} icon={Share}/>}
               <KeyboardButton label={this.props.label} keepFocus action={this.props.action} icon={this.props.icon}
                               cancel={this.props.cancel} cancelIcon={Trash}/>
               {this.props.more}
             </div>
             {false && this.props.getTemperature && <div
                                             ref={this.setSliderRef}
                                             className='inputControlTempSlider' style={sliderStyle}
                                             onPointerDrag={onPointerMove}
                                             onPointerUp={onPointerUp}
                                             onPointerDown={onPointerDown}>
                                             <div>Creative</div><div>Factual</div>
                                             </div>
             }
             {this.props.applyCompletion &&
              <div key='autocompletes' className='inputControlAutocompleteRow' style={bottomStyle}>
                {
                  this.props.completions && this.props.completions.map(x => {
                    return <div className='editorAutocompleteCompletion' onMouseDown={onClick(() => this.props.applyCompletion(x))}>
                             {x}
                           </div>
                  })
                }
              </div>}
           </div>
  }
}

export const KeyboardButton1 = props => {
  let style
  let className = 'inputControlKeyboardButton1'
  let action = props.action
  if (!action) {
    action = async () => {}
    className += ' inputControlKeyboardButton1Disabled'
  }
  return <div style={style} className={className +
                                       (props.className ? ' ' + props.className : '')}>
           <UIOKCancel keepFocus={props.keepFocus} cancel={action} cancelIcon={props.icon}/>
         </div>
}

export const KeyboardButton = props => {
  let style
  if (!props.action && !props.cancel) {
    style= { display: 'none' }
  }
  return <div style={style} className={'inputControlKeyboardButton' +
                                       (props.className ? ' ' + props.className : '')}>
           <UIOKCancel keepFocus={props.keepFocus} label={props.label} ok={props.action} cancel={props.cancel} cancelIcon={Trash} okIcon={props.icon}/>
         </div>
}



const endsWithWhitespace = (str) => {
  if (!str) {
    return true
  }
  const lastChar = str.charAt(str.length - 1);
  return isWhitespace(lastChar)
}

const startsWithWhitespace = (str) => {
  if (!str) {
    return true
  }
  return isWhitespace(str.charAt(0))
}


TurndownService.prototype.escape = x => x
export const turndownService = new TurndownService()
turndownService.addRule('img', {
  filter: ['img'],
  replacement: (content, n, options) => {
    const name = n.getAttribute("emoji-name");
    let result = ''
    if (name) {
      result += name;
    } else {
      const gif = n.getAttribute("gif-link");
      if (gif) {
        result += ' ' + gif + ' ';
      } else {
        result += ' ' + n.src + ' ';
      }
    }
    return result
  }
})

export class Document {
  historyPtr = -1
  history = []
  start = -1
  changeSubject = new Subject()
  observeChanges = () => {
    return this.changeSubject
  }
  constructor(text, advance) {
    if (text || advance) {
      this.advance(text, advance)
    } 
  }
  isFirst = () => {
    return this.historyPtr === 0
  }
  isEmpty = () => {
    return this.historyPtr === -1
  }
  isCurrent = (i) => {
    return i === this.historyPtr
  }
  map = f => {
    return this.history.slice(0, this.historyPtr+1).map(f)
  }
  advance = (content, advance) => {
    if (!advance) {
      if (!this.getCurrent() && !content) {
        return
      }
      if (this.getCurrent() == content) {
        return
      }
    }
    this.history = this.history.slice(0, this.historyPtr + 1)
    this.history.push(content)
    this.historyPtr++
    this.changeSubject.next(this.getCurrent())
  }
  deleteCurrent = () => {
    if (this.canUndo()) {
      this.history = this.history.slice(0, this.historyPtr)
      this.historyPtr--
    }
  }
  undo = () => {
    if (this.canUndo()) {
      this.historyPtr--
      this.changeSubject.next(this.getCurrent())
    }
  }
  redo = () => {
    if (this.canRedo()) {
      this.historyPtr++
      this.changeSubject.next(this.getCurrent())
    }
  }
  canUndo = () => {
    return this.historyPtr > this.start
  }
  canRedo = () => {
    return this.historyPtr < this.history.length - 1
  }
  getCurrent = () => {
    return this.history[this.historyPtr] 
  }
  getNext = () => {
    return this.history[this.historyPtr+1] 
  }
}

let instr = 0
const getNextInstruction = () => {
  instr++
  return instr
}

class CommandMenu {
  constructor (name, commands, presorted) {
    this.name = name
    this.id = name
    this.commands = commands || []
    this.presorted = presorted
  }
  getName = () => this.name
  getId = () => this.id
  isMenu = () => true
  addButton = button => {
    this.commands = this.commands.filter(x => x.getId() != button.id)
    const { id, instruction, category, usage, buttonType, isSystem  } = button
    this.commands.push(new CommandButton({
      buttonType, 
      action: instruction,
      id,
      category,
      isSystem,
      button,
    }))
  }
}

const getCommands = (variable, offset) => {
  if (!variable.options) {
        return []
  }
  return variable.options.slice(offset || 0).map((opt, i) => {
    return new CommandButton({
      action: opt,
      category: variable.variable,
      type: 'variable',
      id: i,
      isSystem: true,
      button: {
        usage: 0
      }
    })
  })
}

class CommandButton {
  constructor (command) {
    this.command = command
    this.name = command.action
    this.template = tryParseTemplate(command.action, command.isSystem)
    if (command.label) {
      this.label = command.label
    } else {
      if (this.template) {
        this.label = labelForTemplate(this.template)
      } else {
        this.label = this.command.action
      }
    }
  }
  getTemplate = () => this.template
  cloneTemplate = () => {
    const command = this.command
    return tryParseTemplate(command.action, command.isSystem)
  }
  isSystem = () => this.command.isSystem
  isWriting = () => {
    return this.command.buttonType == 'create'
  }
  getButtonType = () => this.command.buttonType
  getLabel = () => {
    return this.label
  }
  getIcon = () => {
    return this.command.icon
  }
  getDisplayLabel = () => {
    if (this.template) {
      return renderDisplayTemplate(this.template)
    }
    return this.getLabel()
  }
  getApply = () => this.command.apply
  getAction = () => this.command.action
  getName = () => this.name
  getUsage = () => (this.command.button && this.command.button.usage) || 0
  getId = () => this.command.id
  getTemperature = () => (this.command.button && this.command.button.temperature) || undefined
  getMaxTokens = () => (this.command.button && this.command.button.max_tokens) || undefined
  isMenu = () => false
  getCategory = () => this.command.category
}

const createCommand = (command, commands) => {
  if (command.menu) {
    return createMenu(command.menu, commands)
  }
  return new CommandButton(command)
}

const createMenu = (menu, commands) => {
  const result = new CommandMenu(menu)
  for (const command of commands) {
    result.commands.push(createCommand(command))
  }
  return result
}

class CommandAutocomplete {
  constructor (commands, cat) {
    this.commands = commands
    this.cat = cat
  }
  getAvailableCommands = (search) => {
    if (!this.cat) {
      return {
        cats: [],
        availableCommands: this.commands
      }
    }
    return {
      cats: [this.cat],
      availableCommands: this.commands.filter(x => x.category === this.cat)
    }
  }
}

export class KeyboardAutocomplete extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showFrequentlyUsedOnly: false,
      showMenu: false,
      cat: null,
      availableCommands: [],
      cats: [],
      autocomplete: []
    }
  }

  componentDidMount() {
    this.calcMaxHeight(true)
    if (this.props.observeEditorHeight) {
      this.resizeObserver = this.props.observeEditorHeight().subscribe(height => {
        this.editorHeight = height
        setTimeout(() => this.calcMaxHeight(true))
      })
    }
  }


  componentDidUpdate(prevProps) {
    const eqCmds = (x, y) => {
      if (!x) return !y
      else if (!y) return false
      if (x.length !== y.length) return false
      for (const i = 0; i < x.length; i++) {
        const c1 = x[i]
        const c2 = y[i]
        if (c1.getId() !== c2.getId()) {
          return false
        }
      }
      return true
    }
    if (!eqCmds(prevProps.command, this.props.commmands)) {
      setTimeout(() => this.calcMaxHeight(true))
    }
  }

  componentWillUnmount() {
    clearInterval(this.heightAnimator)
    if (this.resizeObserver) {
      this.resizeObserver.unsubscribe()
    }
  }

  onWindowResize = () => {
    ////console.log('animate1 window resize', window.visualViewport.height)
    this.calcMaxHeight(isDesktop())
  }

  calcMaxHeight = (noAnimate, retries) => {
    if (this.editorHeight === undefined) {
      setTimeout(() => this.calcMaxHeight(noAnimate))
      return
    }
    const getWindowHeight = () => {
      const h = window.visualViewport.height
      ////console.log("window height:", h)
      return h
    }
    const ref = this.ref
    if (ref) {
      const { left, right, bottom } = ref.getBoundingClientRect()
      const top = bottom + 50
      let inputHeight = this.editorHeight
      ////console.log("input height", inputHeight, 'top', top, 'bottom', bottom)
      const maxHeight = getWindowHeight() - top - inputHeight
      clearInterval(this.heightAnimator)
      const lerp = (t, start, end) => start  +  t * (end - start)
      const start = this.state.maxHeight || maxHeight
      const end = maxHeight
      if (this.state.maxHeight === end) {
        return
      }
      const started = Date.now()
      ////console.log("animate max height:", start, end)
      const finish = () => {
        clearInterval(this.heightAnimator)
        const { left, right, bottom } = ref.getBoundingClientRect()
        const top = bottom + 50
        const maxHeight = getWindowHeight() - top - inputHeight
        this.setState({
          maxHeight: Math.max(maxHeight, isDesktop() ? 40 : 120)
        }, () => {
          ////console.log("animate complete", this.state.maxHeight)
        })
      }
      if (isDesktop() || noAnimate) {
        setTimeout(finish)
      }  else {
        const dur =  400
        this.heightAnimator = setInterval(() => {
          const now = Date.now()
          const elapsed = now - started
          const { top, bottom } = ref.getBoundingClientRect()
          const t = elapsed / dur
          let maxHeight = lerp(t, start, end)
          if (t < 1.0) {
            maxHeight += 100
          }
          this.setState({
            maxHeight: Math.max(maxHeight, 120)
          })
          if (t >= 1.0) {
            finish()
          }
        }, 16)
      }
    } else {
        ////console.log("menuRef not set")
    }
  }
   
  hideFrequentlyUsed = () => {
    this.state.showFrequentlyUsedOnly = false
    this.state.cat = null
  }
  show = () => {
    this.setState({
      cat: null,
      menuStack: [],
      showMenu: true
    })
  }
  toggleMenu = () => {
    ////debugger
    this.hideFrequentlyUsed()
    this.setState({
      showMenu: !this.state.showMenu,
    }, this.onWindowResize)
  }
  reset = () => {
    this.resetMenu(false)
    this.setState({
      showMenu: false,
    })
  }
  resetMenu = (show) => {
    this.hideFrequentlyUsed()
    this.menuStack = []
    this.state.cat = null
  }
  menuStack = []
  closeMenu = (e) => {
    if (true) e.preventDefault()
    this.hideFrequentlyUsed()
    this.setState({showMenu: false})
    this.resetMenu()
  }
  peekCat = () => {
    return this.menuStack[this.menuStack.length-1]
  }
  popCat = () => {
    this.hideFrequentlyUsed()
    this.menuStack.pop()
    this.state.showMenu = true
    this.updateCat()
  }
  updateCat = () => {
    const cat = this.peekCat()
    this.state.cat = cat
    this.forceUpdate()
  }
  pushCat = cat => {
    this.state.showMenu = true	
    this.menuStack.push(cat)
    this.updateCat()
  }

  addCommand = command => {
    this.state.showMenu = false
    this.props.addCommand(command, this.props.index)
  }
  
  autoComplete = () => {
    const completer = new CommandAutocomplete(this.props.allCommands,
                                              this.state.cat)
    return completer.getAvailableCommands(this.props.search)
  }

  renderMenu = commands => {
    if (!this.state.showMenu) {
      return null
    }
    return ReactDOM.createPortal(this.doRenderMenu(commands), getPortal())
  }

  setMenuRef = ref => {
    if (ref) {
      ref.scrollTop = ref.scrollHeight;
      this.menuRef = ref
      this.forceUpdate()
    }
  }

  filterCommands = (searchTerm) => {
    if (!searchTerm) {
      searchTerm = ''
    } else {
      searchTerm = searchTerm.trim()
    }
    if (this.props.showAll && !searchTerm) {
      return this.props.commands
    }
    if (!this.props.commands || !searchTerm) {
      return []
    }
    const commands = []
    const seen = {}
    this.props.commands.forEach(cmd => {
      if (cmd.isMenu()) {
        cmd.commands.forEach(cmd => {
          if (!seen[cmd.getId()]) {
            seen[cmd.getId()] = true
            commands.push(cmd)
          }
        })
      } else {
        commands.push(cmd)
      }
    })
    searchTerm = searchTerm.toLowerCase()
    const searchTerms = searchTerm.split(/\s+/)
    const matches = {}
    let prefixMatch = searchTerms.length > 1
    let filtered = commands.filter(cmd => {
      let matched = 0
      if (searchTerms.length > 0) {
        //////console.log(cmd)
        //const terms1 = cmd.getCategory().toLowerCase().split(/\s+/)
        const getLabel = cmd => {
          let label = cmd.getLabel()
          const template = cmd.getTemplate()
          if (template) {
            label = ''
            template.fragments.forEach(x => {
              if (x.type == 'variable') {
                const value = template.vars[x.variable]
                label += x.variable
              } else {
                label += x.text
              }
            })
          }
          return label.toLowerCase().replace(/[\"]/g, '')
        }
        const label = getLabel(cmd)
        if (label.startsWith(searchTerm)) {
          matched += 10
          prefixMatch = true
        }
        const terms2 = label.split(/\s+/)
        ////////console.log('terms1', terms1)
        //////console.log('terms2', terms2)
        const allTerms = [terms2]//[terms1, terms2]
        allTerms.forEach((terms, i) => {
          //////console.log('terms', terms, i)
          terms.forEach((term, j) => {
            //////console.log('term', term)
            if (term) {
              searchTerms.forEach((searchTerm, k) => {
                if (searchTerm) {
                  if (term.startsWith(searchTerm)) {
                    matched++
                  }
                  if (term === searchTerm &&
                      j === k && k === 0) {
                    prefixMatch = true
                  }
                }
              })
            }
          })
        })
      } else {
        matched = 0
      }
      if (matched === 0) return false
      const match = matches[cmd.getId()] || 0
      matches[cmd.getId()] = match + matched
      return true
    })
    if (prefixMatch) {
      filtered = filtered.filter(x => matches[x.getId()] >= 10)
    }
    //////console.log('filtered', filtered)
    //////console.log('matches', matches)
    filtered.sort((x, y) => {
      const w1 = matches[x.getId()] || 0;
      const w2 = matches[y.getId()] || 0;
      let cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      cmp1 = x.getCategory().localeCompare(y.getCategory())
      if (cmp1 !== 0) {
        return cmp1
      }
      return x.getLabel().localeCompare(y.getLabel())
    })
    return filtered.slice(0, 16)
  }

  setMenuRef = ref => {
    if (ref) {
      this.menuRef = ref
    }
  }

  setAutocompletesRef = ref => {
    if (ref) {
      this.autocompletesRef = ref
    }
  }

  renderAutoCompletes = () => {
    const cmds = this.filterCommands(this.props.searchTerm)
    let className = 'keyboardMenuAutocompletes'
    if (this.props.presorted) {
      className += ' keyboardMenuAutocompletesFull'
    }
    let style
    if (this.state.maxHeight) {
      style = { maxHeight: this.state.maxHeight  }
    }
    ////console.log("STYLE", style)
    
    return <div ref={this.setAutocompletesRef} key='keyboardAutocompletes-div' className={className} style={style}>
             {
               cmds.map((command, i) => {
                 const name = command.getName()
                 const onClick = (e) => {
                   if (e.button === 0) {
                     this.addCommand(command)
                   }
                 }
                 let label = command.getDisplayLabel()
                 if (command.isWriting()) {
                   //label += '...'
                 }
                 const className= 'keyboardMenuItem keyboardMenuItemAutocomplete'
                 return <div key={'Autocompletes-Command:'+command.getId()} className={className} onMouseDown={onClick}>
                          <div className='keyboardMenuItemIcon'><ReactSVG src={UserSaid}/></div>
                          <div className='keyboardMenuItemLabel'>{label}</div>
                        </div>
               })
             }
             </div>
  }

  showFrequentlyUsed = () => {
    this.resetMenu()
    this.setState({
      showMenu: true,
      showFrequentlyUsedOnly: true
    })
  }
  
  doRenderMenu = (commands) => {
    const onClickBackCat = e => {
      e.preventDefault()
      setTimeout(this.popCat)
    }
    let toRender = [].concat(this.state.cat ? this.state.cat.commands : commands.filter(x => !(x.isMenu() && x.commands.length === 0)))
    let style
    if (this.ref) {
      const { top, left } = this.ref.getBoundingClientRect()
      const margin = 10;
      const maxHeight = window.innerHeight - (top  + 45)
      const h = this.props.editorHeight
      //////console.log("max height", maxHeight, window.innerHeight, top, 'offsetHeight', h);
      style = { top: top + 40, maxHeight }
      //////console.log(style)
    }
    if (!this.props.presorted && !(this.state.cat && this.state.cat.presorted)) toRender.sort((x, y) => {
      const isMine = a => !this.state.cat && a.getName() === 'Mine'
      if (isMine(x)) return -1
      if (isMine(y)) return 1
      const a = x.isMenu()
      const b = y.isMenu()
      const cmp = b - a
      if (cmp) return cmp
      return x.name.localeCompare(y.name)
    })
    let cmds = []
    toRender.forEach(command => {
      if (command.isMenu()) {
        command.commands.forEach(cmd => {
          cmds.push(cmd)
        })
      } else {
        cmds.push(command)
      }
    })
    if (!this.props.showAll && !this.state.cat) {
      let frequent = []
      cmds.forEach(cmd => {
        if (cmd.getUsage() > 0) {
          frequent.push(cmd)
        }
      })
      frequent.sort((x, y) => {
        const cmp = y.getUsage() - x.getUsage()
        if (cmp) {
          return cmp
        }
        return x.getLabel().localeCompare(y.getLabel())
      })
      frequent = frequent.slice(0, 5)
      let frequentMenu = new CommandMenu('Frequently Used', frequent, true)
      if (cmds.length > 0 && this.state.showFrequentlyUsedOnly) {
        if (frequent.length === 0) {
          frequent = getRandomSubarray(cmds, 5)
          frequentMenu = new CommandMenu('See All', frequent, true)
          toRender.sort((x, y) => {
            return x.name.localeCompare(y.name)
          })
        } 
        this.state.cat = frequentMenu
        toRender = frequent
      } else {
        if (frequent.length > 0) {
          toRender.unshift(frequentMenu)
        }
      }
    }
    return (
      <div key='main-menu' className='keyboardMenuContainer' onClick={this.closeMenu}>
        <div ref={this.setMenuRef} className='keyboardMenu' style={style}>
          {this.state.cat  && <div key={'cat:back'} className={'keyboardMenuItem keyboardMenuItemBack'} onMouseDown={onClickBackCat}>
                                <div className='keyboardMenuItemIcon'><ReactSVG src={Left}/></div>
		                <div className='keyboardMenuItemLabel'>{this.state.cat.getName()}</div>
                              </div>}
          {
            toRender.map(command => {
              const name = command.getName()
              const onClick = (e) => {
                e.preventDefault()
                if (command.isMenu()) {
                  this.pushCat(command)
                } else {
                  this.addCommand(command)
                }
              }
              if (command.isMenu()) {
                let className = 'keyboardMenuItem  keyboardMenuItemCategory'
                return <div key={command.getId()} className={className} onMouseDown={onClick}>
		         <div className='keyboardMenuItemIcon'><ReactSVG src={Right}/></div>
		         <div className='keyboardMenuItemLabel'>{name}</div>
                       </div>
              }
              let className = 'keyboardMenuItem'
              //////console.log('command', command.command)
              let label = command.getDisplayLabel()
              let icon = command.getIcon() || EditIcon
              return <div key={'Command:'+command.getId()} className={className} onMouseDown={onClick}>
                       <div className='keyboardMenuItemIcon'><ReactSVG src={icon}/></div>
                       <div className='keyboardMenuItemLabel'>{label}</div>
                     </div>
            })
          }
        </div>
      </div>);
  }

  setRef = ref => {
    this.ref = ref
  }
  
  render() {
    const showMenu = true
    let className = 'keyboardPopupMenu' + (!showMenu ? ' keyboardPopupMenuEmpty' : '')
    let style
    if (this.props.hidden) {
      style = {visibility: 'hidden'}
    } else {
      style = {
        top: (this.props.editorHeight || 0) + 0
      }
    }
    return <div className={className}  style={style}>
             <div onClick={!this.state.showMenu ? ((e)=> {
                    e.preventDefault()
                    this.toggleMenu(showMenu)
                  }) : undefined} ref={this.setRef} className={'keyboardAddButton' + (this.state.showMenu ? ' keyboardPopupMenuActive' : '' )}><ReactSVG src={this.state.showMenu ? MenuUp : MenuDown}/></div>
             {this.props.commands && this.renderMenu(this.props.commands)}
             {!this.state.showMenu && !this.props.busy && this.renderAutoCompletes()}
           </div>
  }

}


const maxLen = 256
const EMPTY = ''

const enableGeneration = true

export class Keyboard extends Component {

  constructor (props) {
    super(props)
    this.state = {
      nextInstruction: enableGeneration && {
        key: getNextInstruction(),
        instruction: new Document(),
        canSave: true
      },
      instructions: new Document(),
      edits: new Document(),
      editorHeight: 0,
      completions: [],
      lang: {
        name: "English",
        dialect: "United States",
        iso: 'en-US',
        hasVoice: true
      },
      instructionLang: {
        name: "English",
        dialect: "United States",
        iso: 'en-US',
        hasVoice: true
      },
      searchTerm: ''
    }
  }

  share = async () => {
    const text = this.editor.getText()
    const shareData = {
      text
    }
    //////console.log("shareData", shareData)
    try {
      result = await navigator.share(shareData)
      //////console.log(result)
    } catch (err) {
      console.error(err)
    }
  }

  autocompleteVariable = (variable, instruction, noCache) => {
    if (variable && ((noCache || !variable.options) || !variable.isSystem)) {
      const  prev = variable.options || []
      variable.options = []
      instruction = instruction || this.state.nextInstruction
      instruction.completing = true
      this.forceUpdate()
      this.props.me.autocompleteTemplate(displayTemplate(variable.containingTemplate), variable.variable, noCache).then(result => {
        variable.options = result.completions || prev
        instruction.completing = false
        this.forceUpdate()
      })
    }
  }    

  copyToClipboard = async text => {
    navigator.clipboard.writeText(text)
    await delay(0.5)
  }

  copy = async () => {
    const text = this.editor.getText()
    return this.copyToClipboard(text)
  }

  renderText = (edit) => {
    return (edit && edit.getCurrent()) || ''
  }

  getCurrentDocument = () => {
    return this.state.edits.getCurrent()
  }

  onEditorHeightChanged = height => {
    this.setState({editorHeight: height});
  }

  updateButtonsLater = () => {
    clearTimeout(this.buttonsUpdater)
    this.buttonsUpdater = setTimeout(this.updateButtonsNow, 200)
  }

  menus = {}
  
  getMenu = category => {
    if (!this.menus[category]) {
      this.menus[category] = new CommandMenu(category)
    }
    return this.menus[category]
  }

  updateButtonsNow = () => {
    const buttons = Object.values(this.buttons)
    this.menus = {}
    buttons.forEach(button => {
      const { id, category, instruction } = button
      const menu = this.getMenu(category)
      if (this.buttonUsage) {
        button.usage = this.buttonUsage[id]
      }
      menu.addButton(button)
    })
    this.commands = Object.values(this.menus)
    if (this.intellikeyLinkEdit) {
      this.commands.push(new CommandMenu('IntelliKey', [this.intellikeyLinkEdit], true))
    }
    this.forceUpdate()
  }

  writeMenus = {}
  writingCommands = []
  
  getMenu = category => {
    if (!this.menus[category]) {
      this.menus[category] = new CommandMenu(category)
    }
    return this.menus[category]
  }

  getWriteMenu = category => {
    if (!this.writeMenus[category]) {
      this.writeMenus[category] = new CommandMenu(category)
    }
    return this.writeMenus[category]
  }

  updateWritingButtonsNow = () => {
    const buttons = Object.values(this.writingButtons)
    this.writeMenus = {}
    buttons.forEach(button => {
      const { id, category, instruction } = button
      const menu = this.getWriteMenu(category)
      if (this.buttonUsage) {
        button.usage = this.buttonUsage[id]
      }
      menu.addButton(button)
    })
    this.writingCommands = Object.values(this.writeMenus)
    if (this.intellikeyLinkWrite) {
      this.writingCommands.push(new CommandMenu('IntelliKey', [this.intellikeyLinkWrite], true))
    }
    this.forceUpdate()
  }

  updateWritingButtonsLater = () => {
    clearTimeout(this.writeButtonsUpdater)
    this.writeButtonsUpdater = setTimeout(this.updateWritingButtonsNow, 200)
  }

  buttons = {}
  writingButtons = {}

  componentDidMount() {
    let json = localStorage.getItem("keyboard.text.lang")
    if (json) {
      this.state.lang = JSON.parse(json)
    }
    json = localStorage.getItem("keyboard.instruction.lang")
    if (json) {
      this.state.instructionLang = JSON.parse(json)
    }
    this.orientSub = this.props.me.observeOrientation().subscribe(orient => {
      this.setState({
        orient
      })
    })
    this.buttonUsageSub = this.props.me.observeButtonUsage().subscribe(buttonUsage => {
      this.buttonUsage = buttonUsage
      for (const id in buttonUsage) {
        const usage = buttonUsage[id]
        if (this.buttons[id]) {
          this.updateButtonsLater()
        }
        else if (this.writingButtons[id]) {
          this.updateWritingButtonsLater()
        }
      }
    })
    this.buttonsub = this.props.me.observeAllButtons().subscribe(change => {
      const { type, button } = change
      if (type == 'removed') {
        delete this.buttons[button.id]
      } else {
        this.buttons[button.id] = button
      }
      this.updateButtonsLater()
    })
    
    this.writeButtonSub = this.props.me.observeAllWritingButtons().subscribe(change => {
      const { type, button } = change
      if (type == 'removed') {
        delete this.writingButtons[button.id]
      } else {
        this.writingButtons[button.id] = button
      }
      this.updateWritingButtonsLater()
    })
    
    this.sub2 = this.props.me.observeKeyboardIsActive().subscribe(isActive => {
      if (!isActive) {
        if (this.editor) {
          this.editor.blur()
        }
      }
      if (this.autocomplete) {
        this.autocomplete.reset()
      }
    })
    this.sub = this.props.me.observeKeyboard().subscribe(msg => {
      const { document, cursorOffset, buttonId } = msg
      clearTimeout(this.freqTimeout)
      this.buttonResolveSeq++
      const edit = new Document(document || '', true)
      const edits = new Document(edit)
      const instructions = new Document()
      if (!buttonId) {
        this.autocomplete.showFrequentlyUsed()
      } else {
        this.autocomplete.reset()
      }
      this.setState({
        edits: edits,
        instructions: instructions,
        completions: [],
        voiceRecognition: false
      }, () => {
        this.buildNextInstruction()
        this.editor.setText(document)
        setTimeout(() => {
          this.editor.setCaretPosition(cursorOffset)
        }, 100)
        if (buttonId) {
          const seq = this.buttonResolveSeq
          this.resolveButton(buttonId).then(button => {
            if (this.buttonResolveSeq != seq) {
              return
            }
            const { id, instruction, category, usage, buttonType, isSystem  } = button
            const cmd = new CommandButton({
              buttonType, 
              action: instruction,
              id,
              category,
              isSystem,
              button,
            })
            this.addCommand(cmd, !isDesktop())
          })
        } 
        this.forceUpdate()
      })
    })
    this.props.me.keyboardIsReady(this)
    if (this.editor) this.editor.blur()
    this.autocomplete.reset()
    this.freqTimeout = setTimeout(this.autocomplete.showFrequentlyUsed, 400)
    window.visualViewport.addEventListener('resize', this.onWindowResize);
    this.props.me.getReferralCode().then(code => {
      if (code) {
        const label = 'Insert your IntelliKey referral link'
        const action = "https://apps.apple.com/us/app/intellikey/id1667188645 Use referral code " +code
        const icon = Logo
        this.intellikeyLinkEdit = new CommandButton({
          label,
          buttonType: 'edit', 
          action, 
          id: 'editLink',
          category: 'IntelliKey',
          isSystem: true,
          apply: () => {
            this.editor.insertTextAtCaret(action)
            this.getCurrentEdit().advance(this.editor.getText())
          },
          icon
        })
        this.intellikeyLinkWrite = new CommandButton({
          label,
          buttonType: 'create', 
          action,
          id: 'createLink',
          category: 'IntelliKey',
          isSystem: true,
          apply: () => {
            this.editor.insertTextAtCaret(action)
            this.getCurrentEdit().advance(this.editor.getText())
          },
          icon
        })
        this.forceUpdate()
      }
    })
  }
  buttonResolveSeq = 0
  onWindowResize = e => {
    if (this.autocomplete) {
      this.autocomplete.onWindowResize()
    }
  }

  resolveButton = async buttonId => {
    let button = this.writingButtons[buttonId]
    if (!button) {
      button = this.buttons[buttonId]
    }
    if (button) {
      return button
    }
    await delay(0.2)
    return await this.resolveButton(buttonId)
  }

  componentWillUnmount() {
    this.cancelStreamEdit()
    if (this.autocomplete) {
      this.autocomplete.reset()
    }
    window.visualViewport.removeEventListener('resize', this.onWindowResize)
    if (this.recognition) {
      this.recognition.stop()
    }
    if (this.sub) {
      this.sub.unsubscribe()
    }
    if (this.sub2) {
      this.sub2.unsubscribe()
    }
    if (this.sub3) {
      this.sub3.unsubscribe()
    }
    if (this.sub4) {
      this.sub4.unsubscribe()
    }
    if (this.orientSub) {
      this.orientSub.unsubscribe()
    }
    if (this.instructionSub) {
      this.instructionSub.unsubscribe()
    }
    if (this.writeButtonSub) {
      this.writeButtonSub.unsubscribe()
    }
    if (this.buttonUsageSub) {
      this.buttonUsageSub.unsubscribe()
    }
  }


  onEditorUpdate = () => {
    this.setState({
      edited: true
    })
  }

  setEditor = ref => {
    if (this.editorSub) {
      this.editorSub.unsubscribe()
    }
    if (ref) {
      this.state.editorCanApply = true
      this.editor = ref
      this.editorSub = this.editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          editorCanApply: !isEmpty || enableGeneration
        })
      })
    }
    this.forceUpdate()
  }

  isKeyboardShowing = () => !isDesktop() && this.state.textInputFocus
  
  isInstructionKeyboardShowing = () => !isDesktop()  && this.state.instructionFocus

  clearEdit = () => {
    //debugger
    this.editor.clear()
  }

  getCurrentEdit = () => {
    let edit = this.state.edits.getCurrent()
    if (!edit) {
      //////console.log("current edit is null")
      edit = new Document(this.editor.getText())
      edit.advance("", true)
      this.state.edits.advance(edit)
    }
    return edit
  }

  advanceInstruction = instruction => {
    instruction.input = this.getCurrentDocument()
    this.state.instructions.advance(instruction)
    this.forceUpdate()
  }

  buildNextInstruction = (instruction) => {
    let nextInstruction = instruction
    if (!nextInstruction) {
      nextInstruction = {
        key: getNextInstruction(),
        instruction: new Document(),
        canSave: true
      }
    }
    let text = nextInstruction.instruction.getCurrent() || ''
    if (nextInstruction.templateOverride) {
      text = nextInstruction.templateOverride
      nextInstruction.templateOverride = null
    } else {
      if (nextInstruction.inputTemplate) {
        if (!nextInstruction.variableToComplete) {
          text = displayTemplate(nextInstruction.inputTemplate)
        }
      }
    }
    this.instructionEditor.setText(text)
    this.cancelInstructionAutocomplete()
    this.state.nextInstruction = nextInstruction
    this.setState({
      searchTerm: ''
    })
    if (text) {
      this.performAutocomplete()
    }
  }

  setInstructionEditor = editor => {
    if (this.instructionEditorSub) {
      this.instructionEditorSub.unsubscribe()
      this.instructionEditorSub = null
    }
    this.instructionEditor = editor
    if (editor) {
      this.instructionEditorSub = editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          instructionCanApply: !isEmpty
        })
      })
    }
    this.forceUpdate()
  }

  autocompleteSeq = 0
  cancelInstructionAutocomplete = () => {
    this.autocompleteSeq++
  }
  performAutocomplete = () => {
    clearTimeout(this.autocompleteTimer)
    const seq = ++this.autocompleteSeq
    this.autocompleteTimer = setTimeout(() => {
      if (seq === this.autocompleteSeq) {
        const searchTerm = this.instructionEditor.getText().split('.').join('')
        if (this.state.searchTerm != searchTerm) {
          this.setState({
            searchTerm
          })
        }
      }
    }, 200)
  }

  onInstructionInput = () => {
    this.performAutocomplete()
  }

  advance = newText => {
    this.state.edits.advance(new Document(newText))
    this.renderCurrentDocument()
  }

  canUndoCurrentDocument = () => {
    if (!this.state.edits.getCurrent()) {
      return false
    }
    return this.state.edits.getCurrent().historyPtr > 0
  }
  

  renderCurrentDocumentFromTemplate = (template) => {
    const text = expandTemplate(template)
    this.editor.setText(text)
    this.forceUpdate()
  }
  
  renderCurrentDocument = () => {
    const text = this.renderText(this.getCurrentDocument())
    if (this.editor.setText(text)) {
      this.focusedText = undefined
    }
    this.forceUpdate(this.showLastInstruction)
    return text
  }

  undoEdit = async () => {
    ////debugger
    if (this.canUndoCurrentDocument()) {
      const doc = this.getCurrentDocument()
      doc.undo()
    } else {
      this.cancelStreamEdit()
      let i = this.getCurrentInstruction()
      this.state.variableToComplete = null
      if (i) {
        if (i.output) {
          this.state.edits.undo()
          i.undoOutput = i.output
          i.output = null
        } else if (i.inputText) {
          i.inputTemplate = null
          i.variableToComplete = null
          i.instruction = new Document(i.inputText)
          i.inputText = null
        } else {
          this.state.instructions.undo()
          i = this.getCurrentInstruction()
        }
        this.buildNextInstruction(i)
        this.renderCurrentDocument()
        return
      }
      if (this.state.instructions.canUndo()) {
        this.state.instructions.undo()
        const i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      } else {
        this.buildNextInstruction()
      }
    }
    this.renderCurrentDocument()
  }
  
  redoEdit = async () => {
    ////debugger
    const doc = this.getCurrentDocument()
    if (doc && doc.canRedo()) {
      doc.redo()
    } else {
      let i = this.getCurrentInstruction()
      if (i) {
        if (i.undoOutput) {
          i.output = i.undoOutput
          i.undoOutput = null
          this.state.edits.redo()
          this.buildNextInstruction()
        } else {
          this.state.instructions.redo()
          i = this.getCurrentInstruction()
          this.buildNextInstruction(i)
        }
      } else if (this.state.instructions.canRedo()) {
        this.state.instructions.redo()
        i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      }
    }
    this.renderCurrentDocument()
  }

  clearEditor = () => {
    const edit = this.getCurrentEdit()
    edit.advance('')
    this.state.completions = []
    this.editor.clear()
  }

  onFocus = e => {
    if (!this.state.editing) {
      this.setState({
        editing: true
      })
    }
  }

  inputSeq = 0
  onInput = async e => {
    //////console.log("onInput", e)
    const seq = ++this.inputSeq
    if (this.state.completions.length > 0) {
      this.state.completions = []
      this.forceUpdate()
    }
    if (!isDesktop()) {
      return
    }
    setTimeout(async () => {
      if (seq != this.inputSeq) {
        return
      }
      if (!this.editor.focused) {
        return
      }
      var element = this.editor.ref
      var caretOffset = 0;
      var range = window.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
      var divStr = element.textContent
      const textBefore = divStr.substring(0, caretOffset)
      const textAfter = divStr.substring(caretOffset + 1)

      //Split the text before and after the caret into words
      var wordsBefore = textBefore.split(' ');
      var wordsAfter = textAfter.split(' ');
      
      // Get the last word before the caret
      var lastWordBefore = wordsBefore[wordsBefore.length - 1];
      
      // Get the first word after the caret
      var firstWordAfter = wordsAfter[0];

      //////console.log("textBefore", textBefore)
      //////console.log("textAfter", textAfter)
      if (textBefore && !textAfter.trim()) {
        if (true || endsWithWhitespace(textBefore)) {
          const result = await this.props.me.autocomplete(textBefore.trim())
          let { completions, isNewSentence } = result
          if (seq == this.inputSeq) {
            //////console.log("autocomplete", JSON.stringify(result, null, ""))
            this.setState({ completions, isNewSentence })
          }
          return
        }
      }
      this.setState({
        completions: []
      })
    }, 500)
  }

  setCurrentText = text => {
    if (this.state.editing) {
      this.editor.insertTextAtCaret(text)
      const currentText = this.editor.getText()
      this.state.edits.getCurrent().advance(currentText)
    } else {
      this.state.edits.getCurrent().advance(text)
      return this.renderCurrentDocument()
    }
  }

  isEdit = () => {
    if (!this.editor) return false
    return !this.editor.isEmpty()
  }

  getCurrentText = () => {
    const doc = this.getCurrentDocument()
    return doc ? doc.getCurrent() || "" : ""
  }

  getCurrentInstruction = () => {
    return this.state.instructions.getCurrent()
  }

  autodetectLang = async () => {
    const text = this.editor.getText()
    return await this.props.me.getLang(text)
  }

  setLang = async (text) => {
    const result = await this.props.me.getLang(text)
    if (result.lang) {
      this.setState({
        lang: {
          iso: result.lang
        }
      })
    }
  }
  
  onBlur = e => {
    this.state.editing = false
    this.state.completions = []
    const text = this.editor.getText()
    if (this.getCurrentText() == text) {
      //////console.log("onBlur no change")
      this.forceUpdate()
      return 
    }
    //////console.log("onBlur text changed")
    this.clearError()
    const doc = this.getCurrentDocument()
    //////debugger
    if (!doc) {
      if (text) {
        this.advance(text)
      }
    } else {
      doc.advance(text)
    }
    this.forceUpdate()
  }

  receiveVoiceInput = async input => {
    //////console.log("voice input", input)
    if (this.state.instructionSpeechInputActive) {
      const { instruction } = this.state.nextInstruction
      const editor = this.instructionEditor
      editor.insertTextAtCaret(input)
      const text = editor.getText()
      instruction.advance(text)
      this.performAutocomplete()
      const { corrected } = await this.props.me.autocorrect({input: text, lang: this.state.instructionLang.iso})
      if (corrected && text != corrected) {
        instruction.undo()
        instruction.advance(corrected)
        editor.setText(corrected)
        this.performAutocomplete()
      }
    } else if (this.state.textFieldSpeechInputActive) {
      const edit = this.getCurrentEdit()
      this.editor.insertTextAtCaret(input)
      const text = this.editor.getText()
      edit.advance(text)
      const { corrected } = await this.props.me.autocorrect({ input: text, lang: this.state.lang.iso })
      if (corrected && text != corrected) {
        edit.undo()
        edit.advance(corrected)
        this.editor.setText(corrected)
      }
    } else {
      console.error("voice input fail")
    }
    this.forceUpdate()
  }
  
  lastSent = 0

  stopVoiceInput = () => {
    if (this.recognition) {
      this.recognition.stop()
    }
  }
  
  startVoiceInput = () => {
    if (!this.recognition) {
      this.toggleVoiceRecognition()
    } else {
      this.recognition.start()
    }
  }

  getCurrentInstruction = () => {
    return this.state.instructions.getCurrent()
  }

  clearError = () => {
    const i = this.getCurrentInstruction()
    if (i) {
      if (i.serverError && !i.output) {
        this.state.instructions.undo()
      }
    }
  }

  isInstructionEditorEmpty = () => {
    return !this.instructionEditor || this.instructionEditor.isEmpty()
  }

  speakInstruction = async (cancel) => {
    if (cancel) {
      return this.props.me.cancelSpeak()
    }
    const text = this.instructionEditor.getText()
    if (text) {
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.instructionLang.iso)
    }
  }

  isEditorEmpty = () => {
    return !this.editor || this.editor.isEmpty()
  }

  speakText = async (cancel) => {
    if (cancel) {
      return this.props.me.cancelSpeak()
    }
    const text = this.editor.getText()
    if (text) {
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.lang.iso)
    }
  }

  toggleInstructionSpeechInput = async () => {
    const instructionSpeechInputActive = !this.state.instructionSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      instructionSpeechInputActive,
      textFieldSpeechInputActive: false
    }, () => {
      if (instructionSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }

  toggleTextFieldSpeechInput = async () => {
    const textFieldSpeechInputActive = !this.state.textFieldSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      textFieldSpeechInputActive,
      instructionSpeechInputActive: false
    }, () => {
      if (textFieldSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }
  
  toggleVoiceRecognition = async e => {
    if (e) e.preventDefault()
    //////debugger
    this.state.voiceRecognitionActive = !this.state.voiceRecognitionActive
    if (!this.state.voiceRecognitionActive) {
      if (this.recognition) {
        this.recognition.stop()
        this.recognition = null
      }
      this.setState({
        instruction: null
      })
      if (this.sub3) {
        this.sub3.unsubscribe()
        this.sub3 = null
      }
      if (this.sub4) {
        this.sub4.unsubscribe()
        this.sub4 = null
      }
    } else {
      this.recognition = this.props.me.getVoiceRecognizer()
      this.sub3 = this.recognition.observeIsActive().subscribe(isActive => {
        this.state.voiceRecognitionActive = isActive
        this.forceUpdate()
      })
      this.sub4 = this.recognition.observeInstruction().subscribe(instruction => {
        this.receiveVoiceInput(instruction)
      })
      this.updateLang()
      this.recognition.start()
    }
    this.forceUpdate(this.updateLang)
  }

  goBack = async () =>  {
    if (this.props.cancel) {
      this.props.cancel()
    } else {
      //////console.log('cancel')
      this.send(true)
    }
  }

  cancel = async () => {
    this.cancelStreamEdit()
    if (this.props.isWritingAssistant) {
      this.editor.setText('')
      this.setState({
        edits: new Document(),
        instructions: new Document(),
        completions: [],
        variableToComplete: null,
      }, () => {
        this.buildNextInstruction()
      })
    } else {
      await this.goBack()
    }
  }

  getTextSurroundingCaret = () => {
    if (this.focusedText) {
      return this.focusedText
    }
    return {
      textBeforeCaret: '', textAfterCaret: this.getCurrentText()
    }
  }

  send = async (cancel) => {
    if (this.editor.focused) {
      this.focusedText = this.editor.getTextSurroundingCaret()
      this.editor.blur()
      return
    }
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    if (cancel) {
      this.props.cancelKeyboardOutput() 
    } else {
      const { textBeforeCaret, textAfterCaret } = this.getTextSurroundingCaret()
      this.props.sendKeyboardOutput({
        textBeforeCaret, textAfterCaret
      })
    }
    this.state = {edits: [], instructions: []}
    if (this.instructionEditor) {
      this.instructionEditor.clear()
    }
    if (this.editor) {
      this.editor.clear()
      this.editor.blur()
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    const insertNewline = () => {
      e.preventDefault()
      e.stopPropagation()
      document.execCommand('insertLineBreak')
    }
    if (isDesktop()) {
      ////debugLog("key: ", e.key);
      if (e.key === RETURN && !e.shiftKey) {
        insertNewline()
        this.send();
        return
      }
      const ESC = "Escape"
      if (e.key === ESC) {
        this.cancelEdit()
        return 
      }
    } 
    if (e.key === RETURN) {
      insertNewline()
    }
  }

  onPaste = e => {
    ////debugger
    if (e.clipboardData.files && e.clipboardData.files.length > 0) {
      if (this.handleDataTransfer(e, e.clipboardData)) {
        return;
      }
    }
  }
  
  onUpdate = e => {
  }

  onDrop = e => {
    //debugger
    //const transfer = e.dataTransfer;
    //this.handleDataTransfer(e, transfer);
  }

  canEdit = () => {
    return this.getCurrentText() && !this.state.editing
  }


  canUndo = () => {
    if (this.state.instructions.canUndo()) {
      return true
    }
    if (this.getCurrentDocument()) {
      return this.canUndoCurrentDocument()
    }
    if (this.instructionEditor && !this.instructionEditor.isEmpty()) {
      return true
    }
    return false
  }

  canRedo = () => {
    if (this.state.instructions.canRedo()) {
      return true
    }
    const i = this.getCurrentInstruction()
    if (i && i.undoOutput) {
      return true
    }
    const doc = this.getCurrentDocument()
    if (doc) {
      return doc.canRedo()
    }
    return false
  }

  onPointerDrag = e => {
  }

  canCommit = ()  =>  {
    return true
  }

  undoInstruction = () => {
    if (this.state.instructions.canUndo()) {
      this.state.instructions.undo()
      this.state.edits.undo()
      this.renderCurrentDocument()
    }
  }

  redoInstruction = () => {
    if (this.state.instructions.canRedo()) {
      this.state.instructions.redo()
      this.state.edits.redo()
      this.renderCurrentDocument()
    }
  }

  cancelStreamEdit = () => {
    const f = this.streamEditCancel
    this.setState({
      streaming: false
    })
    if (f) {
      f()
    }
  }

  cancelInstruction = async (instruction) => {
    ////debugger
    this.state.instructions.deleteCurrent()
    if (instruction.output) {
      this.state.edits.deleteCurrent()
    }
    this.buildNextInstruction(instruction.parent)
    this.forceUpdate()
  }

  streamEdit = async data => {
    this.instructionEditor.blur()
    const { applying, saving } = data
    //debugger
    data.output = null
    data.undoOutput = null
    data.serverError = null
    this.state.instructions.advance(data)
    const text = this.editor.getText()
    data.isWrite = data.isWrite || !text
    this.forceUpdate(this.showLastInstruction)
    const instructionText = this.instructionEditor.getText()
    const instruction = instructionText
    data.command = instruction
    data.wasWrite = !text
    data.applying = true
    data.streaming = true
    this.state.streaming = true
    this.state.searchTerm = ''
    this.forceUpdate()
    //////console.log("instruction", instruction)
    //////console.log("input", text)
    ////debugger
    let cancelled
    //this.advance(text)
    let output = ''
    await new Promise(async (resolve, reject) => {
      this.props.me.streamEdit(instruction, text, data.id, data.temperature, data.max_tokens, {
        onContent: text => {
          //debugger
          if (!cancelled) {
            output = text
            data.output = output
            this.forceUpdate()
          }
        },
        onDone: () => {
          this.streamEditCancel = null
          data.applying = false
          data.streaming = false
          this.state.streaming = false
          const newText = output
          data.code = parseCode(newText)
          if (!data.code) {
            data.template = tryParseTemplate(newText)
            if (!data.template) {
              data.table = parseTable(newText)
            }
          }
          this.advance(newText)
          this.instructionEditor.clear()
          this.buildNextInstruction()
          this.renderCurrentDocument()
          this.forceUpdate(() => {
            this.showLastInstruction()
          })
          resolve()
        },
        onError: (err) => {
          this.streamEditCancel = null
          console.error(err)
          data.applying = false
          data.streaming = false
          this.state.streaming = false
          data.serverError = {
            label: "Oof, sorry that didnt' work"
          }
          resolve()
        }
      }).then(xhr => {
        this.streamEditCancel = data.cancelAction = () => {
          cancelled = true
          data.applying = false
          data.streaming = false
          this.forceUpdate()
          try {
            if (xhr) xhr.abort()
            xhr = null
          } catch (err) {
            console.error(err)
          }
          reject()
        }
      })
    })
    const voiceInputActive = this.state.voiceRecognitionActive
    if (voiceInputActive) {
      this.startVoiceInput()
    }
  }

  streamEdit0ld = async data => {
    this.instructionEditor.blur()
    const { applying, saving } = data
    if (applying || saving) {
      return
    }
    ////debugger
    data.output = null
    data.undoOutput = null
    data.serverError = null
    this.state.instructions.advance(data)
    const text = this.editor.getText()
    data.isWrite = data.isWrite || !text
    this.forceUpdate(this.showLastInstruction)
    const instructionText = this.instructionEditor.getText()
    const instruction = instructionText
    data.command = instruction
    data.wasWrite = !text
    data.applying = true
    data.streaming = true
    this.state.streaming = true
    this.state.searchTerm = ''
    this.forceUpdate()
    //////console.log("instruction", instruction)
    //////console.log("input", text)
    ////debugger
    this.advance(text)
    const { cancel, subscribe } = this.props.me.observeEdit(instruction, text, data.id, data.temperature)
    data.cancelAction = cancel
    let cancelled = false
    this.streamEditCancel = () => {
      cancelled = true
      data.applying = false
      data.streaming = false
      cancel()
    }
    const sub = subscribe(edit => {
      ////console.log("EDIT", edit)
      const output = edit.output
      if (!cancelled) {
        data.output = output
      }
      this.forceUpdate()
      if (edit.done) {
        this.streamEditCancel = null
        this.state.streaming = false
        data.streaming = false
        data.applying = false
        sub.unsubscribe()
        if (!cancelled) {
          const newText = output
          data.code = parseCode(newText)
          if (!data.code) {
            data.template = tryParseTemplate(newText)
            if (!data.template) {
              data.table = parseTable(newText)
            }
          }
          this.advance(newText)
          this.instructionEditor.clear()
          this.buildNextInstruction()
          this.forceUpdate(() => {
            this.showLastInstruction()
          })
        }
      }
    })
  }

  applyInstruction = async data => {
    if (true || this.props.me.isAdmin) {
      return this.streamEdit(data)
    }
    this.instructionEditor.blur()
    const { applying, saving } = data
    if (applying || saving) {
      return
    }
    ////debugger
    data.output = undefined
    data.undoOutput = null
    data.serverError = null
    this.state.instructions.advance(data)
    const text = this.editor.getText()
    data.isWrite = data.isWrite || !text
    this.forceUpdate(this.showLastInstruction)
    try {
      const instructionText = this.instructionEditor.getText()
      const instruction = instructionText
      data.command = instruction
      data.wasWrite = !text
      data.applying = true
      this.state.searchTerm = ''
      this.forceUpdate()
      //////console.log("instruction", instruction)
      //////console.log("input", text)
      ////debugger
      this.advance(text)
      const {output, error}  = await this.props.me.edit(instruction, text, data.id, data.temperature)
      data.applying = false
      if (error) switch (error) {
        case 'not-enough-tokens':
        return this.notEnoughTokens(data)
        default:
        throw error
        return
      }
      const newText = output
      //////console.log("output", newText)
      if (data.canceled) {
        data.command = null
        data.canceled = false
        return
      }
      data.input = text
      data.output = newText
      data.code = parseCode(newText)
      if (!data.code) {
        data.template = tryParseTemplate(newText)
        if (!data.template) {
          data.table = parseTable(newText)
        }
      }
      this.advance(newText)
      this.instructionEditor.clear()
      this.buildNextInstruction()
      this.forceUpdate(this.showLastInstruction)
    } catch (err) {
      ////////debugger
      console.error(err)
      data.applying = false
      data.serverError = {
        label: "Oof, sorry that didnt' work"
      }
    }
  }

  selectTextInputLang = lang => {
    localStorage.setItem('keyboard.text.lang', JSON.stringify(lang))
    this.setState({
      lang
    }, this.updateLang)
  }

  updateLang = () => {
    if (this.state.instructionSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.instructionLang.iso)
      }
    }
    else if (this.state.textFieldSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.lang.iso)
      }
    } else {

    }
  }

  selectInstructionLang = instructionLang => {
    localStorage.setItem('keyboard.instruction.lang', JSON.stringify(instructionLang))
    this.setState({
      instructionLang
    }, this.updateLang)
  }
  
  addCommand = async (command, noFocus) => {
    ////debugger
    const apply = command.getApply()
    if (apply) {
      apply()
      return
    }
    const text = this.getCurrentText()
    this.clearError()
    let action = command.getAction()
    const inputTemplate = command.cloneTemplate()
    if (!inputTemplate && !text && !command.isWriting()) {
      this.instructionEditor.setText(action + ' ')
      this.state.searchTerm = ''
      this.autocompleteSeq++
      this.forceUpdate(!noFocus ? this.instructionEditor.focus : undefined)
      return
    }
    let applying = true
    const instruction = {
      command: action,
      inputTemplate,
      applying: true,
      key: getNextInstruction(),
      instruction: new Document(inputTemplate ? '' : action),
      id: command.getId(),
      isSaved: true,
      temperature: command.getTemperature(),
      max_tokens: command.getMaxTokens(),
      isSystem: command.isSystem(),
    }
    const prev = this.state.instructions.getCurrent()
    this.buildNextInstruction(instruction)
    this.state.instructions.advance(instruction)
    if (inputTemplate) {
      ////debugger
      instruction.parent = prev
      instruction.variableToComplete = inputTemplate.fragments.find(x => x.type === 'variable' && !inputTemplate.vars[x.variable])
      this.instructionEditor.setText('')
      this.state.searchTerm = ''
      this.autocompleteSeq++
      instruction.applying = false
      this.forceUpdate(() => {
        this.showAutocompletes()
        if (!noFocus) this.instructionEditor.focus()
        const variable = instruction.variableToComplete
        this.autocompleteVariable(variable)
      })
      return
    }
    const deleteIns = () => {
      instruction.command = null
      instruction.serverError = null
      this.forceUpdate()
    }
    if (!text && !command.isWriting()) {
      instruction.serverError = {
        label: "Can't edit an empty document",
        action: deleteIns
      }
      instruction.applying = false
      return
    }
    let voiceInputActive = this.state.voiceRecognitionActive
    this.stopVoiceInput()
    try {
      //////console.log("instruction", instruction)
      //////console.log("input", text)
      this.forceUpdate(this.showLastInstruction)
      if (true) {
        return await this.streamEdit(instruction)
      } else {
        const { output, error } = await this.props.me.edit(action, text, command.getId(), command.getTemperature())
        if (error) switch (error) {
          case 'not-enough-tokens':
          return this.notEnoughTokens(instruction)
          default:
          throw error
        }
        const newText = output
        //////console.log("output", newText)
        const edit = this.advance(newText)
        instruction.output = newText
        instruction.template = tryParseTemplate(newText)
        if (this.instructionEditor) {
          this.instructionEditor.clear()
          this.buildNextInstruction()
          instruction.applying = false
          this.renderCurrentDocument()
        }
      }
    } catch (err) {
      console.error(err)
      instruction.applying = false
      instruction.serverError = {
        label: "Oof, sorry that didn't work",
        action: deleteIns
      }
    }
    if (voiceInputActive) {
      this.startVoiceInput()
    }
    this.forceUpdate(this.showLastInstruction)
  }

  notEnoughTokens = instruction => {
    instruction.serverError = { label: "You're out of words" }
    this.buildNextInstruction()
    this.forceUpdate()
  }

  saveCommand = async data => {
    let { command, inputTemplate } = data
    if (inputTemplate) {
      command = inputTemplate.template
    }
    data.saving = true
    ////debugger
    this.forceUpdate()
    try {
      const { button } = await this.props.me.saveInstruction(command, data.isWrite ? 'create' : 'edit')
      if (button) {
        data.savedCategory = button.category
      }
    } catch (err) {
      console.error(err)
    }
    data.canSave = false
    data.saving = false
    this.forceUpdate()
  }

  findCommand = cmd => {
    if (!cmd) return
    cmd = cmd.toLowerCase()
    const find = commands => {
      const result = commands.find(x => {
        if (x.isMenu()) {
          const result = find(x.commands)
          if (result) {
            return result
          }
        } else {
          const test = x.getAction().toLowerCase()
          const result = test === cmd
          if (result) {
            return x
          }
        }
      })
      return result
    }
    return find(this.commands)
  }

  isCommandSaved = instruction => {
    return this.findCommand(instruction)
  }

  setAutocomplete = ref => {
    this.autocomplete = ref
    if (this.inputRef && ref) {
      ref.setInput(this.inputRef)
    }
  }

  showAutocompletes = () => {
    //this.autocomplete.resetMenu()
  }

  applyCompletion = async (completion) => {
    this.state.completions = []
    const edit = this.getCurrentEdit()
    let text = this.editor.getText()
    edit.advance(text)
    this.editor.insertTextAtCaret(completion + ' ')
    text = this.editor.getText()
    edit.advance(text)
    this.forceUpdate(this.onInput)
  }

  setInstructionFocus = instructionFocus => {
    let textInputFocus = this.state.textInputFocus
    if (instructionFocus) {
      textInputFocus = false
      this.editorHeight = 0;
      if (!isDesktop()) {
        this.state.instructionFocus = true
        this.reportEditorHeight()
      }
    } else {
      if (!isDesktop()) {
        this.state.instructionFocus = false
        this.reportEditorHeight()
      }
    }
    if (textInputFocus) {
      instructionFocus = false
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },this.updateLang)
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }

  setTextInputFocus = textInputFocus => {
    let instructionFocus = this.state.instructionFocus
    if (textInputFocus) {
      instructionFocus = false
    } else {
      this.onBlur()
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },() => {
      this.updateLang()
      if (this.editor.focused) {
        this.onInput()
      }
    })
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }

  renderInstructionInput = (data) => {
    const { input, output, command, instruction, inputTemplate, variableToComplete } = data
    const getTemp = () => {
      return data.temperature || 0
    }
    const setTemp = temp => {
      data.temperature = temp
      this.forceUpdate()
    }
    ////console.log('renderInstructionInput', data)
    const editor = this.instructionEditor
    let document = output
    let mode = data.mode || 'edit'
    let editClassName = "keyboardEditInstructionIcon"
    let editIcon
    let onClick
    let menuLabel = ''
    if (data.applying || data.saving) {
      editIcon = Spin
    }

    const current = this.state.instructions.getCurrent()
    let canApply = !(current && current.applying) && (this.state.editorCanApply || enableGeneration) 
    let next = []
    if (inputTemplate) {
      next = inputTemplate.fragments.filter(x => x.type === 'variable' && !inputTemplate.vars[x.variable])
    }
    if (inputTemplate) {
      canApply = (!data.applying && (next.length === 0 || variableToComplete))
    } else {
      canApply = editor && !editor.isEmpty()
    }
    
    const onFocus = (e) => {
      this.setInstructionFocus(true)
    }
    
    const onBlur = (e) => {
      const text = editor.getText()
      instruction.advance(text)
      this.setInstructionFocus(false)
    }
    
    const undo = async () => {
      if (instruction.canUndo()) {
        instruction.undo()
        const text = instruction.getCurrent() || ''
        editor.setText(text)
        if (!text) {
          if (inputTemplate && !data.variableToComplete) {
            data.variableToComplete = inputTemplate.fragments.find(x => x.type === 'variable')        
          }
        }
        this.forceUpdate()
      } 
    }
    
    const redo = async () => {
      if (instruction.canRedo()) {
        ////debugger
        instruction.redo()
        editor.setText(instruction.getCurrent() || '')
        this.forceUpdate()
      } 
    }
    
    const canUndo = () => {
      return instruction.canUndo()
    }
    
    const canRedo = () => {
      return instruction.canRedo()
    }
    
    const clear = async () => {
      editor.clear()
      if (variableToComplete) {
        inputTemplate.vars[variableToComplete.variable] = undefined
        variableToComplete.template = undefined
        const variable = variableToComplete
        if (inputTemplate.numVars > 1 && !variable.isSystem) {
          variable.options = []
          this.autocompleteVariable(variable)
        }
      } else if (inputTemplate) {
        data.variableToComplete = inputTemplate.fragments.find(x => x.type === 'variable')        
      }
      instruction.advance("")
      this.state.completions = []
      this.forceUpdate(this.onInstructionInput)      
    }

    const isEmpty = () => {
      if (variableToComplete) {
        if (inputTemplate.vars[variableToComplete.variable] !== undefined) {
          return false
        }
      }
      return editor ? editor.isEmpty() : true
    }

    const setVar = async (value, apply) => {
      const template = inputTemplate
      const variable = variableToComplete
      ////debugger
      if (value) {
        const t = tryParseTemplate(value)
        template.vars[variable.variable] = value
        variable.template = t
        ////console.log('setVar', variable.variable, value)
        const nextVariable = template.fragments.find(y => y.type == 'variable' && !template.vars[y.variable])
        if (!nextVariable) {
          const text = displayTemplate(template)
          editor.setText(text)
          if (false) this.props.me.fixGrammar(text).then(result => {
            if (text !== result) {
              editor.setText(result)
            }
          })
          const t = tryParseTemplate(text)
          data.variableToComplete = null
          if (t) {
            const key = getNextInstruction()
            const i = {
              inputTemplate: t,
              key,
              instruction: new Document(),
              id: key,
              temperature: 0,
              parent: data,
            }
            const variable = t.fragments.find(x => x.type == 'variable')
            i.variableToComplete = variable
            i.canSave = true
            if (!variable.options) {
              this.autocompleteVariable(variable)
            }
            editor.setText('')
            this.state.instructions.advance(i)
            this.buildNextInstruction(i)
            return
          }
          if (apply) {
            //debugger
            return this.applyInstruction(data)
          } 
        } else {
          editor.setText('')
          data.variableToComplete = nextVariable
          ////console.log('next variable: ', nextVariable)
          const variable = nextVariable
          this.autocompleteVariable(variable)
        }
      }
      this.forceUpdate()
    }
    let apply
    let cancel
    if (canApply) {
      apply = async ()  => {
        ////console.log('apply', data)
        if (variableToComplete) {
          const variable = variableToComplete
          const text = editor.getText()
          if (text) {
            setVar(text)
          } else if (inputTemplate.vars[variable.variable]) {
            clear()
          } else {
            this.autocompleteVariable(variable, data, true)
          }
        } else {
          const text = editor.getText()
          const template = tryParseTemplate(text, true)
          ////debugger
          if (template) {
            ////debugger
            if (!data.inputTemplate) {
              data.inputText = text
              data.inputTemplate = template
              const variable = template.fragments.find(x => x.type == 'variable')
              data.variableToComplete = variable
              data.completing = true
              data.canSave = true
              data.isWrite = !this.getCurrentText()
              editor.setText('')
              this.state.instructions.advance(data)
              this.autocompleteVariable(variable, data)
              this.forceUpdate()
              return
            } else {
              // handle recursive template
            }
          }
          if (inputTemplate) {
            const templateText = expandTemplate(inputTemplate)
            if (templateText !== text) {
              data.templateOverride = text
            } else {
              if (false) {
                this.forceUpdate()
                const result = await this.props.me.simplify(templateText)
                const { output } = result
                if (output) {
                  data.templateOverride = output
                }
              }
            }
          }
          if (data.inputTemplate) {
            if (this.instructionEditor.isEmpty()) {
              const text = displayTemplate(data.inputTemplate)
              this.instructionEditor.setText(text)
            }
          }
          this.applyInstruction(data)
        }
      }
      cancel = async () => {
        this.cancelInstruction(data)
      }
    }
    const isEdit = this.isEdit()
    let placeholder = variableToComplete ? "Enter " + variableToComplete.display :
        (isEdit ? "Enter an editing command" : "Enter a command")
    let disabled = false
    if (inputTemplate && !variableToComplete) {
      //placeholder = displayTemplate(inputTemplate)
      //disabled = true
    } 
    const i = this.state.instructions.getNext()
    if (i) {
      //placeholder = i.command
    }
    
    //////console.log("instructionCanApply", this.state.instructionCanApply, "canApply", canApply)
    let commands = isEdit ? this.commands : this.writingCommands
    let addCommand = this.addCommand
    let searchTerm = this.state.searchTerm
    let showAll = false
    if (variableToComplete) {
      commands = getCommands(variableToComplete)
      showAll = true
      addCommand = command => {
        setVar(command.getAction())
      }
    } else if (inputTemplate) {
      commands = []
    }
    const style = data.applying  ? { display: 'none' }: null
    return <div key='nextInstruction' className='keyboardInstructionInput' style={style}>
             <InputControl
               fadeTransition={true}
               getTemperature={getTemp}
               setTemperature={setTemp}
               isEmpty={isEmpty}
               me={this.props.me}
               placeholder={placeholder}
               disabled={disabled}
               busy={data.completing}
               onSetEditor={this.setInstructionEditor}
               downward={true}
               onClear={clear}
               speechInputActive={this.state.instructionSpeechInputActive}
               speechInputAction={this.toggleInstructionSpeechInput}
               selectSpeechInputLang={this.selectInstructionLang}
               selectedLang={this.state.instructionLang}
               label='Apply'
               icon={CheckMark}
               action={apply}
               cancel={cancel}
               undo={canUndo() && undo}
               redo={canRedo() && redo}
               speak={this.speakInstruction}
               onFocus={onFocus}
               onBlur={onBlur}
               onInput={this.onInstructionInput}
               menu={<KeyboardAutocomplete
                       intellikeyLink={isEdit ? this.intellikeyLinkEdit: this.intellikeyLinkWrite}
                       observeEditorHeight={this.observeEditorHeight}
                       searchTerm={searchTerm}
                       showAll={showAll}
                       presorted={variableToComplete}
                       busy={data.applying}
                       editorHeight={0} ref={this.setAutocomplete} addCommand={addCommand}
                       commands={this.state.textInputFocus ? [] : commands}/>}                   
             />
             </div>
  }

  renderVariableInput = (variableToComplete) => {
    const { template, variable } = variableToComplete
    const data = variableToComplete.instruction
    const { input, output, command, instruction } = data
    const editor = this.instructionEditor
    let document = output
    let mode = data.mode || 'edit'
    let editClassName = "keyboardEditInstructionIcon"
    let editIcon
    let onClick
    let menuLabel = ''
    if (data.applying || data.saving) {
      editIcon = Spin
    }

    const current = this.state.instructions.getCurrent()
    const canApply = editor.getText()
    
    const onFocus = (e) => {
      this.setInstructionFocus(true)
    }
    
    const onBlur = (e) => {
      this.setInstructionFocus(false)
    }
    
    const clear = async () => {
      editor.clear()
    }

    const applyVariable = value => {
      template.vars[variable.variable] = value.trim()
      this.renderCurrentDocumentFromTemplate(template)
      this.setState({
        variableToComplete: null
      })
      this.instructionEditor.setText('')
    }

    const addCommand = command => {
      applyVariable(command.getAction())
    }

    
    let apply
    let cancel
    apply = async ()  => {
      const text = editor.getText()
      editor.setText('')
      applyVariable(text)
      editor.blur()
    }
    cancel = async () => {
      editor.blur()
      this.setState({
        variableToComplete: null
      })
    }
    const commands = getCommands(variable)
    return <div key='nextInstruction' className='keyboardInstructionInput'>
             <InputControl
               busy={data.completing} 
               me={this.props.me}
               placeholder={'Enter ' +variable.display}
               onSetEditor={this.setInstructionEditor}
               downward={true}
               onClear={clear}
               speechInputActive={this.state.instructionSpeechInputActive}
               speechInputAction={this.toggleInstructionSpeechInput}
               selectSpeechInputLang={this.selectInstructionLang}
               selectedLang={this.state.instructionLang}
               label='Apply'
               icon={CheckMark}
               action={apply}
               cancel={cancel}
               speak={this.speakInstruction}
               onFocus={onFocus}
               onBlur={onBlur}
               onInput={this.onInstructionInput}
               menu={<KeyboardAutocomplete
                       observeEditorHeight={this.observeEditorHeight}
                       presorted={true}
                       showAll={true}
                       editorHeight={0} ref={this.setAutocomplete} addCommand={addCommand}
                       commands={commands}/>}                   
             />
             </div>
  }

  renderBlock = block => {
    const onPointerDown = (evt) => {
      const clientX = evt.clientX
      const clientY = evt.clientY
      const x = clientX
      const y = clientY
      const offset = getCharacterOffsetFromScreenCoordinates(evt.target, x, y)
      ////console.log("target", evt.target, "x", x, "y", y, "offset", offset)
      const elems = block.elements.filter(x => {
        return !(x.start > offset || x.end < offset)
      })
      const toString = elem => {
        return block.text.substring(elem.start,elem.end)
      }
      ////console.log(elems.map(toString))
    }
    let lastIndex = 0
    const  content = []
    const text = block.text
    block.elements.map(x => {
      if (x.start > lastIndex) {
        content.push(<span>{text.substring(lastIndex, x.start)}</span>)
        lastIndex = x.end
      }
      let e
      if (x.elements && x.elements.length > 0) {
        e = <BlockListElement select={this.selectBlock} selection={this.state.selectedBlock} element={x}/>
      } else {
        e = <BlockElement select={()=>this.selectBlock(x)} selected={this.state.selectedBlock === x} element={x}/>
      }
      content.push(e)
    })
                       
    return <div className='textBlock'>
             {content}
           </div>
  }

  selectBlock = e => {
    this.setState({
      selectedBlock: e
    })
  }

  renderInstruction = (data, i) => {
    const instructions = this.state.instructions
    let editClassName = "keyboardEditInstructionIcon"
    let onClick
    let menuLabel = ''
    let editIcon
    //////console.log('instruction', i, ':', data, 'isCurrent', instructions.isCurrent(i))
    let isCurrent = instructions.isCurrent(i)
    let { canSave, input, output, command, instruction, editor, applying, wasWrite, inputTemplate, templateOverride } = data
    if (templateOverride) {
      inputTemplate = null
      command = templateOverride
    }
    let stopIcon = data.streaming ? Stop : data.stopped ? Play : null
    let stopClassName = data.stopped ? 'iconPlay': 'iconStop'
    let cancelAction = data.cancelAction
    const stopAction = cancelAction
    let savedIcon = canSave ? Save : null
    let saveAction = canSave ? async () => this.saveCommand(data) :  async () => {}
    if (data.applying || data.completing) {
      savedIcon = null
    }
    const visible = inputTemplate || !data.undoOutput || data.applying
    const currentText = this.getCurrentText()
    if (data.wasWrite) {
      //savedIcon = null
    }
    const setRef = ref => {
      const scroll = !data.wasInView
      if (data.ref != ref) {
        data.ref = ref
        if (ref && scroll) {
          data.wasInView = true
          ref.scrollIntoView(true)
        }
      }
    }
    let renderedCommand = command
    if (inputTemplate) {
      renderedCommand = inputTemplate.template
    }
    return <div className='keyboardEdit' key={String(data.key)} ref={setRef} >
             <div key='instruction' className='keyboardEditInstruction' style={visible ? null : { display: 'none' }}>
               <div className='keyboardEditIconAndInstruction'>
                 <div className='keyboardEditInstructionLeftIcon'>
                   <ReactSVG src={UserSaid}/>
                 </div>
                  <div className='keyboardEditInstructionText'>
                    {inputTemplate ? output ? displayTemplate(inputTemplate) : renderTemplate(inputTemplate, data.variableToComplete, (variable, value) => {
                      if (!this.state.instructions.isCurrent(i)) {
                        return
                      }
                      if (variable === data.variableToComplete) {
                        if (value) {
                          this.instructionEditor.setText(value)
                        }
                        return
                      }
                      if (data.variableToComplete) {
                        const v = data.variableToComplete
                        const text = this.instructionEditor.getText()
                        if (text) {
                          inputTemplate.vars[v.variable] = text.trim()
                        }
                      }
                      const prev = data.variableToComplete
                      const prevValue = prev ? inputTemplate.vars[prev.variable] || prev.template : undefined
                      data.variableToComplete = variable
                      this.instructionEditor.setText('')
                      if (prevValue && !variable.options) {
                        variable.options = []
                        this.autocompleteVariable(variable, data)
                      }
                      this.forceUpdate(this.showAutocompletes)
                 }) : command}
                  </div>
                 {(savedIcon) && <div key='save' className={'keyboardEditInstructionMode'}>
                                 <KeyboardButton label='Save' icon={savedIcon} className='iconSave' action={saveAction}/>
                               </div>}
                 {(stopIcon) && <KeyboardButton1 icon={stopIcon} className={stopClassName} action={stopAction}/>}
                                 
                 {(data.applying && !stopIcon) && <div key='spin' className='keyboardEditInstructionSpin'>
                                                    <ReactSVG src={Spin}/>
                                     </div>
                 }
               </div>
             </div>
             {data.savedCategory &&<div key='savedCategory' className='keyboardEditDocument'>
                                     <div className='keyboardEditInstructionLeftIcon'>
                                       <ReactSVG src={AISaid}/>
                                     </div>
                                     <div className='keyboardEditDocumentText'>
                                       "{renderedCommand}" saved in "{data.savedCategory}"
                                     </div>
                                     <div className='keyboardEditInstructionLeftIcon aiEditIcon'>
                                     </div>
                                   </div>}
             {(data.applying || output) &&
               instructions.isCurrent(i) && <div key={data.key + '-output'} className='keyboardEditDocument'>
                           <div className='keyboardEditInstructionLeftIcon'>
                             <ReactSVG src={AISaid}/>
                           </div>
                           <div className={'keyboardEditDocumentText' + (data.streaming ? ' keyboardEditDocumentTextStreaming' : '' )}>
                             {
                               output && this.renderOutput(data)
                             }
                           </div>
                           <div className='keyboardEditInstructionLeftIcon'>
                             {data.streaming && <ReactSVG src={Spin}/>}
                           </div>
                         </div>
               }
             {data.serverError && <Oof error={{message: data.serverError.label}} action={data.serverError.action}/>}

           </div>
  }

  renderOutput = data => {
    ////console.log("renderOutput", data)
    if (data.input === data.output) {
      return "No changes"
    }
    if (data.template) {
      return renderTemplate(data.template, this.state.variableToComplete, (variable, value) => {
        this.state.variableToComplete = {
          instruction: data,
          template: data.template,
          variable
        }
        if (!variable.options) {
          variable.options = []
          this.autocompleteVariable(variable, data, true)
        }
        ////debugger
        this.forceUpdate(() => {
          if (value) {
            this.instructionEditor.setText(value)
          } else {
            this.instructionEditor.setText('')
          }
          if (!variable.options) {
            //this.instructionEditor.focus()
          }
          this.showAutocompletes()
        })        
      })
    }
    if (data.table) {
      return renderTable(data.table, () => {
        this.editor.setText(renderTableMarkdown(data.table))
        this.forceUpdate()
      }, () => {
        const text = renderTableMarkdown(data.table)
        this.getCurrentEdit().advance(text)
        this.editor.setText(text)
        this.forceUpdate()
      })
    }
    if (data.code) {
      return renderCode(data.code, this.copyToClipboard)
    }
    return data.output
  }

  renderMarkdownOutput = data => {
    if (data.code) {
      return renderCode(data.code, this.copyToClipboard)
    } else if (data.table) {
      return renderTable(data.table)
    } 
    return data.output
  }


  scrollOutputToBottom = data => {
  }

  showLastInstruction = () => {
    ////debugger
    const i = this.getCurrentInstruction()
    if (i && i.ref) {
      i.ref.scrollIntoView(true)
      return
    }
    if (this.instructionList) {
      this.instructionList.scrollTop = this.instructionList.scrollHeight
    }
  }

  setInstructionList = ref => {
    this.instructionList = ref
  }

  setInputRef = ref => {
    if (ref != this.inputRef) {
      this.inputRef = ref
      if (ref) ref.observeEditorHeight().subscribe(height => {
        this.editorHeight = height
        //this.reportEditorHeight()
      })
    }
  }

  getCurrentEditorHeight = () => {
    let height = this.editorHeight
    if (this.state.instructionFocus) {
      if (!isDesktop()) {
        height = 0
      } 
    } else if (this.state.textInputFocus) {
      if (!isDesktop()) {
      }
    } else {
      if (!isDesktop()) {
        height += 30
      }
    }
    return height
  }
  
  reportEditorHeight = () => {
    const height = this.getCurrentEditorHeight()
    this.editorHeightSubject.next(height)
  }
  editorHeight = 164 - 40
  editorHeightSubject = new Subject()
  observeEditorHeight = () => {
    return concat(of(this.getCurrentEditorHeight()), this.editorHeightSubject)
  }

  render() {
    let inputStyle
    let sendLabel = "Commit"
    let placeholder
    if (this.props.placeholder) {
      placeholder = this.props.placeholder
    } else {
      placeholder = ""
    }
    if (this.state.editing) {
      inputStyle = {
        bottom: 0
      }
    }
    if (this.state.instructionFocus && !isDesktop()) {
      if (!inputStyle) {
        inputStyle = {}
      }
      inputStyle.display = 'none'
    }
    const join = xs => xs.join("&nbsp")
    const instructions = this.state.instructions
    const self = this
    let blurb
    let instructionBlurb


    let buttonLabel
    let buttonAction
    let buttonIcon
    let copyAction = this.copy
    let shareAction = this.share
    if (this.props.isWritingAssistant) {
      if (isDesktop()) {
        buttonLabel = 'Copy'
        buttonIcon = Copy
        buttonAction = this.copy
        copyAction = null
        shareAction= null
      } else {
        shareAction = null
        if (this.editor && this.editor.focused) {
          buttonLabel = 'Done'
          buttonAction = this.editor.blur
          buttonIcon = Send
        } else {
          buttonLabel = 'Share'
          buttonIcon = Share
          buttonAction = this.share
        }          
      }
    } else {
      if (isDesktop()) {
        buttonLabel = 'Copy'
        buttonIcon = Copy
        buttonAction = this.copy
        shareAction = null
        copyAction = null
      } else {
        buttonLabel = 'Done'
        buttonIcon = Send
        buttonAction = this.send
      }
      if (!this.getCurrentText()) {
        shareAction = null
        copyAction = null
      }
    }
    let instructionListStyle
    if (this.state.instructionFocus ||
        this.state.instructionSpeechInputActive) {
      instructionListStyle = {
        transform: 'translate(0, -40px)'
      }
    }

    if (this.editor && instructions.isEmpty()) {
      blurb = <div>Select a {this.editor.isEmpty() ? 'writing' : 'editing'} command from the menu, or enter your own. You can create your own templates by surrounding inputs in square brackets.</div>
    }

    let canEdit = true
    if (this.instructionEditor && !this.instructionEditor.isEmpty()) {
      canEdit = false
    } else if (this.state.nextInstruction && this.state.nextInstruction.variableToComplete) {
      canEdit = false
    }
    
    return <div className={'keyboardWindow' + (this.isKeyboardShowing() ? ' keyboardWindowInactive' : "")}>
             <div key='keyboard' className='keyboard'>
               <div key='instructionContainer' className='keyboardOriginalContainer'>
                 <div className='keyboardHeader' style={!isDesktop() &&this.state.orient === 'landscape' ? { display: 'none' } : null}>
                   <KeyboardButton1 icon={Left} action={this.goBack}/>
                   <KeyboardTitle/>
                   <div className='keyboardHeaderButton keyboardHeaderButtonCancel' onMouseDown={this.cancel}>
                     <ReactSVG src={Cross}/>
                   </div>
                 </div>
                 <div className={'keyboardInstructionListContainer' + (instructions.length > 0 ? ' keyboardInstructionListContainerNonEmpty' : '')}>
                   <div ref={this.setInstructionList} className={'keyboardInstructionList' + (this.state.streaming ? ' keyboardInstructionListStreaming' : '')} style={instructionListStyle}>
                     {blurb && <div className='keyboardEditDocument aiComment'>
                                 <div className='keyboardEditInstructionLeftIcon'>
                                   <ReactSVG src={AISaid}/>
                                 </div>
                                 <div className='keyboardEditDocumentText'>
                                   {blurb}
                                 </div>
                               </div>}
                   {instructions.map((data, i) => {
                     return this.renderInstruction(data,i)
                   })
                 }
                 </div>
                 </div>
                 {this.state.variableToComplete &&
                  this.renderVariableInput(this.state.variableToComplete)
                 }
                 {this.state.nextInstruction && !this.state.variableToComplete &&
                  this.renderInstructionInput(this.state.nextInstruction)}
               </div>
             <div key='inputFieldContainer' className={'keyboardInputContainer' + (this.canCommit() ? ' keyboardInputContainerCanCommit' : '')} style={inputStyle}>
               <div  key='textFieldInput' className='keyboardInputMessageEditorRow'>
                 <InputControl
                   onCreate={this.setInputRef}
                   placeholder={canEdit ? "Enter text you'd like to edit here": ''}
                   me={this.props.me}
                   onSetEditor={this.setEditor}
                   onClear={this.clearEditor}
                   speechInputActive={this.state.textFieldSpeechInputActive}
                   speechInputAction={this.toggleTextFieldSpeechInput}
                   selectSpeechInputLang={this.selectTextInputLang}
                   selectedLang={this.state.lang}
                   autodetect={this.autodetectLang}
                   label={buttonLabel}
                   icon={buttonIcon}
                   action={buttonAction}
                   copy={copyAction}
                   share={shareAction}
                   cancel={undefined}
                   undo={!this.isKeyboardShowing() && this.canUndo() && this.undoEdit}
                   redo={!this.isKeyboardShowing() && this.canRedo() && this.redoEdit}
                   speak={this.speakText}
                   onBlur={() => {
                     this.setTextInputFocus(false)
                   }}
                   onFocus={() => {
                     this.setTextInputFocus(true)
                   }}
                   onInput={this.onInput}
                   applyCompletion={this.applyCompletion}
                   completions={this.state.completions}
                 />
                 
              </div>
             </div>
             </div>
           </div>
  }
}

