import React, { Component } from 'react'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { SimpleButton, DeleteButton, getComponents } from '../ChatGPT2'
import { Markdown} from '../ChatGPT2/Markdown.js'
import Plus from '../../assets/Icons/Plus.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Trash from '../../assets/Icons/Trash.svg'
import ApiVendorList from './ApiVendorList.png'
import './ApiHighlights.css'

export class ApiHighlights extends UComponent {

  componentDidMount() {
    this.components = getComponents()
  }
  
  render() {
    let blurb = `
## Attunewise API Highlights
Get access to all models through a single API endpoint.

- Our API surfaces an extensive list of models.
- Tool use automatically supported for every model.
- Fine-tune OpenAI, Gemini, Meta, and Mistral models.
- Or connect your own models and seamlessly use them with our tools.
`
    return <div className ='apiHighlights'>
             <div className='apiHighlightsBlurb'>
               <Markdown components={this.components}>{blurb}</Markdown>
             </div>
             <div className='apiHighlightsImgContainer'>
               <img src={ApiVendorList}/>
             </div>
           </div>
  }
}
