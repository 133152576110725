import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { Home } from '../Home'
import { Keyboard } from '../Keyboard'
import { GetStarted, KeyboardLogin } from '../KeyboardLogin'
import { ChatGPT } from '../ChatGPT2'
import { KeyboardSettings } from '../KeyboardSettings'
import Logo from '../../assets/Icons/KB3DLogo.png'
import VerticalDots from '../../assets/Icons/MenuUp.svg'
import LargeLogo from '../../assets/Icons/Search/SL_AW_Logo_216_38.svg'
import AssistantIcon from '../../assets/Icons/AssistantICON.png'
import Login from '../../assets/Icons/Signin.svg'
import { SimpleButton } from '../ChatGPT2'
import { SearchGPT } from '../Home/SearchGPT.js'
import phone from 'phone';
import './index.css'
import './SearchGPT.css'
const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))

export const AttunewiseHeader = props => {
  return <div className='attunewiseHeader'>
           <div className='attunewiseHeaderBottomRow'>
             <div className='attunewiseHeaderBottomRowLeft'>
               <div className='attunewiseLargeLogo'>
               <ReactSVG src={LargeLogo}/>
               </div>
             </div>
             <div className='attunewiseHeaderBottomRowRight'>
               <SimpleButton icon={VerticalDots} action={props.action}/>
             </div>
           </div>
         </div>
}

const Splash = props => {
}

const ShowKeyboardLogin = props => {
  return <div key='keyboardLogin' className='keyboardLoginInProgressPage' style={props.visible ? null: {display: 'none'}}>
           <GetStarted text='Logging In' icon={Login}/>
           </div>
}

export class BnApp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      showKeyboardSettings: false,
      hasFullAccess: false,
      isInstalled: false,
      keyboardLoginInProgress: false,
      showSplash: false,
      initializing: !isDesktop(),
      settings: {
        autoCorrection: true,
        hapticFeedback: false,
        audioFeedback: false
      }
    }
    const testSettings = false
    if (testSettings) {
      this.state.showKeyboardSettings = true
      this.state.hasFullAccess = false
      this.state.isInstalled = true
    }
  }

  bg = () => {
    clearTimeout(this.splashTimer)
    this.setState({
      showSplash: true
    })
  }

  fg = () => {
    this.setState({
      showSplash: false
    })
  }

  transition = () => {
    clearTimeout(this.splashTimer)
    this.splashTimer = setTimeout(this.fg, 1000)
  }

  componentDidMount() {
    if (!isDesktop()) {
      //window.addEventListener("focus", this.transition)
      //window.addEventListener("blur", this.bg)
      window.addEventListener("resize", () => {
        this.forceUpdate()
      })
      setTimeout(() => {
        this.setState({
          initializing: false
        })
      }, 1400)
    }
    if (isDesktop) {
      const preventNavigation = (event) => {
        event.preventDefault();
      }
      window.addEventListener('dragover', preventNavigation);
      window.addEventListener('drop', preventNavigation);
    }
    this.sub1 = this.props.me.observeSelf().subscribe(self => {
      if (self) {
        this.setState({
          isLoggedIn: true
        })
      } else {
        this.setState({
          isLoggedIn: false,
          keyboardLoginInProgress: false
        })
      }
    })
    let seq = 0
  }

  componentWillUnmount() {
    if (this.wordSub) {
      this.wordSub.unsubscribe()
    }
  }

  sendMessage = text => {
    this.props.me.sendKeyboardOutput(text)
  }

  onNotEnoughTokens = () => {
    this.setState({
      notEnoughTokens: true
    })
  }

  setHome = home => {
    this.home = home
  }

  getSetting = (key) => {
    return this.state.settings[key]
  }

  toggleSetting = (key) => {
    this.state.settings[key] = !this.state.settings[key]
    this.props.me.saveSettings(this.state.settings)
    this.forceUpdate()
  }

  renderSearchGPT = () => {
    return <SearchGPT onCreate={this.setHome}
                      getSetting={this.getSetting}
                      toggleSetting={this.toggleSetting}
                      notEnoughTokens={this.state.notEnoughTokens} me={this.props.me}/>
  }

  renderHome = () => {
    return <Home onCreate={this.setHome}
                 getSetting={this.getSetting}
                 toggleSetting={this.toggleSetting}
                 notEnoughTokens={this.state.notEnoughTokens} me={this.props.me}/>
  }
    
  
  renderContent() {
    let content = this.props.me.isSearchGPT() ? this.renderSearchGPT() : this.renderHome()
    let style
    return <div className='keyboardApp'>
             <div className='keyboardAppContent' style={style}>
               {content}
             </div>
             <Splash me={this.props.me} visible={this.state.showSplash}/>
           </div>
  }

  render() {
    const showMenu = async () => {
    }
    let className='bnAppContent'
    if (this.props.me.isSearchGPT()) {
      return this.renderSearchGPT()
    }
    return <div className={className}>
             {this.renderApp()}
             </div>
  }

  onLogin = (isSignUp) => {
    debugger
    this.setState({
      loginInProgress: false
    })
  }

  loginInProgress = () => {
    debugger
    this.setState({
      loginInProgress: true
    })
  }

  renderApp() {
    let hiddenHost = 'attunewise.ai'
    console.log('host', window.location.host)
    if (window.location.host === hiddenHost) {
      return <Splash visible={true}/>
    }
    return this.renderContent()
  }
}
