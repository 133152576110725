import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CanvasEditorTabs.css';

/**
 * CanvasEditorTabs component - Renders a tabbed interface for managing multiple files
 */
class CanvasEditorTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activePath || null,
      dropdownOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    // If active path changed externally
    if (prevProps.activePath !== this.props.activePath && this.props.activePath !== this.state.activeTab) {
      this.setState({ activeTab: this.props.activePath });
    }

    // If the file paths have changed and our active tab no longer exists
    if (prevProps.filePaths !== this.props.filePaths) {
      const { filePaths } = this.props;
      const { activeTab } = this.state;
      
      // If active tab is not in file paths, set to first file path
      if (activeTab && !filePaths.includes(activeTab) && filePaths.length > 0) {
        this.setActiveTab(filePaths[0]);
      } else if (!activeTab && filePaths.length > 0) {
        this.setActiveTab(filePaths[0]);
      }
    }
  }

  /**
   * Set the active tab and notify parent component
   * @param {string} path - Path to set active
   */
  setActiveTab = (path) => {
    this.setState({ activeTab: path });
    
    if (this.props.onTabChange) {
      this.props.onTabChange(path);
    }
  };

  /**
   * Close a tab
   * @param {string} path - Path to close
   * @param {Event} e - Event object
   */
  closeTab = (path, e) => {
    e.stopPropagation(); // Prevent triggering tab change
    
    const { filePaths, onTabClose } = this.props;
    const { activeTab } = this.state;
    
    // Call parent close handler
    if (onTabClose) {
      onTabClose(path);
    }
    
    // If we're closing the active tab, switch to another tab
    if (path === activeTab) {
      const index = filePaths.indexOf(path);
      
      // Try to select the tab to the right, or the tab to the left if it's the last tab
      const nextPath = filePaths[index + 1] || filePaths[index - 1];
      
      if (nextPath) {
        this.setActiveTab(nextPath);
      } else {
        this.setState({ activeTab: null });
      }
    }
  };

  /**
   * Toggle file dropdown
   */
  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  /**
   * Handle file selection from dropdown
   * @param {string} path - Selected file path
   */
  handleFileSelect = (path) => {
    // Close dropdown
    this.setState({ dropdownOpen: false });
    
    // Open file
    if (this.props.onFileOpen) {
      this.props.onFileOpen(path);
    }
  };

  /**
   * Get the file name from a path
   * @param {string} path - Full path
   * @returns {string} - File name
   */
  getFileName = (path) => {
    if (!path) return '';
    return path.split('/').pop();
  };


  render() {
    const { filePaths, allFilePaths } = this.props;
    const { activeTab, dropdownOpen } = this.state;
    
    // Get files that aren't currently open
    const closedFiles = allFilePaths?.filter(path => !filePaths.includes(path)) || [];
    
    return (
      <div className="canvas-editor-tabs">
        <div className="tab-list">
          {filePaths.map(path => (
            <div 
              key={path}
              className={`tab ${activeTab === path ? 'active' : ''}`}
              onClick={() => this.setActiveTab(path)}
            >
              <div className="tab-name">{this.getFileName(path)}</div>
            </div>
          ))}
          
          {closedFiles.length > 0 && (
            <div className="file-selector">
              <div className="file-dropdown-button" onClick={this.toggleDropdown}>
                +
              </div>
              {dropdownOpen && (
                <div className="file-dropdown">
                  {closedFiles.map(path => (
                    <div 
                      key={path}
                      className="file-dropdown-item"
                      onClick={() => this.handleFileSelect(path)}
                    >
                      <div className={`tab-icon ${this.getFileIcon(path)}`}></div>
                      <div className="file-name">{this.getFileName(path)}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CanvasEditorTabs.propTypes = {
  filePaths: PropTypes.array.isRequired,
  activePath: PropTypes.string,
  onTabChange: PropTypes.func,
  onTabClose: PropTypes.func,
  allFilePaths: PropTypes.array,
  onFileOpen: PropTypes.func
};

CanvasEditorTabs.defaultProps = {
  filePaths: [],
  allFilePaths: []
};

export default CanvasEditorTabs;
